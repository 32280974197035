import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	padding: 4em 0em 8em 0em;
	//height: 100vh;

	h1 {
		font-family: "Manrope";
		font-style: normal;
		font-weight: bold;
		font-size: 3.5em;
		line-height: 68px;
		text-align: center;

		color: #1c1f26;
		margin-bottom: 2em;
	}
	.social {
		display: flex;
		margin-left: 20px;
		cursor: pointer;
		/* margin-top: 1em; */
	}

	.social img {
		margin-left: 10px;
		cursor: pointer;
		min-width: 45px;
		max-width: 65px;
	}

	.divmap {
		position: relative;
		display: flex;
		justify-content: center;
		width: 100%;
		
		.mapStyle {
			width: 100%;

			iframe {
				border: none;
			}
		}
	}
	.divmap .infos {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 1.5em 2.6em;

		position: absolute;
		bottom: -2.5em;

		background: #ffffff;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
		border-radius: 24px;

		font-family: "Manrope";
		font-style: normal;
		font-size: 1em;
		line-height: 24px;

		animation: shakeX;
		animation-duration: 2s;

		color: #1c1f26;
	}

	@media (max-width: 1280px) {
		h1 {
			font-size: 40px;
		}

		.divmap .infos {
			display: flex;
			flex-direction: column;
			padding: 1em, 2em;
			margin: 0px 30px;
			bottom: -8em;
		}
	}

	@media (max-width: 1024px) {
		h1 {
			font-size: 40px;
		}
		.divmap .infos {
			gap: 20px;
		}
        margin: 0em 0em 3em 0em;
	}
`;

export { Container };
