import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    width: 85%;
    max-height: 23.6em;
    height: 23.6em;
    
    padding: 1.5em 1em 1.5em 0em;

    background: #FFFFFF;
    border-radius: 17px;


    .day{
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        width: 25%;
        
    
        p1{
            font-family: 'Manrope';
            font-weight: bold;
            font-size: 2.5em;
            line-height: 55px;
            text-align: center;

            color: #333131;
        }
    }
    .events{
        width: 80%;
        
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: baseline;
        align-content: flex-start;

        flex-wrap: wrap;
        overflow-x: auto;

        /* Chrome, Edge, and Safari */
        ::-webkit-scrollbar {
          width: 11px;
        }

        ::-webkit-scrollbar-track {
          background: #ffffff;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #cecece;
          border-radius: 12px;
          border: 1px solid #ffffff;
        }
    }

    

@media (max-width: 1280px){
    display: flex;
    height: 50em;
    flex-direction: column;
    justify-content: flex-start;

    align-items: center;
    
    max-height: 180em;
    margin-bottom: 4em;

    .events {
      justify-content: center;
      height: auto;
    }

}
`


export {Container}