import { Container } from "./styled";
import MenuTop from "../../../components/commum/menuTop";
import { API_URL } from "../../../service/config";
import { fadeInLeft, fadeInRight } from 'react-animations';
import styled, { keyframes } from "styled-components";

export default function Index(props){
    
    const info = props.info;
    const titles = props.titles;
  
    const fadeInAnimation = keyframes`${fadeInLeft}`;
 
    const FadeInDiv = styled.div`
        animation: 1s ${fadeInAnimation};
    `;

    const FadeInRightAnimation = keyframes`${fadeInRight}`;
    
    const FadeInRightInDiv = styled.div`
        animation: 1s ${FadeInRightAnimation};
    `;
    console.log(info.descricao);

    return(
        <Container bg={`${API_URL}/website/busca?imagem=${info.imagens.capa.replace(/\\/g, '/')}`}>
            <MenuTop info={titles} color="white" imagem="/assets/images/logo-dark.svg"/>
            <div className="infos">
                <FadeInDiv>
                    <div className="title">
                        {info.nome}
                    </div>
                </FadeInDiv>
                <FadeInRightInDiv>
                  <div className="subTitle">
                      {info.descricao}
                  </div>
                </FadeInRightInDiv>
            </div>
        </Container>
    )
}