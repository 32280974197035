import { Container, Photo } from "./styled";

export default function Guidance(props) {
    return (
        <Container>
            <div className="crate">
                <Photo photo={props.photo} />
                <div className="adap">
                    <div className="name"> {props.name} </div>
                    <div className="schedule">
                        {props.schedule.split(';').map(item => 
                            <div> {item} </div>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
}