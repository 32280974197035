import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8em 2em;

	.react-multi-carousel-list {
		align-items: flex-start;
		padding-top: 3em;
	}

	.box1 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 72vh;

		.caption {
			font-family: "Manrope";
			font-size: 1em;
		}
		.duo {
			display: flex;
			flex-direction: column;
			align-items: center;
			.title {
				font-size: 3em;
				font-weight: 900;
			}
		}

		.title {
			font-family: "Manrope";
			font-size: 2.2em;
		}

		.BoxCaroucel {
			width: 100%;
			height: 40em;
			
			.custom-dot {
				width: 19px;
				height: 19px;

				border-radius: 20px;
				background: #dadada;

				border: solid 0px;
				margin-left: 5px;
			}
			.custom-dot--active {
				background: #160e4a;
				border-radius: 9.5px;
				width: 19px;
				height: 19px;
			}
		}
		.itemCaroucel {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		@media (max-width: 1024px) {
			.duo {
				.title {
					font-size: 40px;
				}
			}
		}
	}

	@media (max-width: 1024px) {
		.box1 {
			height: auto;
		}
		.duo {
			text-align: center;
		}
	}
`;

export { Container };
