import axios from "axios";
import { API_URL } from "../config";
const api = new axios.create({
  baseURL: `${API_URL}/admin`,
  headers: {
    'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYnJ1bm9kZW9saXZlaXJhLjIyLjEwQGdtYWlsLmNvbSIsImlhdCI6MTY0MzgzOTk0N30.oBFi1LnfeJ5h2ZCEAhC44HD5V0uyqolgg43J-HtI8Oo",
    'Content-Type': 'application/json'
  }
});


export default class Api {
  async listActivity(id) {
    let r = await api.get(`/atividades/casa/${id}`);
    return r.data;
  }

  async InfoActivity(id) {
    let r = await api.get(`/atividades/${id}`);
    return r.data;
  }

  async getInfo(id) {
    let r = await api.get(`/atividades/info/${id}`);
    return r.data;
  }

  async postInfo(idCasa, nome, resumo, descricao, ordem, icone, capa) {
    let formData = new FormData();

    formData.append("nome", nome);
    formData.append("resumo", resumo);
    formData.append("descricao", descricao);
    formData.append("ordem", ordem);
    formData.append("imagens", icone);
    formData.append("imagens", capa);

    let r = await api.post(`/atividades/informacao/${idCasa}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return r.data;
  }

  async putInfoIcone(idCasa, nome, resumo, descricao, ordem, icone, capa) {
    let formData = new FormData();

    formData.append("nome", nome);
    formData.append("resumo", resumo);
    formData.append("descricao", descricao);
    formData.append("ordem", ordem);
    formData.append("icone", icone);
    formData.append("capa", capa);

    let r = await api.put(`/atividades/informacao/Icone/${idCasa}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return r.data;
  }
  
  async putInfoCapa(idCasa, nome, resumo, descricao, ordem, icone, capa) {
    let formData = new FormData();

    formData.append("nome", nome);
    formData.append("resumo", resumo);
    formData.append("descricao", descricao);
    formData.append("ordem", ordem);
    formData.append("icone", icone);
    formData.append("capa", capa);

    let r = await api.put(`/atividades/informacao/Capa/${idCasa}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return r.data;
  }
  
  async putInfoImagens(idCasa, nome, resumo, descricao, ordem, icone, capa) {
    let formData = new FormData();

    formData.append("nome", nome);
    formData.append("resumo", resumo);
    formData.append("descricao", descricao);
    formData.append("ordem", ordem);
    formData.append("imagens", icone);
    formData.append("imagens", capa);

    let r = await api.put(`/atividades/informacao/imagens/${idCasa}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return r.data;
  }

  async putInfo(idCasa, nome, resumo, descricao, ordem, icone, capa) {
    let r = await api.put(`/atividades/informacao/${idCasa}`, {
      nome,
      resumo,
      descricao,
      ordem,
      icone,
      capa
    });
    return r.data;
  }

  async deleteActivity(id) {
    let r = await api.delete(`/atividades/${id}`);
    return r.data;
  }

  async updateInfoMore(id, historia, tituloH, fazemos, tituloF, proposta, tituloP) {
    let formData = new FormData();

    formData.append("historia", historia);
    formData.append("proposta", proposta);
    formData.append("fazemos", fazemos);

    let r = await api.put(`/atividades/informacao/mais/${id}`, {
      historia, tituloH, fazemos, tituloF, proposta, tituloP
    });
    return r.data;
  }

  async updateImgSessoes(id, imagem, ordem) {
    if (imagem != null) {
      let formData = new FormData();
      formData.append("imagem", imagem);
      formData.append("ordem", ordem);

      let r = await api.put(
        `/atividades/${id}/informacao/mais/imagem`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return r.data;
    }
    else {
      let r = await api.delete(
        `/atividades/${id}/informacao/mais/imagem`,
        { data: { ordem: ordem } }
      );
      return r.data;
    }
  }

  async getInfoMore(id) {
    let r = await api.get(`/atividades/informacao/mais/${id}`);
    return r.data;
  }

  async getContato(id) {
    let r = await api.get(`/atividades/contato/${id}`);
    return r.data;
  }

  async putContact(
    id,
    titulo,
    desc,
    endereco,
    telefone1,
    telefone2,
    lat,
    lng,
    email,
    LinkTitulo,
    url
  ) {
    let r = await api.put(`/atividades/contato/${id}`, {
      titulo,
      desc,
      endereco,
      telefone1,
      telefone2,
      lat,
      lng,
      email,
      LinkTitulo,
      url,
    });
    return r.data;
  }

  async listSNetwork(id) {
    let r = await api.get(`atividades/redesocial/${id}`);
    return r.data;
  }

  async newSNetwork(nomeRede, urlRede, imagemRede, id) {
    let formData = new FormData();
    formData.append("nome", nomeRede);
    formData.append("url", urlRede);
    formData.append("imagem", imagemRede);

    let r = await api.post(`atividades/${id}/redesocial`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return r.data;
  }

  async alterSNetworkPhoto(id, nomeRede, urlRede, imagemRede) {
    let formData = new FormData();
    formData.append("nome", nomeRede);
    formData.append("url", urlRede);
    formData.append("imagem", imagemRede);

    let r = await api.put(`atividades/redesocialFotos/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return r.data;
  }

  async alterSNetwork(id, nome, url, imagem) {
    let r = await api.put(`atividades/redesocial/${id}`, { nome, url, imagem });
    return r.data;
  }

  async deleteSNetwork(id, imagem) {
    let r = await api.put(`atividades/${id}/redesocial/`, { imagem });
    return r.request.statusText;
  }

  async listOrientador(id) {
    let r = await api.get(`/atividades/${id}/participante`);
    return r.data;
  }

  async newOrientador(nome, imageme, id) {
    let formData = new FormData();
    formData.append("nome", nome);
    formData.append("imagem", imageme);

    let r = await api.post(`/atividades/${id}/participante`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return r.data;
  }

  async updateOrientadorPhoto(idParticipante, nome, imagem) {
    let formData = new FormData();
    formData.append("nome", nome);
    formData.append("imagem", imagem);

    let r = await api.put(
      `/atividades/participanteFotos/${idParticipante}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return r.data;
  }

  async updateOrientador(idParticipante, nome, imagem) {
    let r = await api.put(`/atividades/participante/${idParticipante}`, {
      nome,
      imagem,
    });
    return r.data;
  }

  async deleteOrientador(id, imagem) {
    let r = await api.put(`/atividades/${id}/participante`, { imagem });
    return r.request.statusText;
  }




  async listCalendar(idAtividade){
    let r = await api.get(`/atividades/${idAtividade}/calendario`)
    return r.data
  }
  
  async postDay(idAtividade, atividade, dia){
    let r = await api.post(`/atividades/${idAtividade}/calendario`, {atividade, dia})
    return r.data
  }

  async deleteDay(idAtividade, idCalendario){
    let r = await api.delete(`/atividades/${idAtividade}/calendario/${idCalendario}`)
    return r.data
  }
  
  async deleteItem(idAtividade, idCalendario, idItem){
    let r = await api.delete(`/atividades/${idAtividade}/calendario/${idCalendario}/item/${idItem}`)
    return r.data
  }

  // async updateItem(dia, atividade){
  //   let r = await api.put('/atividades/calendario/',{dia, atividade})
  //   return r.data
  // }

}
