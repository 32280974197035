import { Container } from "../styled";
import { AdminContentBox } from "../../../../components/style/boxes";
import Title from "../../../../components/admin/title";
import Api from "../../../../service/admin/gospel";
import TableAdmin from "../../../../components/admin/tableAdmin";
import { Td, Tr } from "../../../../components/admin/tableAdmin/styled";
import { RoundedDeleteIcon, RoundedEditIcon } from "../../../../components/style/assets";
import { UseGospelContext } from "../gospelContext";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Text } from "../styled";
import { useEffect } from "react";
const api = new Api()



export default function DailyGospel() {

  const { evangelhoState: { list }, setList, editEvangelho } = UseGospelContext();

  async function getgospel() {
    let r = await api.getgospel()
    setList(r)
  }

  async function deleteGospelFunction(id) {
    let r = await api.deletegospel(id);
    if (r === "OK") {
      toast.success("Excluido com sucesso");
      getgospel();
    } else {
      toast.error("Não foi possível excluir este evangelho");
    }
  }

  const deletgospel = async (id) => {
    let _id = id;
    confirmAlert({
      title: "Remover Evangelho do Dia",
      message: `Tem certeza que deseja remover esse Evangelho do dia? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            deleteGospelFunction(_id);
            getgospel();
          },
        },
        { label: "Não" },
      ],
    });
  }

  function editE(item) {
    editEvangelho(
      {
        id: item._id,
        livro: {
          titulo: item.livro.titulo,
          autores: item.livro.autores
        },
        mensagem: {
          parte: item.mensagem.parte,
          capitulo: item.mensagem.capitulo,
          pagina: item.mensagem.pagina,
          texto: item.mensagem.texto
        }
      }
    )
  }

  useEffect(
    () => { getgospel() }, 
    // eslint-disable-next-line 
    []
  )

  return (
    <Container>
      <ToastContainer />

      <AdminContentBox>
        <Title> Todas Mensagens do Evangelho </Title>

        <div className="table-container">

          <TableAdmin
            length="3em"
            size="100%"
            columns={[
              { title: 'Livro' },
              { title: 'Capítulo' },
              { title: 'Texto' },
              { title: '' },
              { title: '' }
            ]}>

            {list.map((item) =>
              <Tr >
                <Td> <Text> {item.livro.titulo} </Text> </Td>
                <Td> <Text> {item.mensagem.capitulo} </Text> </Td>
                <Td> <Text> {item.mensagem.texto.length >= 50 ? item.mensagem.texto.substr(0, 50) + '...' : item.mensagem.texto} </Text> </Td>


                <Td config={{ visibility: 'hidden', width: '1em' }}
                  onClick={() => editE(item)} >

                  <RoundedEditIcon />
                </Td>

                <Td config={{ visibility: "hidden", width: "1em" }}
                  onClick={() => deletgospel(item._id)} >
                  <RoundedDeleteIcon />
                </Td>
              </Tr>
            )}

          </TableAdmin>

        </div>

      </AdminContentBox>

    </Container>
  )
}
