import axios from 'axios'
import { API_URL, Errors } from './config'

import Cookies from 'js-cookie'
import { toast } from 'react-toastify';


export default class ServiceBase {
    constructor(mainPath) {
        this.api = axios.create({
            baseURL: `${API_URL}${mainPath}`,
            headers: {
                'x-access-token': JSON.parse(Cookies.get('userCookie') || '{}').auth,
                'Content-Type': 'application/json'
              }
        });
    }


    async get(path) {
        try {
            let r = await this.api.get(path);
            return this.handleResponse(r);
        } catch (e) {
            return this.handleError(e);
        }
    }

    async post(path, body) {
        try {
            let r = await this.api.post(path, body);
            return this.handleResponse(r);
        } catch (e) {
            return this.handleError(e);
        }
    }

    async put(path, body) {
        try {
            let r = await this.api.put(path, body);
            return this.handleResponse(r);
        } catch (e) {
            return this.handleError(e);
        }
    }

    async delete(path, body) {
        try {
            let r = await this.api.delete(path, body);
            return this.handleResponse(r);
        } catch (e) {
            return this.handleError(e);
        }
    }


    async postFile(path, formData) {
        try {
            let r = await this.api.post(path, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return this.handleResponse(r);
        } catch (e) {
            return this.handleError(e);
        }
    }

    
    async putFile(path, formData) {
        try {
            let r = await this.api.put(path, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return this.handleResponse(r);
        } catch (e) {
            return this.handleError(e);
        }
    }





    handleResponse(response) {
        // console.log('handleResponse:')
        // console.log(response);
        return response.data || {};
    }


    handleError(e) {
        let exception = this.getError(e);        
        toast.error(exception.error);

        // console.log('handleError');
        // console.log(exception);
    }


    getError(e) {
        if (e.response?.data?.error) {
            return {
                error: e.response.data.error,
                errorName: e.response.data.errorName ?? Errors.General,
            }
        } else {
            return {
                error: 'Ocorreu um erro. Já estamos tentando resolver!',
                errorName: Errors.Unknown
            }      
        }
    }




}


