import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background: #f7f9fb;
	padding: 8em 2em;

	.part1 {
		display: flex;
		justify-content: space-around;
		height: 3em;

		button {
			display: none;
		}
	}

	.title {
		font-family: "Manrope";
		font-weight: bold;
		font-size: 3em;
	}

	.BoxCaroucel {
		width: 100%;
		height: 30em;

		.custom-dot {
			width: 19px;
			height: 19px;

			border-radius: 20px;
			background: #dadada;

			border: solid 0px;
			margin-left: 5px;
		}
		.custom-dot--active {
			background: #160e4a;
			border-radius: 9.5px;
			width: 19px;
			height: 19px;
		}
	}
	.itemCaroucel {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media (max-width: 1024px) {
		.part1 {
			display: flex;
			flex-direction: column;
			align-items: center;
			height: 100%;
		}

		.title {
			font-size: 40px;
			width: 7em;
			margin-bottom: 1em;
			text-align: center;
		}

		.BoxCaroucel {
			height: 48em;
		}
	}
`;

export { Container };
