import { UseGospelContext } from "../gospelContext";
import { Container } from "../styled";
import {AdminContentBox} from "../../../../components/style/boxes";
import Title from "../../../../components/admin/title";
import Api from "../../../../service/admin/gospel"
import { InputAdm, TextAreaAdm } from '../../../../components/style/inputs';
import {Label}  from "../styled";
import { ToastContainer, toast } from "react-toastify";
import ConfirmButton from "../../../../components/admin/confirmButton"; 

const api = new Api()

export default function DailyGospel() {
   
    const { 
      evangelhoState:
      {
        evangelho:{
          id, 
          livro:{
            titulo,
            autores
          }, 
          mensagem:{
            parte,
            capitulo,
            pagina,
            texto
          }
        }
      },
        
        setTitulo,
        setAutores,
        setParte,
        setCapitulo, 
        setPagina, 
        setTexto,
        novoEvangelho,
        setList
    } = UseGospelContext();

    async function getgospel() {
        let r = await api.getgospel()
        setList(r)
    }


 async function newGospelFunction() {
      if( id === ""){
          let r = await api.postgospel(titulo, autores, parte, capitulo, pagina, texto)
          if (r.erro){
            toast.error(r.erro);
          } else {
            toast.success("Evangelho cadastrado com sucesso");
            getgospel(); 
            clearConst();
            
          }
      } else {
        let r = await api.putgospel(titulo, autores, parte, capitulo, pagina, texto, id)
        if (r.erro){
          toast.error(r.erro);
        } else {
          toast.success("Evangelho alterado com sucesso");
          getgospel(); 
          clearConst();
        }
      }      
 }

 function clearConst() {
    novoEvangelho();
}

 return (
    <Container>
        <ToastContainer/>
    <AdminContentBox>
    <ConfirmButton onClick={newGospelFunction} />
      <Title> Evangelho do dia -<span> {id === '' ? 'Novo': 'Alterando'} </span> </Title>

 <div className="box-form">  
     <div className="forms-men"> 
        <div className="form-one">
          <Label> Livro: </Label>
          <InputAdm onChange ={e=>setTitulo(e.target.value)} value={titulo}/>
         </div>

         <div className="form-one"> 
         <Label > Autores: </Label>
          <InputAdm onChange ={e=>setAutores(e.target.value)} value={autores}/>
         </div>


     <div className="compartment">
         <div className="form-two">
         <Label> Parte: </Label>
          <InputAdm onChange ={e=>setParte(e.target.value)} value={parte}/>
          </div>

          <div className="form-two">
           <Label> Capítulo: </Label>
            <InputAdm onChange ={e=>setCapitulo(e.target.value)} value={capitulo}/>
          </div>
            
         
          <div className="form-two">
           <Label> Página: </Label>
           <div className="pag">
             <InputAdm onChange ={e=>setPagina(e.target.value)} value={pagina} className="page"/>
            </div>
          </div>
        </div>
    </div>
      <div className="forms-men"> 
          <div className="form-three">
           <Label style={{ justifyContent: "flex-start" }}>  Texto:  </Label>
           <TextAreaAdm onChange ={e=>setTexto(e.target.value)} value={texto}/>
          </div>
      </div>
          
      </div>
     </AdminContentBox>
   </Container>
)}
