import { AdminContentBox } from "../../../../components/style/boxes";
import Title from "../../../../components/admin/title";
import { Container, Label, BoxImagens } from "../styled";
import { CheckboxAdm, InputAdm, TextAreaAdm } from '../../../../components/style/inputs';
import { ChooseImageIcon } from "../../../../components/style/assets";
import ConfirmButton from "../../../../components/admin/confirmButton";
import { ToastContainer, toast } from "react-toastify";

import { UseeventContext } from "../eventContext";

import { UseLoginContext } from '../../login/loginContext'

import { API_URL } from "../../../../service/config";

import ApiEvents from "../../../../service/admin/events";
import { useState } from "react";
const apiEvents = new ApiEvents();






export default function Events() {

  const {
    usuState: { auth },
  } = UseLoginContext();

  const [urlImg, setUrlImg] = useState(null)
  const { eventoState: { eventos: { id, titulo, descricao, imagem, data, ativo, link: { linkTitulo, linkUrl } } },

    setTitulo,
    setDescricao,
    setImagem,
    setData,
    setAtivo,
    setLinkTitulo,
    setLinkUrl,
    setListagem,
    novosEventos } = UseeventContext();






  function selectFile() {
    setUrlImg(imagem === undefined || null ? urlImg : imagem);
    let input = document.getElementById("capa-input-file");
    input.click();
  }

  function previewImage() {
    if (imagem) {
      if (typeof imagem === 'string')
        return `${API_URL}/admin/eventos/busca?imagem=${imagem.replace(/\\/g, '/')}&x-access-token=${auth}`;
      else
        return URL.createObjectURL(imagem);
    } else {
      return '';
    }
  }

  async function fListEvent() {
    let r = await apiEvents.listEvent()
    setListagem(r)
  }

  async function fNewEvent() {
    if (imagem === null) {
      return toast.error('Deve-se cadastrar uma imagem')
    }
    if (id === "") {
      let r = await apiEvents.newEvent(titulo, descricao, imagem, data, ativo, linkTitulo, linkUrl);
      if (r.erro) {
        toast.error(r.erro);

      } else {
        toast.success("O Evento foi cadastrado com sucesso");
        fListEvent();
        clearConst();
      }
    } else {
      if (typeof (imagem) !== 'string') {
        let r = await apiEvents.alterEventPhoto(id, titulo, descricao, imagem, data, ativo, linkTitulo, linkUrl);
        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("O Evento foi alterado com sucesso");
          /*  setIdAlterando(""); */
          fListEvent();
          clearConst();
        }
      } else {
        let r = await apiEvents.alterEvent(id, titulo, descricao, imagem, data, ativo, linkTitulo, linkUrl);

        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("O Evento foi alterado com sucesso");
          /*  setIdAlterando(""); */
          fListEvent();
          clearConst();
        }
      }
    }
  }



  function clearConst() {
    novosEventos();
  }




  return (
    <Container>
      <ToastContainer />
      <AdminContentBox>
        <ConfirmButton onClick={() => fNewEvent()} />
        <Title> Evento -<span> {id === '' ? 'Novo' : 'Alterando'} </span>  </Title>

        <div className="box-forms">
          <div className="box-one">
            <div className="forms-one">
              <Label> Título: </Label>
              <InputAdm onChange={e => setTitulo(e.target.value)} value={titulo} />
            </div>

            <div className="forms-text">
              <Label style={{ justifyContent: "flex-start" }}> Descricao: </Label>
              <TextAreaAdm onChange={e => setDescricao(e.target.value)} value={descricao} />
            </div>

            <div className="forms-one">
              <Label > Data: </Label >
              <div className="put">
                <input
                  type="datetime-local"
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                  disabled={data == '' ? true : false}
                />
                <input type="checkbox"
                  checked={data == '' ? true : false}
                  onChange={e => e.target.checked == true ? setData('') : setData(new Date().toJSON().substr(0, 16))}
                />
                <span>Sem data</span>
              </div>
            </div>


            <div className="activi">
              <Label > Ativo: </Label>
              <div className="s1">
                <CheckboxAdm onClick={() => setAtivo(!ativo)} checked={ativo} />
              </div>
            </div>

          </div>

          <div className="box-two">
            <div className="forms-two">
              <div className="forms-one">
                <Label> Botao: </Label>
                <InputAdm onChange={(e) => setLinkTitulo(e.target.value)} value={linkTitulo} />
              </div>

              <div className="forms-one">
                <Label> Url: </Label>
                <InputAdm onChange={(e) => setLinkUrl(e.target.value)} value={linkUrl} />
              </div>

              <div className="forms-one">
                <Label style={{ justifyContent: "flex-start" }}> Imagem: </Label>
                <BoxImagens>
                  <label>
                    {imagem === null
                      ? <ChooseImageIcon />
                      : <img className="img" src={previewImage()} alt='' />
                    }
                    <input type="file" id="capa-input-file"
                      onClick={selectFile}
                      onChange={(e) => e.target === undefined ? '' : setImagem(e.target.files[0])} />
                  </label>
                </BoxImagens>
              </div>
            </div>


          </div>
        </div>
      </AdminContentBox>
    </Container>

  )

}