import { useEffect } from "react";
import { useState } from "react";
import ContentLoader from "react-content-loader";


export default function Loading(props) {
  const [width, setWidth] = useState(0);
  
  
function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWidth(window.innerWidth)
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
    
  }, [])
  
  let pageWidth = window.screen.width;
  


  if (pageWidth > 1024)
    return (
        <ContentLoader 
        speed={2}
        width={1200}
        height={300}
        viewBox="0 0 1200 300"
        backgroundColor="#f2f2f2"
        foregroundColor="#e8e8e8"
        {...props}
      >
        <rect x="4" y="8" rx="3" ry="3" width="2" height="285" /> 
        <rect x="905" y="9" rx="3" ry="3" width="2" height="285" /> 
        <rect x="6" y="289" rx="3" ry="3" width="900" height="2" /> 
        <rect x="5" y="8" rx="3" ry="3" width="900" height="2" /> 
        
        <rect x="264" y="66" rx="3" ry="3" width="102" height="17" /> 
        <rect x="23" y="28" rx="16" ry="16" width="203" height="180" /> 
        <rect x="260" y="39" rx="3" ry="3" width="231" height="17" /> 
        <rect x="266" y="116" rx="3" ry="3" width="356" height="6" /> 
        
        <rect x="850" y="250" rx="14" ry="14" width="33" height="32" /> 
        <rect x="810" y="250" rx="14" ry="14" width="33" height="32" /> 
        
        <rect x="25" y="217" rx="16" ry="16" width="62" height="55" /> 
        <rect x="97" y="219" rx="16" ry="16" width="59" height="52" /> 
        <rect x="169" y="219" rx="16" ry="16" width="59" height="52" /> 
        
        <rect x="265" y="130" rx="3" ry="3" width="556" height="6" /> 
        <rect x="266" y="145" rx="3" ry="3" width="528" height="6" /> 
        <rect x="267" y="161" rx="3" ry="3" width="528" height="6" />
      </ContentLoader>
    )
  else if (pageWidth < 500)
    return (
      <ContentLoader 
      speed={2}
      viewBox="0 0 550 480"
      backgroundColor="#f2f2f2"
      foregroundColor="#e8e8e8"
      {...props}
    >
        <rect x="0" y="8" rx="3" ry="3" width="2" height="434" /> 
        <rect x="0" y="8" rx="3" ry="3" width="528" height="2" /> 
        <rect x="526" y="7" rx="3" ry="3" width="2" height="434" /> 
        <rect x="0" y="440" rx="3" ry="3" width="528" height="2" /> 
        
        <rect x="25" y="30" rx="3" ry="3" width="231" height="40" /> 
        <rect x="25" y="80" rx="3" ry="3" width="102" height="35" /> 
      
        <rect x="27" y="190" rx="3" ry="3" width="415" height="12" />         
        <rect x="26" y="210" rx="3" ry="3" width="400" height="12" /> 
        <rect x="27" y="130" rx="3" ry="3" width="451" height="12" /> 
        <rect x="27" y="150" rx="3" ry="3" width="431" height="12" /> 
        <rect x="26" y="170" rx="3" ry="3" width="390" height="12" />

        <rect x="27" y="250" rx="16" ry="16" width="156" height="118" /> 
        <rect x="30" y="380" rx="16" ry="16" width="49" height="36" /> 
        <rect x="83" y="380" rx="16" ry="16" width="45" height="35" /> 

        
      </ContentLoader>
    )
  else
    return (
      <ContentLoader 
      speed={2}
      viewBox="0 0 600 400"
      backgroundColor="#f2f2f2"
      foregroundColor="#e8e8e8"
      {...props}
    >
        <rect x="0" y="8" rx="3" ry="3" width="2" height="373" /> 
        <rect x="0" y="8" rx="3" ry="3" width="528" height="2" /> 
        <rect x="526" y="7" rx="3" ry="3" width="2" height="373" /> 
        <rect x="0" y="378" rx="3" ry="3" width="528" height="2" /> 
        
        <rect x="25" y="55" rx="3" ry="3" width="102" height="17" /> 
        <rect x="27" y="196" rx="16" ry="16" width="156" height="118" /> 
        <rect x="25" y="30" rx="3" ry="3" width="231" height="17" /> 
        <rect x="27" y="101" rx="3" ry="3" width="306" height="6" /> 
        
        <rect x="850" y="250" rx="14" ry="14" width="33" height="32" /> 
        <rect x="810" y="250" rx="14" ry="14" width="33" height="32" /> 
        <rect x="30" y="319" rx="16" ry="16" width="49" height="44" /> 
        <rect x="83" y="320" rx="16" ry="16" width="49" height="44" /> 
        <rect x="26" y="115" rx="3" ry="3" width="475" height="6" /> 
        <rect x="27" y="130" rx="3" ry="3" width="451" height="6" /> 
        <rect x="27" y="146" rx="3" ry="3" width="451" height="6" /> 
        
        <rect x="26" y="161" rx="3" ry="3" width="306" height="6" />
      </ContentLoader>
    )
}