import axios from 'axios'
import { API_URL } from '../config'
const api = new axios.create({
    baseURL: `${API_URL}/admin`,
    headers: {
        'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYnJ1bm9kZW9saXZlaXJhLjIyLjEwQGdtYWlsLmNvbSIsImlhdCI6MTY0MzgzOTk0N30.oBFi1LnfeJ5h2ZCEAhC44HD5V0uyqolgg43J-HtI8Oo",
        'Content-Type': 'application/json'
      }
})

export default class Api{

    async getLectures(){
        let r = await api.get('/palestras/')
        return r.data
    }
    async newLectures(titulo, url, data, dia, centro, tipo, ordem, img) {

        let formData = new FormData();
        formData.append('centro', centro);
        formData.append('dia', dia);
        formData.append('tipo', tipo);
        formData.append('titulo', titulo);
        formData.append('url', url);
        formData.append('data', data);
        formData.append('ordem', ordem);
        formData.append('capa', img);

        let r = await api.post('/palestras/', formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});

        return r.data
    
    }

    async altLecturesPhoto(id, titulo, url, data, dia, centro, tipo, ordem, imagem) {
    
        let formData = new FormData();
        formData.append('centro', centro);
        formData.append('dia', dia);
        formData.append('tipo', tipo);
        formData.append('titulo', titulo);
        formData.append('url', url);
        formData.append('data', data);
        formData.append('ordem', ordem);
        formData.append('capa', imagem)

        let r = await api.put(`/palestrasFotos/${id}`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});

        return r.data
    }

    async altLectures(id, titulo, url, data, dia, centro, tipo, ordem, imagem){
        let r = await api.put(`/palestras/${id}`, {titulo, url, data, dia, centro, tipo, ordem, imagem})
        return r.data
    }   

    async deleteLectures(id){
        let r = await api.delete(`/palestras/${id}`)
        return r.data
    }




}