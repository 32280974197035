import { Container } from "./styled";

import Initial from "./initial/";
import FoundationHouse from "./foundationHouse/";
import News from "./news/";
import Registration from './registration';
import Guidance from "./guidance";
import Activity from "./activity";
import Rodape from "../../components/commum/rodape";

import Visit from './visit/';
import Gallery from './gallery/';

import LoadingCF from '../../components/commum/loadingCF';
import wait from '../../components/commum/wait';

import { useParams  } from "react-router-dom";
import { useEffect, useState } from "react";

import Api from "../../service/home/initial";
import ApiNew from "../../service/notice/notices";
import ApiAtividades from "../../service/activity";
import ApiGaleria from "../../service/album/";
import ApiCalendario from "../../service/home/calendar";

const api = new Api();
const apiNew = new ApiNew();
const apiAtividades = new ApiAtividades();
const apiGaleria = new ApiGaleria();
const apiCalendario = new ApiCalendario();

export default function Index() {
  
  const { casa } = useParams();
  const [ info, setInfo ] = useState({})

  const [ exibirNews, setExibirNews ] = useState(false)  
  const [ infoNews, setInfoNew ] = useState([]);

  const [ exibirCalendar, setExibirCalendar ] = useState(false) 
  const [ infoCalendar, setInfoCalendar ] = useState([]);
  
  const [ exibirAtividades, setExibirAtividades ] = useState(false) 
  const [ infoAtividades, setInfoAtividades ] = useState([]);
  
  const [ exibirOrientacao, setExibirOrientacao ] = useState(false) 
  
  const [ exibirGaleria, setExibirGaleria ] = useState(false) 
  const [ infoGaleria, setInfoGaleria ] = useState([]);
  
  const [ titles, setTitles ] = useState([
    {title:'Fundação', local:'1'}
  ]);

  async function getInfo(nome) {
    await wait(1000);
    
    let itensMenu = [];
    let info = await api.listHouse(nome);

    let news = await apiNew.listRecentNews(info.nome);
    setInfoNew(news);

    if(news.length !== 0){
      itensMenu.push({title: 'Notícias', local:'2', scrool: '2'})
      setExibirNews(true)
    } else {
      itensMenu.push({title: 'Notícias', scroll: undefined})
    }

    let calendar = await apiCalendario.listCalendar(info._id);
    setInfoCalendar(calendar)

    if(calendar.length !== 0){
      itensMenu.push({title:'Calendário', local:'3'})
      setExibirCalendar(true)
    }
    
    if(info.orientacao.length !== 0){
      itensMenu.push({title:'Orientação', local:'4'})
      setExibirOrientacao(true)
    }

    let atividades = await apiAtividades.ListActivityHouse(info._id)
    setInfoAtividades(atividades)

    if(atividades.length !== 0){
      itensMenu.push( {title:'Atividades', local:'5'})
      setExibirAtividades(true)
    }

    itensMenu.push({ title: 'Venha nos visitar', local: '6' });

    let galeria = await apiGaleria.listAlbuns(info._id)
    setInfoGaleria(galeria);
    
    if(galeria.length !== 0){
      itensMenu.push({title:'Galeria', local:'7'})
      setExibirGaleria(true)  
    }

    setTitles([...titles,...itensMenu])
    setInfo(info)
  } 
 
  useEffect(() => {getInfo(casa)}, [casa])

  if(info._id)
  return (
    <Container>
      <Initial info={info} titles={titles}/>
      <div className="partTwo">
        <FoundationHouse id='1' info={info} />
      </div>
      { exibirNews       ? <News id='2' nome={casa} info={infoNews} />          : ''}
      { exibirCalendar   ? <Registration id='3' data={infoCalendar} info={info._id}/>               : ''}
      { exibirOrientacao ? <Guidance id='4' info={info}/>                       : ''}
      { exibirAtividades ? <Activity id='5' data={infoAtividades} info={info}/> : ''}
      <Visit id='6' info={info}/>
      { exibirGaleria ? <Gallery id='7' data={infoGaleria} info={info}/>        : ''}  
      <Rodape/> 
    </Container>
  )
  else 
    return (
      <LoadingCF />
    )
}
