
import Box from "./box/";
import Carousel from "react-multi-carousel";
import { Container } from "./styled";
import {responsive, CustomLeftArrow, CustomRightArrow} from './configCarroucel';

import { API_URL  } from "../../../service/config";

export default function Index(props) {
  
  const person= props.info.orientacao;

  return (

    <Container id={props.id} centralizar={person.length <= 5}>
        <div className="title"  > Orientação </div>
        <div className="boxduo">
        <Carousel 
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            showDots={false}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            containerClass="carousel-container"
            responsive={responsive}
        >
            {person.map((item) => (
              <Box
                photo={`${API_URL}/website/busca?imagem=${item.imagem.replace(/\\/g, '/')}`}
                name={item.nome}
                schedule={item.horario}
              />
            ))}
          </Carousel>
          </div>
    </Container>
  );
}