import { Link } from "react-router-dom";
import { Container, Container1 } from "./styled";



// export default function Index() {
// 	return (
//         <Container>
//             <div className="menu">
//                 <div className="logo animate"></div>
//                 <div className="items">
//                     <div className="animate"></div>
//                     <div className="animate"></div>
//                     <div className="animate"></div>
//                     <div className="animate"></div>
//                     <div className="animate"></div>
//                 </div>
//             </div>
//             <div className="content">
//                 <div className="title animate"></div>
//                 <div className="description animate"></div>
//                 <div className="description animate"></div>
//             </div>
// 		</Container>
// 	);
// }



export default function Index2() {
    return (
        <Container1>
            {/* <Link to="/" className="logo">
                <img src="/assets/images/logo-light.svg" alt="" />
            </Link> */}
            <div className="loading">
                <img src="/assets/images/loadingCF.gif" alt="" />
            </div>
        </Container1>
    )
}