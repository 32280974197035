import styled from "styled-components";
import { ChooseImageIcon } from "../../../../../../components/style/assets";
import { API_URL } from "../../../../../../service/config";
import {UseLoginContext} from '../../../../login/loginContext'

const Container = styled.div`
    display: flex;
    align-items: center;

    * {
        cursor: pointer;
    }
    img {
        width: 160px;
        height: 133px;
    }
`




export default function ChooseImage({ value, onChange }) {
    const {
        usuState: {auth},
    } = UseLoginContext();

    function chooseImage() {
        document.getElementById('chooseImageInputFile').click();
    }

    function imageChanged(e) {
        onChange(e.target.files[0]);
    }

    function previewImage() {
        if (typeof (value) === 'string')
            return `${API_URL}/admin/donate/image?imagePath=${value.replace(/\\/g, '/')}&x-access-token=${auth}`;
        else if (typeof(value) === 'object')
            return URL.createObjectURL(value);
        else
            return '';
    }


    return (
        <Container>
            {value
                ? <img src={previewImage()} onClick={chooseImage} alt="" />
                : <ChooseImageIcon onClick={chooseImage} />
            }
            <input type="file" id="chooseImageInputFile" onChange={imageChanged} style={{visibility: 'hidden', position: 'absolute'}} />
        </Container>
    )
}