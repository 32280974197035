import Container from './styled'
import MenuTop from '../../../components/commum/menuTop';
import { API_URL } from '../../../service/config';

import styled, { keyframes } from "styled-components";
import { fadeInLeft, fadeInRight } from 'react-animations';


export default function Index(props){

    const info = props.info

    const fadeInAnimation = keyframes`${fadeInLeft}`;
 
    const FadeInDiv = styled.div`
        animation: 1s ${fadeInAnimation};
    `;

    const FadeInRightAnimation = keyframes`${fadeInRight}`;
    
    const FadeInRightInDiv = styled.div`
        animation: 1s ${FadeInRightAnimation};
    `;

    const scrool = () => {
        let element = document.getElementById(1);
            element.scrollIntoView({behavior: 'smooth'});
    }

    return(
        <Container bg={`${API_URL}/website/busca?imagem=${info.imagens.capa.replace(/\\/g, '/')}` }>
            <MenuTop logoClick="back" info={props.titles} color="white" imagem="/assets/images/logo-dark.svg"/>
            <div className="texts"   >
                <FadeInDiv>                
                    <h1>{info.nome}</h1>
                </FadeInDiv>
                <p1>
                    <FadeInRightInDiv>
                        {info.descricao.completo}
                    </FadeInRightInDiv>
                </p1>
            </div>
            <div onClick={scrool} className="icon"><img className="ani" src="/assets/images/icons8_down 1.svg" alt=""/></div>
        </Container>
    )
}