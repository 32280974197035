import {BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

import Home from './pages/home/';
import Infos from './pages/detailedHouse/';
import Eventos from './pages/houseEvents/';
import Notice from './pages/notice';
import Admin from './pages/admin';
import Login from './pages/admin/login';
import Reset from './pages/admin/ResetPassword';
import LoginContextProvider from "./pages/admin/login/loginContext";

function Router() {
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollIntoView({ block:"start", behavior: 'smooth'});
    }, [location.pathname]);
    return children
  } 
  return (
    <LoginContextProvider>
      <BrowserRouter>
      <Wrapper>
        <Routes>
            <Route path="/" index exact element={<Home />} />
            <Route path="/ce/:casa" element={<Infos/>} />
            <Route path="/ce/:casa/:atividade" element={<Eventos/>}/>
            <Route path="/noticias" element={<Notice/>}  />
            <Route path="/admin"  element={<Admin/>}>
                <Route path="/admin/casa" />
                <Route path="/admin/evangelhoDiario" />
                <Route path="/admin/doacao" />
                <Route path="/admin/noticias" />
                <Route path="/admin/eventos" />
                <Route path="/admin/palestras" />
                <Route path="/admin/GerenciarAcesso" />
                <Route path="/admin/atividade" />
            </Route>
            <Route path="/admin/login" element={<Login/>}/>
            <Route path="/admin/Reset" element={<Reset/>}/>
        </Routes>
        </Wrapper>
      </BrowserRouter>
    </LoginContextProvider>
  );
}

export default Router;
