import Carousel from "react-multi-carousel";
import Infos from "../../../components/commum/home/box-home/";
import CustomButtonGroupAsArrows from "../../../components/commum/carouselButtonExternal/";
import { Container } from "./styled";

import ApiNews from "../../../service/home/notices.js";
import { useState, useEffect } from "react";
import Loading from "./loading";
import wait from "../../../components/commum/wait";

const apiNews = new ApiNews();

export default function Index(props) {
	const [info, setInfo] = useState([]);
	const [loading, setLoading] = useState(true);

  async function fListNews() {
    await wait(1500);
		let r = await apiNews.listNews();
		setInfo(r);
		setLoading(false);
  }
  
  function newsletter() {
    let element = document.getElementById('7');
    element.scrollIntoView({behavior: 'smooth'});
  }

	useEffect(() => {
		fListNews();
	}, []);
	return (
		<Container id={props.id} emptyNews={info.length === 0}>
			<Carousel
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
        showDots={false}
        renderDotsOutside={false}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomButtonGroupAsArrows titulo="Fique Atento!" />}
        containerClass="BoxCaroucel"
        itemClass={loading ? "itemLoading" : "itemCaroucel"}>
        {loading
          ? <Loading />
          : info.length === 0 ? (
					<div className="cont">
						<img src="/assets/images/image 185.svg" alt="icon-erro" />
						<div>
							<div className="title">Não temos eventos programados por enquanto :(</div>
							<div className="describe">Mas fique atento ou se inscreva em nossa <b onClick={newsletter} style={{cursor: 'pointer' }}>newsletter</b> para receber novidades.</div>
						</div>
					</div>
				) : (
					info.map((i) => <Infos link={i.link.url} key={i._id} buttonText={i.link.titulo} info={i} />)
				)}
			</Carousel>
		</Container>
	);
}
