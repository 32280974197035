import Informacoes from "./informacoes/index";
import { Container } from "./styled";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


import ApiSubscribe from '../../../service/rodape/rodape.js'
import { useState } from "react";

const apiSubscribe = new ApiSubscribe();


export default function Rodape(props) {
  const[email, setEmail] = useState('');
  const data = [
    {
      titulo: "Casas Filiadas",
      item: [
        { titulo: "Casa de Fabiano", link: '/ce/casadefabiano/' },
        { titulo: "Neuchatel", link: '/ce/neuchatel' },
        { titulo: "Centro Espírita União", link: '/ce/união' },
        { titulo: "Lar do Heitor", link: '/ce/"Lardoheitor"' },
        { titulo: "Lar da Fernanda", link: '/ce/lardafernanda' },
        { titulo: "lar Ilza Cintra", link: '/ce/larilzacintra' },
        { titulo: "Lar Jésus Golçalves", link: '/ce/larjésusgonçales' },
        { titulo: "Lar Meimei", link: '/ce/larmeimei' },
        { titulo: "Lar Francisco de Assis", link: '/ce/larfranciscodeassis' },
        { titulo: "Educandário Allan Kardec", action: 'https://educandarioallankardec.com.br' }
      ]
    },
    {
      titulo: "Notícias",
      item: [
        {titulo: "Todas as Noticías", link: '/noticias' },
        {titulo: "Últimas Notícias", link: '/noticias' },
        {titulo: "Próximos Eventos", link: '/noticias' },
        {titulo: "Palestras Online", link: '/noticias' }
      ]
    },
    {
      titulo: "Comunicação",
      item: [
        { titulo: "Como doar?", action: 'mailto:contato@casadefabiano.com.br?subject=Quero doar&body=Olá, tenho interesse em realizar uma doação' },
        { titulo: "Quero ser Voluntário", action: 'mailto:contato@casadefabiano.com.br?subject=Quero ser voluntário&body=Olá, tenho interesse em ser voluntário.' },
        { titulo: "Empresas Colaboradoras", action: 'mailto:contato@casadefabiano.com.br?subject=Quero ser um parceiro&body=Olá, temos interesse em ser uma empresa parceira.' }
        ]
    },
    {
      titulo: "Fale Conosco",
      item: [
        { titulo: "(11) 5523-9522", action: 'tel:+551155239522' },
        { titulo: "contato@casadefabiano.com.br", action: 'mailto:contato@casadefabiano.com.br?subject=Tenho uma dúvida&body=Olá, gostaria de saber sobre...' },
        { titulo: "R.Dr.Luís Arrobas Martins, 255", action: 'https://www.google.com/maps?q=-23.687690,-46.704126' }
      ]
    }
  ];


  async function newSubscribeFunction() {
    let r = await apiSubscribe.newSubscribe(email)
        if (r.erro){
          toast.error(r.erro);
        } else {
          toast.success("Incrição realizada com sucesso");
          clearConst();
          
        }
      
    }


    function clearConst() {
      setEmail("");
    }

    
  return (
    <Container id={props.id}>
      <ToastContainer/>
      <div className="titulo">
        <div className="tit">Fique sempre atualizado sobre nossas atividades! </div>
        <div className="SegP">
          <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
          <button className="Insc" onClick={() => newSubscribeFunction()}>Inscrever-se</button>
      </div>
      </div>
      <div className="TerP">
        <div className="comp">
          {data.map((i, index) => (
            <Informacoes key={index} info={i} />
          ))}
        </div>
      </div>
      <div className="ptF">
        <Link to="/">
        <div className="soonY">
          <img src="../../assets/images/logo-dark.svg" alt="" />
        </div>
        </Link>
        <div className="ano">© {new Date().getFullYear()} Todos os direitos reservados. </div>
        <div className="redes">
          <button className="faceB">
            <a href="https://www.facebook.com/CasadeFabianoOficial"><img src="../../assets/images/facebook.svg" alt="" /></a>
          </button>
          <button className="instagram">
            <a href="https://www.instagram.com/casadefabianooficial/"> <img src="../../assets/images/insta.svg" alt="" /></a>
          </button>
          <button className="youtube">
          <a href="https://www.youtube.com/channel/UCO7cMhEe4c7TxSHXO_UezSw"> <img src="../../assets/images/you.svg" alt="" /> </a>
          </button>
        </div>
      </div>
    </Container>
  );
}