import { HeaderAdm, ProfileAdm, ButtonsAdm } from './styled';
import { RefreshIcon, MenuLogoutIcon, PerfilSampleIcon } from '../../style/assets'

import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const navigation = useNavigate()
    const Logout = () => {Cookies.remove('userCookie'); navigation('/admin/login')}
    let user = JSON.parse(Cookies.get('userCookie') ?? "{}").name
    return(
        <HeaderAdm>
            <ProfileAdm>
                <div className="perfil">
                    <div className="user-name">Olá, {user}! </div>
                </div>
    
                <ButtonsAdm>
                    <div className="refresh"><button><RefreshIcon /></button></div>
                    <div className="logout"><button onClick={Logout}><MenuLogoutIcon /></button></div>
                </ButtonsAdm>
            </ProfileAdm>
        </HeaderAdm> 
    ) 
}
