import { AdminYellowBar } from '../../style/assets'
import { Container, Title } from './styled'


export default function Index(props) {
    return (
        <Container>
            <AdminYellowBar />
            <Title> {props.children} </Title>
        </Container>
    )
}