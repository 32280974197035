import { createContext, useContext, useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {

    case 'setId'        : return {...state, id: action.id}
    case 'setOrdem'     : return {...state, ordem: action.ordem}
    case 'setNome'      : return {...state, nome: action.nome}
    case 'setDescricao' : return {...state, descricao: action.descricao}
    case 'setFundacao'  : return {...state, fundacao: action.fundacao}

    case 'setCarousel'  : return {...state, imagens: {...state.imagens, carrousel: action.carrousel}}
    case 'setCapa'      : return {...state, imagens: {...state.imagens, capa: action.capa}}

    case 'setEndereco'    : return {...state, contato: {...state.contato, endereco:  action.endereco  }}
    case 'setLatitude'    : return {...state, contato: {...state.contato, latitude:  action.latitude  }}
    case 'setLongitude'   : return {...state, contato: {...state.contato, longitude: action.longitude }}
    case 'setTelefone1'   : return {...state, contato: {...state.contato, telefone1: action.telefone1 }}
    case 'setTelefone2'   : return {...state, contato: {...state.contato, telefone2: action.telefone2 }}
    case 'setEmail'       : return {...state, contato: {...state.contato, email:     action.email     }}

    case 'setNomerede': return {...state, redesSociais: {...state.redesSociais, nome: action.nome    }}
    case 'setUrlRede':  return {...state, redesSociais: {...state.redesSociais, url: action.url      }}
    case 'setImgRede':  return {...state, redesSociais: {...state.redesSociais, imagem: action.imagem}}

    case 'setNomeOrientador'    :   return {...state, orientacao: {...state.orientacao, nome: action.nome}}
    case 'setHorarioOrientador' :   return {...state, orientacao: {...state.orientacao, horario: action.horario}}
    case 'setImagemOrientador'  :   return {...state, orientacao: {...state.orientacao, imagem: action.imagem}}

    case 'setparticipate'    : return {...state, participate: action.participate}
    case 'setNetworks'        : return {...state, networks:    action.networks}
    case 'setAtividades'      : return {...state, atividades:  action.atividades}

    case 'editRedeSocial'  : return {...state, redesSociais: initialState.redesSociais}
    case 'editOrientador'  : return {...state, orientacao: initialState.orientacao}

    case "setInfoAlbum"    :  return{...state, infoAlbum:     action.infoAlbum  }
    case "setAlbumsArray"  :  return{...state, albumsArray:   action.albums     }

    case "setIDAlbum"      :  return{...state, infoAlbum: {...state.infoAlbum, _id:    action.id}} 
    case "setTituloAlbum"  :  return{...state, infoAlbum: {...state.infoAlbum, titulo:    action.titulo}} 
    case "setCapaAlbum"    :  return{...state, infoAlbum: {...state.infoAlbum, imagem:    action.capa  }} 
    case "setDescAlbum"    :  return{...state, infoAlbum: {...state.infoAlbum, descricao: action.desc  }} 
 
    case "setAlbumId"         : return{...state, infoGaleria:{...state.infoGaleria, albumId: action.id    }} 
    case "setGaleriaTitulo"   : return{...state, infoGaleria:{...state.infoGaleria, titulo:  action.titulo}}   
    case "setGaleriadescricao": return{...state, infoGaleria:{...state.infoGaleria, descricao: action.desc}}   
    case "setGaleriaimagem"   : return{...state, infoGaleria:{...state.infoGaleria, imagem: action.imagem }}
    case "setGaleriadata"     : return{...state, infoGaleria:{...state.infoGaleria, data: action.data     }}

    case 'novoGaleria'  : return{ ...state, infoGaleria: {...initialState.infoGaleria}}

    case "setInfogaleria"  :  return{...state, infoGaleria:   action.infoGaleria}
    case "setGaleriaImages":  return{...state, galeriaImages: action.galeria    }

    default:
      break;
  }

}

const initialState = {
  id: '',
  ordem: 0,
  nome: '',
  descricao: '',
  fundacao: '',
  imagens: {
      carrousel: null,
      capa: null
  },
  contato: {
      endereco: "",
      latitude: "",
      longitude: "",
      telefone1: "",
      telefone2: "",
      email: "",
  },
  redesSociais:{
      nome: "",
      url: "",
      imagem: null
  },
  orientacao: {
      nome: "",
      horario: "",
      imagem: null
  },
  atividades: [],

  infoAlbum: {
    _id: "",
    atividadeId: "",
    centroespiritaId: "",
    descricao: "",
    imagem: "",
    titulo: ""
  }, // OBJETO COM AS INFORMAÇÕES DE UM ALBUM 
  albumsArray: [], // ARRAY COM TODOS OS ALBUMS DA CASA
  

  infoGaleria: {
    albumId: "",
    titulo: "",
    descricao: "",
    imagem: null,
    data: "" 
  }, // objeto com as infos da fotos de um album
  galeriaImages:[],// array com todas as fotos de um album

  networks: [],
  participate: [] 

}


const InstitutionContext = createContext();

export default function InstitutionContextProvider(props) {
  
  const [institutionState, setInstitutionState] = useReducer(reducer, initialState)

  return (
    <InstitutionContext.Provider
      value={{ institutionState, setInstitutionState }}
    >
      {props.children}
    </InstitutionContext.Provider>
  );
}

export function UseInstitutionContext() {
  const context = useContext(InstitutionContext);
  const { institutionState, setInstitutionState } = context;

  return {
    institutionState,
    setId:        (id)        => setInstitutionState( {type: 'setId'   , id   }),
    setOrdem:     (ordem)     => setInstitutionState( {type: 'setOrdem', ordem}),
    setNome:      (nome)      => setInstitutionState( {type: 'setNome',  nome }),
    setDescricao: (descricao) => setInstitutionState( {type: 'setDescricao', descricao}),
    setFundacao:  (fundacao)  => setInstitutionState( {type: 'setFundacao', fundacao}),

    setCarousel:  (carrousel) => setInstitutionState( {type: 'setCarousel', carrousel}),
    setCapa:      (capa)      => setInstitutionState( {type: 'setCapa', capa}),

    setEndereco: (endereco)  => setInstitutionState( {type: 'setEndereco', endereco}), 
    setLatitude: (latitude)  => setInstitutionState( {type: 'setLatitude', latitude}),
    setlongitude:(longitude) => setInstitutionState( {type: 'setLongitude',longitude}),
    setTelefone1:(telefone1) => setInstitutionState( {type: 'setTelefone1', telefone1}),
    setTelefone2:(telefone2) => setInstitutionState( {type: 'setTelefone2', telefone2}),
    setEmail:    (email)     => setInstitutionState( {type: 'setEmail', email}),
  
    setNomerede: (nome)      => setInstitutionState( {type: 'setNomerede', nome}),
    setUrlRede:  (url)       => setInstitutionState( {type: 'setUrlRede', url}),
    setImgRede:  (imagem)    => setInstitutionState( {type: 'setImgRede', imagem}),
 
    setNomeOrientacao:    (nome)      => setInstitutionState( {type: 'setNomeOrientador', nome}),
    setHorarioOrientacao: (horario)   => setInstitutionState( {type: 'setHorarioOrientador', horario}),
    setImagemOrientador:  (imagem)    => setInstitutionState( {type: 'setImagemOrientador', imagem}),

    setParticipante: (participate)  => setInstitutionState( {type: 'setparticipate', participate}),
    setnetworks:     (networks)     => setInstitutionState( {type: 'setNetworks', networks}),
    setatividades:   (atividades)   => setInstitutionState( {type: 'setAtividades', atividades}),

    editRedeSocial: () => setInstitutionState( {type: 'editRedeSocial'}),
    editOrientador: () => setInstitutionState( {type: 'editOrientador'}),

    setInfoAlbum:    (infoAlbum) => setInstitutionState( {type: 'setInfoAlbum', infoAlbum}),
    setAlbumsArray:  (albums)    => setInstitutionState( {type: 'setAlbumsArray', albums}),
    setTituloAlbum:  (titulo)    => setInstitutionState( {type: 'setTituloAlbum', titulo}),
    setCapaAlbum:    (capa)      => setInstitutionState( {type: 'setCapaAlbum',   capa}),
    setDescAlbum:    (desc)      => setInstitutionState( {type: 'setDescAlbum',  desc}),
    setIDAlbum:      (id)        => setInstitutionState( {type: 'setIDAlbum',     id }),

    setAlbumId:          (id)    =>setInstitutionState( {type: 'setAlbumId'         ,  id    }),
    setGaleriaTitulo:    (titulo)=>setInstitutionState( {type: 'setGaleriaTitulo'   ,  titulo}),
    setGaleriadescricao: (desc)  =>setInstitutionState( {type: 'setGaleriadescricao',  desc  }),
    setGaleriaimagem:    (imagem)=>setInstitutionState( {type: 'setGaleriaimagem'   ,  imagem}),
    setGaleriadata:      (data)  =>setInstitutionState( {type: 'setGaleriadata'     ,  data  }),

    setInfogaleria:  (infoGaleria)=> setInstitutionState( {type: 'setInfogaleria', infoGaleria}),
    setGaleriaImages:(galeria)    => setInstitutionState( {type: 'setGaleriaImages', galeria}),
    novoGaleria: () => setInstitutionState({type: 'novoGaleria'}) 
   }
}
