import {Render} from './box-gallery'
import Galeria from "./gallery/index"

import { useState } from 'react';

import Api from '../../../service/galeria/galeria';
const api = new Api()

export default function Index(props){

    const images = props.images 
    const [state, setState] = useState(true)
    const [galeria, setGaleria] = useState([])
    const [info, setInfo] = useState({})

    async function setStateFunction(album) {
        
        if(state === false) 
           return setState(true)   

        let r = await api.listgaleria(album._id)
        setGaleria(r)
            
        setInfo(album)
        setState(!state)   

    }

    if(state === true){
        return(
            <Render state={(info) => setStateFunction(info)} images={images} />
        )
    } else {
        return(
            <Galeria state={setStateFunction} galeria={galeria} info={info}/>
        )
    }
    
}