
const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },              
      items:4,
      partialVisibilityGutter: 100
    },
    mobile: {
      breakpoint: {
        max: 1280,
        min: 0
      },
      items:1
    },
    
  };

export {responsive};
