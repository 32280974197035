import axios from 'axios'
import { API_URL } from '../config'
const api = new axios.create({
    baseURL: `${API_URL}/admin`,
    headers: {
        'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYnJ1bm9kZW9saXZlaXJhLjIyLjEwQGdtYWlsLmNvbSIsImlhdCI6MTY0MzgzOTk0N30.oBFi1LnfeJ5h2ZCEAhC44HD5V0uyqolgg43J-HtI8Oo",
        'Content-Type': 'application/json'
      }
})

export default class Api{
    async listgaleria(id){
        let r = await api.get(`/casa/galeria/${id}`)
        return r.data
    }
    async deleteGaleria(id){
        let r = await api.delete(`/casa/galeria/${id}`)
        return r.data
    }
    async postGaleria(  albumId, titulo,descricao,imagem,data){

        let formData = new FormData();

        formData.append('albumId', albumId);
        formData.append('titulo', titulo);
        formData.append('descricao', descricao);
        formData.append('data', data);
        formData.append('imagem', imagem)

        let r = await api.post('/casa/galeria', formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});

        return r.data
    }

}