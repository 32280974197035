import { InfoA } from "./styled";
import {Link } from 'react-router-dom'

import { API_URL } from "../../../../service/config";


export default function IA(props) {
  const info = props.info;
  
    return (
    <InfoA>
      <div className="img"  >
        <img src={`${API_URL}/website/busca?imagem=${info.imagens.icone.replace(/\\/g, '/')}`} alt="" />
      </div>
      <div className="group"  >
        <div className="title">{info.nome}</div>
        <div className="desc">{info.descricao.resumo.length >= 75 ? info.descricao.resumo.substring(0,75) + '...' : info.descricao.resumo}</div>
        
          {info.mostrarDetalhes == true &&
            <div className="button">
              <Link to={`/ce/${props.casa.toLowerCase().replace(/ /g, "")}/${info.nome.toLowerCase().replace(/ /g, "")}`} state={{ info: info, house: props.house }}><button>Veja mais</button></Link>
            </div>
          }
      </div>
    </InfoA>
  );
}
