import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    //height: 100vh;
    padding: 0em 5em 12em 5em;
    background: #F7F9FB;

    overflow: hidden;

    h1{
        font-family: 'Manrope';
        font-weight: bold;
        font-size: 3.5em;
        line-height: 68px;
        color: #1C1F26;
        padding: 1em 0em;
        width: 82%;
    align-self: center;
       
    }
    .containerCaroucel{
        height: 100%;
        display: flex;
        align-items: center;
        overflow: unset;

        .itemClass{
          display: flex;
          justify-content: center;
        }

        .react-multi-carousel-dot-list {
          bottom: -100px;
        }

        .custom-dot {
            width: 36px;
            height: 36px;

            border-radius: 50%;
            cursor: pointer;
                    
            color: #160E4A;
            border: 2px solid #160E4A;
            background: #FFFF;

            margin-left: 5px;
            font-family: 'Manrope';
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;

            display: flex;
            align-items: center;
            justify-content: center;
          }

          .custom-dot--active {
            background: #160e4a;
            color: #F7F9FB;
            cursor: pointer;
          }
      
    }

    @media (max-width: 1280px){

       display: flex;
       flex-direction: column;
       justify-content: space-between;
       text-align: center;
       height: auto;

       padding: 2em 0em 7em 0em;

      h1{
        font-size: 40px;
        padding-left: 0em;
      }

       .containerCaroucel {
          height: none;

          .react-multi-carousel-dot-list {
            bottom: -10px;
          }

        }

    }


    @media (max-width: 1024px) {
    h1 {
      font-size: 40px;
    }
  }

`

export {Container}