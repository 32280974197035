import styled from "styled-components";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding: 12px;

    background: #F4F6F8;
    border-radius: 8px;

    color: #181818;

    width: 100%;
    min-width: 170px;
    max-width: 300px;

    height: fit-content;
    margin: 1em 1em;


    .text{
        display: flex;
        flex-direction: column;
        
        //height: 100%;
    }
    p1{ 
        font-size: 1em;
        font-family:'Manrope';
        font-weight: 700;
        margin-bottom: 0.8em;
        color: #181818;
    }
    .datas{
        height: 4em;
        overflow-y: auto;
        
        ::-webkit-scrollbar-button:single-button {
            background-color: transparent;
            display: flex;
            border-style: solid;
            flex-direction: column;
            justify-content: center;
            height: 14px;
            width: 10px;
        }

        /* Up */
        ::-webkit-scrollbar-button:single-button:vertical:decrement {
            border-width: 0px 8px 20px 8px;
            border-color: transparent transparent #d3d7db transparent;
        }

        
        ::-webkit-scrollbar{
            background-color: transparent;
        }
         /* Down */
        ::-webkit-scrollbar-button:single-button:vertical:increment {
            border-width: 20px 8px 0px 8px;
            border-color: #d3d7db transparent transparent transparent;
            
        }

    }
    .data{
        font-family: 'Roboto';
        font-size: 1em;
        line-height: 22px;
        color: #181818;
            
    }
    p3{
        font-family: 'Roboto';
        font-size: 1em;
        color: #181818;
    }

@media (max-width: 1280px){
    margin-bottom: 1em;
    height: auto;
    
    .datas {
        height: auto;
    }
    .data {
        line-height: 140%;
    }
}
`

export {Container}