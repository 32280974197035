import styled from "styled-components";



const Container = styled.div`
min-width: 50vw;
.infos{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 80%;
    .titleAlbum{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: bold;
        font-size: 2em;
        line-height: 59px;

        color: #000000;

        button{
            height: 2em;
        }
    }
    .desc{
        
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 1em;
        line-height: 25px;

        color: #000000;

    }
}
.btm-more{
    margin: 3em 0em 0em;
    font-size: 1em;
    font-family: "Manrope";
    font-weight: 600;
    cursor: pointer;
    align-self: center;

    border: unset;
    background-color: unset;
    color: #1c1f26;
    padding: 0.5em 2em;
  }

  .disable {
		display: none;
  }


`



export {Container}