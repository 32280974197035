

export let layout = [
    {
        order: 1,
        type: 'computed',
        title: 'Título',
        webservice: 'Valor',
        style: {}
    },
    {
        order: 2,
        type: 'text',
        title: 'Título',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a tellus tellus. Quisque egestas purus nec et tellus tellus. Quisque egestas purus nec nib',
        style: {}
    },
    {
        order: 3,
        type: 'image',
        image: null,
        style: {}
    }
]






export function initLayout() {
    getLayout(
        null, 'Título', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a tellus tellus. Quisque egestas purus nec et tellus tellus. Quisque egestas purus nec nib',
        'Título', 'Valor')
}


export function getLayout(
    image,
    title,
    description,
    wsTitle1,
    wsUrl1) {
    return layout = [
        {
            order: 1,
            type: 'computed',
            title: wsTitle1,
            webservice: wsUrl1,
            className: 'navy'
        },
        {
            order: 2,
            type: 'text',
            title: title,
            description: description,
            className: ''
        },
        {
            order: 3,
            type: 'image',
            image: image,
            className: ''
        }
    ]
}


export function setColumns(columns) {
    layout = columns;
}