import styled from "styled-components";

const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    width: 14em;
    height: 17em;

    background: #FFFFFF;
    border: 1px solid #B9B9B9;
    box-sizing: border-box;
    border-radius: 5px;

    a {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .centralizar {
        width: 70%;
        max-height: 80%;
    }
    img{
        cursor: pointer;
        width: 100%;
        height: 14em;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 108%;
        height: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        color: #666666;

        width: 100%;
        padding: 1em;

        border-top: 1px solid #B9B9B9;
    }
`


export default Container