

import { AdminContentBox } from "../../../../components/style/boxes";
import { ChooseImageIcon } from "../../../../components/style/assets";
import { InputAdm } from '../../../../components/style/inputs';

import Title from "../../../../components/admin/title";
import ConfirmButton from "../../../../components/admin/confirmButton";

import { BoxImagens, Label, TextAreaAdm } from '../styled';

import { UseActivityContext } from "../activityContext";

import { API_URL } from "../../../../service/config";

import Api from "../../../../service/admin/activity";

import { useEffect } from "react";

import {UseLoginContext} from '../../login/loginContext'

import { toast } from "react-toastify";
const api = new Api()

export default function Index() {
    const {
        usuState: {auth},
    } = UseLoginContext();
    const {
        activityState: {
            _id,
            centroEspiritaId,
            nome,
            ordem,
            imagens:{
                capa,
                icone
            },
            descricao:{
                resumo,
                completo
            }
        },
        set_ID,
        setNome,
        setOrdem,
        setIcone,
        setResumo,
        setCompleto,
        setCapa
    } = UseActivityContext();

    function selectFile(id) {
        let input = document.getElementById(id);
        input.click();
    }

    async function post() {
        if(icone === null)
            return toast.error('Um icone deve ser escolhido !!!')

        if(_id === ""){
            let r = await api.postInfo(centroEspiritaId, nome, resumo, completo, ordem, icone, capa)

            if(r.erro)
                return toast.error(r.erro)
            else 
                toast.success('As informações sobre a Atividade foram cadastradas 🥳')
            
            set_ID(r.insertedId)

        } else {
            
            let r;

            if( typeof(icone) === 'string' && typeof(capa) === 'string'){
                r = await api.putInfo(_id, nome, resumo, completo, ordem, icone, capa)
            } else if(typeof(icone) === 'string' && typeof(capa) === 'object'){
                r = await api.putInfoCapa(_id, nome, resumo, completo, ordem, icone, capa)
            }else if(typeof(icone) === 'object' && typeof(capa) === 'string'){
                r = await api.putInfoIcone(_id, nome, resumo, completo, ordem, icone, capa)
            }else {
                r = await api.putInfoImagens(_id, nome, resumo, completo, ordem, icone, capa)
            }   
            getInfo()
            if(r.erro)
                return toast.error(r.erro)
            else 
                return toast.success('As informações sobre a Atividade foram Alteradas 🥳')
        
        }

    }

    
    function previewImage() {
        if(icone){
            if (typeof icone === "string")
                return `${API_URL}/admin/casa/busca?imagem=${icone.replace(/\\/g, '/')}&x-access-token=${auth}`;
            else 
                return URL.createObjectURL(icone);
        }else {
            return ""
        }
    }
    function previewImageCapa() {
        if(capa){
            if (typeof capa === "string")
                return `${API_URL}/admin/casa/busca?imagem=${capa.replace(/\\/g, '/')}&x-access-token=${auth}`;
            else 
                return URL.createObjectURL(capa);
        }else {
            return ""
        }
    }
    

    async function getInfo() {

        if(_id === '')
            return ""
            
        let r = await api.getInfo(_id)

        setNome(r.nome)
        setOrdem(r.ordem)
        setResumo(r.descricao.resumo)
        setIcone(r.imagens.icone)
        setCapa(r.imagens.capa)
        setCompleto(r.descricao.completo)
    }
    useEffect(
        () => {getInfo()}, 
        // eslint-disable-next-line 
        [_id]
    )

    return(
        <AdminContentBox>
            <ConfirmButton onClick={() =>post()}/>
            <Title>Informações Gerais</Title>
            <div className="total">
                <div className="left">
                    <br />
                    <div className="form-row-one">
                        <Label> Ordem: </Label>
                        <div className="s2"><InputAdm value={ordem} onChange={e => setOrdem(e.target.value)}/></div>
                    </div>
                    <div className="form-row-one">
                        <Label> Nome: </Label>
                        <InputAdm value={nome} onChange={e => setNome(e.target.value)}/>
                    </div>
                    <div className="form-row-one" style={{alignItems:'flex-start'}}>
                        <Label> Resumo: </Label>
                        <TextAreaAdm value={resumo} onChange={e => setResumo(e.target.value)}/>
                    </div>
                    <div className="form-row-I">
                        <Label>Ícone:</Label>
                        <BoxImagens >
                            <label>
                                {icone === null ? (
                                    <ChooseImageIcon />
                                ) : (
                                    <img className="imgPrevi" src={previewImage()} alt="" style={{maxWidth: '200px'}}/>
                                )}
                                <input
                                    type="file"
                                    id="icon"
                                    accept="image/*"
                                    onClick={() => selectFile("icon")}
                                    onChange={(e) => e.target.files[0] === undefined ? '' : setIcone(e.target.files[0])}
                                />
                            </label>
                        </BoxImagens>
                    </div>
                </div>
                <div className="right">
                    <div className="form-row-top">
                        <Label> Descrição: </Label>
                        <TextAreaAdm onChange={e => setCompleto(e.target.value)} value={completo}/>
                    </div>
                    <div className="form-row-I">
                        <Label>Capa:</Label>
                        <BoxImagens >
                            <label>
                                {capa === null ? (
                                    <ChooseImageIcon />
                                ) : (
                                    <img className="imgPrevi" src={previewImageCapa()} alt="" style={{maxWidth: '200px'}}/>
                                )}
                                <input
                                    type="file"
                                    id="capa"
                                    accept="image/*"
                                    onClick={() => selectFile("capa")}
                                    onChange={(e) => e.target.files[0] === undefined ? '' : setCapa(e.target.files[0])}
                                />
                            </label>
                        </BoxImagens>
                    </div>
                </div>
            </div>
        </AdminContentBox>
    )
}