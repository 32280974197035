import React from "react";
import classNames from "classnames";


const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },              
      items: 1,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  };


const CustomDot = ({ index, onClick,    active}) => {
    return (
        <button onClick={e => {onClick(); e.preventDefault();}}
            className={classNames("custom-dot", {"custom-dot--active": active })}
        >
        {React.Children.toArray[index]}
        </button>
    );
}
export {responsive, CustomDot};
