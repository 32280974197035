import Container from './styled'
import InfoImg from './info_Img/index'
import Days from "./partFive/index"
import Participant from './participants/index'
import Rodape from "../../components/commum/rodape"
import Gallery from './gallery/index'
import Proposal from './proposal'
import Networks from './networks'
import Notices from './notices'
import Initial from './initial/'
import LoadingCF from '../../components/commum/loadingCF'

import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import ApiAc from '../../service/activity';
import Api from "../../service/home/initial";
import ApiNotices from "../../service/notice/notices"
import ApiGaleria from '../../service/album/index';
import wait from '../../components/commum/wait';

const api = new Api();
const apiNoticias = new ApiNotices();
const apigaleria = new ApiGaleria();
const apiAc = new ApiAc();

export default function Index() {

    const { casa, atividade } = useParams();
    const [allData, setInfo] = useState([]);

    const [exibirNoticias, setNoticias] = useState(false);
    const [infoNoticias, setInfoNoticias] = useState([])
    const [exibirDays, setExibirDays] = useState(false);

    const [exibirParticipantes, setParticipantes] = useState(false);
    const [exibirNetWorks, setExibirNetWorks] = useState(false);

    const [exibirGaleria, setExibirGaleria] = useState(false);
    const [infoGaleria, setInfoGaleria] = useState([]);

    const [sessao1, setSessao1] = useState(false);
    const [sessao2, setSessao2] = useState(false);
    const [sessao3, setSessao3] = useState(false);

    const [titles, setTitles] = useState([]);

    async function getInfo(casa, atividade) {

        await wait(1000);

        let itensMenu = [];
        let infoCasa = await api.listHouse(casa)

        let atividadeInfo = await apiAc.ListActivityForHouse(infoCasa._id, atividade)

        let galeriaInfo = await apigaleria.listAlbunsAtiv(atividadeInfo._id)

        setInfoGaleria(galeriaInfo);

        

        setInfoNoticias(apiNoticias.listNews(infoCasa.nome, atividadeInfo.nome));
        setInfo(atividadeInfo);

        
        if (atividadeInfo.sessoes[0] && atividadeInfo.sessoes[0].titulo) {
            setSessao1(true)
            itensMenu.push({ title: atividadeInfo.sessoes[0].titulo, local: '1' })
        }
        if (atividadeInfo.sessoes[1]  && atividadeInfo.sessoes[1].titulo) {
            setSessao2(true)
            itensMenu.push({ title: atividadeInfo.sessoes[1].titulo, local: '2' })
        }
        if (atividadeInfo.sessoes[2] && atividadeInfo.sessoes[2].titulo) {
            setSessao3(true)
            itensMenu.push({ title: atividadeInfo.sessoes[2].titulo, local: '3' })
        }
        if (atividadeInfo.calendario.length !== 0) {
            setExibirDays(true)
            if (atividadeInfo.calendario.some(c => c.atividades.length > 0))
                itensMenu.push({ title: 'Horários', local: '4' })
        }
        if (atividadeInfo.redesSociais.length !== 0) {
            setExibirNetWorks(true)
            itensMenu.push({ title: 'Contato', local: '6' })
        }
        if (atividadeInfo.participantes.length !== 0) {
            setParticipantes(true)
        }
        
        if (galeriaInfo.length !== 0){
            setExibirGaleria(true)
            itensMenu.push({ title: 'Galeria', local: '7', scroll: true })
        }
        
        if (infoNoticias.length !== 0) {
            setNoticias(true)
            itensMenu.push({ title: 'Notícias', local: '5', scrool: true })
        } else {
            itensMenu.push({ title: 'Notícias' })
        }
        
        setTitles(itensMenu)
    }
    useEffect(() => { getInfo(casa, atividade) }, [casa, atividade])

    if (allData._id) {
        return (
            <Container>
                <Initial info={allData} titles={titles} />
                {sessao1 ? (<InfoImg id='1' row="row" margin={"0px 52px"} info={allData.sessoes[0]} />) : ''}
                {sessao2 ? (<InfoImg id='2' margin={"0px 52px"} row='row-reverse' bg="#F7F9FB" info={allData.sessoes[1]} />) : ''}
                {sessao3 ? (<Proposal id='3' info={allData.sessoes[2]} />) : ''}
                {exibirDays ? (<Days id='4' info={allData.calendario} />) : ''}
                {exibirParticipantes ? (<Participant people={allData.participantes} />) : ''}
                {exibirNoticias ? (<Notices id='5' data={infoNoticias} atividade={allData.nome} house={casa} />) : ''}
                {exibirNetWorks ? (<Networks id='6' info={allData} />) : ''}
                {exibirGaleria ? (<Gallery id='7' data={infoGaleria} info={allData} />) : ''}
                <Rodape />
            </Container>
        )
    } else
        return (<LoadingCF />)
}