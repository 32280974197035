import axios from 'axios'
import { API_URL } from '../config'

const api = new axios.create({
    baseURL: `${API_URL}/admin/noticia`,
    headers: {
        'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYnJ1bm9kZW9saXZlaXJhLjIyLjEwQGdtYWlsLmNvbSIsImlhdCI6MTY0MzgzOTk0N30.oBFi1LnfeJ5h2ZCEAhC44HD5V0uyqolgg43J-HtI8Oo",
        'Content-Type': 'application/json'
      }
})

export default class Api{

    async getNews(){ 
        let r = await api.get('/')
        return r.data
    }

    async addNews(categoria, subCategoria, emDestaque, ativo, titulo, descricao, link, imagens, data) {
    

        let formData = new FormData();
        formData.append('categoria', categoria)
        formData.append('subCategoria', subCategoria)
        formData.append('emDestaque', emDestaque)
        formData.append('ativo', ativo)
        formData.append('titulo', titulo)
        formData.append('descricao', descricao)
        formData.append('linkTitulo', link.titulo)
        formData.append('linkUrl', link.url)
        formData.append('data', data);
        
        for (let item of imagens)
            formData.append('imagens', item)
                
        let r = await api.post('/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return r.data
    }

    async delNews(id){
        let r = await api.delete(`/${id}`)
        return r.data
    }

    async putNews(id, categoria, subCategoria, emDestaque, ativo, titulo, descricao, link, imagens, data) {
        let formData = new FormData();
        formData.append('categoria', categoria)
        formData.append('subCategoria', subCategoria)
        formData.append('emDestaque', emDestaque)
        formData.append('ativo', ativo)
        formData.append('titulo', titulo)
        formData.append('descricao', descricao)
        formData.append('linkTitulo', link.titulo)
        formData.append('linkUrl', link.url)
        formData.append('data', data);
        
        for (let item of imagens)
            formData.append('imagens', item)
        
        let r = await api.put(`/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return r.data
    }
}