import Infos from '../../../components/commum/home/box-home';
import { Container } from './styled';


export default function Index(props){
    const info = {
        titulo: "Fundação da Casa",
        imagem: "/assets/images/fundacao.png",
        descricao: props.info.fundacao,
        link: null//{url: '#'}
    }
    
    const scrool = () => {
        let element = document.getElementById(7);
        element.scrollIntoView({behavior: 'smooth'});
    }
    return(
        <Container id={props.id}>
            <Infos  img={info.imagem} bgSize="contain" imgLocal={true} info={info} buttonColor="#5261EA" btmOn={scrool} buttonText="Ver Galeria" />
        </Container>
    )
}