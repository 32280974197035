import styled from 'styled-components'


const Container = styled.div`
 

 .forms-one{
     display: flex;
     flex-direction: row;
     padding-bottom: 1.5em;
     textArea{
         width: 100%;
         height: 70%;
     }
 }

 .forms-text{
    display: flex;
     flex-direction: row;
     padding-bottom: 1.5em;
     height: auto;
     textArea{
         width: 100%;
         height: 114px;
     }
 }
 
 .box-forms {
     display: flex;
     flex-direction: row; 
     justify-content: space-between;
     width: 90%;
     height: 100%;
 }

 .box-one{
    height: auto;
    width: 50%;
 }

 .box-two{
    height: auto;
    width: 50%;
 }

 .activi{
     width: 60%;
     display: flex;
     flex-direction: row;
     margin: 0.3em -2.7em;
 }


 .put{
    display: flex;
    align-items: center;
    justify-content: left;
    width:100%;
    gap: 10px;
    
    font: 1em Roboto;
    text-align: right;
    border:black;
 }

 .put input:not([type="checkbox"]):not([type="radio"]){
    height:auto;

    width: 64%;
    height: 2.5em;
    font: 400 1em Roboto;
    color:  #615858 ;
    border-radius: 5px;
    border: 1px solid rgba(168,168,168,1);
    outline: none;
    padding: 0em 0.5em;


}

input[type="file"] {
                display: none;
    }



    .img{
        width: 100%;
        height:auto;
    }
 
`

const Label = styled.div`
   display:flex;
   flex-direction:column;
   justify-content:space-evenly;
   font: 1em Roboto;
   width: 150px;
   text-align: right;
   margin-right: 10px;
`



const Text = styled.div`
    font: 1em Roboto;
    text-align: left;
    margin-right: 10px;
`



export const BoxImagens = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
        justify-content: flex-start;
    }

    & svg {
        width: 9em;
        height: 9em;
        margin: 0em 1em;
    }

    @media (max-width: 1366px) {
        & svg {
            width: 8em;
            height: 8em;
            margin: 0em 1em;
        }
    }
    
    @media (max-width: 1280px) {
        & svg {
            width: 7em;
            height: 7em;
            margin: 0em 1em;
        }
    }
`
export { Container,Text, Label/* , BoxImagens */ }