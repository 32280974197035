import styled from "styled-components";


export const Label = styled.div`
    font: 1em Roboto;
    width: 80px;
    text-align: right;
    margin-right: 10px;
`

export const Tab = styled.div`
    font: 500 1em Roboto;
    text-align: right;
    margin-right: 2em;

    color: ${props => props.selected ? '#CFB115' : 'inherit'};
    cursor: pointer;
`

export const LayoutBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 20em;
    background-color: #EDEDED;
    border-top: 1px solid #C5C5C5;

    padding: 2em;

`

export const AdminContainer = styled.div`
    display: flex;
    flex-direction: column;


    .content-top-center{
        display:flex;
        flex-direction: row;
        justify-content: space-around;
    }




    .form-column {
        display: flex;
        flex-direction: column;
        margin-left: 0em;
    }

    .content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin: 0.3em 0em;
    }
    
    .form-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        margin: 0.3em 0em;
    }

    .center {
        justify-content: center;
    }

    .top {
        align-items: flex-start;
    }

    .s1 { width: 10%; }
    .s2 { width: 20%; }
    .s3 { width: 30%; }
    .s4 { width: 40%; }
    .s5 { width: 50%; }
    .s6 { width: 60%; }
    .s7 { width: 70%; }
    .s8 { width: 80%; }

    .s100 {
        width: 100%;
    }


    @media (max-width: 1440px) {
        .content > div:nth-child(1) {
            align-self: flex-start;
            margin-bottom: 3em;
        }

        .content > div:nth-child(2) {
            width: 90%;
        }

        .content {
            flex-direction: column;
            align-items: center;
        }
    }


`

export const BoxImagens = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
        justify-content: flex-start;
    }

    & svg {
        width: 9em;
        height: 9em;
        margin: 0em 1em;
    }

    @media (max-width: 1366px) {
        & svg {
            width: 8em;
            height: 8em;
            margin: 0em 1em;
        }
    }
    
    @media (max-width: 1280px) {
        & svg {
            width: 7em;
            height: 7em;
            margin: 0em 1em;
        }
    }
`

