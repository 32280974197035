import { Container } from "./styled.js";
import MenuTop from '../../../components/commum/menuTop'
import BoxNotice from '../../../components/commum/box-noticia'
import Menu from '../../../components/commum/menuNotice'
import { useEffect, useState } from "react";
import Modal from '../../../components/commum/modal'
import {UseeventContext} from '../newsContext.js'
import { ToastContainer } from "react-toastify";
import { API_URL } from "../../../service/config.js";

import Loading from "../loading/index.js";

import Service from '../../../service/notice/menu.js'
import Api from "../../../service/notice/notices.js";

const apiMenu = new Service();
const apiNotice = new Api();
  

export default function  App(props) {
  const [viewed, setViewed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showCustomMenu, setShowCustomMenu] = useState();
    
  const { eventoState: {limit,  title, subTitle, thisModal, photoModal, btmMore, noticeVisu, menuList},
    setLimit,
    setThisModal,
    setPhotoModal,
    setBtmMore,
    setNoticeVisu,
    setMenuList
  } = UseeventContext();


  function wait(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  async function OpenModal(infos){
    setPhotoModal(`${API_URL}/website/busca?imagem=${infos.photo.replace(/\\/g, '/')}`)
  }

  function customMenu() {
    setShowCustomMenu(!showCustomMenu)
  }

  async function ListMenu(){
    let r = await apiMenu.listMenu();
    setMenuList(r);
  }

  async function loadingNotice() {
    if (btmMore === false) {
      return ''
    }
    
    if (showCustomMenu)
      setShowCustomMenu(false);
    
    
    if (limit === 5) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setLoading(true);
      await wait(1000);
    }

    let r = [];
    
    if (props.info !== 'Geral' && !viewed) {
      setNoticeVisu([props.info])
      setViewed(true);
    }
    else {
      r = await apiNotice.pagination(limit, title, subTitle);
      setNoticeVisu(r);
    }

    
    if (limit > r.length) {
      setBtmMore(false)
    }
    
    ListMenu()
    setLoading(false);
  }


  function imagemCarregada() {
    if (photoModal) {
      setThisModal({show: !thisModal.show})
    }
  }
  
  


  // eslint-disable-next-line 
  useEffect(() => {
    loadingNotice()
  }, [limit, title, subTitle])


  return (
    <Container showCustomMenu={showCustomMenu}>
      <ToastContainer autoClose={10000} limit={1} hideProgressBar={true}/>
      
      <Modal open={() => setThisModal(false)} options={thisModal}>
        <img className="modalImg" src={photoModal} onLoad={imagemCarregada} alt="ModalIMG" key={photoModal} />
      </Modal>
      
      <MenuTop info={props.titles} imagem="/assets/images/logo-light.svg" />

      <div className="box-conteudo">

        <div className="pt1" >
          <div className="titles">
            <div className="home"> {title} </div>
            <div className="detail"> {subTitle} </div>
          </div>
          <div className="filterNews" onClick={() => customMenu()}> {showCustomMenu ? 'Voltar para Notícias' : 'Filtrar Notícias'} </div>
          <div className="notices">
            {loading
              ? ( <>
                <Loading />
                <Loading />
                <Loading />
                </>
              )
              : noticeVisu.map(i =>
                  <BoxNotice open={OpenModal} info={i} />
                )
            }
          </div>  
          <button className={btmMore === false ? 'disable' : 'btm-more'} onClick={() => setLimit(limit + 5)}>
            {btmMore === false ? 'É tudo por hoje.' : 'Carregar mais >>'}
          </button>
        </div>

        <div className="pt2">
          <Menu noticeMenu={menuList} loading={loading} info={props.info} showCustomMenu={showCustomMenu} customMenu={customMenu} />
        </div>
        
      </div>

    </Container>
  )
}