import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #F2F2F2;
    height: 100vh;
`


export const BoxLogin = styled.div`
    display: flex;
    flex-direction: column;

    background-color: #fff;
    box-shadow: 0px 0px 2px 1px rgba(115, 115, 115, 0.25);
    border-radius: 5px;

    padding: 4em 4em 1.5em 4em;
    width: 455px;


    .titulo {
        font: 700 1.5em Roboto;
        color: #384C63;
        text-align: center;

        margin-bottom: 1em;
    }

    .logo {
        text-align: center;
        margin-bottom: 8em;
    }

    .label {
        font: 400 1em Roboto;
        color: #5C5C5C;
        margin-bottom: .8em;
    }

    input {
        font: 500 1em Roboto;
        outline: none;
        border: none;
        border-bottom: 1px solid #9F9F9F;
        
        width: 100%;
        height: 2em;

        padding: 0.5em 0em;
        margin-bottom: 3.5em;
    }

    button {
        background-color: #384C63;
        color: #fff;
        outline: none;
        border: none;
        border-radius: 2em;

        cursor: pointer;
        font: 500 1em Roboto;
        padding: 1em 0em;
        width: 100%;

        margin-bottom: 5.5em;
    }

    .esqueciSenha {
        font: 500 0.9em Roboto;
        color: #5C5C5C;
        text-align: center;

        span {
            font: 700 1em Roboto !important;
            cursor: pointer;
        }
    }


    
    @media (max-width: 1440px) {
        transform: scale(0.9);
    }

    @media (max-width: 1366px) {
        transform: scale(0.8);
    }

    @media (max-width: 375px) {
        transform: scale(0.85);
    }

`