import { Text } from "../styled";

import { AdminContentBox } from "../../../../components/style/boxes";
import { InputAdm } from "../../../../components/style/inputs";

import {
  RoundedDeleteIcon,
  RoundedEditIcon,
} from "../../../../components/style/assets";

import TableAdmin from "../../../../components/admin/tableAdmin";
import { Td, Tr } from "../../../../components/admin/tableAdmin/styled";

import Title from "../../../../components/admin/title";

import { Label } from "../styled";

import { useState, useEffect } from "react";

import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { UseActivityContext } from "../activityContext";
import Api from "../../../../service/admin/activity";
const api = new Api();

export default function Index() {
  const {
    activityState: {
      _id,
      redesSociais: { nome, url, imagem },
      ArrayRedeSocial,
    },
    setNomeRede,
    setImgRede,
    setUrlRede,
    setRedeArray,
    setRedeNova
  } = UseActivityContext();

  const [idAlterando, setIdAlterando] = useState("");

  async function fListSNetwork() {

    if(_id === "")
        return 
    
    
    let r = await api.listSNetwork(_id);
    if(r.redesSociais){
        setRedeArray(r.redesSociais)
    }
  }

  async function fDeleteSNetwork(imagem) {
    let r = await api.deleteSNetwork(_id, imagem);
    if (r === "OK") {
      toast.success("Rede social excluída com sucesso");
      fListSNetwork();
    } else {
      toast.error("Não foi possível excluir a rede social");
      fListSNetwork();
    }
  }

  const SNdelete = async (imagem) => {
    confirmAlert({
      title: "Remover Rede Social",
      message: `Tem certeza que deseja remover esta rede social? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            fDeleteSNetwork(imagem);
          },
        },
        { label: "Não" },
      ],
    });
  };

  async function fNewSNetwork() {

    if (imagem === null) 
        return toast.error("Deve-se cadastrar uma imagem");

    if (idAlterando === "") {
      let r = await api.newSNetwork(nome, url, imagem, _id);
      if (r.erro) {
        toast.error(r.erro);
      } else {
        toast.success("A rede social foi cadastrada com sucesso");
        fListSNetwork();
        clearConst();
      }
    } else {
      if (typeof imagem !== "string") {
        let r = await api.alterSNetworkPhoto(idAlterando, nome, url, imagem);
        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("A rede social foi alterada com sucesso");
          setIdAlterando("");
          fListSNetwork();
          clearConst();
        }
      } else {
        let r = await api.alterSNetwork(idAlterando, nome, url, imagem);

        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("A rede social foi alterada com sucesso");
          setIdAlterando("");
          fListSNetwork();
          clearConst();
        }
      }
    }
  }

  function clearConst() {
    setRedeNova();
  }

  useEffect(() => {
      fListSNetwork();
    },
    // eslint-disable-next-line  
    [_id])
  ;

  return (
    <div className="networks">
      <AdminContentBox>
        <Title>Redes Sociais</Title>
        <div className="all">
          <div className="manager">
            <div>
              <div className="itemManager">
                <Label>Rede Social:</Label>
                <InputAdm
                  value={nome}
                  onChange={(e) => setNomeRede(e.target.value)}
                />
              </div>
              <div className="itemManager">
                <Label>Link:</Label>
                <InputAdm
                  value={url}
                  onChange={(e) => setUrlRede(e.target.value)}
                />
              </div>
              <div className="itemManager">
                <Label>Imagem:</Label>
                <div style={{ width: "100%" }}>
                  <label className="input-file">
                    {imagem === null
                      ? "ARQUIVO"
                      : imagem.name === undefined
                      ? nome.length >= 9
                        ? nome.substring(0, 9) + ".png"
                        : nome + ".png"
                      : imagem.name}
                    <input
                      className="custom-file-input"
                      onChange={(e) =>
                        e.target.files[0] === undefined
                          ? ""
                          : setImgRede(e.target.files[0])
                      }
                      type="file"
                    />
                  </label>
                </div>
              </div>
            </div>
            <button className="salveBtm"  onClick={() => fNewSNetwork()} >Salvar</button>
          </div>
          <div className="list">
            <TableAdmin
              length="3em"
              size="17em"
              columns={[{ title: "Rede Social" }, { title: "" }, { title: "" }]}
            >
              {ArrayRedeSocial.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    {" "}
                    <Text> {item.nome} </Text>{" "}
                  </Td>

                  <Td
                    config={{ visibility: "hidden", width: "1em" }}
                    onClick={() => {
                      setIdAlterando(item._id);
                      setNomeRede(item.nome);
                      setUrlRede(item.url);
                      setImgRede(item.imagem);
                      setIdAlterando(item._idRede);
                    }}
                  >
                    <RoundedEditIcon />
                  </Td>

                  <Td
                    config={{ visibility: "hidden", width: "1em" }}
                    onClick={() => SNdelete(item.imagem)}
                  >
                    <RoundedDeleteIcon />
                  </Td>
                </Tr>
              ))}
            </TableAdmin>
          </div>
        </div>
      </AdminContentBox>
    </div>
  );
}
