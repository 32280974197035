import { AdminContainer, Label } from "../..//styled";
import { AdminContentBox } from '../../../../../components/style/boxes';
import Title from '../../../../../components/admin/title';
import { ChooseImageIcon } from "../../../../../components/style/assets";

import ConfirmButton from "../../../../../components/admin/confirmButton";

import {UseLoginContext}  from "../../../login/loginContext.js";

import { UseInstitutionContext } from "../../institutionContext";
import { useEffect, useState } from "react";

import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'

import { API_URL } from '../../../../../service/config'
import ApiInstitution from "../../../../../service/admin/institution";
const api = new ApiInstitution()

export default function Institution() {

  
    const { search } = useLocation()

    const { usuState: {auth} } = UseLoginContext();

    const { 
        institutionState: {
            id,
            imagens: {
                capa, 
                carrousel
            }
        },
        setCapa,
        setCarousel

    } = UseInstitutionContext()

    const [urlImgCapa, setUrlImgCapa] = useState('')
    const [urlImgcaroucel, seturlImgcaroucel] = useState('')

    function selectFile() {
        setUrlImgCapa(capa === undefined ? urlImgCapa : capa);
        let input = document.getElementById("capa-input-file");
        input.click();
    }
    function previewImageCAPA() {
        if (capa) {
            if (typeof capa === "string")
                return `${API_URL}/admin/casa/busca?imagem=${capa.replace(/\\/g, '/')}&x-access-token=${auth}`;
            else 
                return URL.createObjectURL(capa);
        } else {
            setCapa(urlImgCapa)
            return URL.createObjectURL(urlImgCapa);      
        }
    }
    
    function selectFileCarrousel() {
        seturlImgcaroucel(carrousel === undefined ? urlImgcaroucel : carrousel);
        let input = document.getElementById("caroucel-input-file");
        input.click();
    }
    function previewImageCAROUCEL() {
        if (carrousel) {
            if (typeof carrousel === "string")
            return `${API_URL}/admin/casa/busca?imagem=${carrousel.replace(/\\/g, '/')}&x-access-token=${auth}`;
            else return URL.createObjectURL(carrousel);
        } else {
            setCarousel(urlImgcaroucel)
            return URL.createObjectURL(urlImgcaroucel);
        }
    }
    async function putImg() {
        let arrayImgs = [ capa, carrousel]
        
        let r;

        if(typeof capa !== 'string' && typeof carrousel === 'string' )
            r = await api.putCapa(id, capa, carrousel)

        else if (typeof carrousel !== 'string' && typeof capa === 'string')
            r =  await api.putCaroucel(id, capa, carrousel)        
        else    
            r = await api.postPhoto(id, arrayImgs)
        
        if(r.erro){
            toast.error(r.error)
        } else {
            toast.success("Alteração feita com sucesso!")
        }
    }
    async function getImage(){
        let r = await api.getPhoto(search.slice(4))
        setCarousel(r.imagens.carrousel)
        setCapa(r.imagens.capa)
    }
    useEffect(
        () => {getImage()}, 
        // eslint-disable-next-line 
        [search]
    );
    return (
        <AdminContainer>
            <ToastContainer/>
            <div className="images">
                <AdminContentBox>
                    <ConfirmButton onClick={putImg}/>
                    <Title>Imagens</Title>
                    <div className="imgs">
                        <div className="imgOne">
                        <label className="lblImg">
                            {capa === null ? (
                                <ChooseImageIcon />
                            ) : (
                                <img className="imgPrevi" src={previewImageCAPA()} alt="" />
                            )}
                            <input
                                type="file"
                                id="capa-input-file"
                                onClick={selectFile}
                                onChange={(e) => setCapa(e.target.files[0])}
                                accept="image/*"
                            />
                        </label>
                            <Label>Capa</Label>
                        </div>
                        <div className="imgTwo">
                            <label className="lblImg">
                                {carrousel === null ? (
                                    <ChooseImageIcon />
                                ) : (
                                    <img className="imgPrevi" src={previewImageCAROUCEL()} alt="" />
                                )}
                                <input
                                    type="file"
                                    id="caroucel-input-file"
                                    onClick={selectFileCarrousel}
                                    onChange={(e) => setCarousel(e.target.files[0])}
                                    accept="image/*"
                                />
                            </label>
                            <Label>Carrousel</Label>
                        </div>
                    </div>
                </AdminContentBox>
            </div>
        </AdminContainer>
    )
}