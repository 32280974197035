import styled from "styled-components";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    
    align-items: center;
    margin: 10em 2em;

    img{
        max-width:30em!important;
    }

`
export {Container}