import Container from "./styled"

import Manager from "./manager"
import List from "./list"
import ManagerUserContexProvider from "./context"

export default function Index(){
  return(
    <Container>
      <ManagerUserContexProvider>
        <Manager/>
        <List/>
      </ManagerUserContexProvider>
    </Container>
  )
}