import React from "react";
import classNames from "classnames";


//Carousel Responsiveness Setting (Required)
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 1280, min: 1 },
        items: 1
    }
};

// customization of navigation points
const CustomDot = ({ index, onClick, active, days}) => {
    return (
        <button onClick={e => {onClick(); e.preventDefault();}}
            className={classNames("custom-dot", {"custom-dot--active": active })}
        >
        {React.Children.toArray(days)[index]}
        </button>
    );
}
export {responsive,CustomDot};

