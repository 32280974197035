import { Label } from "../../styled";
import { AdminContentBox } from '../../../../../components/style/boxes';
import { InputAdm, TextAreaAdm } from '../../../../../components/style/inputs';
import Title from '../../../../../components/admin/title';
import ConfirmButton from "../../../../../components/admin/confirmButton";
import { useLocation } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';

import { UseInstitutionContext } from "../../institutionContext";
import { useEffect } from 'react';


import ApiInstitution from '../../../../../service/admin/institution';
const apiInstitution = new ApiInstitution()

export default function Index() {

    const { search } = useLocation()

    const { 
        institutionState: {id, nome, descricao, ordem, fundacao},
        setId,
        setNome,
        setDescricao,
        setOrdem,
        setFundacao 
    } = UseInstitutionContext();

    async function putGeral() {
        let r = await apiInstitution.putGeral(id, nome, ordem, descricao, fundacao)
        if(r.erro){
            toast.error(r.erro)
        } else {
            toast.success('Dados Alterados com Sucesso!')
        }
    }


    async function getGeral() {
        let r = await apiInstitution.getGeral(search.slice(4))
        setId(r._id)
        setNome(r.nome)
        setDescricao(r.descricao)
        setFundacao(r.fundacao)
        setOrdem(r.ordem)
    }

    useEffect(
    () => {getGeral()}, 
    // eslint-disable-next-line 
    [search]);

    return(
    <div className="infos-all">
    <ToastContainer/>
    <AdminContentBox>
        <ConfirmButton onClick={putGeral}/>
        <Title>Informações Gerais</Title>
        <div className="form-row top center">
            
            <div className="form-column s2">
                <div className="form-row">
                    <Label> Nome: </Label>
                    <InputAdm value={nome} readOnly={true} onChange={e => setNome(e.target.value)}/>
                </div>

                <div className="form-row2">
                    <Label> Descrição: </Label>
                    <TextAreaAdm value={descricao} onChange={e => setDescricao(e.target.value)} />
                </div>
            </div>
            <div className="form-column s2">
                <div className="form-row3">
                    <Label> Ordem: </Label>
                    <div style={{width: '100%'}}>
                        <input value={ordem} onChange={e => setOrdem(e.target.value)}/>
                    </div>
                </div>

                <div className="form-row2">
                    <Label> Fundação: </Label>
                    <TextAreaAdm value={fundacao} onChange={e => setFundacao(e.target.value)}/>
                </div>
            </div>
        </div>
    </AdminContentBox>
    </div>
    )
}