import styled from "styled-components";
import { CheckIcon, CheckIconBlack } from "../../style/assets";


const Container = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;

    * {
        cursor: pointer;
    }
`


export default function ConfirmButton(props) {

    return (
        <Container {...props}>
            {props.theme === "BLACK" ? <CheckIconBlack/> :<CheckIcon />}
        </Container>
    )
}

