import styled from "styled-components";

const Container=styled.div`

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 90vh;
    width: 100%;

    background-image: url(${(props) => props.bg});
    background-repeat: no-repeat;
    background-size: cover;

    .infos{
        width: 40%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        text-align: left;

        font-family: 'Manrope';
        color: #FFFFFF;

        margin-left: 5em;

        .title{
            font-weight: bold;
            font-size: 3em;
            line-height: 68px;
            margin-bottom: .5em;
        }
        .subTitle{
            font-family: 'Manrope';
            font-size:1.1em;
            line-height: 30px;
            font-weight: lighter;
            margin-top: 1em;
            max-width: 38em;
            white-space: pre-wrap;

           
        }
        .containerCaroucel{
            li{
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }

 @media (max-width: 1280px) { 
     .title{
         font-size: 2.3em !important; 
     }

     .subTitle{
         font-size: 1em !important;
         width: 23em  !important;
     }
 }

 @media (max-width: 1024px) {
    .infos {
        margin-left: 2em;
        width: 80%;
        .title {
            line-height: 41px;
        }
    }
 }


`

export {Container}