import axios from 'axios'
import { API_URL } from '../config'
const api = new axios.create({
    baseURL: `${API_URL}/admin`,
    headers: {
        'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYnJ1bm9kZW9saXZlaXJhLjIyLjEwQGdtYWlsLmNvbSIsImlhdCI6MTY0MzgzOTk0N30.oBFi1LnfeJ5h2ZCEAhC44HD5V0uyqolgg43J-HtI8Oo",
        'Content-Type': 'application/json'
      }
})



    
export default class Api{
    async listEvent(){
        let r = await api.get("/eventos")
    
        return r.data
    }
 
    async newEvent(titulo,descricao,imagem,data,ativo,linkTitulo, linkUrl){
        let formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('descricao', descricao);
        formData.append('imagem', imagem);
        formData.append('data', data);
        formData.append('ativo', ativo);
        formData.append('linkTitulo', linkTitulo);
        formData.append('linkUrl', linkUrl)

        let r = await api.post('/eventos', formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});
        return r.data
    
    }

    async alterEventPhoto(id,titulo,descricao,imagem,data,ativo,linkTitulo, linkUrl) {
 
        let formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('descricao', descricao);
        formData.append('imagem', imagem);
        formData.append('data', data);
        formData.append('ativo', ativo);
        formData.append('linkTitulo', linkTitulo);
        formData.append('linkUrl', linkUrl)

        let r = await api.put(`/eventosFotos/${id}`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});
        return r.data
    }

 
    async alterEvent(id,titulo,descricao,imagem,data,ativo,linkTitulo, linkUrl){
        
        let r = await api.put(`/eventos/${id}`, {titulo,descricao,imagem,data,ativo,linkTitulo, linkUrl})
        return r.data
    }
 
    async deleteEvent(id){
     let r = await api.delete(`/eventos/${id}`)
     return r.request.statusText
    }
  
 
 }