import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 93%;
  background-size: auto;

  
  .subs {
    display: flex;
    flex-direction: column;
    padding: 1em;
    width: 100%;
    max-width: 34em;
    height: 34em;
    justify-content: flex-start;

    animation: fadeInRight;
    animation-duration: 2s;
  }
  .date {
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;

    color: #7e8494;
  }
  .titulo {
    font-family: "Manrope";
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
  }

  .descricao {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    padding-right: 3em;
    line-height: 30px;
    max-height: 34em;

    margin-top: 1em;

    white-space: pre-wrap;

    color: #777c86;

    overflow: auto;

    scrollbar-width: thin;
    scrollbar-color: #cecece #ffffff;
    scrollbar-track {
      border-radius: 12px;
    }

    /* Chrome, Edge, and Safari */
    ::-webkit-scrollbar {
      width: 11px;
    }

    ::-webkit-scrollbar-track {
      background: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #cecece;
      border-radius: 12px;
      border: 1px solid #ffffff;
    }
  }

  .imagem {
    border-radius: 13px;

    background: url(${(props) => props.img});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 32em;
    height: 26em;
    background-size: ${(props) => props.bgSize === undefined ? "cover" : props.bgSize};

    margin-right: 2em;

    animation: fadeInLeft;
    animation-duration: 2s;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    .imagem {
      width: 93%;
      max-width: 30em;
      height: 22em;
      margin-right: 0em;
    }
    .desc {
      line-height: 0px;
    }
    .titulo {
      font-size: 1em;
      margin-bottom: 1em;
    }
    .subs {
      height: auto;
      max-width: 40em;
    }
    .descricao {
      overflow: scroll;
      padding: 0em;
    }
  }
`;

export { Container };
