

export let layout = [
    {
        order: 1,
        type: 'image',
        image: null,
        className: ''
    },
    {
        order: 2,
        type: 'text',
        title: 'Título',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a tellus tellus. Quisque egestas purus nec et tellus tellus. Quisque egestas purus nec nib',
        className: ''
    }
]





export function initLayout() {
    getLayout(
        null, 'Título', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi a tellus tellus. Quisque egestas purus nec et tellus tellus. Quisque egestas purus nec nib')
}


export function getLayout(
    image,
    title,
    description) {
    return layout = [
        {
            order: 1,
            type: 'image',
            image: image,
            style: {}
        },
        {
            order: 2,
            type: 'text',
            title: title,
            description: description,
            style: {}
        }
    ]
}


export function setColumns(columns) {
    layout = columns;
}