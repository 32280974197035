
import { AdminContentBox } from '../../../../components/style/boxes';
import { ChooseImageIcon } from '../../../../components/style/assets';

import Title from "../../../../components/admin/title";
import ConfirmButton from "../../../../components/admin/confirmButton";

import { Label, TextAreaAdm } from '../styled';

import { UseActivityContext } from '../activityContext';

import { API_URL } from '../../../../service/config';

import { useEffect } from "react";

import { toast } from "react-toastify";
import {UseLoginContext} from '../../login/loginContext'


import Api from "../../../../service/admin/activity";
import { InputAdm } from '../../../../components/style/inputs';
const api = new Api()


export default function Index() {
    const {
        usuState: {auth},
    } = UseLoginContext();
    const {
        activityState:{
            _id,
            textos: {
                historia: {
                    descricao: descricaoH,
                    imagem: imagemH,
                    titulo: tituloH
                },
                fazemos:{
                    descricao: descricaoF,
                    imagem: imagemF,
                    titulo: tituloF
                },
                proposta:{
                    descricao: descricaoP,
                    imagem: imagemP,
                    titulo: tituloP
                }
            }
        },
        
        setHistoriaD,
        setHistoriaI, 
        setHistoriaT,

        setFazemosD,
        setFazemosI,
        setFazemosT,

        setPropostaD,
        setPropostaI,
        setPropostaT  

    } = UseActivityContext();

    function selectFile(id) {
        let input = document.getElementById(id);
        input.click();
    }

    function previewImageFazemos() {
        if(imagemF){
            if (typeof imagemF === "string")
                return `${API_URL}/admin/casa/busca?imagem=${imagemF.replace(/\\/g, '/')}&x-access-token=${auth}`;
            else 
                return URL.createObjectURL(imagemF);
        } else {
            return ""
        }
    }

    function previewImageHistoria() {
        if(imagemH){
            if (typeof imagemH === "string")
                return `${API_URL}/admin/casa/busca?imagem=${imagemH.replace(/\\/g, '/')}&x-access-token=${auth}`;
            else 
                return URL.createObjectURL(imagemH);    
        } else {
            return ""
        }
    }
    
    function previewImageProjetos() {
        if(imagemP){
            if (typeof imagemP === "string")
                return `${API_URL}/admin/casa/busca?imagem=${imagemP.replace(/\\/g, '/')}&x-access-token=${auth}`;
            else 
                return URL.createObjectURL(imagemP);
        } else {
            return ""
        }
    }

     async function putInfoMore() {
        let r = await api.updateInfoMore(_id,  descricaoH, tituloH, descricaoF, tituloF, descricaoP, tituloP)
        if(r.erro)
            return toast.error(r.erro)
        else 
            return toast.success('Informações complementares Alteradas✨')
    }
 
    async function getInfoMore() {

        if(_id === "")
            return

        let r = await api.getInfoMore(_id)

        if (r.sessoes){
            setHistoriaI(r.sessoes[0].imagem )
            setHistoriaD(r.sessoes[0].descricao)
            setHistoriaT(r.sessoes[0].titulo)

            setFazemosI(r.sessoes[1].imagem)
            setFazemosD(r.sessoes[1].descricao)
            setFazemosT(r.sessoes[1].titulo)

            setPropostaI(r.sessoes[2].imagem)
            setPropostaD(r.sessoes[2].descricao)
            setPropostaT(r.sessoes[2].titulo)
        }
    }

    async function updateImg(tipo, arq) {
        let r;
        switch (tipo) {
            
            case "Historia":
                r = await api.updateImgSessoes(_id, arq, 1)
                setHistoriaI(arq)
            break;
            
            case "Fazemos":
                r = await api.updateImgSessoes(_id, arq, 2)
                setFazemosI(arq)
            break;
                
            case "Projetos":
                r = await api.updateImgSessoes(_id, arq, 3)
                setPropostaI(arq)
            break;
        
            default:
                break;
        }
        if(r.erro){
            toast.error(r.erro)
        } else{
            toast.success("A imagem foi Alterada")
        }
    }
    useEffect(
        () => {getInfoMore()},
        // eslint-disable-next-line 
        [_id]
    )
    return(
        <AdminContentBox>
            <ConfirmButton onClick={putInfoMore}  />
            <Title>Seções Complementares</Title>
            <div className="form-center">
                <div className="form-row">
                    <div className="text">
                        <Label  style={    {height: '50px'}} className='titulo'>Titulo:</Label>
                        <Label className='textAreaSecao'>Descrição: </Label>
                    </div>
                    <div className='titles '>
                        <InputAdm className='inpuit' value={tituloH} onChange={e => setHistoriaT(e.target.value)} placeHolder="História"/>
                        <TextAreaAdm value={descricaoH} onChange={e => setHistoriaD(e.target.value)}/>
                        <Label className='desc'>Obs.: Imagem alinhada a esquerda</Label>
                    </div>
                    <label className='chooseImage'>
                        {imagemH === null
                            ? <ChooseImageIcon />
                            : <img className="imgPrevi" src={previewImageHistoria()} alt="" />
                        }
                        <input
                            type="file"
                            id="Himg"
                            accept="image/*"
                            onClick={() => selectFile("Himg")}
                            onChange={(e) => e.target.files[0] === undefined ? '' : updateImg("Historia", e.target.files[0])}
                        />
                    </label>
                    <img src="/assets/images/trash-3.svg" onClick={() => updateImg("Historia", null)} alt="" style={{width: '1.5em', cursor: 'pointer', marginLeft: '1em'}} />
                </div>
                <div className="form-row">
                    <div className="text">
                        <Label  style={    {height: '50px'}} className='titulo'>Titulo:</Label>
                        <Label className='textAreaSecao'>Descrição: </Label>
                    </div>
                    <div className='titles '>
                        <InputAdm className='inpuit' value={tituloF} onChange={e => setFazemosT(e.target.value)} placeHolder="Fazemos"/>
                        <TextAreaAdm value={descricaoF} onChange={e => setFazemosD(e.target.value)}/>
                        <Label className='desc'>Obs.: Imagem alinhada a direita</Label>
                    </div>
                    <label className='chooseImage'>
                        {imagemF === null
                            ? <ChooseImageIcon />
                            : <img className="imgPrevi" src={previewImageFazemos()} alt="" />
                        }
                        <input
                            type="file"
                            id="Fimg"
                            accept="image/*"
                            onClick={() => selectFile("Fimg")}
                            onChange={(e) => e.target.files[0] === undefined ? '' : updateImg("Fazemos", e.target.files[0])}
                        />
                    </label>
                    <img src="/assets/images/trash-3.svg" onClick={() => updateImg("Fazemos", null)} alt="" style={{width: '1.5em', cursor: 'pointer', marginLeft: '1em'}} />
                </div>
                <div className="form-row">
                     <div className="text">
                        <Label  style={    {height: '50px'}} className='titulo'>Titulo:</Label>
                        <Label className='textAreaSecao'>Descrição: </Label>
                    </div>
                    <div className='titles '>
                        <InputAdm className='inpuit' value={tituloP} onChange={e => setPropostaT(e.target.value)} placeHolder="Projetos"/>
                        <TextAreaAdm value={descricaoP} onChange={e => setPropostaD(e.target.value)}/>
                        <Label className='desc'>Obs.: Imagem abaixo do título, ambos centralizados</Label>
                    </div>
                    
                    <label className='chooseImage'>
                        {imagemP === null
                            ? <ChooseImageIcon />
                            : <img className="imgPrevi" src={previewImageProjetos()} alt="" />
                        }
                        <input
                            type="file"
                            id="Pimg"
                            accept="image/*"
                            onClick={() => selectFile("Pimg")}
                            onChange={(e) => e.target.files[0] === undefined ? '' : updateImg("Projetos", e.target.files[0])}
                        />
                    </label>
                    <img src="/assets/images/trash-3.svg" onClick={() => updateImg("Projetos", null)} alt="" style={{width: '1.5em', cursor: 'pointer', marginLeft: '1em'}} />
                    
                </div>
            </div>
        </AdminContentBox>
    )
}