import axios from 'axios'
import { API_URL } from '../config.js'
const api = new axios.create({
    baseURL: `${API_URL}/website`    
})


export default class Api{

    async ListActivityHouse(idCasa){
        let r = await api.get(`/atividade/${idCasa}/casa`)
        return r.data
    }
    
    async ListActivityForHouse(nome, atividade){
        let r = await api.get(`/atividade/${atividade}/casa/${nome}`)
        return r.data[0]
    }
    
}

