import React from "react";
import * as C from "./styled";


export default function App(props) {

  function OpenModal() {
    return props.open({ status: true, link: props.info.url, titulo: props.info.titulo });
  }

  function JoinsDate(fullDate) {
    let date = new Date(fullDate);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString().substring(0,5)}`;
  }

  
  return (
    <C.Container onClick={OpenModal} className="cont" img={props.image}>
      <div className="imagem" />

      {/* <C.Pt2>

        <C.Data>
            {props.categoria.dia}
          </C.Data>

          <div>

            <C.Title>
              {props.info.titulo.length >= 48
                ? props.info.titulo.substring(0, 58) + "..."
                : props.info.titulo}
            </C.Title>

            <C.Casa> {props.categoria.centroEspirita}</C.Casa>
              
           </div>

        <C.Extra> Assista por aqui </C.Extra>
      </C.Pt2> */}
    </C.Container>
  );
}
