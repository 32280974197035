import axios from 'axios'
import { API_URL } from '../config'
const api = new axios.create({
    baseURL: `${API_URL}/admin/evangelhoDia`,
    headers: {
        'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYnJ1bm9kZW9saXZlaXJhLjIyLjEwQGdtYWlsLmNvbSIsImlhdCI6MTY0MzgzOTk0N30.oBFi1LnfeJ5h2ZCEAhC44HD5V0uyqolgg43J-HtI8Oo",
        'Content-Type': 'application/json'
      }
})


export default class Api{
   async getgospel(){
       let r = await api.get("/")
       
       return r.data
   }

   async putgospel(livro, autores, parte, capitulo, pagina, texto, id){
       
       let r = await api.put(`/${id}`, {livro, autores, parte, capitulo, pagina, texto})
       return r.data
   }

   async postgospel(livro, autores, parte, capitulo, pagina, texto){

       let r = await api.post("/", {livro, autores, parte, capitulo, pagina, texto})
         
       return r.data
      
   }

   async deletegospel(id){
    let r = await api.delete(`/${id}`)
    return r.request.statusText
   }
 

}