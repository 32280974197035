import { Container } from "./styled";

import  GospelContextProvider from "./gospelContext";

import Gerenciar from "./gerenciar/index.js"
import Lista from "./listar/index.js"

export default function DailyGospel() {

     return (
        <Container>
          <GospelContextProvider>
            <Gerenciar/>
            <Lista/>
          </GospelContextProvider>
        </Container>
    ) 
}