import { createContext, useContext, useReducer } from "react";

const reducer = (state,action) =>{
    switch(action.type){
        case 'setTitulo': return {...state,eventos:{...state.eventos, titulo: action.titulo}};
        case 'setDescricao': return {...state,eventos:{...state.eventos, descricao: action.descricao}};
        case 'setImagem': return {...state,eventos:{...state.eventos, imagem: action.imagem}};
        case 'setData': return {...state,eventos:{...state.eventos, data: action.data}};
        case 'setAtivo': return {...state,eventos:{...state.eventos, ativo: action.ativo}};

        case 'setLinkTitulo': return {...state,eventos:{...state.eventos,link:{...state.eventos.link, linkTitulo: action.linkTitulo}}};
        case 'setLinkUrl': return {...state,eventos:{...state.eventos, link:{...state.eventos.link, linkUrl: action.linkUrl}}};

        case 'editEventos': return{...state,eventos:action.eventos}
        case 'novosEventos': return{...state, eventos: initialState.eventos }

        case 'setListagem': return {...state,  listagem: action.listagem};

        default:
            break;
    }
}



const initialState={
    titulo:'',
    eventos: {
        id:'',
        titulo:'',
        descricao:'',
        imagem:null,
        data:'',
        ativo:false,
        link: {
            linkTitulo:"",
            linkUrl:""
        }
    },
    listagem:[]
}





const EventContext = createContext();




export default function EventContextProvider(props) {
    const[eventoState,setEventoState]=useReducer(reducer, initialState)

    
    
    return(
        <EventContext.Provider value={{ eventoState, setEventoState}}>
            {props.children}
        </EventContext.Provider>
    )
}





export function UseeventContext() {
    const context = useContext(EventContext);

    const{ eventoState, setEventoState} = context
        return{
            eventoState,
            setTitulo:(titulo)=>setEventoState({type:'setTitulo', titulo}),
            setDescricao:(descricao)=>setEventoState({type:'setDescricao', descricao}),
            setImagem:(imagem)=>setEventoState({type:'setImagem', imagem}),
            setData:(data)=>setEventoState({type:'setData', data}),
            setAtivo:(ativo)=>setEventoState({type:'setAtivo',ativo}),
            setLinkTitulo:(linkTitulo)=>setEventoState({type:'setLinkTitulo', linkTitulo}),
            setLinkUrl:(linkUrl)=>setEventoState({type:'setLinkUrl', linkUrl}),


            setListagem:(listagem)=>setEventoState({type:'setListagem', listagem}),
            editEventos:(eventos)=>setEventoState({type:'editEventos', eventos}),
            novosEventos:()=>setEventoState({type:'novosEventos'})
            


        }
}