
import {Container, People, Format} from './styled'
import { API_URL } from '../../../service/config';

export default function Index(props){
  const people = props.people;
 
  const colors = ['#A25AFF',
                  '#0CCE84',
                  '#F14E1C',
                  '#18BCFF',
                  '#FE7362',
                  '#F5984C']

 
  function randomColor(colors){
    let length_array = colors.length
    let random_number = Math.floor(Math.random() * length_array);
    return colors[random_number]
  };
  function randowPosition(min, max){
    let number = [min, max]
    let length_array = number.length
    let random_number = Math.floor(Math.random() * length_array);
    return number[random_number]
  }
  

  return(
    <Container>
      <h1  >Participantes</h1>
      <div className="imgParticipant" > 
        {people.map(i => 
            <People photo={`${API_URL}/website/busca?imagem=${i.imagem.replace(/\\/g, '/')}`}  className="people"  >
              <Format 
                display={randomColor(['flex;','none;'])}
                bg={randomColor(colors)}
                format={randomColor(['50px 0px 0px 50px;','50px 0px 50px 50px;'])}
                top={randowPosition(-8,70) + '%'}
                left={randowPosition(-8,70) + '%'}
              />
            </People>
        )}
      </div>
    </Container>
  )
}