import axios from 'axios'
import { API_URL } from '../config.js'
const api = new axios.create({
    baseURL: `${API_URL}/website`    
})

export default class Api{

    async listNews(casa, categoria){
        let r = await api.get(`/noticia/list?casa=${casa}&categoria=${categoria}`)
        return r.data
    }
    async listRecentNews(casa){
        let r = await api.get(`/noticia/${casa}`)
        return r.data
    }
    async addLike(id, type){
        let r = await api.put(`/noticia/${id}/curtir`,{type})
        return r 
    }

    async addView(id){
        let r = await api.put(`/noticia/${id}/views`)
        return r.data;
    }

    async pagination(limit, categoria, subCategoria){
        let r = await api.get(`/noticia/itens/paginacao?limite=${limit}&categoria=${categoria}&subCategoria=${subCategoria}`);
        return r.data;
    }

    
}