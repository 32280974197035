import {Container, ContainerRender} from "./styled";
import {API_URL} from '../../../../service/config'

function  BoxAlbum(props){

    const info = props.info

    return(
        <Container image={info.images}>
            <div className="prev" />
            <div className="infos">
                <div>
                    <div className="title">{info.titulo}</div>
                    <p1>{info.descricao.length >= 50 ? info.descricao.substr(0, 50) + '...' : info.descricao}</p1>
                </div>
                <button onClick={() => props.open(info)}> Ver álbum </button>
            </div>
        </Container>
    )
}

function Box(props) {
    const info = props.info

    return (
        <Container image={`${API_URL}/website/busca?imagem=${info.imagem.replace(/\\/g, '/')}` }>
            <div className="prev" />
            <div className="infos">
                <div>
                    <div className="title">{info.titulo}</div>
                    <p1>{info.descricao.length >= 50 ? info.descricao.substr(0, 50) + '...' : info.descricao}</p1>
                </div>
                <button onClick={() => props.state(info)}> Ver álbum </button>
            </div>
        </Container>
    )
}

function Render(props){
    const images = props.images
    
    function alterState(info) {
        props.state(info)
    }
    return(
        <ContainerRender> 
            {images.map((i) => 
                <Box info={i} state={(info) => alterState(info)} />
            )}
        </ContainerRender>
    )
}

export {Box, Render, BoxAlbum}