import { Container } from "../styled";
import { useEffect } from "react";
import Title from "../../../../components/admin/title";
import { AdminContentBox } from "../../../../components/style/boxes";

import { UseeventContext } from "../eventContext";

import TableAdmin from "../../../../components/admin/tableAdmin";

import { Td, Tr } from "../../../../components/admin/tableAdmin/styled";

import { Text } from "../../news/listagem/styled";

import { RoundedDeleteIcon, RoundedEditIcon } from "../../../../components/style/assets";

import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";

import ApiEvents from "../../../../service/admin/events.js";

const apiEvents = new ApiEvents();

export default function Events() {
  const { eventoState: { listagem }, setListagem, editEventos } = UseeventContext();

  async function fListEvent() {
    let r = await apiEvents.listEvent()
    setListagem(r)
  }

  async function fDeleteEvent(id) {
    let r = await apiEvents.deleteEvent(id)
    if (r === "OK") {
      toast.success("Evento excluído com sucesso")
      fListEvent()
    } else {
      toast.error("Não foi possível excluir o evento")
    }
  }

  const Cdelete = async (id) => {
    let _id = id;
    confirmAlert({
      title: "Remover Evento",
      message: `Tem certeza que deseja remover este evento? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            fDeleteEvent(_id);
            fListEvent();
          },
        },
        { label: "Não" },
      ],
    });
  };

  function edit(item) {
    editEventos({
      id: item._id,
      titulo: item.titulo,
      descricao: item.descricao,
      imagem: item.imagem,
      data: item.data,
      ativo: item.ativo,
      link: {
        linkTitulo: item.link.titulo,
        linkUrl: item.link.url
      }
    })
  }

  useEffect(
    () => { fListEvent() }, 
    // eslint-disable-next-line 
    []
  )

  return (
    <Container>
      <AdminContentBox>
        <Title> Todos Eventos </Title>

        <div className="table-container">


          <TableAdmin
            length="3em"
            size="100%"
            columns={[
              { title: 'Título' },
              { title: 'Data' },
              { title: 'Ativo' },
              { title: '' },
              { title: '' }
            ]}>


            {listagem.map((item) => (
              <Tr>
                <Td> <Text> {item.titulo} </Text> </Td>
                <Td> <Text> {item.data} </Text> </Td>
                <Td> <input type="checkbox" checked={item.ativo} disabled='true' /> </Td>

                <Td config={{ visibility: 'hidden', width: '1em' }}
                  onClick={() => edit(item)} >
                  <RoundedEditIcon />
                </Td>

                <Td config={{ visibility: 'hidden', width: '1em' }}
                  onClick={() => Cdelete(item._id)}>
                  <RoundedDeleteIcon />
                </Td>
              </Tr>
            ))}

          </TableAdmin>

        </div>

      </AdminContentBox>
    </Container>
  )

}