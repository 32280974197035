import {
  RoundedDeleteIcon,
  RoundedEditIcon,
} from "../../../../../components/style/assets";
import { AdminContentBox } from "../../../../../components/style/boxes";
import { InputAdm } from "../../../../../components/style/inputs";
import Title from "../../../../../components/admin/title";
import { Label, Text } from "../../styled";
import { useState, useEffect } from "react";
import TableAdmin from "../../../../../components/admin/tableAdmin";
import { Td, Tr } from "../../../../../components/admin/tableAdmin/styled";
import { UseInstitutionContext } from "../../institutionContext";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocation } from "react-router-dom";

import ApiSNetwork from "../../../../../service/admin/institution";
const apiSNetwork = new ApiSNetwork();

export default function Index() {
  const { search } = useLocation();

  const {
    institutionState: {id, redesSociais: {nome,url,imagem}, networks},
    setNomerede,
    setUrlRede,
    setImgRede,
    setnetworks,
    editRedeSocial,
  } = UseInstitutionContext();

  const [idAlterando, setIdAlterando] = useState("");

  async function fListSNetwork() {
    let r = await apiSNetwork.listSNetwork(search.slice(4));
    setnetworks(r.redesSociais);
  }

  async function fNewSNetwork() {
    if (imagem === null) 
      return toast.error("Deve-se cadastrar uma imagem");

    if (idAlterando === "") {
      let r = await apiSNetwork.newSNetwork(nome, url, imagem, id);
      if (r.erro) {
        toast.error(r.erro);
      } else {
        toast.success("A rede social foi cadastrada com sucesso");
        fListSNetwork();
        clearConst();
      }
    } else {
      if (typeof imagem !== "string") {
        let r = await apiSNetwork.alterSNetworkPhoto(
          idAlterando,
          nome,
          url,
          imagem
        );
        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("A rede social foi alterada com sucesso");
          setIdAlterando("");
          fListSNetwork();
          clearConst();
        }
      } else {
        let r = await apiSNetwork.alterSNetwork(
          idAlterando,
          nome,
          url,
          imagem
        );

        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("A rede social foi alterada com sucesso");
          setIdAlterando("");
          fListSNetwork();
          clearConst();
        }
      }
    }
  }

  function clearConst() {
    editRedeSocial()
  }

  async function fDeleteSNetwork(imagem) {
    let r = await apiSNetwork.deleteSNetwork(id, imagem);
    if (r === "OK") {
      toast.success("Rede social excluída com sucesso");
      fListSNetwork();
    } else {
      toast.error("Não foi possível excluir a rede social");
      fListSNetwork();
    }
  }

  const SNdelete = async (imagem) => {
    confirmAlert({
      title: "Remover Rede Social",
      message: `Tem certeza que deseja remover esta rede social? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            fDeleteSNetwork(imagem);
          },
        },
        { label: "Não" },
      ],
    });
  };

  useEffect(() => {
    fListSNetwork();
  }, 
  // eslint-disable-next-line 
  [search]);

  return (
    <div className="networks">
      <ToastContainer />
      <AdminContentBox>
        <Title>Redes Sociais</Title>
        <div className="all">
          <div className="manager">
            <div>
              <div className="itemManager">
                <Label>Rede Social:</Label>
                <InputAdm
                  value={nome}
                  onChange={(e) => setNomerede(e.target.value)}
                />
              </div>
              <div className="itemManager">
                <Label>Link:</Label>
                <InputAdm
                  value={url}
                  onChange={(e) => setUrlRede(e.target.value)}
                />
              </div>
              <div className="itemManager">
                <Label>Imagem:</Label>
                <div style={{ width: "100%" }}>
                  <label className="input-file"> { imagem === null 
                                                  ? "ARQUIVO" 
                                                  : imagem.name === undefined 
                                                    ? nome.length >= 9 
                                                      ? nome.substring(0,9) + '.png'  
                                                      : nome + '.png' 
                                                  : imagem.name}
                      <input class="custom-file-input"  onChange={(e) => e.target.files[0] === undefined ? '' :setImgRede(e.target.files[0])} type="file"/>
                  </label>  
                </div>
              </div>
            </div>
            <button className="salveBtm" onClick={fNewSNetwork}>Salvar</button>
          </div>
          <div className="list">
            <TableAdmin
              length="3em"
              size="100%"
              columns={[{ title: "Rede Social" }, { title: "" }, { title: "" }]}
            >
              {networks.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    <Text> {item.nome} </Text>
                  </Td>

                  <Td
                    config={{ visibility: "hidden", width: "1em" }}
                    onClick={() => {
                      setIdAlterando(item._id);
                      setNomerede(item.nome);
                      setUrlRede(item.url);
                      setImgRede(item.imagem);
                      setIdAlterando(item._idRede)
                    }}
                  >
                    <RoundedEditIcon />
                  </Td>

                  <Td
                    config={{ visibility: "hidden", width: "1em" }}
                    onClick={() => SNdelete(item.imagem)}
                  >
                    <RoundedDeleteIcon />
                  </Td>
                </Tr>
              ))}
            </TableAdmin>
          </div>
        </div>
      </AdminContentBox>
    </div>
  );
}
