import styled from "styled-components";

const ButtonStyled = styled.button`

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.Bgcolor === undefined ? '#0180F5' : props.Bgcolor};
    color: ${(props) => props.fontColor === undefined ? 'white': props.fontColor};

    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    
    border: solid 0px;
    border-radius: ${(props) =>  props.radius === undefined ? '21px': props.radius};

    cursor: pointer;
    transition: .3s;

    padding: 11px 24px 13px 24px;

    img{
        margin-left: 0px;
        margin-top: 2px;
    }

    &:hover {
        background-color: ${(props) => props.HoverBgcolor === undefined ? '#0072db' : props.HoverBgcolor};
    }
`



const ButtonDark = styled.button`
    width: ${props => props.size};
    height: 40px;

    background-color: #2B3031;
    color: white;

    border: 0px;
    border-radius: 50px;

    font-family: Roboto-medium;
    font-size: 14px;

    margin: 10em 0em 0em 1em;
    cursor: pointer;

    &:hover {
      background-color: #394041;
      transition: all 0.5s ease-in;
    }
`


export default ButtonStyled;
export { ButtonDark }