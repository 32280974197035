import { Container } from './styled'
import InformacoesD from '../../../components/commum/home/infoCollections';
import ButtonStyled from "../../../components/style/button";
import Carousel from "react-multi-carousel";
import { CustomDot, responsive } from "./configCarousel";

import Api from '../../../service/home/donate'
import { useEffect } from 'react';
import { useState } from 'react';
const api = new Api();

export default function Doa(props) {
  const [donates, setDonates] = useState([]);
  const [loading, setLoading] = useState(false);


  async function listDonates() {
    let r = await api.listDonates();
    setDonates(r);
  }
  
  useEffect(() => {
    listDonates();
  }, [])
  
  return (
    
    <Container id={props.id}>
      <div className="part1">
        <div className="title"   > Arrecadações & Doações</div>
         <ButtonStyled >Veja mais sobre doações<img src="./assets/images/Icon metro-arrow-right.svg" alt=""/></ButtonStyled> 
      </div>
      
      <Carousel
          responsive={responsive}  
          arrows={false}
          showDots={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={10000}
          customDot={<CustomDot />}
          containerClass="BoxCaroucel"
          itemClass="itemCaroucel"
        >
        {donates.map((i, index) => (
          <InformacoesD info={i} key={index}/>
        ))}
      </Carousel>
    
     </Container>
  
  );
}
