import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	text-align: center;

	padding: 5em 0em 0em 0em;

	.title {
		font-family: "Manrope";
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		line-height: 60px;

		letter-spacing: -1.584px;

		color: #1c1f26;
		margin-bottom: 0.5em;
	}

	.text {
		font-family: "Manrope";
		font-style: normal;
		font-weight: 500;
		font-size: 1.1em;
		line-height: 30px;
		color: #777c86;

		width: 70%;
		margin: 0px auto;
		margin-bottom: 2em;
	}

	@media (max-width: 1280px) {
		.text {
			width: 70%;
		}
	}

	@media (max-width: 1024px) {
      .title {
         font-size: 40px;
      }
      .text {
         padding: 0em 2rem;
         width: 100%;
      }
	}
`;

const Imga = styled.div`
	background-image: url(${(props) => props.bg});
	background-size: cover;
	background-position: center center;

	width: 100%;
	height: 35em;
`;

export { Container, Imga };
