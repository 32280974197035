import axios from 'axios'
import { API_URL } from '../config'
const api = new axios.create({
    baseURL: `${API_URL}/website`    
})



export default class Api{
    async newSubscribe(email){
        let r = await api.post("/inscrever", {email})
        return r.data 
    }
}