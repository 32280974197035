import styled from "styled-components";

const Container = styled.div`
	padding-bottom: 2em;
	p {
		margin: 0px;
	}
	
  .content {
	max-width: 60%;
    width: auto;
    height: 60%;
  }
  
  .modalImg {
	max-width: 100%;
    width: auto;
    height: 100%;
  }


	.box-conteudo {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 2.5em;
	}

	.pt1 {
		display: flex;
		flex-direction: column;
		width: 50%;
		margin-bottom: 1em;
	}

	.disable {
		display: none;
		opacity: 0.5;
		border: solid 2px #1c1f26;
		color: white;
		font-size: 1em;
		font-family: "Manrope";
		font-weight: bolder;
		align-self: center;
		background-color: #1c1f26;
		color: white;
		padding: 0.5em 2em;
		border: solid 1px;
	}

	.notices {
		padding-right: 1em;

		scrollbar-track-color: #ffffff;

		::-webkit-scrollbar {
			width: 11px;
		}

		::-webkit-scrollbar-track {
			background: #ffffff;
		}

		::-webkit-scrollbar-thumb {
			background-color: #cecece;
			border-radius: 12px;
			border: 1px solid #ffffff;
		}
		animation: slideInLeft;
		animation-duration: 2s;
	}

	.home {
		font-family: Manrope;
		font-weight: bold;
		font-size: 40px;
		line-height: 60px;
		color: #1c1f26;
	}

	.detail {
		font-family: Manrope;
		font-weight: bold;
		font-size: 30px;
		line-height: 60px;
		color: #364c63;
	}

	.titles {
		margin-top: 0.5em;
	}

	.btm-more {
		margin: 1em 0px;
		font-size: 1em;
		font-family: "Manrope";
		font-weight: 600;
		cursor: pointer;
		align-self: center;

		border: unset;
		background-color: unset;
		color: #1c1f26;
		padding: 0.5em 2em;
	}

	.higth {
		padding: 1em;
    align-items: flex-start;
    flex-direction: column-reverse;

    .box-pt2 {
      width: 100%;
      margin: 0px;
      padding: .5em;
    }
    .extra {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1em;
    
      .box-interaction {

        .interaction img {
          margin: 0em;
          margin-right: 0.4em;
        }
        margin-bottom: 1em;
      }
    }
	}

  .filterNews {
    display: none;
    font: 600 1em 'Manrope';
    align-self: flex-end;
    cursor: pointer;
  }


  
	@media (max-width: 1440px) {
		.pt1 {
			width: 60%;
		}
	}

	@media (max-width: 1280px) {
		.pt1 {
		}
	}


  
	@media (max-width: 1024px) {
    .filterNews {
      display: block;
    }

    .notices {
      padding-right: 0em;
    }
    
    .low {
      padding: 1em;
      align-items: flex-start;
      flex-direction: column-reverse;

      .box-pt2 {
        width: 100%;
        margin: 0px;
        padding: 0em .5em;
      }
      .extra {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1em;
      
        .box-interaction {

          .interaction img {
            margin: 0em;
            margin-right: 0.4em;
          }
          margin-bottom: 1em;
        }
      }
    }

		
	.content {
		max-width: 90%;
		width: auto;
		height: auto;
	}
	
	.modalImg {
		max-width: 100%;
		width: auto;
		height: 100%;
	}




    .pt1 {
      width: 85%;
    }
		.pt2 {
			
    }




		.box-conteudo {
			position: relative;
      animation-name: ${props => props.showCustomMenu === undefined ? 'unset' : props.showCustomMenu ? 'slideMenuLeft' : 'slideMenuRight'};
      animation-duration: 1s;
      animation-fill-mode: forwards;
		}

    @keyframes slideMenuLeft {
      0% { left: 0em; }
      100% { left: -27em; }
    }

    @keyframes slideMenuRight {
      from { left: -27em; }
      to { left: 0em; }
    }

    .containerMenu {
      position: absolute;
      top: 3em;
      right: -25em;
      transition: all 5s cubic-bezier(0, 1, 0, 1);
    }
  
    
  
	}



`;

export { Container };
