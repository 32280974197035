import { createContext, useContext, useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case 'setNome': return { ...state, evento: { ...state.evento, nome: action.nome } };
    case 'setAtividade': return { ...state, evento: { ...state.evento, atividade: action.atividade } };
    case 'setTitulo': return { ...state, evento: { ...state.evento, titulo: action.titulo } };
    case 'setDescricao': return { ...state, evento: { ...state.evento, descricao: action.descricao } };
    case 'setAtivo': return { ...state, evento: { ...state.evento, ativo: action.ativo } };
    case 'setDestaque': return { ...state, evento: { ...state.evento, destaque: action.destaque } };
    case 'setBotao': return { ...state, evento: { ...state.evento, botao: action.botao } };
    case 'setUrl': return { ...state, evento: { ...state.evento, url: action.url } };
    case 'setImagem': return { ...state, evento: { ...state.evento, imagem: action.imagem } };
    case 'setUrlImg': return { ...state, evento: { ...state.evento, urlImg: action.urlImg } };
    case 'setImagem1': return { ...state, evento: { ...state.evento, imagem1: action.imagem1 } };
    case 'setUrlImg1': return { ...state, evento: { ...state.evento, urlImg1: action.urlImg1 } };
    case 'setImagem2': return { ...state, evento: { ...state.evento, imagem2: action.imagem2 } };
    case 'setUrlImg2': return { ...state, evento: { ...state.evento, urlImg2: action.urlImg2 } };
    case 'setImagem3': return { ...state, evento: { ...state.evento, imagem3: action.imagem3 } };
    case 'setUrlImg3': return { ...state, evento: { ...state.evento, urlImg3: action.urlImg3 } };
    case 'setData': return {...state, evento: {...state.evento, data: action.data}}
    case 'setSelectedInstitution': return{...state, selectedInstitution: action.selectedInstitution }
    case 'editEvento': return { ...state, evento: action.evento };
    case 'novoEvento': return { ...initialState };

    case 'setListagem': return { ...state, listagem: action.listagem };

    default:
      break;
  }
}



const initialState = {
  titulo: 'Novo',
  evento: {
    id: null,
    data: '',
    nome: '',
    atividade: '',
    titulo: '',
    descricao: '',
    ativo: false,
    destaque: false,
    botao: '',
    url: '',
    imagem: null,
    urlImg: null,
    imagem1: null,
    urlImg1: null,
    imagem2: null,
    urlImg2: null,
    imagem3: null,
    urlImg3: null,
  },
  selectedInstitution: '',
  listagem: []
}






const EventContext = createContext();


export default function EventContextProvider(props) {
  const [eventoState, setEventoState] = useReducer(reducer, initialState);

  return (
    <EventContext.Provider value={{ eventoState, setEventoState }}>
      {props.children}
    </EventContext.Provider>
  )
}





export function UseeventContext() {
  const context = useContext(EventContext)

  const { eventoState, setEventoState } = context;

  return {
    eventoState,
    setNome: (nome) => setEventoState({ type: 'setNome', nome }),
    setAtividade: (atividade) => setEventoState({ type: 'setAtividade', atividade }),
    setTitulo: (titulo) => setEventoState({ type: 'setTitulo', titulo }),
    setDescricao: (descricao) => setEventoState({ type: 'setDescricao', descricao }),
    setDestaque: (destaque) => setEventoState({ type: 'setDestaque', destaque }),
    setAtivo: (ativo) => setEventoState({ type: 'setAtivo', ativo }),
    setBotao: (botao) => setEventoState({ type: 'setBotao', botao }),
    setUrl: (url) => setEventoState({ type: 'setUrl', url }),
    setImagem: (imagem) => setEventoState({ type: 'setImagem', imagem }),
    setUrlImg: (urlImg) => setEventoState({ type: 'setUrlImg', urlImg }),
    setImagem1: (imagem1) => setEventoState({ type: 'setImagem1', imagem1 }),
    setUrlImg1: (urlImg1) => setEventoState({ type: 'setUrlImg1', urlImg1 }),
    setImagem2: (imagem2) => setEventoState({ type: 'setImagem2', imagem2 }),
    setUrlImg2: (urlImg2) => setEventoState({ type: 'setUrlImg2', urlImg2 }),
    setImagem3: (imagem3) => setEventoState({ type: 'setImagem3', imagem3 }),
    setUrlImg3: (urlImg3) => setEventoState({ type: 'setUrlImg3', urlImg3 }),
    setData: (data) => setEventoState({ type: 'setData', data }),
    setSelectedInstitution: (selectedInstitution) => setEventoState({ type: 'setSelectedInstitution', selectedInstitution }),
    setListagem: (listagem) => setEventoState({ type: 'setListagem', listagem }),
    editEvento: (evento) => setEventoState({ type: 'editEvento', evento }),
    novoEvento: () => setEventoState({ type: 'novoEvento' }),
  }

}