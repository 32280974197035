import styled from 'styled-components';

const Container = styled.div`
    height: 98vh;
    width: 100%;

    display: flex;
    flex-direction: column;

    background: url(${(props) => props.bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 100%;
    position: relative;

    

    .texts{
        width: 75%;
        height: 78%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
        align-self: center;


        animation:fadeInLeftBig;
        animation-duration: 2s;
    }
    h1{
                
        font-family: 'Canela';
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 50px;

        color: #FBDE37;
    }

    p1{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;

        color: #D6D6D6;

        max-width: 29em;
    }
    .icon{
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

        position: absolute;
        width: 2.8em;
        height: 2.8em;

        bottom: -1em;
        left: 49%;

        border-radius: 50% 50%;
        
        cursor: pointer;

        background: #F7C23C;
        border: 6px solid #FFFFFF;
        box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.14);

        img{
            cursor: pointer;
        }


        animation:fadeInDownBig;
        animation-duration: 2s;
        
    }
    
    
    .ani {
        -moz-animation: ani 2s infinite;
        -webkit-animation: ani 2s infinite;
        animation: ani 2s infinite;
    }

    @keyframes ani {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
    
    }
    @media (max-width: 1024px){
        h1 {
            font-size: 3rem;
        }
    }
`


export default Container