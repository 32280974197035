import { Container } from "./styled";

export default function Index(props) {
  const info = props.info;

  function extrairTextoPlano(html) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html.replaceAll('<br>', ' - ');
    return (tempDiv.textContent || tempDiv.innerText || '').replace(/\s*\n\s*/g, ' - ');
  }
  
  return (
    <Container> 
      <p1>{info.nome}</p1>
      <div className="datas">
        {info.descricoes[0] === undefined ? '-' :
        info.descricoes.map((i) =>
          <div className="text"> 
            <div className="data">{extrairTextoPlano(i)}</div>
          </div>
        )
        }
      </div>
      
    </Container>
  );
}
