import { Container } from "./styled";

export default function Phrases (props) {
  return (
    <Container>
      <div className="boxone">
        
        <img className="evaluation" src='../assets/images/avaliacao.png' alt="" />
        <div className="phrase">  
          <img className="aspas One" src="../assets/images/aspa.svg" alt=""/>
            {props.info.mensagem.texto}  
          <img className="aspas Two" src="../assets/images/aspa.svg" alt=""/>
        </div>
        <div className="phrase booktitle">
          {props.info.livro.titulo}
          {props.info.livro.autores === undefined ? '' : ', ' + props.info.livro.autores}
        </div>
        <div className="chapter"> 
          {props.info.mensagem.capitulo === undefined ? '' : props.info.mensagem.capitulo}
          {props.info.mensagem.parte === undefined ? '' : '. ' + props.info.mensagem.parte}  
          {props.info.mensagem.pagina  === undefined ? '' : '. pág. ' + props.info.mensagem.pagina}  
            
             
        </div>
      </div>
    </Container>
  );
}
