import { Container } from "./styled";

import { UseActivityContext } from "./activityContext";

import InfoAll  from './infoAll/'
import InfoMore from './infoMore/'
import Contact  from './contact/'
import Networks from './netWorks/'
import Participant from './participant/'
import Gallery from './gallery/'
import Calender from   './Calendar/'

import { useEffect } from "react";
import { useLocation } from 'react-router-dom';


export default function Activity() {
    
    const info = useLocation().state;
    const {
        activityState: {_id},
        set_ID,
        setId
    } = UseActivityContext()

    
    useEffect(() => {
            if (info.idAtividade !== undefined){
                set_ID(info.idAtividade)
                setId(info.idCasa)
            }  else {
                setId(info.idCasa)
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        // eslint-disable-next-line 
        []
    )
    return (
        <Container>
            <InfoAll/>
            <div className="Opacity"  style={
                _id === '' ? {
                pointerEvents: 'none',
                opacity: '0.4'
                } : {

                }
            } 
            >
                <InfoMore/>
                <Contact/>
                <Networks/>
                <Participant/>
                <Gallery/> 
                <Calender/> 
            </div>
        </Container>
    )
}