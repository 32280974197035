import styled from "styled-components";

const Container = styled.div`
	padding: 0.8em;
	border: 1px solid #e1e1e1;
	border-radius: 21px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 1em 0;
	position: relative;

	.box-pt1 {
		width: 12.35em;
		animation: slideInLeft;
		animation-duration: 2s;
	}

	.select {
		border: solid 2px;
		border-color: #0180f5;
	}

	.teme {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		width: 100%;
		
		margin-top: 7px;
		margin-bottom: 10px;

		color: #364c63;
		display: flex;
		justify-content: space-between;
		text-transform: uppercase;
	}
	.more-info {
		font-family: "Manrope";
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-align: right;

		color: #6f6f6f;
		cursor: pointer;
	}
	.title {
		font-family: Manrope;
		color: #1c1f26;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;
	}

	.data {
		color: #7e8494;
		font-family: Manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		text-decoration: none;
	}

	.desc {
		font-family: Manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		margin: 1em 0em 0em;
	}

	.box-pt2 {
		position: relative;
		display: flex;
		flex-direction: column;
		margin: 0em 1em;
		width: 68%;
		justify-content: space-between;
		gap: 20px;
	}

	

	.img-two {
		display: flex;
		flex-direction: row;
		margin: 0.7em 0em 0em;
		cursor: pointer;
		& img {
			width: 42px;
			height: 42px;
			border-radius: 7px;
			cursor: pointer;
			margin-right: 0.2em;
		}
	}
	.infos {
		margin-top: 10px;
		font-size: 1em;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
		font-weight: bold;

		cursor: pointer;
	}

	.extra {
		font-family: Manrope;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.box-interaction {
			display: flex;
			justify-content: space-between;
			width: auto;

			.interaction {
				display: flex;
				flex-direction: row;
				align-items: center;
				transition: all 1s;
				justify-content: space-between;
				margin: 0em 0.2em 0em 0em;
			}

			.interaction img {
				margin: 0em 0.4em 0em 0em;
			}
		}
	}

	.saibamais {
		position: absolute;
		font: 500 0.85em "Manrope";
		color: #6f6f6f;
		right: 1em;
		top: 1em;
		padding: 5px 10px;
		border-radius: 50px;
		z-index: 99;
		cursor: pointer;
	}

	.saibamais a {
		cursor: pointer;
	}
	

	.img-one img {
		width: 197px;
		height: 197px;
		border-radius: 14px;
		cursor: pointer;
	}

	.imagem {
        border-radius: 11px;

        background: url(${(props) => props.img});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        
        /* width: 150px;
        min-width: 150px;
        max-width:150px; */
        width: 197px;
		height: 197px;
		border-radius: 14px;
		cursor: pointer;
        
        background-size: ${(props) => props.bgSize === undefined ? "cover" : props.bgSize};

        /* margin-right: 2em;
        animation: fadeInLeft;
        animation-duration: 2s; */
    }


  @media (max-width: 1024px) {
    .saibamais {
      top: 2em;
    }
  }
`;



const Imagem = styled.div`
	border-radius: 11px;

	background: url(${(props) => props.img});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	
	/* width: 150px;
	min-width: 150px;
	max-width:150px; */
	width: 197px;
	height: 197px;
	border-radius: 14px;
	cursor: pointer;
	
	background-size: ${(props) => props.bgSize === undefined ? "cover" : props.bgSize};

	/* margin-right: 2em;
	animation: fadeInLeft;
	animation-duration: 2s; */

	.select {
		border: solid 2px;
		border-color: #0180f5;
	}
`



const Thumbnail = styled.div`
	border-radius: 11px;

	background: url(${(props) => props.img});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	
	background-size: ${(props) => props.bgSize === undefined ? "cover" : props.bgSize};

	width: 42px;
	height: 42px;
	border-radius: 7px;

	cursor: pointer;
	margin-right: 0.2em;

	.select {
		border: solid 2px;
		border-color: #0180f5;
	}
`

export { Container, Imagem, Thumbnail };
