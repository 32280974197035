import axios from 'axios'
import { API_URL } from '../config'

const api = new axios.create({
    baseURL: `${API_URL}/admin/casa`,
    headers: {
      'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYnJ1bm9kZW9saXZlaXJhLjIyLjEwQGdtYWlsLmNvbSIsImlhdCI6MTY0MzgzOTk0N30.oBFi1LnfeJ5h2ZCEAhC44HD5V0uyqolgg43J-HtI8Oo",
      'Content-Type': 'application/json'
    }
})


export default class ApiInstitution{

    async getHouse(){
        let r = await api.get('/name')
        return r.data
    }

    async getGeral(nome){
        let r = await api.get(`/${nome}/geral`)
        return r.data
    }

    async putGeral(id, nome, ordem, descricao, fundacao){
        let r = await api.put(`/${id}/geral`,{nome, ordem, descricao, fundacao})
        return r.data
    }

    
    async postPhoto(id, imagem) {
        
        let formData = new FormData();
        formData.append('capa', imagem[0])
        formData.append('capa', imagem[1])
        let r = await api.put(`/${id}/imagens/`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});
        
        return r.data
    }

    async putCapa(id, capa, caroucel){
            
        let formData = new FormData();
        formData.append('capa', capa)
        formData.append('carousel', caroucel)
        let r = await api.put(`/${id}/imagens/capa`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});
        
        return r.data

    }
    async putCaroucel(id, capa, caroucel){
            
        let formData = new FormData();
        formData.append('capa', capa)
        formData.append('carousel', caroucel)
        let r = await api.put(`/${id}/imagens/carousel`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});
        
        return r.data

    }

    async getPhoto(nome){
        let r = await api.get(`/${nome}/imagens`)
        return r.data
    }



    async getContact(nome){
        let r = await api.get(`/${nome}/contato`)
        return r.data
    }
    async putContact(id,endereco,latitude,longitude,telefone1,telefone2, email){
        let r = await api.put(`/${id}/contato`,{
            id,
            endereco,
            latitude,
            longitude,
            telefone1,
            telefone2,
            email
        })
        return r.data
    }





    /*----------------------------------------------------------------------------------------------------------*/


    async listSNetwork(nome){
         let r = await api.get(`/${nome}/redesocial`)
        return r.data
    }

    async newSNetwork(nomeRede, urlRede, imagemRede, id){
        let formData = new FormData();
        formData.append('nome', nomeRede);
        formData.append('url', urlRede);
        formData.append('imagem', imagemRede);


        let r = await api.post(`/${id}/redesocial`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});
        return r.data
    
    }

    async alterSNetworkPhoto(id,nomeRede, urlRede, imagemRede) {
 
        let formData = new FormData();
        formData.append('nome', nomeRede);
        formData.append('url', urlRede);
        formData.append('imagem', imagemRede);


        let r = await api.put(`/redesocialFotos/${id}`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});
        return r.data
    }

    async alterSNetwork(id,nome, url, imagem){
        let r = await api.put(`/redesocial/${id}`, {nome, url,  imagem})
        return r.data
    }


    async deleteSNetwork(id,imagem){
        let r = await api.put(`/${id}/redesocial/`, {imagem})
        return r.request.statusText
    }

























    async listOrientador(nome){
        let r = await api.get(`/${nome}/orientacao`)
       return r.data
   }

   async newOrientador(nome, horario, imgOrientador, id){
       let formData = new FormData();
       formData.append('nome', nome);
       formData.append('horario', horario);
       formData.append('imagem', imgOrientador);


       let r = await api.post(`/${id}/orientacao`, formData, {
           headers: {
           "Content-Type": "multipart/form-data"
       }});
       return r.data
   
   }

   async updateOrientadorPhoto(id, nome, horario, imagem) {

       let formData = new FormData();
       formData.append('nome', nome);
       formData.append('horario', horario);
       formData.append('imagem', imagem);

       let r = await api.put(`/orientacaoFotos/${id}`, formData, {
           headers: {
           "Content-Type": "multipart/form-data"
       }});

       return r.data
   }

   async updateOrientador(id,nome, horario, imagem){

       let r = await api.put(`/orientacao/${id}`, {nome, horario,  imagem})
       return r.data
   }


   async deleteOrientador(id,imagem){
       let r = await api.put(`/${id}/orientacao/`, {imagem})
       return r.request.statusText
   }




}