import styled from 'styled-components'


const Container = styled.div`


.box-form{
    justify-content: space-between;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.form-one{
    display: flex;
    flex-direction: row;
    padding-bottom: .5em;
}

.form-two{
    display: flex;
    flex-direction: row;
    padding-bottom: .5em;
}

.compartment{
    padding-top: 1.8em;
}
.pag{
    width: 100%;
}
.form-three{
  display: flex;
  flex-direction: row;
  height: 100%;
  
  textArea{
    height: 100%;
  }
}

.page{
    width: 3em;
}

.forms-men{
    height: auto;
    width: 50%;
}




`

 const Label = styled.div`
    font: 1em Roboto;
    width: 150px;
    text-align: right;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
`

 const Text = styled.div`
    font: 1em Roboto;
    text-align: left;
    margin-right: 10px;
`
export { Container, Label, Text }