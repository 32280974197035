import { useDonateContext } from "../../../context/donateContext";
import ChooseImage from "../comps/chooseImage";
import InputLabel from "../comps/inputLabel";
import { Container } from "../styled";
import { getLayout } from "./model";




export default function Layout2() {
    const {
        columns,
        setLayout
    } = useDonateContext();


    
    let [ column1, column2 ] = columns;
    
    let { image } = column1;
    let { title, description } = column2;



    const setLayoutValues = (values) => {
        let l = getLayout(
            values.image            ?? image,
            values.title            ?? title,
            values.description      ?? description);
        setLayout(l);
    }

    
    
    return (
        <Container style={{ justifyContent: 'center' }}>
            <div className="image" style={{marginRight: '2em'}}>
                <ChooseImage value={image} onChange={value => setLayoutValues({ image: value})} />
            </div>

            <div className="text-box" style={{width: '27em'}}>
                <div className="title">
                    <InputLabel value={title} onChange={value => setLayoutValues({ title: value})} width="20em" />
                </div>
                <div className="description">
                    <InputLabel value={description} onChange={value => setLayoutValues({ description: value})} type="textarea" width="20em" />
                </div>
            </div>

        </Container>
    )
}