import { Container } from "./styled.js";
import PartOne from './partOne';
import Rodape from '../../components/commum/rodape'
import { data } from "./apiresponse.js";
import EventContextProvider from './newsContext.js'
import { ToastContainer} from "react-toastify";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function Index(){
  const titles = [{title:'Casas Afiliadas', local:'1', pag: '/'}, {title: 'Fique Atento', local:'2', pag: '/'}, {title:'Doações', local:'3', pag: '/'}, {title:'Palestras', local:'5',  pag: '/'},{title:'Notícias', local:'6',  pag: '/'}];
  
  const notice = useLocation().state;

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth'})
  }, []);

  return (
    <EventContextProvider>
      <Container> 
        <ToastContainer />
        <PartOne info={notice !== null ? notice : 'Geral'} titles={titles}/>
        <Rodape info={data} />
      </Container>
    </EventContextProvider>
  )
}