import {Container} from "./styled";

import PartOne from './initial/';
import PartTwo from './notices/';
import PartThree from './phrase/';
import PartFour from './donate/';
import PartFive from './eventOne/';
import PartSix from './eventTwo/';
import Rodape from "../../components/commum/rodape";

import 'react-multi-carousel/lib/styles.css';
import { useEffect } from "react";
import { useLocation  } from "react-router-dom";

export default function Home(){
    const titles = [{title:'Casas Afiliadas', local:'1'}, {title: 'Fique Atento', local:'2'}, {title:'Doações', local:'3'}, {title:'Palestras', local:'5'},{title:'Notícias', /*local:'6' scrool: null/*'6'*/}];

    const position = useLocation().state;

    const scrool = () => {
        let element = document.getElementById(position);
            element.scrollIntoView({behavior: 'smooth'});
    }
    useEffect(
        () => {if(!!position) scrool()},  // eslint-disable-next-line 
        []
    )
    return(
        <Container>
            <div className="center">
                <PartOne id='1'  titles={titles}/>
                <PartTwo id='2' />
                <PartFour id='3' />
                <PartThree id='4' />
                <PartFive id='5' className="2" />
                <PartSix id='6'/>  
            </div>
            <Rodape id='7'  /> 
        </Container>

    )
} 