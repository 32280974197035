
import { useEffect, useState } from 'react'
import Albums from './album'
import NewAlbum from './newAlbum'


import { UseInstitutionContext } from "../../institutionContext";

import  ApiAlbum  from '../../../../../service/admin/album';
import ApiGaleria from '../../../../../service/admin/galeria';
const apiAlbum = new ApiAlbum()
const apiGaleria = new ApiGaleria()

export default function Galley() {
    const [ type, setType ] = useState(false)

    const { 

        institutionState: {
            id
        },
        
        setInfoAlbum,
        setAlbumsArray,
        setGaleriaImages

    } = UseInstitutionContext();
    

    function altType() {
        setType(!type)
        getAlbum()
    }


    async function altAlbm(album) {
        
        if (album === "New") {
            
            setInfoAlbum( 
                {
                    _id: "",
                    atividadeId: "",
                    centroespiritaId: "",
                    descricao: "",
                    imagem: null,
                    titulo: "Novo"
                }
            )  
            
            setGaleriaImages([])
        } else {
            let r = await apiGaleria.listgaleria(album._id)
     
            setGaleriaImages(r)
            setInfoAlbum(album)
        }
        altType()
    }

    async function getAlbum() {
        if (id === '')
            return
        
        let r = await apiAlbum.listAlbuns(id)
        setAlbumsArray(r)
    }
    
    useEffect(
    () => {getAlbum()}, 
    // eslint-disable-next-line 
    [id]);
    
    if(type === false){
        return (<Albums type={altAlbm}/>)        
    } else {
        return (<NewAlbum type={altType}/>)
    }
    
}