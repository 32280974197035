import styled from "styled-components";

const InfoA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20em;
  height: 25em ;
  padding: 1em 1.5em;
  background-color: white;
  border-radius: 1em;
  transition: 0.3s;

  &:hover{
    transform: scale(1.05)
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
    height: 14.5em;
    
    //background-color: #fafbff;
    border-radius: 10px;

  
  }

  img{
    width:58%;
    height: 8em;
  }


  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .title {
    font-family: Roboto;
    font-weight: 500;
    font-size: 1.5em;
    color: #604949;
    text-align: center;
  }

  .desc {
    font-family: Roboto;
    font-weight: 400;
    font-size: 0.9em;
    color: #747474;
    margin-top: 1.5em;
    padding: 0px 10px;
    text-align: center;
  }

  .button {
    align-self: center;
    margin-top: 1.5em;

  
  }

  button {
    align-self: center;
    padding: 0.8em 1.4em 1em;

    cursor: pointer;
    border-radius: 1.5em;

    background-color: #e8efff;
    border: black;
    color: #004fff;

    &:hover {
      background-color: #004fff;
      color: #e8efff;
      transition: 0.7s;
    }
  }



  @media (max-width: 1024px) {
      margin-left: 1em;
      margin-right: 1em;
  }
  
`;

export { InfoA };
