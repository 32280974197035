import React from "react";
import * as C from "./styled";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../service/config";



//
// Notícias Passadas
//
export default function App(props) {
  const navigation = useNavigate();


  function OpenModal() {
    if (props.record === false) {
      navigation("/noticias",{ state: props.all });
    }
    return props.open({ status: true, link: props.url });
  }

  function JoinsDate(fullDate) {
    let date = new Date(fullDate);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString().substring(0,5)}`;
  }

  
  return (
    <C.Container onClick={OpenModal} className="cont" img={props.image}>
      {/* <img src={props.image} alt="" /> */}
      <div className="imagem" />

      <C.Pt2>
          <div className="grupo">
            <C.Data>
              <div className="text">{props.data.length > 10 ? JoinsDate(props.data): props.data.split('-').reverse().join('/')}</div>
            </C.Data>

            <div className="TextTitle">
              {props.casa !== undefined ? (
                <C.Casa> {props.casa}</C.Casa>
              ) : (
                <C.Casa> {null} </C.Casa>
              )}

              <C.Title>
                {props.title.length >= 15 && false
                  ? props.title.substring(0, 8) + "..."
                  : props.title}
              </C.Title>
            </div>
          
          </div>

        <C.Extra >
          {props.record === true ? "Assista por aqui" : "Veja mais"}
        </C.Extra>
      </C.Pt2>
    </C.Container>
  );
}
