import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;

 


  padding: 0em 0em 12em 0em;
  .carousel-container {
      .react-multi-carousel-item--active .cont {
        opacity: 1;
      }
      .react-multi-carousel-item{
        display: flex;
        justify-content: center;
        align-items: center;
      }
  } 

  @media (max-width: 1280px) {
    h1 {
      font-size: 40px;
    }
  }

  @media (max-width: 1024px) {
    padding: 0em 2em 12em 2em;
  }
`;

export {Container}
