import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  //height: 100vh;
  margin-bottom: 3em;
 

  .infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-height: 27vh;
    justify-content: space-evenly;
    margin-top: 1em; 
    @media (max-width: 768px) {
      max-height: none;
    }
  }
  
  .home{
    max-width: 1322px;
    max-height: 811px;
    align-self: center;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media (max-height: 795px) {
      height: 795px;
    }
  }
  .title {
    display: flex;
    flex-direction: column;
    font-family: "Manrope";
    font-weight: bold;
    font-size: 20px;
    margin-bottom:1em;

    margin-top: .5em;

    text-align: center;

    color: #1c1f26;
    & span {
      font-size: 30px;
      margin-top:.2em;
    }
  }
  .text {
    text-align: center;
    max-width: 700px;
    font-family: "Manrope";
    font-size: 1em;

    text-align: center;

    color: #646975;
  }
  .btms {
    display: flex;
    justify-content: space-between;
    width: 28em;
    @media (max-width: 700px) {
      width: auto;
    }
  }


  .overlay {
      display: flex;
      justify-content: center;
      
      width: 100%;
      height: 32em;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

    }
    
    .wrapper {
      width: 100vw;
      max-width: 1322px;
    }

// Customization of the first carousel
  .box-carousel {
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1279px;
    margin: 0px auto;


    .carousel-container {
      position: relative;
      width: 100%;
      height: 32em;
      display: flex;
      justify-content: space-between;

      .custom-right-arrow {
        position: absolute !important;
        right: 30px;
        z-index: 1;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
        opacity: 0.8;
        cursor: pointer;
      }
      .custom-right-arrow img {
        width: 3em;
        cursor: pointer;
      }

      .custom-left-arrow {
        position: absolute !important;
        left: 30px;
        z-index: 1;
        border-width: 0 3px 3px 0;
        display: inline-block;
        cursor: pointer;
      }

      .custom-left-arrow img {
        cursor: pointer;
        width: 3em;
      }
      .react-multi-carousel-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .react-multi-carousel-item div .information {
        display: none;
      }

      .react-multi-carousel-item--active div .information {
        display: flex;
        
      }
      .react-multi-carousel-item--active .center {
        height: 27em;
        box-shadow: inset 0px -180px 200px 50px rgba(0,0,0,0.80), rgb(38 57 77) 0px 20px 30px -10px;

        &:hover {
          box-shadow: inset 0px -30em 200px 50px rgba(0,0,0,0.7), rgb(38 57 77) 0px 20px 30px -10px;
        }
      }
      

     .custom-dot {
        width: 19px;
        height: 19px;

        border-radius: 20px;
        background: #dadada;

        border: solid 0px;
        margin-left: 5px;
      }
      .custom-dot--active {
        background: #160e4a;
        border-radius: 9.5px;
        width: 41px;
        height: 19px;
      }
    }
  }

  @media (min-height: 400px) {
    .btms{
      margin-top: 1em;
    }
  }
  @media (max-width: 1280px){
    height:50em;

    .react-multi-carousel-item{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .home{
      width: 100%;
      max-width: auto;
    }
  }
  @media (max-width: 1024px){
    display: flex;
    flex-direction: column;
    height:60em;

    .overlay {
        height: 33em;
    }

    .home{
      width: 100%;
      max-width: auto;
      justify-content: space-evenly;
    }
    .infos{
      width:97%;
    
    }
    .text{
      width:90%;
    }
    .btms{
      display: flex;
      flex-direction: column;
      align-items: center;
      button{
        margin: .5em;
      }
    }
    .box-carousel {
      max-width:800px;
    }

}
  
  
`;

export { Container };
