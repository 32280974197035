import Gerenciar from "./gerenciar";
import Listagem from "./listagem";
import { Container } from "./styled";

import EventContextProvider from './newsContext.js'
import { ToastContainer} from "react-toastify";


export default function News() {
    return (
        <EventContextProvider>
            <Container>
                <ToastContainer />
                    <Gerenciar />
                    <Listagem />
            </Container>
        </EventContextProvider>
    )
}