import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5em;
    width: 100%;
    h1{
        font-family: 'Manrope';
        font-weight: bold;
        font-size: 50px;
        line-height: 68px;
        text-align: center;

        color: #1C1F26;
    }
    .gallery{
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

`

export {Container}