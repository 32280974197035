import {
  RoundedDeleteIcon,
  RoundedEditIcon,
} from "../../../../../components/style/assets";
import { AdminContentBox } from "../../../../../components/style/boxes";
import { InputAdm } from "../../../../../components/style/inputs";
import Title from "../../../../../components/admin/title";
import { Label, Text } from "../../styled";
import { useEffect, useState } from "react";
import TableAdmin from "../../../../../components/admin/tableAdmin";
import { Td, Tr } from "../../../../../components/admin/tableAdmin/styled";
import { UseInstitutionContext } from "../../institutionContext";
import { useLocation } from "react-router-dom";
import ApiInstitution from "../../../../../service/admin/institution";

import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const api = new ApiInstitution();

export default function Index() {
  const { search } = useLocation();
  const [idAlterando, setIdAlterando] = useState("");

  const {
    institutionState: {id, orientacao: {horario,nome, imagem}, participate},
    setNomeOrientacao,
    setHorarioOrientacao,
    setImagemOrientador,
    setParticipante,
  } = UseInstitutionContext();


  async function fListSorientacao() {
    let r = await api.listOrientador(search.slice(4));
    setParticipante(r.orientacao);
  }

  async function fNewOrientacao() {
    if (imagem === null)
      return toast.error("Deve-se cadastrar uma imagem");

    if (idAlterando === "") {
      let r = await api.newOrientador(nome, horario, imagem, id);
      if (r.erro) {
        toast.error(r.erro);
      } else {
        toast.success("Orientador foi cadastrada com sucesso");
        clearConst()
        fListSorientacao();
      }
    } else {
      if (typeof imagem !== "string") {
        let r = await api.updateOrientadorPhoto(
          idAlterando,
          nome,
          horario,
          imagem
        );
        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("Orientador foi alterada com sucesso");
          clearConst()
          setIdAlterando("");
          fListSorientacao();
        }
      } else {
        let r = await api.updateOrientador(
          idAlterando,
          nome,
          horario,
          imagem
        );

        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("Orientador foi alterada com sucesso");
          clearConst()
          setIdAlterando("");
          fListSorientacao();
        }
      }
    }
  }



  async function fDeleteOrientador(imagem) {
    let r = await api.deleteOrientador(id, imagem);
    if (r === "OK") {
      toast.success("Orientador excluído com sucesso");
      fListSorientacao();
    } else {
      toast.error("Não foi possível excluir este Orientador");
    }
  }

  const SNdelete = async (imagem) => {
    confirmAlert({
      title: "Remover este Orientador",
      message: `Tem certeza que deseja remover este Orientador ? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            fDeleteOrientador(imagem);
            fListSorientacao();
            clearConst()
          },
        },
        { label: "Não" },
      ],
    });
  };

  function clearConst() {
    setImagemOrientador(null);
    setNomeOrientacao("");
    setHorarioOrientacao("");
  }

  useEffect(() => {
    fListSorientacao();
  }, 
  // eslint-disable-next-line 
  [search]);


  return (
    <div className="networks">
      <ToastContainer />
      <AdminContentBox>
        <Title>Orientação</Title>
        <div className="all">
          <div className="manager">
            <div>
              <div className="itemManager">
                <Label>Orientador:</Label>
                <InputAdm
                  onChange={(e) => setNomeOrientacao(e.target.value)}
                  value={nome}
                />
              </div>
              <div className="itemManager">
                <Label>Horários:</Label>
                <InputAdm
                  onChange={(e) => setHorarioOrientacao(e.target.value)}
                  value={horario}
                />
              </div>
              <div className="itemManager">
                <Label>Imagem:</Label>
                <div style={{ width: "100%" }}>
                    <label className="input-file"> {imagem === null ? "ARQUIVO" : imagem.name === undefined ? nome + '.png' : imagem.name}
                      <input class="custom-file-input"  onChange={(e) => e.target.files[0] === undefined ? '' : setImagemOrientador(e.target.files[0])} type="file"/>
                    </label>
                </div>
              </div>
            </div>
            <button className="salveBtm" onClick={fNewOrientacao}>
              Salvar
            </button>
          </div>
          <div className="list">
            <TableAdmin
              length="3em"
              size="100%"
              columns={[
                { title: "" },
                { title: "Orientador" },
                { title: "Horário" },
                { title: "" },
                { title: "" },
              ]}
            >
              {participate.map((item) => (
                <Tr key={item.id}>
                  <Td config={{ width: "3%" }}>
                    <img
                      src="/assets/images/Group 61.svg"
                      style={{ height: "10px" }}
                      alt=""
                    />
                  </Td>
                  <Td>
                    <Text> {item.nome} </Text>
                  </Td>

                  <Td>
                    <Text> {item.horario} </Text>
                  </Td>
                  <Td
                    config={{ visibility: "hidden", width: "1em" }}
                    onClick={() => {
                      setNomeOrientacao(item.nome);
                      setHorarioOrientacao(item.horario);
                      setImagemOrientador(item.imagem);
                      setIdAlterando(item._idOrientacao);
                    }}
                  >
                    <RoundedEditIcon />
                  </Td>

                  <Td
                    config={{ visibility: "hidden", width: "1em" }}
                    onClick={() => SNdelete(item.imagem)}
                  >
                    <RoundedDeleteIcon />
                  </Td>
                </Tr>
              ))}
            </TableAdmin>
          </div>
        </div>
      </AdminContentBox>
    </div>
  );
}
