import styled from "styled-components";


export const Text = styled.div`
    font: 1em Roboto;
    text-align: left;
    margin-right: 10px;
`

export const AdminContainer = styled.div`
    display: flex;
    flex-direction: column;

    .table-container {
        width: 95%;
        margin: 0px auto;
        margin-top: 1em;
    }

    & input:checked{
        background-color: #c4c4c4;
    }

`