import { AdminContentBox } from "../../../../components/style/boxes";
import Title from "../../../../components/admin/title";
import { UseActivityContext } from '../activityContext'


import Box from "./boxAlbum/boxAlbum";

import Carousel from "react-multi-carousel";
import {
  responsive,
  CustomLeftArrow,
  CustomRightArrow,
} from "./configCarroucel";

import { toast } from "react-toastify";

import { confirmAlert } from "react-confirm-alert";

import ContentLoader from "react-content-loader";


import ApiAlbum  from '../../.../../../../service/admin/album';
import { useEffect } from "react";
const apiAlbum = new ApiAlbum();

export default function Index(props) {

  const Loader = () => (
    <ContentLoader 
      speed={2}
      width={400}
      height={460}
      viewBox="0 0 400 460"
      backgroundColor="#c0c0c0"
      foregroundColor="#ecebeb"
    >
      <rect x="-3" y="-2" rx="2" ry="2" width="187" height="250" /> 
      <rect x="195" y="-2" rx="2" ry="2" width="187" height="250" /> 
      <rect x="499" y="52" rx="2" ry="2" width="187" height="250" />
    </ContentLoader>
  );

  const { 

    activityState: {
        _id,
        albumsArray, 
    },
    
    setAlbumsArray,

  } = UseActivityContext();

  async function getAlbum() {
    if(_id === "")
      return

    let r = await apiAlbum.listAlbunsAtividade(_id);
    setAlbumsArray(r)
  }

  function clique(id) {
    if (albumsArray.length < 5)
      return

    let x = document.getElementById(id);
    x.click();
  }

  async function fDelete(id) {
    let r = await apiAlbum.deleteAlbum(id);
    if (!r.erro) {
      toast.success("Album excluído com sucesso");
      getAlbum()
    } else {
      toast.error("Não foi possível excluir este Album");
      getAlbum()
    }
  }

  const SNdelete = async (id) => {
    confirmAlert({
      title: "Remover Álbum",
      message: `Tem certeza que deseja remover este Álbum? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            fDelete(id);
          },
        },
        { label: "Não" },
      ],
    });
  };

  useEffect(
    () => {getAlbum()},
    // eslint-disable-next-line 
    [_id]
  )

  return (
    <AdminContentBox style={{ height: "26em" }}>
      <Title>
        Galeria - <span>Álbuns</span>
      </Title>
      
      <div className="box-row">
        {albumsArray.length === 0 ? (<Loader/>, <Loader/>, <Loader/>) :
          <div className="caroucel">
            <Carousel
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow id="fl"/>}
              showDots={false}
              containerClass="carousel-container"
              responsive={responsive}
              itemClass="item-conteiner"
              infinite={true}
              arrows={true}
            >
              {albumsArray.map((i) => (
                <div style={{ position: "relative" }}>
                  <img
                    src="/assets/images/trash-2.svg"
                    alt=""
                    className="iconDelete"
                    onClick={() => SNdelete(i._id)}
                  />
                  <Box onClick={() => props.type(i)} info={i} />
                </div>
              ))}
            </Carousel>
          </div>
        }
        <div className="staticBtm">
          
          <button
            className="custom-right-arrow"
            onClick={() => clique("fl")}

          >
            <img src="/assets/images/Icon metro-arrow-right.svg" alt="" />
          </button>

          <div className="box-more">
            <img src="/assets/images/Group 403.svg" alt="more-icon" onClick={() => props.type("New")}/>
            <div>Novo</div>
          
          </div>
        </div>
      </div>
      
    </AdminContentBox>
  );
}
