import ChooseImage from "../comps/chooseImage";
import InputLabel from "../comps/inputLabel";
import { Container } from "../styled";
import { useDonateContext } from "../../../context/donateContext";
import { getLayout } from "./model";




export default function Layout1() {
    const {
        columns,
        setLayout
    } = useDonateContext();

    
    let [column1, column2, column3, column4] = columns;
    
    let { image } = column1;
    let { title, description } = column2;
    let { title: wsTitulo1, webservice: webserviceUrl1 } = column3 || {};
    let { title: wsTitulo2, webservice: webserviceUrl2 } = column4 || {};

    const setLayoutValues = (values) => {
        let l = getLayout(
            values.image            ?? image,
            values.title            ?? title,
            values.description      ?? description,
            values.wsTitulo1        ?? wsTitulo1,
            values.webserviceUrl1   ?? webserviceUrl1,
            values.wsTitulo2        ?? wsTitulo2,
            values.webserviceUrl2   ?? webserviceUrl2);
        setLayout(l);
    }


    return (
        <Container>
            <div className="image">
                <ChooseImage value={image} onChange={value => setLayoutValues({ image: value})} />
            </div>

            <div className="text-box" style={{width: '22em'}}>
                <div className="title">
                    <InputLabel value={title} onChange={value => setLayoutValues({ title: value})} width="20em" />
                </div>
                <div className="description">
                    <InputLabel value={description} onChange={value => setLayoutValues({ description: value})} type="textarea" width="20em" />
                </div>
            </div>

            <div className="box-value">
                <div className="value">
                    <InputLabel value={webserviceUrl1} onChange={value => setLayoutValues({ webserviceUrl1: value })}  /> {/*mask="[URL]"*/}
                </div>
                <div className="value-title">
                    <InputLabel value={wsTitulo1} onChange={value => setLayoutValues({ wsTitulo1: value})} />
                </div>
            </div>

            <div className="box-value light-blue">
                <div className="value">
                    <InputLabel value={webserviceUrl2} onChange={value => setLayoutValues({ webserviceUrl2: value})} /> {/*mask="[URL]"*/}
                </div>
                <div className="value-title">
                    <InputLabel value={wsTitulo2} onChange={value => setLayoutValues({ wsTitulo2: value})} />
                </div>
            </div>


        </Container>
    )
}