import { Container, Imga } from "./styled"
import { API_URL } from "../../../service/config"


export default function index(props) {
    const info = props.info
    return( 
       <Container id={props.id}> 
           <div>
                <div className="title"> 
                    {info.titulo}
                </div>
                <div className="text" dangerouslySetInnerHTML={{__html: info.descricao}}></div>
            </div>
           <Imga bg={`${API_URL}/website/busca?imagem=${info.imagem === null ? '' : info.imagem.replace(/\\/g, '/')}`}  />
       </Container>
       
    )
}