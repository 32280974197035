import ServiceBase from '../serviceBase';


export default class DonateApi extends ServiceBase {
    constructor() {
        super('/admin/donate')
    }

    async list() {
        let resp = await super.get('/');
        return resp ?? [];
    }

    async save(layout, order, active, columns, imageFile) {
        let formData = new FormData();
        formData.append('layout', layout);
        formData.append('order', order);
        formData.append('active', active);
        formData.append('columns', JSON.stringify(columns));
        formData.append('image', imageFile);

        let resp = await super.postFile('/', formData);
        return resp;
    }

    async update(id, layout, order, active, columns, imageFile) {
        let formData = new FormData();
        formData.append('layout', layout);
        formData.append('order', order);
        formData.append('active', active);
        formData.append('columns', JSON.stringify(columns));
        formData.append('image', imageFile);

        let resp = await super.putFile(`/${id}`, formData);
        return resp;
    }

    async delete(id) {
        let resp = await super.delete(`/${id}`);
        return resp;
    }
}

