import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 3em 0em;
  
  .carousel-container {
      .react-multi-carousel-item--active .cont {
        opacity: 1;
      }
      .react-multi-carousel-item{
        display: flex;
        justify-content: center;
        align-items: center;
        //width: 300px !important;
      }
  }

  /* @media (max-width: 1024px) {
    h1 {
      font-size: 40px;
    }
	} */

`;

export {Container}
