import Carousel, { Modal, ModalGateway } from "react-images";
import PhotoAlbum from "react-photo-album";

import React, { useState, useCallback, useEffect } from "react";
import { Container } from "./styled";

import { API_URL } from "../../../../service/config";

import ButtonStyled from "../../../style/button";

export default function Index(props){
    const info = props.info
    const [photos] = useState(props.galeria)
    const [visuPhotos, setVisuPhoto] = useState([])
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [more, setMore] = useState('')
    
    const openLightbox = useCallback(({index}) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };
   
    function contaisImgs() {
        let images =
            photos.slice(0, visuPhotos.length + 20)
                .map(i => ({
                    src: `${API_URL}/website/busca?imagem=${i.imagem.replace(/\\/g, '/')}`,
                    width: 5000,
                    height: 4000,
                    onClick: ()=> alert('oii'),
                    titulo: i.titulo
                }))
        
        setVisuPhoto(images)
        
        if (photos.length === visuPhotos.length) {
            setMore('')
        } else {
            setMore('Ver Mais...')
        }
    }
    useEffect(
        () => {contaisImgs()}, 
        // eslint-disable-next-line 
        []
    )

    return(
        <Container>
            <div className="gallery">
            <div className="infos">
                <div className="titleAlbum">{info.titulo}<ButtonStyled onClick={() => props.state(" ")}>Voltar</ButtonStyled></div>
                <div className="desc">{info.descricao}</div>

                <PhotoAlbum layout="rows" photos={visuPhotos} onClick={openLightbox} />
            </div>
            
                <div className={more ? "btm-more" : 'disable'} onClick={contaisImgs}>{'Carregar mais >>'}</div>
                <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                    <Carousel
                        currentIndex={currentImage}
                        views={visuPhotos.map( i =>( {...i, caption: i.titulo}))}
                    />
                    </Modal>
                ) : null}
                </ModalGateway>

            </div>
        </Container>
    )
}