import Carousel from "react-multi-carousel";
import { Container } from "./styled";
import BoxDay from "./boxDay";
import { responsive, CustomDot } from "./configCarroucel";
import Api from '../../../service/home/calendar'
import { useEffect, useState, useRef } from "react";
const api = new Api();

export default function Index(props) {

  const [ id ] = useState(props.info)
  const data = props.data;
  const [event, setEvent] = useState([])
  const carouselRef = useRef()

  async function getCalendar() {
    if(data){
      setEvent(data);
      return;
    }

    const response = await api.listCalendar(id)
    setEvent(response)
  }

  const dotsDay=[ event.map(i => i.dia.substr(0,1)) ]

  useEffect(
    () => {getCalendar()}, 
      // eslint-disable-next-line 
    [id]
  )  

  
  const day = () => {
    let r = new Date().getDay()
    console.log(r)
    switch (r) {
      case 0:
        r = 6;
      break;
      case 1:
        r = 0;
      break;
      case 2:
        r = 1
      break;
      case 3:
        r = 2
      break;
      case 4:
        r = 3
      break;
      case 5:
        r = 4
      break;
      case 6:
        r = 5
      break;
      default: 
      break;            
    }
    console.log(r)
    return r;
  }
  
  useEffect(() => {
    if (carouselRef && carouselRef.current && carouselRef.current.goToSlide) {
      setTimeout(() => {
        if (carouselRef?.current)
          carouselRef.current.goToSlide(day())
      }, 1000)
    } 
    }, []);


  return (
    <Container id={props.id}>
      <h1  >Calendário Semanal</h1>
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        arrows={false} 
        showDots={true}
        containerClass="containerCaroucel"
        customDot={<CustomDot days={dotsDay} />}
        itemClass="itemClass"
      >
        {event.map((i) => (
            <BoxDay info={i} />
        ))}
      </Carousel>
    </Container>
  );
}
