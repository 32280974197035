import styled from "styled-components";

const Container = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    width: 12em;
    height: 15em;

    background: #FFFFFF;
    border: 1px solid #B9B9B9;
    box-sizing: border-box;
    border-radius: 5px;
    img{
        cursor: pointer;
        width: 100%;
        height: 99%;    
        max-height: 80%;
    }
    .title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 1em;
        line-height: 108%;
        cursor: pointer;
        display: flex;
        align-items: center;
        text-align: center;

        color: #666666;

        width: 100%;

        padding: 1em;

        border-top: 1px solid #B9B9B9;
    }
`


export default Container