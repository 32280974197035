import styled from "styled-components";


const Container = styled.div`
        display: flex;
        flex-direction: column;
        .form-column{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .Zoomimg{
            width: 99%;
            height: 98%;
        }
        .svgImg{
            max-width: 369px;
            height: 250px;
        }
        .form-two-column{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            height: 100%;
            width: 50%;
            max-height: 15em;
            overflow-y: auto;

            scrollbar-width: auto;
            scrollbar-color: #4d4d4d #ffffff;
         
            ::-webkit-scrollbar {
                width: 10px;
            }
            
            ::-webkit-scrollbar-track {
                background: #ffffff;
            }

            ::-webkit-scrollbar-thumb {
                background-color: #747474;
                border-radius: 8px;
                border: 3px solid #ffffff;
            }
            .icon-more{
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    width: 2em;
                    height: 2em;
                }
                width: 50px;
                height: 50px;
                background: #FFFFFF;
                border: 1px solid #C4C4C4;
                box-sizing: border-box;
                margin: 5px;
                
                svg{
                    cursor: pointer;
                }
                path{
                    cursor: pointer;
                }
                input[type="file"] {
                    display: none;
                }
            }
        }
        .form-row{
            display: flex;

            svg{
                cursor: pointer;
            }
            path{
                cursor: pointer;
            }
            input[type="file"] {
                display: none;
            }
        }
        .imgBox{
            width: 50px;
            height: 49px;
            background: #FFFFFF;
            border: 1px solid #C4C4C4;
            box-sizing: border-box;
            margin: 5px
        }
        .iconDelete{
            position: absolute;
            top:  6px;
            right: 6px;

        }
        .containerModal{
            width: 80%;
            height: 80%;
            display: flex;
            flex-direction: column;
            button{
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                
                text-align: center;

                color: #FFFFFF;

                width: 95px;
                height: 35px;

                background: #2B3031;
                border-radius: 44px;
                border: solid 0px;
                align-self: center;

                cursor: pointer;
            }
        }
        .title{
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 2em;
            line-height: 26px;

            color: #3C3939;
        }
        .form-center{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 20em;
            align-items: center;
            .form-two-column{
                display: flex;
                align-items: center;
                height: 100%;
                max-height: none;
                overflow-y: hidden;
                path{
                   width: 369px;
                   height: 250px;
                    cursor: pointer;

                }
                svg{
                    width: 369px;
                    height: 250px;
                    cursor: pointer;
                }
            }
        }
        .form-row-data{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            input{
                
                width: 100%;
                height: 2.5em;

                font: 400 1em Roboto;
                color: #615858;
                
                border-radius: 5px;
                border: 1px solid rgba(168, 168, 168, 1);
                outline: none;

                padding: 0em .5em;
            }
        }
        
        .modalimg {
            .content{
                width: 67%;
                height: 86%;
            }
        }
    
    `

export default Container