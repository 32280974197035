import Container from "./styled"

import { useEffect, useState } from 'react'

export default function Modal(props) {
  const [show, setShow] = useState(false);


  useEffect(() => {
    setShow(props.options.show);
  }, [props.options.show]);
  
  
  function hide(e) {
    if(e.currentTarget !== e.target ) 
      return;

    props.options.show = false;
    setShow(false);
    props.open(false)
  }

  
  return (
    <Container show={show} onClick={hide}>
        <div className="content">
          <div className="close" ><img onClick={hide} src="/assets/images/close.svg" alt=""/></div>
          {props.children}
        </div>
    </Container>
  )
}