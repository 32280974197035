import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .aspas{
    width: 20.9px;
    display: absolute;
  }

  .boxone {
    .evaluation {
      margin-top: 2em;
      align-self: center;
      width: 7em;
      height: 1em;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    background-repeat: no-repeat;
    background-position-x: 32em;
    background-position-y: 13em;
    font-family: "Manrope";
    font-size: 1.2em;
    text-align: center;
    width: 45em;
  }
  .booktitle {
    margin-top: 1.5em !important;
    font-weight: 600;
    font-size: 0.9em;
  }
  .phrase{
    margin-top: 0.5em;
    display: block;
    position: relative;
  }
  .aspas{
    width: 1.2em;
    display: absolute;
    position: absolute;
  }
  .One{
    top: -2em;
    left: -2em;
  } 
  .Two{
    transform: rotate(180deg);
    right: -2em;
    bottom: 0em;
  } 

  .chapter{
      margin-top: 0.2em;
      font-size: 0.9em;
  }

  @media (max-width: 1024px){
    .One  {
      left: 0em;
    }
    .Two {
      right: 0em;
    }

    .boxone{
      width: 85%;
      
      img{
        height: 1.3em!important;
      }
    }

    .chapter {
      margin-top: 1em;
    }

    .boxone {
      .evaluation {
        display: none;
      }
    }

    /* @media (max-width: 344px){
      background-position-x: 11em;
      background-position-y: 29em;
    }
    @media (max-width: 667px){
      background-position-x: 29em;
      background-position-y: 14em;
    } */
  }

`;

export { Container };
