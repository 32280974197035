
const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1320
    },              
    items: 3,
    partialVisibilityGutter: 100
  },
  tablet: {
    breakpoint: {
      max: 1440,
      min: 800
    },              
    items: 3,
    partialVisibilityGutter: 100
  },
  mobile: {
    breakpoint: {
      max: 1280,
      min: 0
    },
    items:1
  }
};

export {responsive};
