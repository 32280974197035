import { useEffect } from "react";
import { toast } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'
    
import { AdminContainer, Text } from "./styled";

import { AdminContentBox } from '../../../../components/style/boxes';
import Title from '../../../../components/admin/title';
import TableAdmin from "../../../../components/admin/tableAdmin";
import { Td, Tr } from "../../../../components/admin/tableAdmin/styled";
import { RoundedDeleteIcon, RoundedEditIcon } from "../../../../components/style/assets";

import DonateApi from "../../../../service/admin/donate";
import { useDonateContext } from "../context/donateContext";
const api = new DonateApi();


export default function Listagem() {
    const {
        list,
        setList,
        editDonate
    } = useDonateContext();

    async function listDonate() {
        let r = await api.list();
        if (!r) return;

        setList(r);
    }

    async function deleteDonate(id, title) {
        confirmAlert({
            title: 'Remover doação',
            message: `Tem certeza que deseja remover a doação ${title}?`,
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        let r = await api.delete(id);
                        if (!r) return;

                        toast('🚀 Doação removida com sucesso.');
                        listDonate();
                    }
                },
                {
                    label: 'Não'
                }
            ]
        });
        
    }

    useEffect(
        () => listDonate(), 
        // eslint-disable-next-line 
        []
    );

    return (
        <AdminContainer>
            <AdminContentBox>
                <Title> Todas Arrecadações e Doações </Title>
                
                <div className="table-container">

                    
                <TableAdmin
                    length="3em"
                    size="100%"
                    columns={[
                        { title: 'Ordem' },
                        { title: 'Título' },
                        { title: 'Ativo' },
                        { title: '' },
                        { title: '' }
                    ]}>

                        
                    {list.map(item => 
                        <Tr key={item.id}>
                            <Td> <Text> {item.order} </Text> </Td>
                            <Td> <Text> {item.columns.filter(c => c.type === 'text')[0].title} </Text> </Td>
                            <Td> <input type="checkbox" checked={item.active} readOnly={true} /> </Td>
                            
                            <Td config={{ visibility: 'hidden', width: '1em' }}
                                onClick={() => editDonate(item) }>
                                <RoundedEditIcon />
                            </Td>
                            
                            <Td config={{ visibility: 'hidden', width: '1em' }}
                                onClick={() => deleteDonate(item.id, item.columns.filter(c => c.type === 'text')[0].title)}>
                                <RoundedDeleteIcon />
                            </Td>
                        </Tr>    
                    )}

                </TableAdmin>
                    
                </div>
            </AdminContentBox>
        </AdminContainer>
    )
}