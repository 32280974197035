import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Manrope';
  background-color: #1c1f26;
  color: white;
  padding: 8em 4em;
  
  .ptF{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: solid 2px rgba(255, 255, 255, 0.14);
    
    width: 100%;
    max-width: 1280px;
    
    margin: 0px auto;
    margin-top: 3em;
    
    padding-top: 2em;
  }
  .TerP{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;   
  }
  .titulo {
    align-self: center;
    font-size: 2em;
    width: 20em;
    text-align: center;
  }
  .tit {
    width: 14em;
    margin: 0px auto;
  }
  .SegP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 4em;
  }

  .Ld {
    padding: 0em 3em;
  }
  
  .SegP input{
    width: 70%;
    margin-top: 2em;
    padding: 1.2em;
  }
  .SegP button{
    height: 3.7em;
    width: 22%;
  }
  input {
    padding: 0.7em;
    border-radius: 4px;
    background-color: #1c1f26;
    border: solid 1px white;
    padding-right: 7em;
    margin-right: 1.8em;
    outline: none;
    color: white;
    cursor:auto;
    width: 22em;
  }
  .Insc {
    background-color: #fbde37;
    padding: 0.8em;
    border-radius: 4px;
    border: black;
    margin-right: 4em;
    font-weight: bold;
    cursor: pointer;
  }
  .comp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    font-family: Manrope;
    font-size: .9em;
    font-weight: 400;
    margin-bottom: 1em
  }
  
  .baixe {
    font-family: Manrope;
    font-size: 1.2em;
    font-weight: 700;
    width: 8em;
    margin-bottom: 1em;
  }
  .ba {
    background-color: #1c1f26;
    border: black;
  }
  .ba img{
    cursor: pointer;
  }
  .appS {
    margin-bottom: 0.4em;
  }
  .gPlay {
    margin-bottom: 9em;
  }
  
  .soonY{
    margin-top: 1em;
    img{width: 9em;}
  }
  .ano {
    font-family: Manrope;
    font-size: 1em;
    margin-top: 1.5em;
  }
  .redes img {
    margin-top: 1em;
    cursor: pointer;
  }
  .faceB {
    background-color: #1c1f26;
    border: black;
    
  }
  .instagram {
    background-color: #1c1f26;
    border: black;
  
  }
  .youtube {
    background-color: #1c1f26;
    border: black;
   
  }


  @media (max-width: 1280px) {
    .TerP {
      width: 100%;
    }
  }

  @media (max-width: 1165px){
    padding: 8em 3em;
    .TerP{
      flex-direction: column;
    }
    .comp{
      flex-direction: row;
      flex-wrap: wrap;
    }
    .ptF{
      flex-direction: column;
      align-items: center;
    }
    .SegP{
      display: flex;
      flex-direction: column;

      justify-content: space-between;

      height: 5em;
      width: 100%;

      input{
        width: 100%;
      }
      button{
        width: 100%;
      }
    }
    .Insc, .in{
      width: 100%;
      margin: 0px;
      input{
        margin: 0px
      }
    }
    .in{
      margin: 0px;
    }
    .bot{
      margin: 0px;
    }
    .titulo, .tit{
      margin: 0px;
      width: auto;
    }
    .tit {
      max-width: 15em;
      width: auto;
    }
    .Ld{
      padding: 3em 
    }
    .gPlay{
      margin: 0px;
    }
    .ano{
      text-align: center;
    }
  }

  @media (max-width: 1009px) {
    .TerP {
      align-items: center;
    }
    .comp {
      justify-content: flex-start;
      width: 40em;
    }
  }

  @media (max-width: 665px) {
    .comp {
      width: auto;
    }
  }
`;

export { Container };