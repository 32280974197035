import styled from "styled-components";

const Container = styled.div`
  .Line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .inputConfig{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: .5em;
  }
  .column{
    width:40%;
  }
  .Two{
    align-items: end;
  }
  .three{
    align-items: center
  }
  .multiple{
    align-items: flex-start;
  }
  .reset{
    width: 42%;
  }
`

export default Container