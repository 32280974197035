import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 5em;
	h1 {
		font-family: "Manrope";
		font-weight: bold;
		font-size: 3.5em;
		line-height: 68px;
		text-align: center;

		color: #1c1f26;
	}
	.gallery {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media (max-width: 1280px) {
		h1 {
			font-size: 50px;
		}
	}
`;

export { Container };
