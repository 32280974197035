import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    padding: 8em 2em;

    .BoxCaroucel{
        width: 100%;
        /* height: 25em; */
        align-items: start;
        @media (max-width: 792px){
            height: auto;
        }
    }
    .itemCaroucel{
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    .itemLoading{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .cont{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 93%;
        background-size: auto;
        max-width: 1200px;

        img {
            margin-right: 5em;
        }

        & > div {
            width: 450px;
        }
        
        .title{
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 44px;

            color: #1C1F26;
        }
        .describe {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            width: 400px;
            margin-top: 1em;

            color: #777C86;

        }
    }


    @media (max-width: 1024px) {
        padding: ${props => props.emptyNews ? '3em 2em 8em 2em' : '3em 2em'};

        .BoxCaroucel {
            height: ${props => props.emptyNews ? '35em' : '53em'};
        }

        .cont {
            flex-direction: column;
            width: 100%;
            max-width: 600px;
            img {
                width: 90%;
                max-width: 500px;
                margin-right: 0em;
            }
            .title, .describe {
                width: auto;
            }
            & > div {
                width: auto;
                max-width: 500px;
            }
        }
        
    }



`


export {Container}