import { useState } from "react";
import { Container } from "./styled.js";
import { UsemenuContext } from "../menuNotice/MenuContext.js";
import { UseeventContext } from "../../../pages/notice/newsContext.js";


export default function App(props) {
  
  const {
    menuState: { volta },
    setVolta
  } = UsemenuContext();

  const { 
    setFiltersToLoad
  } = UseeventContext();

  const { categoria, qtd, SubCategoria } = props.info;
  const [ selected, setSelected] = useState(true);
  const [ geral] = useState(false);
  const [ geralValue] = useState('Geral')

  async function FilterNews(house, category) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setFiltersToLoad(5, house, category);
  }

  return (
    <Container display={props.displayImg}>
      <div
        className="title"
        onClick={() => {
          setSelected(!selected);
        }}
      >
        <div
          className="title-text"
          style={{ fontWeight: volta === 'TODOS' && categoria === 'TODOS' ? "800" : "100" }}
          onClick={() => {
            if (categoria === 'TODOS') {
              setVolta('TODOS');
              FilterNews('Casa de Fabiano', 'Todas as Notícias');
            }
          }}>
          {categoria} ({qtd})
        </div>
        <img
          className="arrow"
          src={
            selected === true
              ? "/assets/images/closeArrow.svg"
              : "/assets/images/open.svg"
          }
          alt=""
        />
      </div>
      <div
        className={
          props.displayImg === "none"
            ? "names"
            : selected === true
            ? "names open"
            : "names"
        }
      >
        {
          geral === true ?   
            <div
              className="topic"
              id={-1}
              style={{
                fontWeight: volta === -1  ? "800" : "100",
              }}
              onClick={() => {
                setVolta(-1);
              }}
            >
              <div className="name">{geralValue}</div>
              <div className="qtd"></div>
            </div> 
          : ''
        }
        {SubCategoria.map((i, index) => (    
          <div
            className="topic"
            id={index}
            style={{
              fontWeight:
                volta === i.id  ? "800" : "100",
            }}
            onClick={() => {
              setVolta(i.id);
              FilterNews(i.categoria, i.subCategoria);
            }}
          >
            <div className="name">{i.subCategoria}</div>
            <div className="qtd">({i.qtd})</div>
          </div>
        ))}
      </div>
    </Container>
  );
}
