import { useEffect } from "react";
import Title from "../../../../components/admin/title";
import { AdminContentBox } from "../../../../components/style/boxes";

import { UselecturesContext } from "../lecturesContext";

import TableAdmin from "../../../../components/admin/tableAdmin";

import { Td, Tr } from "../../../../components/admin/tableAdmin/styled";

import { Text } from "../../news/listagem/styled";

import {
  RoundedDeleteIcon,
  RoundedEditIcon,
} from "../../../../components/style/assets";

import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";

import ApiLectures from "../../../../service/admin/lectures";
import ApiInstitution from "../../../../service/admin/institution.js";
const apiInstitution = new ApiInstitution();
const apiLectures = new ApiLectures();

export default function Gerenciar() {
  const {
    palestraState:{listagem}, 
    setListagem, 
    setHouse, 
    editPalestra
  } = UselecturesContext();

  const deletarConfirm = async (id) => {
    let _id = id;
    confirmAlert({
      title: "Remover Palestra",
      message: `Tem certeza que deseja remover esta Palestra? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            deleteLecturesFunction(_id);
            getLecturesFunction();
          },
        },
        { label: "Não" },
      ],
    });
  };

  async function deleteLecturesFunction(id) {
    let r = await apiLectures.deleteLectures(id);
    if (r === "OK") {
      toast.success("Excluida com sucesso");
      getLecturesFunction();
    } else {
      toast.error("Não foi possível excluir esta palestra");
    }
  }
  async function getLecturesFunction() {
    let r = await apiLectures.getLectures();
    setListagem(r)
    let house = await apiInstitution.getHouse();
    setHouse(house);
  }
  function onChang(json) {
    editPalestra(
      {
        id:json._id,                              
        categoria: {                                 
          dia:json.categoria.dia,
          centroEspirita:json.categoria.centroEspirita,
          tipo: json.categoria.tipo,
          ordem: json.categoria.ordem
        },
        info: {
          titulo:json.info.titulo,
          url:json.info.url,
          imagem:json.info.imagem,
          data:json.info.data 
        }  
      }
    )
}

  useEffect(() => {
    getLecturesFunction();
  }, 
  // eslint-disable-next-line 
  []);

  return (
    <AdminContentBox>
      <Title> Todas Palestras Online </Title>

      <div className="table-container">
        <TableAdmin
          length="3em"
          size="100%"
          columns={[
            { title: "Centro Espírita" },
            { title: "Dia" },
            { title: "Tipo" },
            { title: "Título" },
            { title: "Data" },
            { title: "" },
            { title: "" },
          ]}
        >
          {listagem.map((item) => (
            <Tr>
              <Td>
                <Text> {item.categoria.centroEspirita} </Text>
              </Td>
              <Td>
                <Text> {item.categoria.dia} </Text>
              </Td>
              <Td>
                <Text> {item.categoria.tipo} </Text>
              </Td>
              <Td>
                <Text> {item.info.titulo} </Text>
              </Td>
              <Td>
                <Text> {item.info.data} </Text>
              </Td>

              <Td
                config={{ visibility: "hidden", width: "1em" }}
                onClick={() => { onChang(item); 
                }}
                
              >
                <RoundedEditIcon />
              </Td>

              <Td
                config={{ visibility: "hidden", width: "1em" }}
                onClick={() => deletarConfirm(item._id)}
              >
                <RoundedDeleteIcon />
              </Td>
            </Tr>
          ))}
        </TableAdmin>
      </div>
    </AdminContentBox>
  );
}
