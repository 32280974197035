import { Container } from "./styled";
import { API_URL } from "../../../service/config";

export default function Redes(props) {
  const redesSociais = props.info.redesSociais
  const info = props.info

  return (
    <Container id={props.id}>
      <div className="title"  >Acompanhe também nossas Redes Sociais</div>
      <div className="imgR" >
        {redesSociais.map(i => 
          <a href={i.url} target="_blank"><img src={`${API_URL}/website/busca?imagem=${i.imagem.replace(/\\/g, '/')}`} alt=""/></a>  
        )}
      </div>
      
      <div className="part2"  >
        <div className="sub">{info.contato.titulo}</div>
        <div className="text">
          {info.contato.descricao}
          
          {info.contato.email &&
            <div><span className="subtit"><b>E-mail:</b></span> <span>{info.contato.email} </span><br /></div>
          }

          {info.contato.telefone1 &&
            <div><span className="subtit"><b>Telefone(s):</b> </span><span>{info.contato.telefone1} {info.contato.telefone2 ? `/ ${info.contato.telefone2}` : '' }</span><br/></div>
          }

          {info.contato.endereco &&
            <div><span className="subtit"><b>Endereço:</b> </span><span>{info.contato.endereco}</span></div>
          }
        </div>
      </div>
    </Container>
  );

  
}


