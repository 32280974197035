import { Container } from "./styled";
import InfoBox from "./infoBox";
export default function Index(props) {
  const info = props.info;
  console.log('calendario');
  console.log(info.atividades);
  
  return (
    <Container>
      <div className="day"  data-aos-duration="950" > 
        <img src="/assets/images/calendario.svg" alt="" />
        <p1>{info.dia.substring(0,3)}</p1>
      </div>
      <div className="events">
          {info.atividades.filter(a => a.descricoes.length > 0).map((i) => (
              <InfoBox info={i}  />
          ))}
      </div>
    </Container>
  );
}
