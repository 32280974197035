import EventContextProvider from "./eventContext";

import { Container } from "./styled";
import Gerenciar from "./gerenciar/index";
import List from "./listagem/index";


import { ToastContainer} from "react-toastify";



export default function Event() {
  return (
    <EventContextProvider>
    <Container>
      <ToastContainer />
        <Gerenciar/> 
        <List/>
    </Container>
    </EventContextProvider>
  );
}
