import LecturesContextProvider from "./lecturesContext";

import { Container } from "./styled";
import List from "./components/list";
import Gerenciar from "./components/gerenciar";


import { ToastContainer} from "react-toastify";



export default function Lectures() {
  return (
    <LecturesContextProvider>
    <Container>
      <ToastContainer />
        <Gerenciar/> 
        <List/>
    </Container>
    </LecturesContextProvider>
  );
}