import { AdminContainer, Text } from "./styled";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { AdminContentBox } from '../../../../components/style/boxes';
import Title from '../../../../components/admin/title';
import TableAdmin from "../../../../components/admin/tableAdmin";
import { Td, Tr } from "../../../../components/admin/tableAdmin/styled";
import { RoundedDeleteIcon, RoundedEditIcon } from "../../../../components/style/assets";

import { ToastContainer, toast } from "react-toastify";

import {UseeventContext} from '../newsContext.js'

import Api from "../../../../service/admin/news.js";
import { useEffect } from "react";
const apiNews = new Api()

export default function Listagem() {

    const { eventoState: { listagem }, setListagem, editEvento } = UseeventContext();

    async function getNews() {
        let r = await apiNews.getNews()
        setListagem(r)
    }

    async function delNews(id, house){
        confirmAlert({
            title: "Remover Noticia",
            message: `Tem certeza que deseja remover esta notícia de ${house}? `,
            buttons: [
              {
                label: "Sim",
                onClick: async () => {
                    let r = await apiNews.delNews(id)
                    toast.success(r !== r.erro? 'Deletada com sucesso': r.erro)
                    getNews()
                },
              },
              { label: "Não" },
            ],
          });
    }

    function setAlts(item) {
        editEvento({
            data: item.data,
            id: item._id,
            nome: item.categoria,
            atividade: item.subCategoria,
            titulo: item.titulo,
            descricao: item.descricao,
            destaque: item.emDestaque,
            ativo: item.ativo,
            botao: item.link.titulo,
            url: item.link.url,
            imagem: item.imagens[0] === 'undefined' ? undefined : item.imagens[0],
            imagem1: item.imagens[1] === 'undefined' ? undefined : item.imagens[1],
            imagem2: item.imagens[2] === 'undefined' ? undefined : item.imagens[2],
            imagem3: item.imagens[3] === 'undefined' ? undefined : item.imagens[3]
        });
    }

    useEffect(() => {
            getNews()
        }, 
        // eslint-disable-next-line 
        []
    )
    

    return (
        <AdminContainer>
            <ToastContainer/>
            <AdminContentBox>
                <Title> Todas Notícias </Title>
                
                <div className="table-container">

                <TableAdmin
                    length="3em"
                    size="100%"
                    columns={[
                        { title: 'Casa' },
                        { title: 'Atividade' },
                        { title: 'Título' },
                        { title: 'Em Destaque' },
                        { title: 'Ativo' },
                        { title: 'Data' },
                        { title: '' },
                        { title: '' }
                    ]}>

                    {listagem.map(item => 
                        <Tr key={item._id}>
                            <Td> <Text> {item.categoria} </Text> </Td>
                            <Td> <Text> {item.subCategoria} </Text> </Td>
                            <Td> <Text> {item.titulo} </Text> </Td>
                            <Td> <input type="checkbox" checked={item.emDestaque} readOnly={true} /> </Td>
                            <Td> <input type="checkbox" checked={item.ativo} readOnly={true} /> </Td>
                            <Td> <Text> {item.data} </Text> </Td>
                            <Td config={{ visibility: 'hidden', width: '1em' }}
                                onClick={() => setAlts(item)}>
                                <RoundedEditIcon />
                            </Td>
                            
                            <Td config={{ visibility: 'hidden', width: '1em' }}
                                onClick={() => delNews(item._id, item.categoria) }>
                                <RoundedDeleteIcon />
                            </Td>
                        </Tr>    
                    )} 

                </TableAdmin>
                    
                </div>
            </AdminContentBox>
        </AdminContainer>
    )
}