import { useEffect, useState } from "react";
import * as C from "./styled.js";
import { API_URL } from "../../../service/config.js";

import Api from "../../../service/notice/notices.js";
const api = new Api();


//
// COMPONENTE NOTICIAS PASSADAS (BLOG) COMPLETO
//
export default function App(props) {
  const {
    _id,
    categoria,
    subCategoria,
    titulo,
    descricao,
    data,
    estatisticas,
    imagens,
    link: { titulo: saibaMais , url},
    more,
  } = props.info;

  const [valueView, setValueView] = useState(estatisticas.visualizacoes);
  const [valueLike, setValueLike] = useState(estatisticas.curtidas);
  const [image, setImage] = useState(imagens[0]);
  const [openNotice, setOpenNotice] = useState(false);
  const [activeHeart, setActiveHeart] = useState(false);
  const [viewsPost, setViewsPost] = useState([]);
  const [view, SetView] = useState(0);

  
  const active = () => {
    if (activeHeart === true) {
      setActiveHeart(false);
      setValueLike(valueLike - 1);
      api.addLike(_id, 0);
    } else {
      setActiveHeart(true);
      setValueLike(valueLike + 1);
      api.addLike(_id, 1);
    }
  };

  function DateFormat(data) {
    let date = new Date(data);
    return (
      date.getDate() +
      " " +
      date.toLocaleString("default", { month: "long" }).substring(0, 3) +
      " " +
      date.getFullYear()
    );
  }

  function OpenModal() {
    addView(_id)
    return props.open({ status: true, photo: image, _id: _id });
  }
  
  async function addView(_id) {
    let r = viewsPost.filter(i => i === _id)
    if(r.length === 0){
      let qtd = await api.addView(_id);
      setViewsPost([...viewsPost, _id]);
      SetView(qtd.qtd)
    }
   return ''
  }

 

  useEffect(() => {
    DateFormat(data);
    setImage(imagens[0]);
    setOpenNotice(false);
    setValueLike(estatisticas.curtidas);
    setActiveHeart(false);
    setValueView(estatisticas.visualizacoes);
  },  
  // eslint-disable-next-line 
    [props.info]);
  
  
  return (
    <C.Container className={openNotice ? "higth" : "low"}>      
      
      {saibaMais && <div className="saibamais"> <a target='_blank' rel="noreferrer" href={url}>{saibaMais}</a></div>}

      
      <div className="box-pt1">
        
        <div className="img-one">
          <C.Imagem
            img={`${API_URL}/website/busca?imagem=${image.replace(/\\/g, "/")}`}
            onClick={OpenModal}
            alt=""
          />
        </div>

        <div className="img-two">
          {imagens.map((i) => (
            <C.Thumbnail
              img={`${API_URL}/website/busca?imagem=${i.replace(/\\/g, "/")}`}
              className={image === i ? "select" : ""}
              onClick={() => setImage(i)}
              alt=""
            />
          ))}
        </div>

      </div>

      <div className="box-pt2">
        
        <div className="text">
          <div className="teme">
            {categoria} {'>'} {subCategoria}<span className="more-info">{more}</span>
          </div>
          <div className="title"> {titulo} </div>
          <div className="data"> {DateFormat(data)}</div>
          
          <div className="desc" dangerouslySetInnerHTML={{
            __html: descricao.length >= 245 && openNotice === false && false // false para iniciar sempre aberto
                      ? descricao.substr(0, 245) + "..."
                      : descricao
          }}></div>

          
          {/* Comentado abaixo para iniciar sempre Aberto */}

          {/* <div className="infos" onClick={() => {setOpenNotice(!openNotice); addView(_id)}}>
            {descricao.length >= 245
              ? openNotice
                ? "Esconder"
                : "Ler Mais"
              : ""}
          </div> */}
        </div>
        <div className="extra">
          
          <div className="box-interaction">
            <div className="interaction">
              <img
                onClick={() => active()}
                src={
                  activeHeart === true
                    ? "/assets/images/heart-on.svg"
                    : "/assets/images/heart-off.svg"
                }
                alt=""
              />
              <div
                className="value"
                style={{ color: activeHeart === true ? "#FF4387" : "" }}
              >
                {valueLike}
              </div>
            </div>
            <div className="interaction">
              <img src="/assets/images/view.svg" alt="" />
              <div className="value"> {  viewsPost.filter(i => i === _id).length === 0 ? valueView : view }</div>
            </div>
          </div>
        </div>
      </div>
    </C.Container>
  );
}
