import styled from "styled-components";

const Container = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	align-items: center;
	align-self: center;

	width: 90%;

	padding-top: 1em;
	margin: 0 auto;

	.soon img {
		width: 9em;
		cursor: pointer;
	}

	@media (max-width: 768px) {
		.soon {
			margin-bottom: 2em;
		}
	}
	.titles {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		flex-grow: 1;
		min-width: 450px;

		color: #000000;
		.titlee {
			font-family: "Manrope", sans-serif;
			line-height: 22px;

			padding: 0px 1em;
			min-width: 7.2em;
			text-align: center;

			cursor: pointer;

			a {
				padding: unset !important;
			}

			@media (max-width: 1024px) {
				width: 80%;
				height: 5em;
				align-self: center;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 12px;
				margin-top: 1em;

				color: #5b5656 !important;
				font-size: 1.2em;
				font-weight: 600;

				&:hover {
					background-color: white;
				}
			}
		}

		@media (max-width: 1024px) {
			flex-direction: column;
			transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
			transition: transform 0.3s ease-in-out;
			z-index: 1;
			min-width: 0;
			display: ${({ open }) => (open ? "flex" : "none")};
			position: fixed;
			top: 0em;
			right: 0em;

			height: 100vh;
			width: 100%;

			background-color: #f2f2f2;
			text-align: center;
			justify-content: center;
			padding-top: 2em;
		}
	}
`;

const StyledBurger = styled.div`
	width: 2rem;
	height: 2rem;
	top: 2.5em;
	right: 2em;
	z-index: 20;
	display: none;

	@media (max-width: 1024px) {
		display: flex;
		justify-content: space-around;
		flex-flow: column nowrap;
		overflow: hidden;
	}
	div {
		width: 2rem;
		height: 0.25rem;
		background-color: ${({ open, color }) => (open ? "#1c1f26" : color || "#333")};
		//border-radius: 10px;
		transform-origin: 1px;
		transition: all 0.3s linear;

		&:nth-child(1) {
			transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
		}
		&:nth-child(2) {
			opacity: ${({ open }) => (open ? 0 : 1)};
		}
		&:nth-child(3) {
			transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
		}
	}
`;

export { Container, StyledBurger };
