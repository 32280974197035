import { createContext, useContext, useReducer } from 'react'
import { layout as layout1, initLayout as initLayout1, setColumns as setColumns1 } from '../gerenciar/layouts/layout1/model';
import { layout as layout2, initLayout as initLayout2, setColumns as setColumns2 } from '../gerenciar/layouts/layout2/model';
import { layout as layout3, initLayout as initLayout3, setColumns as setColumns3 } from '../gerenciar/layouts/layout3/model';
import { layout as layout4, initLayout as initLayout4, setColumns as setColumns4 } from '../gerenciar/layouts/layout4/model';


const reducer = (state, action) => {
    
    switch (action.type) {
        case 'setTab':
            return setLayout(action.tab);
        
        case 'setLayout':
            return { ...state, donate: { ...state.donate, columns: action.layout }};
        
        case 'setOrder':
            return { ...state, donate: { ...state.donate, order: action.order } };
        
        case 'setActive':
                return { ...state, donate: { ...state.donate, active: action.active } };
        
        case 'setList':
            return { ...state, list: action.list };
        
        case 'setTitle':
            return { ...state, title: action.title };
        
        case 'newDonate': {
            initLayout1();
            initLayout2();
            initLayout3();
            initLayout4();
            return { ...state, title: 'Novo', donate: initial.donate, selectedTab: 1 };
        }
        
        case 'editDonate':
            editDonate(action.donate);
            return { ...state, title: 'Alterando', selectedTab: action.donate.layout, donate: action.donate };
    
        default:
            return '';
    }


    function editDonate(donate) {
        switch (donate.layout) {
            case 1: setColumns1(donate.columns); break;
            case 2: setColumns2(donate.columns); break;
            case 3: setColumns3(donate.columns); break;
            case 4: setColumns4(donate.columns); break;
                
            default:
                break;
        }
    }


    function setLayout(tab) {
        switch (tab) {
            case 1: return { ...state, selectedTab: tab, donate: { ...state.donate, layout: tab, columns: layout1 } }
            case 2: return { ...state, selectedTab: tab, donate: { ...state.donate, layout: tab, columns: layout2 } }
            case 3: return { ...state, selectedTab: tab, donate: { ...state.donate, layout: tab, columns: layout3 } }
            case 4: return { ...state, selectedTab: tab, donate: { ...state.donate, layout: tab, columns: layout4 } }
            default: return '';
        }
    }
}


const initial = {
    selectedTab: 1,
    title: 'Novo',
    donate: {
        id: null,
        order: 0,
        active: false,
        layout: 1,
        columns: layout1
    },
    list: []
}







const DonateContext = createContext();

export default function DonateContextProvider({ children }) {
    const [donateState, setDonateState] = useReducer(reducer, initial);
    return (
        <DonateContext.Provider value={{ donateState, setDonateState }}>
            {children}
        </DonateContext.Provider>
    )
}



export function useDonateContext() {
    const { donateState: { title, selectedTab, donate: { id, layout, order, active, columns }, list }, setDonateState } = useContext(DonateContext);

    return {
        selectedTab,
        title,
        id,
        layout,
        order,
        active,
        columns,
        list,
        setSelectedTab: (tab) => { setDonateState({ type: 'setTab', tab }) },
        setLayout:  (layout) => { setDonateState({ type: 'setLayout', layout }) },
        setOrder:    (order) => { setDonateState({ type: 'setOrder', order }) },
        setActive:  (active) => { setDonateState({ type: 'setActive', active })},
        setList:      (list) => { setDonateState({ type: 'setList', list })},
        newDonate:        () => { setDonateState({ type: 'newDonate' }) },
        editDonate: (donate) => { setDonateState({ type: 'editDonate', donate })}
    }
}

