import { createContext, useContext, useReducer } from "react";

const reducer = (state, action) =>{

  switch (action.type) {

    case 'setIdAlter': return {...state, idAlter: action.id}
    case 'setUser'  : return {...state, User: action.user}
    case 'setEmail' : return {...state, email: action.email}
    case 'setReset' : return {...state, reset: action.reset}
    case 'setHouse' : return {...state, house: action.house}
    case 'setPosition': return {...state, position: action.position}
    case 'setActive': return {...state, active: action.active}
    case 'setAdmin' : return  {...state, admin: action.admin}
    case 'setActivitys': return  {...state, activitys: [...action.activitys]}
    case 'setAdministrative': return  {...state, administrative: [...action.administrative]}
    case 'setList' : return {...state, list: action.list}
    case 'clearValues' : return {...initialState, list: [...state.list]}

    default:
      break;
  }
}

const initialState ={
  idAlter:'',
  User: '',
  email: '',
  reset: '',
  house: '',
  position: '',
  active: false,
  admin: false,
  activitys: [],
  administrative: [],
  list:[]
}

const ManagerUserContext = createContext();

export default function ManagerUserContexProvider(props) {

  const [managerUserState, setManagerUserState] = useReducer(reducer, initialState);

  return(
    <ManagerUserContext.Provider value={{managerUserState, setManagerUserState}}>
      {props.children}
    </ManagerUserContext.Provider>
  )
}

export function UseManagerUserContext() {
  const context = useContext(ManagerUserContext);

  const {managerUserState, setManagerUserState} = context

  return {
    managerUserState,
    setUser:  (user)    => setManagerUserState({ type: "setUser", user}),
    setEmail: (email)   => setManagerUserState({ type: "setEmail", email}),
    setReset: (reset)   => setManagerUserState({ type: "setReset", reset}),
    setHouse: (house)   => setManagerUserState({ type: "setHouse", house}),
    setPosition:(position)    => setManagerUserState({ type: "setPosition",position}),
    setActive:(active)        => setManagerUserState({ type: "setActive", active}),
    setAdmin:(admin)          => setManagerUserState({ type: "setAdmin", admin}),
    setActivitys: (activitys)          => setManagerUserState({ type: "setActivitys", activitys}),
    setAdministrative:(administrative) => setManagerUserState({ type: "setAdministrative", administrative}),
    setList:(list)                     => setManagerUserState({ type: "setList", list}),   
    setIdAlter:(id)                    => setManagerUserState({ type: "setIdAlter", id}),
    clearValues:()                     => setManagerUserState({ type: "clearValues"})
  }
}