import styled from 'styled-components';
const Container = styled.div`
    position: relative;


    h1{
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 24px!important;
        line-height: 33px;
        text-align: center;
        margin: 0px;
    }
    color: #FFFFFF;
    .street{
                
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
}
    .icons{
        display: flex;
        flex-direction: row;
        width: 6em;
        justify-content: space-around;
        position: relative;
        img{cursor: pointer;}  
        .tel div {display:none;position:relative}
        .tel:hover div {
            display: block;
            position: absolute;
            top: 1.5em;
            right: -1.5em;
            width: 9em;
            height: 1.5em;
            background-color: #FFFFFF;
            color: black;
            font-family: 'Maronpe';
            text-align: center;
            border-radius: 5px;
            font-weight: bolder;
        }
        
    }
    .texto{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 30px;
    }
    .info{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 15px;
    }
    
    .information{
        height: 100%;
        display: flex;
        flex-direction: column;

        justify-content: flex-end;
        align-items: center;

    }
    
    @media (max-width: 1280px){
        width: 90%;
        display: flex;
        justify-content: center;

    }
    @media (max-width: 800px){
        width: 90%;
        display: flex;
        justify-content: center;
        
        .street {
            display: none;
        }
    }
`

const Imga = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    align-self:center;

    color: white;

    width: 39em;
    height: 21.5em;
        
    //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.0660115) 0.01%, rgba(0, 0, 0, 0.83) 100%), url(${(props) => props.image});
    background-image: url(${(props) => props.image});
    box-shadow: inset 0px -170px 200px 50px rgba(0,0,0,0.80);  // alterar tbm na class .react-multi-carousel-item--active .center
    
    background-size: cover;
    background-attachment: scroll !important;
    background-size: cover !important;
    transition: 1s;

    
    &:hover{
        box-shadow: inset 0px 0px 0px 20em rgba(0,0,0,0.7);
    }

    @media (max-width: 800px){
        width: 100%;
        height: 27em;
    }

`


export  {Container, Imga};