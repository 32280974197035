import styled from "styled-components";

const Container = styled.div`
  //height: 52vh;
  padding: ${p => p.empty ? '2em 0em 2em 0em' : '7em 0em 9em 0em'};

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  text-align: center;

  h1{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: bold;

    font-size: 50px;
    line-height: 60px;

    letter-spacing: -1.584px;

    color: #1C1F26;

    margin-bottom:1em;
   
  }

  .days{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 1024px){

    h1 {
      font-size: 40px;
    }
    
    .days{
      height: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    
  }
`;

export default Container;
