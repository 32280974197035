import { useState } from "react"
import styled from "styled-components";


const Container = styled.div`
    word-wrap: break-word;
    textarea, input { width: ${p => p.width}; outline: none; padding: 5px; border-radius: 5px; border-width: 1px;}
    textarea { height: 5em; resize: none; }
`


export default function InputLabel({ value, onChange, type, width, mask }) {
    const [editing, setEditing] = useState(false);

    width = width || '5em';
    
    if (!editing && mask)
        value = mask;

    function checkEnter(e) {
        if (e.keyCode === 13 && type !== 'textarea') {
            endEditing();
            e.preventDefault();
        }
    }

    function textChanged(e) {
        onChange(e.target.value);
    }

    function endEditing() {
        if (!value)
            onChange('-');
        setEditing(false);
    }


    return (
        <Container width={width}>
            {editing
                ? type === 'textarea'
                    ? <textarea type="text" value={value} onChange={textChanged} onKeyDown={checkEnter} autoFocus={true} onBlur={() => endEditing()} />
                    : <input type="text" value={value} onChange={textChanged} onKeyDown={checkEnter} autoFocus={true} onBlur={() => endEditing()} />
                : <div onClick={() => setEditing(true)}> {value} </div>
            }
        </Container>
    )
}