import { ToastContainer } from "react-toastify";
import DonateContextProvider from "./context/donateContext";
import Gerenciar from "./gerenciar";
import Listagem from "./listagem";
import { Container } from "./styled";


export default function Donate() {
    return (
        <Container>
            <ToastContainer />
            <DonateContextProvider>
                <Gerenciar />
                <Listagem />
            </DonateContextProvider>
        </Container>
    )
}