
import { useEffect, useState } from 'react'
import Albums from './album'
import NewAlbum from './newAlbum'

import { UseActivityContext } from '../activityContext'


import ApiAlbum from '../../.../../../../service/admin/album';
import ApiGaleria from '../../.../../../../service/admin/galeria';
const apiAlbum = new ApiAlbum()
const apiGaleria = new ApiGaleria()


export default function Galley() {
    const [type, setType] = useState(false)

    const {
        activityState: {
            _id,
        },
        setInfoAlbum,
        setAlbumsArray,
        setGaleriaImages

    } = UseActivityContext();


    function altType() {
        setType(!type)
        getAlbum()
    }


    async function altAlbm(album) {

        if (album === "New") {

            setInfoAlbum(
                {
                    _id: "",
                    atividadeId: "",
                    centroespiritaId: "",
                    descricao: "",
                    imagem: null,
                    titulo: "Novo"
                }
            )

            setGaleriaImages([])
        } else {
            let r = await apiGaleria.listgaleria(album._id)

            setGaleriaImages(r)
            setInfoAlbum(album)
        }
        altType()
    }

    async function getAlbum() {
        if (_id === '')
            return

        let r = await apiAlbum.listAlbunsAtividade(_id)
        setAlbumsArray(r)
    }

    useEffect(
        () => { getAlbum() }, 
        // eslint-disable-next-line 
        [_id]
    );


    if (type === false) {
        return (<Albums type={altAlbm} />)
    } else {
        return (<NewAlbum type={altType} />)
    }

}