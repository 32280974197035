import styled from "styled-components";

export const Label = styled.div`
  font: 1em Roboto;
  width: 150px;
  text-align: right;
  font-weight: 500;
  margin-right: 10px;

  color: #615858;
`;

export const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;

  
  
  .iconDelete{
          display: none;
          position: absolute;
          top: 6px;
          right: 5px;
          width: 2em;
          cursor: pointer;
        }

  .imgPreviNet{
    width: 99%;
    height: 10em;
    cursor: pointer;
  }
  .inputfake{
    cursor: pointer;
  }

  .lblImg {
    img{
      height: 20em!important;
      width: 20em!important;
      margin-bottom: 5px ;
    }
    svg{
      height: 15em;
      cursor: pointer;
    }
    path{
      cursor: pointer;
    }
    input[type=file]{
      display: none;
    }
  }
  .icon-search {
      position: absolute;
      right: 12px;
      width: 1.5em;
      cursor: pointer;

      &:hover {
        animation: bounce 1s;
      }

      @keyframes bounce {
        10%,
        20%,
        30% {
          transform: translateX(-5px);
        }
        40%,
        50%,
        60% {
          transform: translateY(5px);
        }
        70%,
        80%,
        90% {
          transform: translateY(-5px);
          transform: translateX(+5px);
        }
      }
    }

  .box-row {
    position: relative;
    .staticBtm {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
      right: 37px;
      top: 0;
      .custom-right-arrow {
        position: absolute !important;
        right: -48px;
        z-index: 2;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 12px 15px;
        background-color: #dadada;
        cursor: pointer;
        top: 100px;
        border: solid 0px;
        img {
          cursor: pointer;
        }
        &:hover {
          background-color: #160e4a;
        }
      }
    }
    .box-more {
      width: 11.5em;
      height: 15em;

      background: #ffffff;
      border: 1px solid #b9b9b9;
      box-sizing: border-box;
      border-radius: 5px;

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      cursor: pointer;

      img {
        cursor: pointer;
        width: 4em;
        height: 4em;

        margin-bottom: 1em;
      }
      font-family: Montserrat;
      font-style: normal;
      font-weight: thin;
      font-size: 1em;
      line-height: 108%;

      text-align: center;

      color: #666666;
    }
  }
  .caroucel {
    
    max-width: 66vw;


    .item-conteiner:hover .iconDelete {
      display: block;
    }

    .carousel-container {
        
        ul {
            gap: 40px;

            li {
                width: auto !important;
            }
        }
      .custom-left-arrow {
        position: absolute !important;
        left: 0px;
        z-index: 2;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 12px 15px;
        background-color: #dadada;
        cursor: pointer;
        img {
          cursor: pointer;
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
        &:hover {
          background-color: #160e4a;
        }
      }
    }
  }
  .all {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    .manager {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .salveBtm {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        background: #151515;
        border-radius: 44px;
        padding: 0.6em 1.4em;
        border: none;
        cursor: pointer;
      }
    }
    .itemManager {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5em;
      width: 82%;
      div {
        width: 9em;
      }
      .input-file {
        width: 100px;
        height: 25px;
        background: #9673d4;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "Roboto";
        font-weight: 300;
        font-size: 10px;
        line-height: 116.5%;
        cursor: pointer;

        color: #ffffff;
      }
      input[type="file"] {
        display: none;
      }
    }
    .list {
      margin-left: 5em;
      width: 32%;
    }
  }
  .form-column {
    display: flex;
    flex-direction: column;
    margin-left: 0em;
  }
  .form-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 0.3em 0em;
  }
  .form-row2 {
    display: flex;
    flex-direction: row;

    margin: 0.3em 0em;
    div {
      margin-top: 0.3em;
    }
  }
  .form-row3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.3em 0em;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    input {
      width: 3.6em;
      height: 2.5em;

      font: 400 1em Roboto;
      color: #615858;

      border-radius: 5px;
      border: 1px solid rgba(168, 168, 168, 1);
      outline: none;

      padding: 0em 0.5em;
    }
  }
  .map {
    border: 1px solid #a8a8a8;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }
  .form-column2 {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    height: 100%;
  }
  .form-column5 {
    width: 46%;
  }
  .center {
    justify-content: center;
  }

  .top {
    align-items: flex-start;
  }

  .s1 {
    width: 30%;
  }

  .s2 {
    width: 40%;
  }

  .s3 {
    width: 50%;
  }

  .form-tel {
    display: flex;
    margin: 1em 0em;
    align-items: center;
    justify-content: left;

    input {
      width: 40%;
    }
  }

  .imgs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    width: 100%;
    div {
      width: 100%;
      text-align: center;
    }
    img {
      max-height: 10em;
      max-width: 20em;
      border-radius: 5px;
    }
  }
`;

export const BoxImagens = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    justify-content: flex-start;
  }

  & svg {
    width: 9em;
    height: 9em;
    margin: 0em 1em;
  }

  @media (max-width: 1366px) {
    & svg {
      width: 8em;
      height: 8em;
      margin: 0em 1em;
    }
  }

  @media (max-width: 1280px) {
    & svg {
      width: 7em;
      height: 7em;
      margin: 0em 1em;
    }
  }
`;

export const Text = styled.div`
  font: 1em Roboto;
  text-align: left;
  margin-right: 10px;
`;
