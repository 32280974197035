import { useDonateContext } from "../../../context/donateContext";
import ChooseImage from "../comps/chooseImage";
import InputLabel from "../comps/inputLabel";
import { Container } from "../styled";
import { getLayout } from "./model";



export default function Layout4() {
    const {
        columns,
        setLayout
    } = useDonateContext();




    
    let [column3, column2, column1] = columns;
    
    let { image } = column1 || {};
    let { title, description } = column2 || {};
    let { title: wsTitulo1, webservice: webserviceUrl1 } = column3 || {};


    const setLayoutValues = (values) => {
        let l = getLayout(
            values.image            ?? image,
            values.title            ?? title,
            values.description      ?? description,
            values.wsTitulo1        ?? wsTitulo1,
            values.webserviceUrl1   ?? webserviceUrl1);
        setLayout(l);
    }


    return (
        <Container style={{ justifyContent: 'center' }}>
            
            <div className="box-value">
                <div className="value">
                    <InputLabel value={webserviceUrl1} onChange={value => setLayoutValues({ webserviceUrl1: value})}  />{/*mask="[URL]"*/}
                </div>
                <div className="value-title">
                    <InputLabel value={wsTitulo1} onChange={value => setLayoutValues({ wsTitulo1: value})} />
                </div>
            </div>

            <div className="text-box" style={{width: '22em'}}>
                <div className="title">
                    <InputLabel value={title} onChange={value => setLayoutValues({ title: value})} width="20em" />
                </div>
                <div className="description">
                    <InputLabel value={description} onChange={value => setLayoutValues({ description: value})} type="textarea" width="20em" />
                </div>
            </div>

            <div className="image">
                <ChooseImage value={image} onChange={value => setLayoutValues({ image: value})} />
            </div>

        </Container>
    )
}
