

//export const API_URL = 'http://132.226.247.98:3030'
//export const API_URL = 'http://localhost:3030'
export const API_URL = process.env.REACT_APP_API_URL;

console.log(process.env.REACT_APP_API_URL);

export const Errors = {
    General: 'GeneralError',
    Unknown: 'UnknownError'
}

