import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 3em 0em 5em 0em;
 
  .carousel-container {
      .react-multi-carousel-item--active .cont {
        opacity: 1;
        }
        .react-multi-carousel-item{
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }

  @media (max-width: 1024px) {
    padding: 3em 2em 5em 2em;
  }
   
`;

export {Container}
