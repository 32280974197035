import styled from "styled-components";

const Container=styled.div`
    display: flex;
    width: 76%;
    justify-content: space-between;  
    align-items: center;
    text-align: center;
    align-self: center;
    font-family: 'Manrope';
    font-weight: bold;
    font-size: 25px;
    color: #1C1F26;

    button{
        
    }

    .btn-actions {
        display: flex;
    }
    
    @media (max-width: 1280px) {
        display: flex;
        flex-direction: column;

        margin-bottom: 2em;
        
    }

    
`

const ButtonStyledLeft = styled.button`

    background-color: ${(props) => (props.bg === 0 ? "#DADADA" : '#160E4A')};;
    border: solid 0px;
    width: 2.2em;
    height: 2.2em;   
    cursor: pointer;
    margin-right: 1em;
    img{cursor: pointer;}

    display: flex;
    justify-content: center;
    align-items: center;

`


const ButtonStyledRigth = styled.button`

    background-color: ${(props) => (props.bg === props.total - 1  ? "#DADADA" : '#160E4A')};;
    border: solid 0px;
    width: 2.2em;
    height: 2.2em;   
    cursor: pointer;
    margin-right: 1em;
    img{cursor: pointer;}

    display: flex;
    justify-content: center;
    align-items: center;


`

export {Container, ButtonStyledLeft, ButtonStyledRigth}