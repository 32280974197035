import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    max-width: 28em;
    /* min-width: 23em; */
    height: 16em;

    background: #FFFFFF;
    box-shadow: 0px 3px 16px rgba(173, 173, 173, 0.25);
    border-radius: 11px;

    display: flex;
    flex-direction: row;
    padding: 1em;
    transform: scale(0.95);
    transition: all ease 0.2s;
    cursor: pointer;
    opacity: 0.5;

    * {
        cursor: pointer;
    }
    :hover{
        transform: scale(1);
    }

    img {
        border-radius: 11px;
        height: 100%;
        max-width:150px;
        width: 13em;
    }
    

    .imagem {
        border-radius: 11px;

        background: url(${(props) => props.img});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        
        width: 13em;
        max-width:150px;
        
        background-size: ${(props) => props.bgSize === undefined ? "cover" : props.bgSize};

        /* margin-right: 2em;
        animation: fadeInLeft;
        animation-duration: 2s; */
    }


    @media (min-height: 668px) and (min-width: 1280px) {
        transform: scale(0.869);

        :hover{
        transform: scale(0.935);
        }
    }
`

const Pt2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.3em 1.3em;
    flex-grow: 1;
    
    .grupo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`

const Data = styled.div`
    font-family: Manrope;
    display: flex;
    flex-direction: column;
    background-color: #E8EFFF;
    color: #004FFF;
    border: solid 0px;
    border-radius: 24px;
    height: 31px;
    align-items: center;
    font-size: .9em;
    justify-content: center;
    padding: 10px;
    
    margin-bottom: 20px;


    .text {
        border: solid 0px;
    }
`

const Title = styled.div`
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
`

const Extra = styled.div`
    font-family: Manrope;
    font-size: 1em;
    line-height: 19px;
    font-style: normal;
    font-weight: 400;
    color: #747474;
    cursor: pointer;

    /* :hover {
        text-decoration: underline;
    } */
`

const Casa = styled.div`
    color: #636363;
    font-family: Manrope;
    font-size: .8em;
    margin: 5px 0px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
`



export {Container, Pt2, Data, Title, Extra, Casa}