import Container from "./styled";

import { AdminContentBox } from "../../../../components/style/boxes";
import TableAdmin from "../../../../components/admin/tableAdmin";
import { Tr, Td } from "../../../../components/admin/tableAdmin/styled";
import Title from '../../../../components/admin/title';
import { UseManagerUserContext } from "../context";
import { RoundedDeleteIcon } from "../../../../components/style/assets";
import { RoundedEditIcon } from "../../../../components/style/assets";
import { Text } from "../../news/listagem/styled";
import { useEffect } from "react";

import { confirmAlert } from "react-confirm-alert";

import ApiAdmin from '../../../../service/admin/login';
import { toast, ToastContainer } from "react-toastify";
const api = new ApiAdmin();

export default function List() {

  const { 
    managerUserState:{
      list
    },
    setIdAlter,
    setUser,
    setEmail,
    setHouse,
    setPosition,
    setActive,
    setAdmin,
    setAdministrative,
    setActivitys,
    setList
  } = UseManagerUserContext();

  function editAdmin(item) {
    setIdAlter(item._id);
    setUser(item.usuario);
    setEmail(item.email);
    setHouse(item.centrosespiritas[0]._id);
    setPosition(item.centrosespiritas[0].cargo);
    setActive(item.ativo);
    setAdmin(item.admin);
    setAdministrative(item.administrativo);
   setActivitys(item.centrosespiritas[0].atividades);      
  }

  const deleteAdmin = async (id) => {
    confirmAlert({
      title: "Remover Administrador",
      message: `Tem certeza que deseja remover este Administrador? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            await api.deleteAdmin(id);
            let admins = await api.listAdmin();
            setList(admins);   
            toast.success("Administrador excluido com suceso")          
          },
        },
        { label: "Não" },
      ],
    });
  };


  useEffect(() => {},[list])
  return(
    <Container>
      <AdminContentBox>
        <ToastContainer/>
        <Title> Todos Usuário </Title>
        <div className="ContainerTable">
        <TableAdmin
          length="3em"
          size="100%"
          columns={[
              { title: 'Usuario' },
              { title: 'E-mail' },
              { title: 'Admin' },
              { title: 'Ativo' },
              { title: '' },
              { title: '' }
          ]}>
            {list.map(item => (
              <Tr>
              <Td>
                <Text> {item.usuario} </Text>
              </Td>
              <Td>
                <Text> {item.email} </Text>
              </Td>
              <Td> <input type="checkbox" checked={item.admin} disabled='true' /> </Td>
              <Td> <input type="checkbox" checked={item.ativo} disabled='true' /> </Td>
              <Td
                config={{ visibility: "hidden", width: "1em" }} 
                onClick={() => editAdmin(item)}
              >
                <RoundedEditIcon />
              </Td>

              <Td
                config={{ visibility: "hidden", width: "1em" }}
                onClick={() => deleteAdmin(item._id)}
              >
                <RoundedDeleteIcon />
              </Td>
            </Tr>
            ))}
        </TableAdmin>
        </div>
      </AdminContentBox>
    </Container>
  )
}