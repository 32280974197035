import styled from 'styled-components';

export const Container = styled.div`
  margin: 0.7em;
  height: 100;
  .topics.select{
    font-weight: bolder;
  }

  .title {
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .arrow{
    display: ${props => props.display};
  }

  .title-text {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
  }

  .names {
    margin-left: 1em;
    font-family: Manrope;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 22px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0, 1,0,1);
  }

  .names.open{
    height: auto;
    max-height: 999px;
    transition: all 1.8s cubic-bezier(1, 1, 0, 0);
  }

  .topic{
    cursor: pointer;
    margin: 5px 0px;
    display: flex;
    transition: all 0.2s;
    :hover{
      font-size: 1.08em;
      margin-left: 4px
    }

    .name{
      :hover {text-decoration: underline;}
    }

    .qtd{
      text-decoration: none;
    }
  }
`;


