import axios from 'axios'

import { API_URL } from '../config.js'

const api = new axios.create({
    baseURL: `${API_URL}/website`    
})

export default class Api{
    async listAlbuns(id){
        let r = await api.get(`/album/casa/${id}`)
       return r.data
    }
    async listAlbunsAtiv(id){
        let r = await api.get(`/album/atividade/${id}`)
       return r.data
    }


}