import ContentLoader from "react-content-loader";



export default function Index(props) {
    let pageWidth = window.screen.width;
    let svgWidth;
    if (pageWidth < 500)
        svgWidth = pageWidth * 0.8;
    else if (pageWidth < 800)
        svgWidth = pageWidth * 0.6;
    else
        svgWidth = pageWidth * 0.5;
    
    
    if (pageWidth > 1024)
        return (
            <ContentLoader speed={2} width={1200} height={600} viewBox="0 0 1200 600" backgroundColor="#f2f2f2" foregroundColor="#e8e8e8" {...props}>
                <rect x="0" y="18" rx="0" ry="0" width="500" height="450" /> 
                
                <rect x="700" y="100" rx="2" ry="2" width="169" height="20" /> 
                <rect x="700" y="130" rx="2" ry="2" width="400" height="30" /> 
                
                <rect x="700" y="220" rx="2" ry="2" width="400" height="7" /> 
                <rect x="700" y="240" rx="2" ry="2" width="360" height="7" />
                <rect x="700" y="260" rx="2" ry="2" width="360" height="7" /> 
                <rect x="700" y="280" rx="2" ry="2" width="380" height="7" /> 
                <rect x="700" y="300" rx="2" ry="2" width="350" height="7" /> 
                <rect x="700" y="320" rx="2" ry="2" width="360" height="7" /> 
                <rect x="700" y="340" rx="2" ry="2" width="360" height="7" /> 
                <rect x="700" y="360" rx="2" ry="2" width="340" height="7" /> 

                <rect x="700" y="440" rx="10" ry="10" width="150" height="30" /> 
            </ContentLoader>
        )
    else 
        return (
            <ContentLoader speed={2} width={svgWidth} viewBox="0 0 400 600" backgroundColor="#f2f2f2" foregroundColor="#e8e8e8" {...props}>
                <rect x="0" y="0" rx="0" ry="0" width="400" height="200" /> 
                
                <rect x="0" y="210" rx="2" ry="2" width="169" height="15" /> 
                <rect x="0" y="230" rx="2" ry="2" width="250" height="20" /> 
                
                <rect x="0" y="280" rx="2" ry="2" width="380" height="7" /> 
                <rect x="0" y="300" rx="2" ry="2" width="380" height="7" /> 
                <rect x="0" y="320" rx="2" ry="2" width="360" height="7" /> 
                <rect x="0" y="340" rx="2" ry="2" width="380" height="7" /> 
                <rect x="0" y="360" rx="2" ry="2" width="340" height="7" /> 

                <rect x="700" y="440" rx="10" ry="10" width="150" height="30" /> 
            </ContentLoader>
        )
}