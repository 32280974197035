import ContentLoader from "react-content-loader";



export default function Index() {

    let pageWidth = window.screen.width;
    let svgWidth = parseInt(pageWidth * 0.8);
    let svgHeight = 33 * 16;
    //alert(pageWidth);

    if (pageWidth <= 1024)
        return (
            <ContentLoader speed={2} width={svgWidth} height={svgHeight} viewBox={`0 0 450 500`}
                backgroundColor="#f2f2f2" foregroundColor="#e8e8e8">
            
                <rect x="0" y="35" rx="0" ry="0" width="450" height="400" />
            
                <rect x="50" y="445" rx="0" ry="0" width="350" height="12" />
                <rect x="180" y="465" rx="10" ry="10" width="103" height="19" />
            
                {/* <rect x="55" y="61" rx="0" ry="0" width="258" height="340" />
                <rect x="785" y="61" rx="0" ry="0" width="258" height="340" /> */}
            
                {/* <circle cx="23" cy="220" r="23" />
                <circle cx="1075" cy="220" r="23" /> */}
            </ContentLoader>
        )
    else 
    return (
        <ContentLoader speed={2} width={1100} height={500} viewBox="0 0 1100 500"
            backgroundColor="#f2f2f2" foregroundColor="#e8e8e8">
        
            <rect x="325" y="35" rx="0" ry="0" width="450" height="400" />
        
            <rect x="370" y="445" rx="0" ry="0" width="350" height="12" />
            <rect x="500" y="465" rx="10" ry="10" width="103" height="19" />
        
            <rect x="55" y="61" rx="0" ry="0" width="258" height="340" />
            <rect x="785" y="61" rx="0" ry="0" width="258" height="340" />
        
            <circle cx="23" cy="220" r="23" />
            <circle cx="1075" cy="220" r="23" />
        </ContentLoader>
    )
}