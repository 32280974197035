import { createContext, useContext,  useReducer } from "react";

const reducer=(state,action)=>{
  switch(action.type){

    case 'setDia': return {...state, palestra: {...state.palestra,  categoria: {...state.palestras.categoria, dia: action.dia}}};

    case 'setTitulo': return{...state,evangelho:{...state.evangelho, livro:{...state.evangelho.livro,titulo:action.titulo}}};
    case 'setAutores': return{...state,evangelho:{...state.evangelho, livro:{...state.evangelho.livro,autores: action.autores}}};

    case 'setParte': return{...state,evangelho:{...state.evangelho,mensagem:{...state.evangelho.mensagem, parte: action.parte}}};
    case 'setCapitulo': return{...state,evangelho:{...state.evangelho,mensagem:{...state.evangelho.mensagem, capitulo:action.capitulo}}};
    case 'setPagina': return{...state,evangelho:{...state.evangelho,mensagem:{...state.evangelho.mensagem, pagina:action.pagina}}};
    case 'setTexto': return{...state,evangelho:{...state.evangelho, mensagem:{...state.evangelho.mensagem,texto:action.texto}}};

    case 'editEvangelho': return{...state,evangelho:action.evangelho}; 
   
    case 'novoEvangelho': return{...state, evangelho: initialState.evangelho };

    case 'setList': return {...state,  list: action.list};


    default:
      break;
  }
}


const initialState={
  titulo:'',
  evangelho: {
    id:'',
    livro: {
      titulo:'',
      autores:''
    },
  mensagem: {
      parte:'',
      capitulo:'',
      pagina:'',
      texto:''
    },
  },
  list:[]
}

const GospelContext = createContext();

export default function GospelContextProvider(props) {
  
  const [evangelhoState, setEvangelhoState]=useReducer(reducer,initialState)
    

    return (
      <GospelContext.Provider value={{ evangelhoState, setEvangelhoState}}>
        {props.children}
      </GospelContext.Provider>
    )
  }

export function UseGospelContext() {
    const context = useContext(GospelContext);

    const { evangelhoState, setEvangelhoState} = context;
    
    return {
      evangelhoState,
      setTitulo:(titulo)=> setEvangelhoState({type:'setTitulo', titulo}),
      setAutores:(autores)=> setEvangelhoState({type:'setAutores', autores}),

      setParte:(parte)=> setEvangelhoState({type:'setParte', parte}),
      setCapitulo:(capitulo)=> setEvangelhoState({type:'setCapitulo', capitulo}),
      setPagina:(pagina)=> setEvangelhoState({type:'setPagina', pagina}),
      setTexto:(texto)=> setEvangelhoState({type:'setTexto', texto}),

      setList:(list)=>setEvangelhoState({type:'setList', list}),
      editEvangelho:(evangelho)=>setEvangelhoState({type:'editEvangelho', evangelho}),   //dúvida
      novoEvangelho:()=>setEvangelhoState({type:'novoEvangelho'})

    }
  }
  