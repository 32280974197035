import {createContext, useContext, useReducer} from 'react'


const reducer = (state, action) => {
    switch (action.type) {
        case 'setOpen': return {...state, open: action.open}
        case 'setHouse': return {...state, house: action.house}
        case 'setVolta': return {...state, volta: action.volta}
        case 'setToastAC': return {...state, toastAC: action.toastAC}
        default:
            break;
    }
}

const InitialState = {
    open: false,
    volta: 'TODOS',
    House: undefined,
    toastAC: true
}


const EventContext = createContext();

export default function EventContextProvider(props){
    const [menuState, setMenuState] = useReducer(reducer, InitialState)

    return (
        <EventContext.Provider value={{menuState, setMenuState}}> 
            {props.children}
        </EventContext.Provider>
    )
}

export function UsemenuContext(){
    const context = useContext(EventContext)

    const {menuState, setMenuState} = context;

    return{
        menuState,
        setOpen: (open) => setMenuState({type: 'setOpen', open}),
        setVolta: (volta) => setMenuState({type: 'setVolta', volta}),
        setHouse: (house) => setMenuState({type: 'setHouse', house}),
        setToastAC: (toastAC) => setMenuState({type: 'setToastAC', toastAC})
    }
}


// const {nome, _id} = props.info;
// const [selected, setSelected] = useState(false)
// const [volta, setVolta] = useState(null)
// const [topics, setTopics] = useState([])
