import { useState } from "react";
import { LogoCFLightIcon } from "../../../components/style/assets";
import { BoxLogin, Container } from "./styled";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import Api from "../../../service/admin/login";
const api = new Api()


export default function Login() {

    
    const navigation = useNavigate()

    let usuarioLogado = Cookies.get('userCookie')

    if( usuarioLogado !== undefined ){
        navigation('/admin')
    }

    const [ email, setEmail ] = useState('')
    const [ password, setPassWord ] = useState('')


    async function login(){ 
        let r = await api.login(email, password);

        if(r.erro){
            toast.error(r.erro)
        } else {

            let id = r._id;
            if(r.PrecisaMudarSenha === true)
            {
                delete r._id;
                delete r.PrecisaMudarSenha
                Cookies.set('userCookie', JSON.stringify(r))
                navigation('/admin/reset', {state:id})
                return
            }
            Cookies.set('userCookie', JSON.stringify(r))                        
            navigation('/admin')
        }
    }
    return (
        <Container>
            <ToastContainer />
            <BoxLogin>
                <div className="titulo">
                    Seja Bem-Vindo!
                </div>
                
                <div className="logo">
                    <LogoCFLightIcon />
                </div>

                <div className="label">
                    E-mail
                </div>

                <input type="text" onChange={e => setEmail(e.target.value)} placeholder="Informe seu e-mail" />

                
                <div className="label">
                    Senha
                </div>

                <input type="password" onChange={e => setPassWord(e.target.value)} placeholder="Informe sua senha" />


                <button onClick={login}>
                    ENTRAR
                </button>

                <div className="esqueciSenha">
                    Esqueceu a Senha? <br/><span> Entre em contato com o administrado </span>
                </div>
            </BoxLogin>
        </Container>
    )
}