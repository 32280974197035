import { toast } from "react-toastify";

import { AdminContainer, Label, Tab, LayoutBox } from "./styled";
import { AdminContentBox } from '../../../../components/style/boxes';
import { CheckboxAdm, InputAdm } from '../../../../components/style/inputs';

import Title from '../../../../components/admin/title';
import ConfirmButton from "../../../../components/admin/confirmButton";

import Layout1 from "./layouts/layout1";
import Layout2 from "./layouts/layout2";
import Layout3 from "./layouts/layout3";
import Layout4 from "./layouts/layout4";
import { useDonateContext } from "../context/donateContext";

import DonateApi from "../../../../service/admin/donate";
const api = new DonateApi();


export default function Gerenciar() {
    const {
        id,
        layout,
        order,
        active,
        columns,
        selectedTab,
        title,
        setSelectedTab,
        setOrder,
        setActive,
        setList,
        newDonate
    } = useDonateContext();


    function isSelected(tab) {
        return tab === selectedTab;
    }

    function getLayout() {
        switch (selectedTab) {
            case 1: return <Layout1 />
            case 2: return <Layout2 />
            case 3: return <Layout3 />
            case 4: return <Layout4 />
            default: return <Layout1 />
        }
    }


    async function save() {
        let imageColumn = columns.filter(x => x.type === 'image');
        if (imageColumn.length === 0)
            return toast.error('Preencha as informações do layout.');
        
        let r;
        if (!id)
            r = await api.save(layout, order, active, columns, imageColumn[0].image);
        else
            r = await api.update(id, layout, order, active, columns, imageColumn[0].image);
        
        if (!r) return;


        toast('🚀 Operação realizada com sucesso.');
        listDonate();
        setSelectedTab(1);
        newDonate();
    }

    async function listDonate() {
        let r = await api.list();
        if (!r) return;

        setList(r);
    }


    return (
        <AdminContainer>
            <AdminContentBox>
                <ConfirmButton onClick={save} />

                <Title> Arrecadações e Doações <span> - {title}</span> </Title>
                <div className="content-top-center">
                    
                    <div className="form-column s2">
                        <br />
                        <div className="form-row">
                            <Label> Ordem: </Label>
                            <div className="s3">
                                <InputAdm type="number" min={0} max={9999} value={order} onChange={e => setOrder(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-row">
                            <Label> Ativo: </Label>
                            <div className="s1">
                                <CheckboxAdm checked={active} onChange={e => setActive(e.target.checked)} />
                            </div>
                        </div>
                    </div>


                    <div className="form-column s7">
                        <div className="form-row">
                            {[1,2,3,4].map(i => 
                                <Tab
                                    key={i}
                                    selected={isSelected(i)}
                                    onClick={() => setSelectedTab(i)}>
                                    Layout {i}
                                </Tab>
                            )}
                        </div>

                        <div className="form-row">
                            <LayoutBox>
                                {getLayout()}
                            </LayoutBox>
                        </div>


                    </div>
                </div>
            </AdminContentBox>
        </AdminContainer>
    )
}