import Carousel from "react-multi-carousel";
import { Container } from "./styled";
import House from "../../../components/commum/home/homeHouses";
import MenuTop from "../../../components/commum/menuTop";
import { responsive, CustomLeftArrow, CustomRightArrow, CustomDot } from "./configCarroucel";
import ButtonStyled from "../../../components/style/button";
import { Link } from "react-router-dom";

import ApiInitial from "../../../service/home/initial.js";
import { useState, useEffect } from "react";

import Loading from "./loading";

const apiInitial = new ApiInitial();

export default function Index(props) {
	const [houses, setHouses] = useState([]);
	const [loading, setLoading] = useState(true);

    async function fListHouse() {
        let r = await apiInitial.listHouse();
		setHouses(r);
		setTimeout(() => setLoading(false), 1000);
	}

	function getResolution() {
		if (window.devicePixelRatio > 1.25) return window.screen.width;
		else return window.screen.width * window.devicePixelRatio;
	}

	const scrool = (n) => {
		let element = document.getElementById(n);
		element.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		fListHouse();
	}, []);

	

	return (
		<Container id={props.id}>
			<MenuTop info={props.titles} imagem="/assets/images/logo-light.svg" />
			<div className="home">
				<div className="infos">
					<div className="title">
						Centro Espírita
						<span>Casa de Fabiano</span>
					</div>
					<div className="text">
						A Casa de Fabiano objetiva o estudo, a prática e a divulgação da Doutrina Espírita codificada por Allan Kardec e seus ensinamentos, além da prática da caridade espiritual e
						moral.
					</div>
					<div className="btms">
						<Link to="/noticias">
							<ButtonStyled Bgcolor="#F7C23C" HoverBgcolor="#f2b929">Acompanhe as Notícias </ButtonStyled>{" "}
						</Link>
						<ButtonStyled onClick={() => scrool(5)}>Conheça nossas Atividades</ButtonStyled>
					</div>
				</div>

				<div className="wrapper">
				<div className="box-carousel">
						{loading &&
							<div className="overlay">
								<Loading />
							</div>
						}
					
						<Carousel
							customLeftArrow={<CustomLeftArrow />}
							customRightArrow={<CustomRightArrow />}
							arrows={getResolution() <= 1024 ? false : true}
							centerMode={getResolution() <= 1024 ? false : true}
							showDots={true}
							infinite={true}
							autoPlay={true}
        					autoPlaySpeed={5000}
							containerClass="carousel-container"
							responsive={responsive}
							customDot={<CustomDot />}>
							{houses.map((i, index) => (
								<House key={index} info={i} />
							))}
						</Carousel>
				
				</div>
				</div>
			</div>
		</Container>
	);
}
