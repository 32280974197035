import { useState, useEffect } from "react";
import { BoxImagens, AdminContainer, Label } from "./styled";

import { AdminContentBox } from '../../../../components/style/boxes';
import { CheckboxAdm, DropdownAdm, InputAdm, TextAreaAdm } from '../../../../components/style/inputs';

import Title from '../../../../components/admin/title';
import { ChooseImageIcon } from "../../../../components/style/assets";
import ConfirmButton from "../../../../components/admin/confirmButton";

import { ToastContainer, toast } from "react-toastify";

import { UseeventContext } from '../newsContext.js'

import apiHouse from '../../../../service/admin/institution.js'
import ApiNews from '../../../../service/admin/news.js'
import apiActivity from '../../../../service/admin/activity.js'

import { API_URL } from "../../../../service/config.js";

import {UseLoginContext} from '../../login/loginContext'

const House = new apiHouse()

const apiNews = new ApiNews()

const ApiActivity = new apiActivity()


export default function Gerenciar() {

    const {
        usuState: {auth},
    } = UseLoginContext();

    const [casas, setCasas] = useState([])
    const [atividades, setAtividades] = useState([])
    const { eventoState: { evento: { id, nome, titulo, descricao, atividade, destaque, ativo, botao, url, imagem, urlImg, imagem1, urlImg1, imagem2, urlImg2, imagem3, urlImg3, data }, selectedInstitution },
        setNome,
        setTitulo,
        setAtividade,
        setDescricao,
        setDestaque,
        setAtivo,
        setBotao,
        setUrl,
        setImagem,
        setUrlImg,
        setImagem1,
        setUrlImg1,
        setImagem2,
        setUrlImg2,
        setImagem3,
        setUrlImg3,
        setListagem,
        novoEvento,
        setData,
        setSelectedInstitution
    } = UseeventContext();

    const link = {
        titulo: botao,
        url: url
    }

    function selectFile(imagem, urlImg, id) {
        let setUrlImgFunction;

        let imagemId = id.substr(id.length - 1);
        switch (imagemId) {
            case '1': setUrlImgFunction = setUrlImg1; break;
            case '2': setUrlImgFunction = setUrlImg2; break;
            case '3': setUrlImgFunction = setUrlImg3; break;
            default: setUrlImgFunction = setUrlImg; break;
        }

        setUrlImgFunction(imagem === undefined ? urlImg : imagem);
        document.getElementById(id).click();
    }

    function previewImage(imagem, urlImg) {
        if (imagem) {
            if (typeof imagem === 'string')
                return `${API_URL}/admin/noticia/busca?imagem=${imagem.replace(/\\/g, '/')}&x-access-token=${auth}`;
            else
                return URL.createObjectURL(imagem);
        } else {   
            return '';
        }
    }

    function clear() {
        novoEvento();
    }

    async function getNews() {
        let r = await apiNews.getNews()
        setListagem(r)
    }

    async function getHouse() {
        let r = await House.getHouse()
        setCasas(r)
    }


    async function getActivity(id){
        let r = await ApiActivity.listActivity(id)
        if(r === undefined)
            return
        else 
            setAtividades(r)

    }


    async function postNews() {
        let r;

        if (!id)
            r = await apiNews.addNews(nome, atividade, destaque, ativo, titulo, descricao, link, [imagem1, imagem2, imagem3, imagem], data)
        else
            r = await apiNews.putNews(id, nome, atividade, destaque, ativo, titulo, descricao, link, [imagem1, imagem2, imagem3, imagem], data)
        
        if (r.erro) {
            toast.error(r.erro);
        } else {
            toast.success("Operação realizada com sucesso!")
            clear()
            getNews()
        }
    }

    useEffect(() => {
        getActivity('61da1934bb8abcb5df2b3f23')
        getHouse()
    }, [])



    

    return (
        <AdminContainer>
            <ToastContainer />
            <AdminContentBox>
                <ConfirmButton onClick={() => postNews()} />

                <Title> Notícia <span> - Novo</span> </Title>
                <div className="form-row top center">

                    <div className="form-column s2">
                        <div className="form-row">
                            <Label> Nome: </Label>
                            <DropdownAdm onChange={e => {setSelectedInstitution(e.target.value); setNome(e.target.selectedOptions[0].text); getActivity(e.target.value)}}  value={selectedInstitution}>                            
                                <option value="Selecionar"> Selecione um nome </option>
                                {casas.map(x =>
                                    <option key={x._id} value={x._id}> {x.nome} </option>
                                )}
                            </DropdownAdm>
                        </div> 

                        <div className="form-row">
                            <Label> Atividade: </Label>
                            <DropdownAdm onChange={e => setAtividade(e.target.value)} value={atividade}>
                                <option value="Selecionar"> Selecione uma atividade </option>
                                <option value={'Geral'}> Geral </option>
                                {atividades.map(x =>
                                    <option key={x.nome} value={x.nome}> {x.nome} {x.id}</option>
                                )}
                            </DropdownAdm>
                        </div>

                        <br />
                        <br />

                        <div className="form-row">
                            <Label> Título: </Label>
                            <InputAdm onChange={e => setTitulo(e.target.value)} value={titulo} />
                        </div>

                        <div className="form-row top">
                            <Label> Descrição: </Label>
                            <TextAreaAdm onChange={e => setDescricao(e.target.value)} value={descricao} />
                        </div>

                        <div className="form-row top">
                            <Label > Data: </Label >
                            <div className="put"> <input type="datetime-local" value={data} onChange={(e)=> setData(e.target.value)} /> </div>
                        </div>



                        <div className="form-row">
                            <Label> Em Destaque: </Label>
                            <CheckboxAdm onClick={() => setDestaque(!destaque)} checked={destaque} />
                        </div>

                        <div className="form-row">
                            <Label> Ativo: </Label>
                            <CheckboxAdm onClick={() => setAtivo(!ativo)} checked={ativo} />
                        </div>
                    </div>


                    <div className="form-column s3">
                        <div className="form-row">
                            <Label> Botão: </Label>
                            <InputAdm onChange={e => setBotao(e.target.value)} value={botao} />
                        </div>

                        <div className="form-row">
                            <Label> Url: </Label>
                            <InputAdm onChange={e => setUrl(e.target.value)} value={url} />
                        </div>

                        <br />
                        <br />

                        <div className="form-row top">
                            <Label> Imagem: </Label>
                            <BoxImagens>
                                <div className="form-row">
                                    <div style={{ cursor: 'pointer' }}
                                        onClick={() => selectFile(imagem, urlImg, "capa-input-file")}>
                                        {!imagem
                                            ? <ChooseImageIcon />
                                            : (
                                                <>
                                                    <img className="img" src={previewImage(imagem, urlImg)} alt='' />
                                                    <div className="rem" onClick={(e)=> { e.stopPropagation(); setImagem(null); }}> remover </div>
                                                </>
                                              )
                                        }
                                        <input type="file" id="capa-input-file"
                                            onChange={(e) => setImagem(e.target.files[0])} />
                                    </ div>

                                    <div style={{ cursor: 'pointer' }}
                                        onClick={() => selectFile(imagem1, urlImg1, "capa-input-file1")}>
                                        {!imagem1
                                            ? <ChooseImageIcon />
                                            : (
                                                <>
                                                    <img className="img" src={previewImage(imagem1, urlImg1)} alt='' />
                                                    <div className="rem" onClick={(e) => { e.stopPropagation(); setImagem1(null); }}> remover </div>
                                                </>
                                              )
                                        }
                                        <input type="file" id="capa-input-file1"
                                            onChange={(e) => setImagem1(e.target.files[0])} />
                                    </ div>
                                </div>

                                
                                <div className="form-row">
                                    <div style={{ cursor: 'pointer' }}
                                        onClick={() => selectFile(imagem2, urlImg2, "capa-input-file2")}>
                                        {!imagem2
                                            ? <ChooseImageIcon />
                                            : (
                                                <>
                                                    <img className="img" src={previewImage(imagem2, urlImg2)} alt='' />
                                                    <div className="rem" onClick={(e)=> { e.stopPropagation(); setImagem2(null); }}> remover </div>
                                                </>
                                              )
                                        }
                                        <input type="file" id="capa-input-file2"
                                            onChange={(e) => setImagem2(e.target.files[0])} />
                                    </ div>

                                    <div style={{ cursor: 'pointer' }}
                                       onClick={() => selectFile(imagem3, urlImg3, "capa-input-file3")}>
                                        {!imagem3
                                            ? <ChooseImageIcon />
                                            : (
                                                <>
                                                    <img className="img" src={previewImage(imagem3, urlImg3)} alt='' />
                                                    <div className="rem" onClick={(e) => { e.stopPropagation(); setImagem3(null); }}> remover </div>
                                                </>
                                              )
                                        }
                                        <input type="file" id="capa-input-file3"
                                            onChange={(e) => setImagem3(e.target.files[0])} />
                                    </ div>
                                </div>
                                
                              
                            </BoxImagens>
                        </div>

                    </div>

                </div>
            </AdminContentBox>
        </AdminContainer>
    )
}