import  {Label}  from "../styled";
import { AdminContentBox } from "../../../../components/style/boxes";
import Title from "../../../../components/admin/title";
import ConfirmButton from "../../../../components/admin/confirmButton";
import { UseActivityContext } from '../activityContext'
import { API_URL } from "../../../../service/config";

import { useState } from "react";
import { InputAdm, TextAreaAdm } from "../../../../components/style/inputs";
import {
  ChooseImageIcon,
  AddIcon2,
} from "../../../../components/style/assets";
import { ToastContainer, toast } from "react-toastify";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Container from "./albumStyled";

import Modal from "./modal/index";
import Modalimg from '../../../../components/commum/modal/index'

import {UseLoginContext} from '../../login/loginContext'

import ApiGaleria from "../../../../service/admin/galeria";
import ApiAlbum from "../../../../service/admin/album";
const apiAlbum = new ApiAlbum()
const apiGaleria = new ApiGaleria();

export default function Index(props) {
  const {
    usuState: {auth},
} = UseLoginContext();
  const {
    activityState: {
      _id, 
      centroEspiritaId,
      infoAlbum: {
        _id: _idAlbum,
        descricao,
        imagem,
        titulo,
      },
      infoGaleria:{
        titulo: tituloGaleria,
        descricao: descricaoGaleria,
        imagem: imagemGaleria,
        data 
      },
      galeriaImages,
    },
    setTituloAlbum,
    setDescAlbum,
    setCapaAlbum,
    
    setIDAlbum,
    
    setGaleriaTitulo,
    setGaleriadescricao,
    setGaleriaimagem,
    setGaleriadata,
    setGaleriaImages,
    novoGaleria

  } = UseActivityContext();

  const [exibirModal, setExibirModal] = useState({ show: false });
  const [ZoomImg, setZoom] = useState({ show: false })
  const [imgZoom, setImgZoom] = useState(null)
  const [capaUrl, setCapaUrl] = useState("")
  const [imgUrl, setImgUrl] = useState('')


    function selectFile() {
      setImgUrl(imgUrl === undefined ? imgUrl : imagemGaleria)
        let input = document.getElementById("img-input-file");
        input.click();
    }
    function previewImage() {
      if(imagemGaleria)
        if (typeof imagemGaleria === "string")
            return `${API_URL}/admin/casa/busca?imagem=${imagemGaleria.replace(/\\/g, '/')}&x-access-token=${auth}`;
        else 
            return URL.createObjectURL(imagemGaleria);
      else {
        setGaleriaimagem(imgUrl)
        return URL.createObjectURL(imgUrl);
      }
    }

  function selectFileCapa() {
    setCapaUrl(imagem === undefined ? capaUrl : imagem)
    let input = document.getElementById("img-input-file-capa");
    input.click();
  }
  function previewImageCapa() {
    if(imagem){
      if (typeof imagem === "string")
          return `${API_URL}/admin/casa/busca?imagem=${imagem.replace(/\\/g, '/')}&x-access-token=${auth}`;
      else 
          return URL.createObjectURL(imagem);
    }else{
      setCapaAlbum(capaUrl)
        return URL.createObjectURL(capaUrl);
    }
  }


  async function postAlbum() {

    if(_idAlbum !== ""){
        if( typeof imagem === "string"){
            let r = await apiAlbum.putAlbumAtividade(_idAlbum, centroEspiritaId, _id, titulo, descricao, imagem)
            if (r.erro)
                toast.error(r.erro)
            else {
                toast.success('Album Atualizado!')
            }
        } else {
            if(typeof imagem  !== "object")
              return toast.error('O campo da capa está vazio')
            let r = await apiAlbum.putAlbumCapaAtividade(_idAlbum, centroEspiritaId, _id, titulo, descricao, imagem)
            if (r.erro)
                toast.error(r.erro)
            else {
                toast.success('Album Atualizado!')
            }
        }
    } else {
        if(imagem === null)
          return toast.error('O campo da capa está vazio')
  
        let r = await apiAlbum.postAlbumAtividade(centroEspiritaId, _id, titulo, descricao, imagem)
        if (r.erro)
            toast.error(r.erro)
        else {
            toast.success('Novo album cadastrado')
            setIDAlbum(r.insertedId)
        }
    }
  }

  async function deletarIMG(id) {
    
    let r = await apiGaleria.deleteGaleria(id);
    if (r.erro) 
        toast.error(r.erro);
    else 
      toast.success("Imagem removida com sucesso");
      let g = await apiGaleria.listgaleria(_idAlbum)
      setGaleriaImages(g)
    
  }

  const SNdelete = async (id) => {
    confirmAlert({
      title: "Remover Imagem",
      message: `Tem certeza que deseja excluir esta imagem ? `,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            deletarIMG(id);
          },
        },
        { label: "Não" },
      ],
    });
  };

  function openaddImg() {
      if(_id === "")
        return toast.warning('Primeiro clique em salvar')

    setExibirModal({ show: true })
  }

  async function addArrayImg() {

    if (imagemGaleria === null){
        toast.error('A imagem deve ser escolhida')
        return
    } 
    let g = await apiGaleria.postGaleria(_idAlbum, tituloGaleria,descricaoGaleria,imagemGaleria,data)
    if(g.erro)
      return toast.error(g.erro)
    

    let r = await apiGaleria.listgaleria(_idAlbum)
    setGaleriaImages(r)
    
    toast.success('A imagem foi Adicionada')
    
    novoGaleria()
  }

  function OpenZoom(img) {
    setZoom({show: true})
    setImgZoom(`${API_URL}/admin/casa/busca?imagem=${img.replace(/\\/g, '/')}&x-access-token=${auth}`)
  }
  return (
    <Container>
      <Modalimg className="modalimg" open={() => ""} options={ZoomImg}>
        <img src={imgZoom} className="Zoomimg" alt=""/>
      </Modalimg>
      <ToastContainer />
      <Modal className="modal" options={exibirModal}>
        <div className="containerModal">
          <div className="title">Nova Imagem</div>
          <div className="form-center">
            <div className="form-fist-column" style={{margin: '1em'}}>
              <div className="form-row" style={{width: '100%'}}>
                <Label>Título:</Label>
                <InputAdm
                  onChange={(e) => setGaleriaTitulo(e.target.value)}
                  value={tituloGaleria}
                />
              </div>
              <div className="form-row" style={{width: '100%'}}>
                <Label>Descrição:</Label>
                <InputAdm
                  onChange={(e) => setGaleriadescricao(e.target.value)}
                  value={descricaoGaleria}
                />
              </div>
              <div className="form-row-data">
                <Label>Data:</Label>
                <input 
                    value={data}
                    onChange={e => setGaleriadata(e.target.value)} 
                    type="date" 
                />
              </div>
            </div>
            <div
              className="form-two-column"
              style={{ 
                overflowX: "hidden",
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Label style={{Display: 'flex', Height: '38%'}}>Imagem:</Label>
              <label>
                {imagemGaleria === null ? (
                    <ChooseImageIcon />
                ) : (
                  <img
                    className="imgPrevi"
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      maxheight: "16em",
                      height: "13em",
                    }}
                    src={previewImage()}
                    alt=""
                  />
                )}
                <input
                  id="img-input-file"
                  style={{ display: "none" }}
                  type="file"
                  onChange={(e) => setGaleriaimagem(e.target.files[0])}
                  onClick={selectFile}
                  accept="image/*"
                />
              </label>
            </div>
          </div>
          <button className="add" style={{Height: '5em'}} onClick={addArrayImg}>
            Adicionar
          </button>
        </div>
      </Modal>
      <AdminContentBox>
        <img 
          src="/assets/images/seta.svg" 
          style={{
            cursor: 'pointer',
            height: '1em',
            alignSelf: 'baseline',
            marginBottom: '1em'
          }}
          onClick={() =>
              props.type()
          } 
          alt="opa"
        />  
        <ConfirmButton onClick={postAlbum} />
        <Title >
          Galeria - <span>{titulo}</span>
        </Title>
        <div className="form-column">
          <div className="form-fist-column">
            <div className="form-row">
              <Label>Album:</Label>
              <InputAdm
                id="titulo"
                value={titulo}
                onChange={(e) => setTituloAlbum(e.target.value)}
              />
            </div>
            <div className="form-row" style={{ alignItems: "baseline" }}>
              <Label>Descrição:</Label>
              <TextAreaAdm
                id="desc"
                value={descricao}
                onChange={(e) => setDescAlbum(e.target.value)}
                style={{ height: "62px" }}
              />
            </div>
            <div className="form-row" style={{ alignItems: "flex-start" }}>
              <Label>Capa:</Label>
              <label style={{ width: "100%" }}>
                {imagem === null ? (
                  <div
                    className="svgImg"
                    style={{ width: "8em"}}
                  >
                    <ChooseImageIcon />
                  </div>
                ) : (
                  <img
                    className="imgPrevi"
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      cursor: "pointer"
                    }}
                    src={previewImageCapa()}
                    alt=""
                  />
                )}
                <input
                  id="img-input-file-capa"
                  style={{ display: "none" }}
                  type="file"
                  onChange={(e) => setCapaAlbum(e.target.files[0])}
                  onClick={selectFileCapa}
                  accept="image/*"
                />
              </label>
            </div>
          </div>
          <div className="form-two-column">
            <div
              className="icon-more"
              onClick={openaddImg}
            >
              <AddIcon2 />
            </div>
            {galeriaImages.map((i) => (
              <div
                onClick={() => OpenZoom(i.imagem)} 
                style={{ position: "relative" }}
              >
                <img
                  src="/assets/images/trash-2.svg"
                  alt=""
                  className="iconDelete"
                  onClick={() => SNdelete(i._id)}
                  style={{ width: "1em" }}
                />
                <img
                  className="imgBox"
                  src={`${API_URL}/admin/casa/busca?imagem=${i.imagem.replace(/\\/g, '/')}&x-access-token=${auth}`}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
      </AdminContentBox>
    </Container>
  );
}
