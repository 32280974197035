import axios from 'axios'
import { API_URL } from '../../config'
const api = new axios.create({
    baseURL: `${API_URL}/website`    
})



    
export default class Api{
    async listRecentNews(){
        let r = await api.get("/noticia")
    
        return r.data
    }
}