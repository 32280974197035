import styled from "styled-components";

const Container = styled.div`
	//height: 75vh;

	padding: 5em 4em;

	display: flex;
	flex-direction: ${(props) => (props.row === undefined ? "row" : props.row)};
	justify-content: center;
	align-items: flex-start;
	background-color: ${(props) => props.bg};

	img {
		margin: 7em 5rem 0em 5rem;
        width: 23em;
	}

	.info {
		max-width: 35em;

		h1 {
			font-family: "Manrope";
			font-style: normal;
			font-weight: bold;
			font-size: 50px;
			line-height: 55px;

			padding: 0rem 2rem;
			color: #1c1f26;
		}
		div {
			font-family: "Manrope";
			font-style: normal;
			font-weight: 500;
			font-size: 1.1em;
			line-height: 30px;

			color: #777c86;
			display: block;
			max-height: 35em;
			overflow-y: auto;

			padding: 0rem 2rem;
			scrollbar-width: thin;
			scrollbar-color: #cecece #ffffff;
			scrollbar-track {
				background-color: ${(props) => props.bg};
				border-radius: 12px;
			}

			/* Chrome, Edge, and Safari */
			::-webkit-scrollbar {
				width: 11px;
			}

			::-webkit-scrollbar-track {
				background: ${(props) => props.bg};
			}
			
			::-webkit-scrollbar-thumb {
				background-color: #cecece;
				border-radius: 12px;
				border: 1px solid #ffffff;
			}
		}
	}

	
	@media (max-width: 1440px) {
		img {
			width: 25em;
		}
	}


  @media (max-width: 1024px) {
		flex-direction: column-reverse;
		text-align: center;
		padding: 7em .7em;
    
		
        img {
            width: 90%;
            max-width: 25em;
            margin: 2rem;
        }

        .info {
            h1 {
                font-size: 40px;
                padding: 0em 1rem;
            }
            div {
                text-align: left;
                overflow-x: unset;
                max-height: unset;
            }
            margin-bottom: 2em;
        }
	}



`;

export default Container;
