import { useState } from "react";
import { LogoCFLightIcon } from "../../../components/style/assets";
import { BoxLogin, Container } from "./styled";
import { ToastContainer} from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

import Api from "../../../service/admin/login";
const api = new Api()

export default function Reset() {

    const navigation = useNavigate()

    const [ password, setPassWord ] = useState('')

    const id = useLocation().state;
    async function Login(){ 
        await api.resetPassword(id, password);
        navigation('/admin')
    }
    return (
        <Container>
            <ToastContainer />
            <BoxLogin>
                <div className="titulo">
                    Altere sua senha
                </div>
                
                <div className="logo">
                    <LogoCFLightIcon />
                </div>

                <div className="label">
                    Nova Senha
                </div>

                <input type="password" onChange={e => setPassWord(e.target.value)} placeholder="Informe sua senha" />


                <button onClick={Login}>
                    ENTRAR
                </button>

                <div className="esqueciSenha">
                    Esqueceu a Senha? <br/><span> Entre em contato com o administrado </span>
                </div>
            </BoxLogin>
        </Container>
    )
}