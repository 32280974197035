import { Link } from "react-router-dom"
import Container from "./styled"
import { API_URL } from "../../../../../../service/config"
import {UseLoginContext}  from "../../../../login/loginContext.js";


const replaceImg = (img) => {
    if(img)
        return img.replace(/\\/g, '/')
    else 
        return null
}

export default function box(props) {    
    const info = props.info;
    
    const { usuState: {auth} } = UseLoginContext();

    if(info.titulo === undefined){
        return(
            <Container>
                    <Link to={'/admin/atividade/'} state={{ idAtividade: info._id, idCasa: props.idCasa }} >
                        <img className="centralizar" src={`${API_URL}/admin/casa/busca?imagem=${replaceImg(info.imagem) ?? replaceImg(info.imagens.icone)}&x-access-token=${auth}`} alt=""/>
                    </Link>
                    <div className="title">
                        <span>{info.nome.length >= 17 ? info.nome.substring(0, 15) + '...' : info.nome}</span>
                    </div>
            </Container>
        )    
    }else{
        return(
            <Container onClick={() => props.onClick('')}>
                <img src={`${API_URL}/admin/casa/busca?imagem=${replaceImg(info.imagem) ?? replaceImg(info.imagens.icone)}&x-access-token=${auth}`} alt=""/>
                <div className="title">
                    <span>{info.titulo.length >= 17 ? info.titulo.substring(0, 15) + '...' : info.titulo}</span>
                </div>
            </Container>
        )
    }
}