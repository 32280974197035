import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    background-color: #fafafa;
    height: 90vh;
    padding-top: 2em;

    .menu {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        width: 9em;
        height: 50px;
        background-color: #e6e6e6;
        //box-shadow: 0px 0px 30px 10px #ededed;
    }

    .items {
        display: flex;
    }
    .items > div {
        margin: 0px 20px;
        width: 80px;
        height: 20px;
        background-color: #e6e6e6;
        //box-shadow: 0px 0px 30px 10px #f7f7f7;
    }


    .content {
        flex-grow: 1;
        width: 90%;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title {
        width: 300px;
        height: 100px;
        background-color: #e6e6e6;
        //box-shadow: 0px 0px 30px 10px #ededed;
    }
    .description {
        margin-top: 1em;
        width: 40%;
        height: 15px;
        background-color: #e6e6e6;
    }




    .animate {
        cursor: progress; 
        background: 
            linear-gradient(0.25turn, transparent, #f0f0f0, transparent),
            linear-gradient(#e3e3e3, #e3e3e3);  
        background-repeat: no-repeat;
        background-size: 100% 100%; 
        background-position: -100px 0, 0 0, 0px 1000px, 50px 1000px; 
        animation: loading1 2.5s infinite;
    }


    @keyframes loading1 {  
        to {
            background-position: 100px 0, 0 0, 1000px, 50px 1000px;
        }
    }

`

export const Container1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    margin: 0px auto;
    width: 90%;
    height: 100vh;
    padding-top: 1em;

    .loading {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo {
        align-self: flex-start;
    }
    .logo img {
        width: 9em;
        cursor: pointer;
    }
`