
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Cookies from "js-cookie";

import { Logo, Category, MenuItem, ContainerMenu, TitleCategory } from "./styled"

import {
    MenuDonateIcon,
    MenuEventIcon,
    MenuGospelIcon,
    MenuLectureIcon,
    MenuInstitutionIcon,
    MenuLogoutIcon,
    MenuNewsIcon,
    LogoCFIcon,
    MenuManagerUser
} from '../../style/assets'


export default function MenuAdm() {
    const [menu] = useState(loadMenu());
    const [itemSelected, setItemSelected] = useState('');

    function isSelected(item) {
        return (itemSelected === item);
    }

    function select(item) {
        setItemSelected(item);
    }

    function loadMenu() {
        return [
            {
                category: 'Casas',
                itens: [
                    { to: '/admin/casa?ce=Casa de Fabiano', name: 'Casa de Fabiano', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=Neuchatel', name: 'Neuchatel', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=União', name: 'União', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=Lar do Heitor', name: 'Lar do Heitor', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=Lar da Fernanda', name: 'Lar da Fernanda', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=Lar Ilza Cintra', name: 'Lar Ilza Cintra', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=Lar Jésus Gonçales', name: 'Lar Jésus Gonçales', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=Lar Meimei', name: 'Lar Meimei', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=Lar Francisco de Assis', name: 'Lar Francisco de Assis', icon: <MenuInstitutionIcon /> },
                    { to: '/admin/casa?ce=Educandário Allan Kardec', name: 'Educandário Allan Kardec', icon: <MenuInstitutionIcon /> },
                ]
            },
            {
                category: 'Gerenciar',
                itens: [
                    { to: '/admin/eventos', name: 'Eventos', icon: <MenuEventIcon /> },
                    { to: '/admin/noticias', name: 'Notícias', icon: <MenuNewsIcon /> },
                    { to: '/admin/palestras', name: 'Palestras Doutrinárias', icon: <MenuLectureIcon /> },
                    { to: '/admin/doacao', name: 'Arrec. & Doações', icon: <MenuDonateIcon /> },
                    { to: '/admin/evangelhoDiario', name: 'Evangelho do Dia',  icon: <MenuGospelIcon /> },
                    { to: '/admin/GerenciarAcesso', name: 'Gerenciar Acesso',  icon: <MenuManagerUser /> }
                ]
            },
            {
                category: 'Conta',
                itens: [
                    { to: '/admin/login', name: 'Sair', icon: <MenuLogoutIcon /> }
                ]
            }
        ]
    }

    const navigation = useNavigate();

    const logout = () => {
        Cookies.remove('userCookie'); 
        navigation('/admin/login');
    }

    return (
        <ContainerMenu>
            <Link to="/admin">
                <Logo>
                    <LogoCFIcon />
                </Logo>
            </Link>
        
            {menu.map(x => 
                <div key={x.category}>
                    <TitleCategory>
                        {x.category}
                    </TitleCategory>
                    <Category>
                        {x.itens.map(x => 
                            x.name !== 'Sair' ? 
                            ( 
                                <Link to={x.to} key={x.name}>
                                    <MenuItem onClick={() => select(x.name)} selected={isSelected(x.name)}>
                                        {x.icon}
                                        {x.name}
                                    </MenuItem>
                                </Link>
                            )
                            : 
                            (
                                <MenuItem onClick={() => {logout()}}>
                                    {x.icon}
                                    {x.name}
                                </MenuItem>
                            ) 
                    )}
                    </Category>
                </div>    
            )}
        </ContainerMenu>
    )

}