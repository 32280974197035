import { Text } from "../styled";

import { AdminContentBox } from '../../../../components/style/boxes';
import { InputAdm } from '../../../../components/style/inputs';

import { RoundedDeleteIcon, RoundedEditIcon } from "../../../../components/style/assets";

import TableAdmin from '../../../../components/admin/tableAdmin'
import { Td, Tr } from "../../../../components/admin/tableAdmin/styled";

import Title from "../../../../components/admin/title";

import { Label } from '../styled';

import { useState, useEffect } from "react";

import { UseActivityContext } from "../activityContext";

import {  toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Api from "../../../../service/admin/activity";
const api = new Api()

export default function Index() {
    
    const {
        activityState:{
            _id,
            participantes:{
                nome,
                imagem
            },
            ArrayParticipante
        },
        setNomePart,
        setImgPart,
        setArrayPart,
        setPartNova
    } = UseActivityContext();

    const [idAlterando, setIdAlterando] = useState("");


    async function fListSParticipant() {

        if(_id === "")
            return
        
        let r = await api.listOrientador(_id);
        
        if(r.participantes){
            setArrayPart(r.participantes);
        }
    }

    async function fNewOrientacao() {
        if (imagem === null)
          return toast.error("Deve-se cadastrar uma imagem");
    
        if (idAlterando === "") {
          let r = await api.newOrientador(nome, imagem, _id);
          if (r.erro) {
            toast.error(r.erro);
          } else {
            toast.success("Participante foi cadastrado com sucesso");
            clearConst()
            fListSParticipant();
          }
        } else {
          if (typeof imagem !== "string") {
            let r = await api.updateOrientadorPhoto(
              idAlterando,
              nome,
              imagem
            );
            if (r.erro) {
              toast.error(r.erro);
            } else {
              toast.success("Participante alterado com sucesso");
              clearConst()
              setIdAlterando("");
              fListSParticipant();
            }
          } else {
            let r = await api.updateOrientador(
              idAlterando,
              nome,
              imagem
            );
    
            if (r.erro) {
              toast.error(r.erro);
            } else {
              toast.success("Participante alterado com sucesso");
              clearConst()
              setIdAlterando("");
              fListSParticipant();
            }
          }
        }
      }
    

    async function fDeleteOrientador(imagem) {
        let r = await api.deleteOrientador(_id, imagem);
        if (r === "OK") {
            toast.success("Participante excluído com sucesso");
            fListSParticipant();
        } else {
            toast.error("Não foi possível excluir este Participante");
        }
    }
    
    const SNdelete = async (imagem) => {
        confirmAlert({
            title: "Remover este Participante",
            message: `Tem certeza que deseja remover este Participante ? `,
            buttons: [
            {
                label: "Sim",
                onClick: async () => {
                fDeleteOrientador(imagem);
                fListSParticipant();
                clearConst()
                },
            },
            { label: "Não" },
            ],
        });
    };
    
    function clearConst() {
        setPartNova()
    }

    useEffect(() => {
        fListSParticipant();
      }, 
      // eslint-disable-next-line 
      [_id]
    );

    return(
        <div className="participation">
            <AdminContentBox>
                <Title>Participantes</Title>
                <div className="all">
                    <div className="manager">
                        <div>
                            <div className="itemManager">
                                <Label>Nome:</Label>
                                <InputAdm value={nome} onChange={e => setNomePart(e.target.value)} />
                            </div>
                            <div className="itemManager">
                                <Label>Imagem:</Label>
                                <div style={{ width: '100%' }}>
                                    <label className="input-file"> { imagem === null 
                                                ? "ARQUIVO" 
                                                : imagem.name === undefined 
                                                    ? nome.length >= 9 
                                                    ? nome.substring(0,9) + '.png'  
                                                    : nome + '.png' 
                                                : imagem.name}
                                        <input class="custom-file-input"  onChange={(e) => e.target.files[0] === undefined ? '' :setImgPart(e.target.files[0])} type="file"/>
                                    </label>  
                                </div>
                            </div>
                        </div>
                        <button className="salveBtm" onClick={fNewOrientacao}>Salvar</button>
                    </div>
                    <div className="list">
                        <TableAdmin
                            length="3em"
                            size="17em"
                            columns={[
                                { title: 'Participantes' },
                                { title: '' },
                                { title: '' }
                            ]}>
                            {ArrayParticipante.map(item =>
                                <Tr key={item.id}>
                                    <Td> <Text> {item.nome} </Text> </Td>

                                    <Td config={{ visibility: 'hidden', width: '1em' }}
                                        onClick={() => {
                                            setNomePart(item.nome);
                                            setImgPart(item.imagem);
                                            setIdAlterando(item._IDparticipant);
                                          }}
                                    >
                                        <RoundedEditIcon />
                                    </Td>

                                    <Td config={{ visibility: 'hidden', width: '1em' }}
                                        onClick={() => SNdelete(item.imagem)}
                                    >   
                                        <RoundedDeleteIcon />
                                    </Td>
                                </Tr>
                            )}

                        </TableAdmin>
                    </div>
                </div>
            </AdminContentBox>
        </div>
    )
    
}