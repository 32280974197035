import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  //height: 80vh;
  padding: 5em 0em;

  .title {
    font-family: Manrope;
    font-weight: 700;
    font-size: 3.5em;
    color: #1C1F26;

    

  }
  .boxduo{
    width: 80%;
   
  }


  .carousel-container {
      justify-content: ${p => p.centralizar == true ? 'center' : 'flex-start'};
      
      .react-multi-carousel-item--active {
          width: 15em;
      }
      .custom-right-arrow {
        position: absolute !important;
        right: 30px;
        z-index: 2;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 12px 15px;
        background-color: #DADADA;
        cursor: pointer;
        img{
          cursor: pointer;
        }
        &:hover{
            background-color: #160E4A;
        }
      }

      .custom-left-arrow {
        position: absolute !important;
        left: 30px;
        z-index: 2;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 12px 15px;
        background-color: #DADADA;
        cursor: pointer;
        img{
          cursor: pointer;
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
        &:hover{
            background-color: #160E4A;
        }
      }
    }

  @media (max-width: 1280px){
    .carousel-container{
      justify-content: start;
    }
    .title{
      font-size:40px;
      padding-top: 1em;
    }

    .react-multi-carousel-item{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 40px;
    }
  }


`;

export { Container };