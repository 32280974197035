import { Container, BoxImagens, Label } from "../styled";
import { AdminContentBox } from "../../../../components/style/boxes";
import Title from "../../../../components/admin/title";

import { DropdownAdm, InputAdm } from "../../../../components/style/inputs";

import { useState } from "react";

import { ChooseImageIcon } from "../../../../components/style/assets";
import ConfirmButton from "../../../../components/admin/confirmButton";

import { ToastContainer, toast } from "react-toastify";

import { UselecturesContext } from "../lecturesContext";

import { API_URL } from "../../../../service/config";

import {UseLoginContext} from '../../login/loginContext'

import ApiLectures from "../../../../service/admin/lectures";
import ApiInstitution from "../../../../service/admin/institution.js";
const apiInstitution = new ApiInstitution();
const apiLectures = new ApiLectures();

export default function Lectures() {
    const {
      usuState: {auth},
  } = UseLoginContext();
  const[urlImg, setUrlImg]=useState(null)
  const {
    palestraState: { 
      house, 
      palestra: {
        id, 
        categoria: { dia, centroEspirita, tipo, ordem }, 
        info: { titulo, url, imagem, data}
      }
    },
    setOrdem,
    setDia,
    setCentro,
    setTipo,
    setTitulo,
    setUrl,
    setImagem,
    setData,
    novaPalestra,
    setListagem,
    setHouse
  } = UselecturesContext();

  const days = [
    "Selecione",
    "Segunda-feira",
    "Terça-feira",
    "Quarta-feira",
    "Quinta-feira",
    "Sexta-feira",
    "Sábado",
    "Domingo",
  ];
  const types = ["Selecione", "Palestras Online", "Esclarecimento", "Desobsessão", "Psicografia", "Saúde"];

  function selectFile() {
    setUrlImg(imagem === undefined || null ? urlImg : imagem);
    let input = document.getElementById("capa-input-file");
    input.click();
  }
  function previewImage() {
    if (imagem) {
      if (typeof imagem === "string")
        return `${API_URL}/admin/palestras/busca?imagem=${imagem.replace(/\\/g, '/')}&x-access-token=${auth}`;
      else 
        return URL.createObjectURL(imagem);
    } else {
      setImagem(urlImg)
      return URL.createObjectURL(urlImg);
    }
  }
  async function getLecturesFunction() {
    let r = await apiLectures.getLectures();
    setListagem(r);
    let house = await apiInstitution.getHouse();
    setHouse(house);
  }
  async function newLecturesFunction() {
    if (imagem === null){
      return toast.error('Deve-se cadastrar uma imagem')
    }
    
    if (id === "") {
      let r = await apiLectures.newLectures(
        titulo,
        url,
        data,
        dia,
        centroEspirita,
        tipo,
        ordem,
        imagem
      );
      if (r.erro) {
        toast.error(r.erro);
      } else {
        toast.success("Palestra cadastrada com sucesso");
        getLecturesFunction();
        clearConst();
      }
    } else {
      if (typeof imagem !== "string") {
        let r = await apiLectures.altLecturesPhoto(
          id,
          titulo,
          url,
          data,
          dia,
          centroEspirita,
          tipo,
          ordem,
          imagem
        );
        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("Palestra alterada com sucesso");
          getLecturesFunction();
          clearConst();
        }
      } else {
        let r = await apiLectures.altLectures(
          id,
          titulo,
          url,
          data,
          dia,
          centroEspirita,
          tipo,
          ordem,
          imagem
        );
        if (r.erro) {
          toast.error(r.erro);
        } else {
          toast.success("Palestra alterada com sucesso");
          getLecturesFunction();
          clearConst();
        }
      }
    }
  }
  function clearConst() {
    novaPalestra();
  }

  
  return (
    <Container>
      <ToastContainer />
      <AdminContentBox>
        <ConfirmButton onClick={newLecturesFunction} />
        <Title>
          Palestras Online -<span> {id === '' ? 'Novo': 'Alterando'} </span>
        </Title>

        <div className="form-row1">
          <div className="form-columnS2">
            <div className="form-row">
              <Label> Centro Espírita: </Label>
              <DropdownAdm
                value={centroEspirita}
                onChange={(e) => setCentro(e.target.value)}
              >
                <option key="Selecione" value="Selecione"> Selecione </option>
                {house.map((x) => (
                  <option key={x.nome} value={x.nome}>
                    {x.nome}
                  </option>
                ))}
              </DropdownAdm>
            </div>

            <div className="form-row">
              <Label> Dia: </Label>
              <DropdownAdm value={dia} onChange={(e) => setDia(e.target.value)}>
                {days.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </DropdownAdm>
            </div>

            <div className="form-row">
              <Label> Tipo: </Label>
              <DropdownAdm
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
              >
                {types.map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </DropdownAdm>
            </div>

            <div className="form-row">
              <Label> Ordem: </Label>
              <InputAdm
                onChange={(e) => setOrdem(e.target.value)}
                value={ordem}
              />
            </div>

            <br />
            <br />
          </div>

          <div className="form-columnS2">
            <div className="form-row">
              <Label> Título: </Label>
              <InputAdm
                onChange={(e) => setTitulo(e.target.value)}
                value={titulo}
              />
            </div>

            <div className="form-row">
              <Label> Url: </Label>
              <InputAdm onChange={(e) => setUrl(e.target.value)} value={url} />
            </div>

            <div className="form">
              <Label> Data: </Label>
              <div className="inp">
                <input
                  disabled={data == '' ? true : false}
                  onChange={(e) => setData(e.target.value)}
                  value={data}
                  type="datetime-local"
                />

                <input type="checkbox"
                  checked={data == '' ? true : false}
                  onChange={e => e.target.checked == true ? setData('') : setData(new Date().toJSON().substr(0, 16))}
                />
                <span>Sem data</span>
              </div>
            </div>

            <br />

            <div className="form-row top">
              <Label>Imagem:</Label>
              <BoxImagens>
                <label>
                  {imagem === null ? (
                    <ChooseImageIcon />
                  ) : (
                    <img className="imgPrevi" src={previewImage()} alt="" />
                  )}
                  <input
                    type="file"
                    id="capa-input-file"
                    onClick={selectFile}
                    onChange={(e) => e.target === undefined ? '' : setImagem(e.target.files[0])}
                  />
                </label>
              </BoxImagens>
            </div>
          </div>
        </div>
      </AdminContentBox>
    </Container>
  );
}
