import { createContext, useContext, useReducer } from "react";

const reducer  = (state, action) => {
    switch (action.type) {
      
      case 'set_ID'      : return { ...state, _id: action.id}
      case 'setId'       : return { ...state, centroEspiritaId: action.id }
      case 'setNome'     : return { ...state, nome: action.nome           }
      case 'setOrdem'    : return { ...state, ordem: action.ordem         }
      case 'setIcone'    : return { ...state, imagens:{ ...state.imagens, icone: action.icone}}
      case 'setCapa'     : return { ...state, imagens:{ ...state.imagens, capa:  action.capa}}
      case 'setResumo'   : return { ...state, descricao: {...state.descricao, resumo:   action.resumo   }}
      case 'setCompleto' : return { ...state, descricao: {...state.descricao, completo: action.completo }}

      case 'setHistoriaD' : return{...state,  textos: {...state.textos, historia: {...state.textos.historia, descricao: action.descricao} } }
      case 'setHistoriaI' : return{...state,  textos: {...state.textos, historia: {...state.textos.historia, imagem:    action.imagem   } } }
      case 'setHistoriaT' : return{...state,  textos: {...state.textos, historia: {...state.textos.historia, titulo:    action.descricao} } }
      
      case 'setFazemosD' : return{...state,   textos: {...state.textos, fazemos: {...state.textos.fazemos, descricao: action.descricao} } }
      case 'setFazemosI' : return{...state,   textos: {...state.textos, fazemos: {...state.textos.fazemos, imagem:    action.imagem   } } }
      case 'setFazemosT' : return{...state,   textos: {...state.textos, fazemos: {...state.textos.fazemos, titulo:    action.descricao   } } }
      
      case 'setPropostaD' : return{...state,  textos: {...state.textos, proposta: {...state.textos.proposta, descricao: action.descricao} } }
      case 'setPropostaI' : return{...state,  textos: {...state.textos, proposta: {...state.textos.proposta, imagem:    action.imagem   } } }
      case 'setPropostaT' : return{...state,  textos: {...state.textos, proposta: {...state.textos.proposta, titulo:    action.descricao   } } }
      
      case 'setEndereco'     : return{...state, contato: {...state.contato, endereco:  action.endereco }} 
      case 'setEmail'        : return{...state, contato: {...state.contato, email:     action.email    }}
      case 'setTelefone1'    : return{...state, contato: {...state.contato, telefone1: action.telefone1}}
      case 'setTelefone2'    : return{...state, contato: {...state.contato, telefone2: action.telefone2}}
      case 'setLatitude'     : return{...state, contato: {...state.contato, latitude : action.latitude }}
      case 'setLongitude'    : return{...state, contato: {...state.contato, longitude: action.longitude}}
      case 'setDescContato'  : return{...state, contato: {...state.contato, descricao: action.desc     }}
      case 'setTituloContato': return{...state, contato: {...state.contato, titulo:    action.titulo   }}
      case 'setURLContato'   : return{...state, contato: {...state.contato, link: { ...state.contato.link,    url: action.url    }}}
      case 'setTituloURL'    : return{...state, contato: {...state.contato, link: { ...state.contato.link, titulo: action.titulo }}}

      case 'setNomeRede' : return{...state, redesSociais: {...state.redesSociais, nome:   action.nome}}
      case 'setUrlRede'  : return{...state, redesSociais: {...state.redesSociais, url:    action.url }}
      case 'setImgRede'  : return{...state, redesSociais: {...state.redesSociais, imagem: action.img }}
      case 'setRedeNova' : return{...state, redesSociais: {...initialState.redesSociais              }}
      case 'setRedeArray': return{...state, ArrayRedeSocial: action.array                             }
      
      case 'setNomePart' : return{...state, participantes: {...state.participantes, nome: action.nome  }}
      case 'setOrdemPart': return{...state, participantes: {...state.participantes, ordem: action.ordem}}
      case 'setImgPart'  : return{...state, participantes: {...state.participantes, imagem: action.img }}
      case 'setArrayPart': return{...state, ArrayParticipante: action.array                             }
      case 'setPartNova' : return{...state, participantes: {...initialState.participantes              }}

      case 'setIdCalendario'     : return{...state, calendario:{...state.calendario, _idCalendario: action.id   }}
      case 'setTipoCalendario'   : return{...state, calendario:{...state.calendario, tipo: action.tipo          }}
      case 'setDescCalendario'   : return{...state, calendario:{...state.calendario, descricao: action.desc     }}
      case 'setDiaCalendario'    : return{...state, calendario:{...state.calendario, dia: action.dia            }}
      case 'setArrayCalendario'  : return{...state, calendarioArray: action.array                                }
      case 'setNovaCalendario'   : return{...state, calendario: {...initialState.calendario                     }}
      
      case "setInfoAlbum"    :  return{...state, infoAlbum:     action.infoAlbum  }
      case "setAlbumsArray"  :  return{...state, albumsArray:   action.albums     }
  
      case "setIDAlbum"      :  return{...state, infoAlbum: {...state.infoAlbum, _id:    action.id}} 
      case "setTituloAlbum"  :  return{...state, infoAlbum: {...state.infoAlbum, titulo:    action.titulo}} 
      case "setCapaAlbum"    :  return{...state, infoAlbum: {...state.infoAlbum, imagem:    action.capa  }} 
      case "setDescAlbum"    :  return{...state, infoAlbum: {...state.infoAlbum, descricao: action.desc  }} 
   
      case "setAlbumId"         : return{...state, infoGaleria:{...state.infoGaleria, albumId: action.id    }} 
      case "setGaleriaTitulo"   : return{...state, infoGaleria:{...state.infoGaleria, titulo:  action.titulo}}   
      case "setGaleriadescricao": return{...state, infoGaleria:{...state.infoGaleria, descricao: action.desc}}   
      case "setGaleriaimagem"   : return{...state, infoGaleria:{...state.infoGaleria, imagem: action.imagem }}
      case "setGaleriadata"     : return{...state, infoGaleria:{...state.infoGaleria, data: action.data     }}
  
      case 'novoGaleria'  : return{ ...state, infoGaleria: {...initialState.infoGaleria}}
  
      case "setInfogaleria"  :  return{...state, infoGaleria:   action.infoGaleria}
      case "setGaleriaImages":  return{...state, galeriaImages: action.galeria    }
  
    
      default:
        break;
    }
}

const initialState = {
    _id: '',
    centroEspiritaId: '',
    ordem: 0,
    nome: '',
    imagens:{
      capa: null,
      icone: null
    },
    descricao: {
        resumo: "",
        completo:""
    },
    textos: {
        historia:{
            titulo: "",
            descricao:"",
            imagem: null
        },
        fazemos:{
            titulo: "",
            descricao:"",
            imagem: null
        },
        proposta:{
            titulo: "",
            descricao:"",
            imagem: null    
        }
    },
    sessoes: [{
        ordem: 0,
        tipo: "", // linha, coluna
        titulo: "",
        descricao: "",
        imagem: null
    }],    
    calendario:{
        _idCalendario: '',
        dia: '',
        descricao:'', 
        tipo: ''
    },
    calendarioArray : [],
    participantes: {
        ordem:"",
        nome:"",
        imagem:null
    },
    ArrayParticipante:[],
    redesSociais: {
        nome: "",
        url: "",
        imagem: null
    },
    ArrayRedeSocial:[],
    contato: {
        titulo:"",
        descricao:"",
        endereco:"",
        telefone1:"",
        telefone2: "",
        latitude:"",
        longitude:"",
        email:"",
        link: {             // pensei em enviar para um formulário no caso da pessoa querer entrar em um grupo de estudo
            titulo:"",
            url:""
        }
    },
    infoAlbum: {
        _id: "",
        atividadeId: "",
        centroespiritaId: "",
        descricao: "",
        imagem: "",
        titulo: ""
      }, // OBJETO COM AS INFORMAÇÕES DE UM ALBUM 
      albumsArray: [], // ARRAY COM TODOS OS ALBUMS DA CASA
      
    
      infoGaleria: {
        albumId: "",
        titulo: "",
        descricao: "",
        imagem: null,
        data: "" 
      }, // objeto com as infos da fotos de um album
      galeriaImages:[]// array com todas as fotos de um album
}

const ActivityContext = createContext();

export default function ActivityContextProvider(props) {
  
  const [ activityState, setActivityState ] = useReducer(reducer, initialState)

  return (
    <ActivityContext.Provider
      value={{ activityState, setActivityState }}
    >
      {props.children}
    </ActivityContext.Provider>
  );
}

export function UseActivityContext() {
  const context = useContext(ActivityContext);
  const { activityState, setActivityState } = context;

  return {
    activityState,
    
    set_ID      : (id)       => setActivityState({type: 'set_ID'       ,   id     }),
    setId       : (id)       => setActivityState({type: 'setId'        ,   id     }),
    setNome     : (nome)     => setActivityState({type: 'setNome'      ,  nome    }),
    setOrdem    : (ordem)    => setActivityState({type: 'setOrdem'     , ordem    }),   
    setCapa     : (capa)     => setActivityState({type: 'setCapa'      , capa     }),  
    setIcone    : (icone)    => setActivityState({type: 'setIcone'     , icone    }),  
    setResumo   : (resumo)   => setActivityState({type: 'setResumo'    , resumo   }), 
    setCompleto : (completo) => setActivityState({type: 'setCompleto'  , completo }),       
    
    setHistoriaD : (descricao) => setActivityState({type: 'setHistoriaD' , descricao}),
    setHistoriaT : (descricao) => setActivityState({type: 'setHistoriaT' , descricao}),
    setHistoriaI : (imagem)    => setActivityState({type: 'setHistoriaI' , imagem   }),

    setFazemosD :  (descricao) => setActivityState({type: 'setFazemosD' , descricao}),
    setFazemosT :  (descricao) => setActivityState({type: 'setFazemosT' , descricao}),
    setFazemosI :  (imagem)    => setActivityState({type: 'setFazemosI' ,  imagem   }),

    setPropostaD : (descricao) => setActivityState({type: 'setPropostaD' , descricao}),
    setPropostaI : (imagem)    => setActivityState({type: 'setPropostaI' , imagem   }),
    setPropostaT : (descricao) => setActivityState({type: 'setPropostaT' , descricao   }),

    setEndereco         : (endereco)  => setActivityState({type: 'setEndereco'     ,    endereco        }),
    setEmail            : (email   )  => setActivityState({type: 'setEmail'         ,   email           }),
    setTelefone1        : (telefone1) => setActivityState({type: 'setTelefone1'     ,   telefone1       }),
    setTelefone2        : (telefone2) => setActivityState({type: 'setTelefone2'     ,   telefone2       }),
    setLatitude         : (latitude)  => setActivityState({type: 'setLatitude'      ,   latitude        }),
    setLongitude        : (longitude) => setActivityState({type: 'setLongitude'     ,   longitude       }),
    setDescContato      : (desc     ) => setActivityState({type: 'setDescContato'   ,   desc            }),
    setTituloContato    : (titulo   ) => setActivityState({type: 'setTituloContato' ,   titulo          }),
    setURLContato       : (url      ) => setActivityState({type: 'setURLContato'    ,   url             }),
    setTituloURL        : (titulo   ) => setActivityState({type: 'setTituloURL'     ,   titulo          }),


    setNomeRede         : (nome)   => setActivityState({type: 'setNomeRede' , nome }),
    setUrlRede          : (url)    => setActivityState({type: 'setUrlRede'  , url  }),
    setImgRede          : (img)    => setActivityState({type: 'setImgRede'  , img  }),
    setRedeArray        : (array)  => setActivityState({type: 'setRedeArray', array}),
    setRedeNova         : ()       => setActivityState({type: 'setRedeNova' }), 

    setNomePart         : (nome ) => setActivityState({type: 'setNomePart' , nome }),
    setOrdemPart        : (ordem) => setActivityState({type: 'setOrdemPart', ordem}),
    setImgPart          : (img  ) => setActivityState({type: 'setImgPart'  , img  }), 
    setArrayPart        : (array) => setActivityState({type: 'setArrayPart', array}),
    setPartNova         : (     ) => setActivityState({type: 'setPartNova' }),

    setIdCalendario     : ( id )   => setActivityState({type: 'setIdCalendario', id      }),
    setTipoCalendario   : ( tipo ) => setActivityState({type: 'setTipoCalendario', tipo  }),
    setDescCalendario   : ( desc ) => setActivityState({type: 'setDescCalendario', desc  }),
    setDiaCalendario    : ( dia  ) => setActivityState({type: 'setDiaCalendario'  , dia  }),
    setArrayCalendario  : (array ) => setActivityState({type: 'setArrayCalendario', array}),    
    setEdtCalendario    : (      ) => setActivityState({type: 'setNovaCalendario'        }),

    setInfoAlbum:    (infoAlbum) => setActivityState( {type: 'setInfoAlbum', infoAlbum}),
    setAlbumsArray:  (albums)    => setActivityState( {type: 'setAlbumsArray', albums}),
    setTituloAlbum:  (titulo)    => setActivityState( {type: 'setTituloAlbum', titulo}),
    setCapaAlbum:    (capa)      => setActivityState( {type: 'setCapaAlbum',   capa}),
    setDescAlbum:    (desc)      => setActivityState( {type: 'setDescAlbum',  desc}),
    setIDAlbum:      (id)        => setActivityState( {type: 'setIDAlbum',     id }),

    setAlbumId:          (id)    => setActivityState( {type: 'setAlbumId'         ,  id    }),
    setGaleriaTitulo:    (titulo)=> setActivityState( {type: 'setGaleriaTitulo'   ,  titulo}),
    setGaleriadescricao: (desc)  => setActivityState( {type: 'setGaleriadescricao',  desc  }),
    setGaleriaimagem:    (imagem)=> setActivityState( {type: 'setGaleriaimagem'   ,  imagem}),
    setGaleriadata:      (data)  => setActivityState( {type: 'setGaleriadata'     ,  data  }),

    setInfogaleria:  (infoGaleria)=> setActivityState( {type: 'setInfogaleria', infoGaleria}),
    setGaleriaImages:(galeria)    => setActivityState( {type: 'setGaleriaImages', galeria}),
    novoGaleria: () => setActivityState({type: 'novoGaleria'}) 
  

    }
}