import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7f9fb;
  margin: 0px;
  width: 100%;
  align-items: center;
  padding: 5em 0em;

 

  .title1 {
    color: #1c1f26;
    font-family: Manrope;
    font-weight: 700;
    font-size: 3.5em;
    padding: 1em;
    
  }
  .comp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    
    gap: 3em;
    width: 100%;
    max-width: 1200px;
   
  }

  @media (max-width: 1280px){
    .title{
      font-size: 1.5em;
    }
    .title1 {
      font-size: 40px;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 1024px) {
    .title1 {
      font-size: 40px;
    }
  }
`;

export { Container };
