
import React, { useEffect, useState } from "react";
import { Container } from "./styled";
import Gallery from "../../../components/commum/galleryContainer/Routes";


import Api from '../../../service/album/index'
const api = new Api()

export default function Index(props){

    const [imgs, setImgs] = useState([])
    const [id]   = useState(props.info._id)
    const data = props.data;

    async function getAlbum() {
        if(data){
            setImgs(data);
            return;
        }
        let r = await api.listAlbuns(id)
        setImgs(r)
    }
  
    useEffect(
        () => { getAlbum()}, 
        // eslint-disable-next-line 
        [id]
    )
    return(
        <Container id={props.id}>
            <h1  >Galeria</h1>
            <div className="gallery"  data-aos-anchor-placement="top-bottom"  >
                <Gallery images={imgs}/>
            </div>
        </Container>
    )
}