import Container from "./styled";
import BoxDay from './boxDay/index';

export default function Index(props) {
  const days = props.info
  let day = new Date().getDay();



  return (
    <Container id={props.id} empty={days.every(d => d.atividades.length == 0)}>
      {days.every(d => d.atividades.length == 0)
        ? ''
        : <>
          <h1> Horário de atuação </h1>
          <div className="days"  >
            <BoxDay day="SEG" presentDay={day === 1 ? '' : undefined} hours={days[0]?.atividades ?? []} />
            <BoxDay day="TER" presentDay={day === 2 ? '' : undefined} hours={days[1]?.atividades ?? []} />
            <BoxDay day="QUA" presentDay={day === 3 ? '' : undefined} hours={days[2]?.atividades ?? []} />
            <BoxDay day="QUI" presentDay={day === 4 ? '' : undefined} hours={days[3]?.atividades ?? []} />
            <BoxDay day="SEX" presentDay={day === 5 ? '' : undefined} hours={days[4]?.atividades ?? []} />
            <BoxDay day="SAB" presentDay={day === 6 ? '' : undefined} hours={days[5]?.atividades ?? []} />
            <BoxDay day="DOM" presentDay={day === 0 ? '' : undefined} hours={days[6]?.atividades ?? []} />
          </div>
        </>
      }
    </Container>
  );
}
