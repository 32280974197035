import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  

  .adap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .schedule {
    font-family: "Manrope";
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    width: 13em;
    
  }

  .crate {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    padding: 1em;
    width: 13em;
    height: 20em;
    
    border-radius: 21px;
    color: #1C1F26;
    :hover{
      transition: 0.5s;
      box-shadow: 0px 13px 60px #DBDBDB;
      transform: scale(1.05)
    }
    margin: 5em 0em;

  }

  .name {
    font-family: "Manrope";
    font-weight: 700;
    font-size: 20px;
    padding: 0.7em 0em 0.7em 0em;
    letter-spacing: 1px;

    color: #1C1F26;
  }

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
     .crate{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0em;
    } 
  }

  
`;

const Photo = styled.div`
  border-radius: 50%;
  background-image: url(${(props) => props.photo});
  background-size: cover;
  height: 9.5em;
  width: 9.5em;
  background-repeat: no-repeat;
  background-position: center center;

`;

export { Container, Photo };