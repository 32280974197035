import { useEffect, useState } from 'react';
import {Container, StyledBurger} from './styled';
import { Link, useNavigate } from 'react-router-dom'

export default function MenuTop(props){
    const titles = props.info;
    const navigation = useNavigate();

    const[open, setOpen] = useState(false)

    const scrool = (n) => {
        if(n.pag) return navigation(n.pag, {state: n.local});
        let element = document.getElementById(n.local);
            element.scrollIntoView({behavior: 'smooth'});
    }

    function logoClick() {
        if (!props.logoClick)
            navigation('/');
        else if (props.logoClick == 'back')
            navigation(-1);
    }

    useEffect(() => {
        if (open) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
    
        return () => {
          document.body.style.overflow = "auto";
        };
      }, [open]);

    
    return(
        <Container open={open}>
            <div onClick={logoClick} className="soon"><img src={props.imagem} alt="logo"/></div>
            
            <StyledBurger open={open} onClick={() => setOpen(!open)} color={props.color}>
                <div />
                <div />
                <div />
            </StyledBurger>


            <div className="titles">
                {titles.map((i) =>
                    i.title === "Notícias" && i.scrool === undefined 
                    ?
                        <div className="titlee" key={i.local} style={{ color: props.color === undefined ? "black" : props.color }}>
                            <Link key={i.local} to="/noticias" style={{padding: '1em 3em'}}>
                                {i.title}
                            </Link>
                        </div>
                    :
                    <div
                        key={i.local} 
                        className="titlee" 
                        onClick={() => { setOpen(false); scrool(i); }} 
                        style={{color: props.color === undefined? "black": props.color }}>
                            {i.title}
                    </div>
                )}
            </div>
        </Container>

    )
}