import { Routes, Route, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { Container, Content, PageContent } from "./styled";

import LoginContextProvider, { UseLoginContext} from "./login/loginContext.js";

import Header from "../../components/admin/header";
import Menu from "../../components/admin/menu";

import Institution from "./institution";
import Activity from "./activity";
import DailyGospel from "./dailyGospel";
import Donate from "./donate";
import News from "./news";
import Events from "./events";
import Lectures from "./lectures";
import ManagerUser from "./ManageUser";

import ActivityContextProvider from "./activity/activityContext";
import { useEffect } from "react";

function ValidAuth(navigation, setAuth) {
  let c = Cookies.get("userCookie");

  if (c === undefined) {
    navigation("/admin/login");
    return null;
  }
  
  let r = JSON.parse(Cookies.get('userCookie'))
  setAuth(r.auth)
}

export default function Admin() {
  const { setAuth } = UseLoginContext();
  const navigation = useNavigate();
  useEffect(
    () => {  
      ValidAuth(navigation, setAuth)
    }, 
      // eslint-disable-next-line 
  [navigation])



  return (
    <LoginContextProvider>
      <Container>
        <ActivityContextProvider>
          <Menu />
          <Content>
            <Header />
            <PageContent>
              <Routes>
                <Route path="/casa"  element={<Institution/>} />
                <Route path="/evangelhoDiario"  element={<DailyGospel/>} />
                <Route path="/doacao"  element={<Donate/>} />
                <Route path="/noticias"  element={<News/>} />
                <Route path="/eventos"  element={<Events/>} />
                <Route path="/palestras"  element={<Lectures/>} />
                <Route path="/GerenciarAcesso"  element={<ManagerUser/>} />
                <Route path="/atividade"  element={<Activity/>} />
              </Routes>
            </PageContent>
          </Content>
        </ActivityContextProvider>
      </Container>
    </LoginContextProvider>
  );
}
