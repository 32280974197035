import styled from "styled-components";

const StyledBoxLogin = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 0.7em;

    background-color: rgba(31, 28, 34, 0.86);

    height: ${props => props.screen === "cadastre" ? "24em" 
                                                   : props.screen === "forgot" ? "25em" : "21.5em" };
    width: 24em;

    @media(min-width: 1024px){
        height: ${props => props.screen === "cadastre" ? "23em" : "25em" };
        width: 24em;
    }

    @media(max-width: 1280px){
        height: ${props => props.screen === "cadastre" ? "30em" : "28em" };
        width: 30em;
    }

    @media(min-width: 1440px){
        height: ${props => props.screen === "cadastre" ? "30em" : "28em" };
        width: 30em;
    }

    @media(min-width: 1920px){
        height: ${props => props.screen === "cadastre" ? "34em" : "32em" };
        width: 34em;
    }
`

const WhiteBoardExercices = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat-bold;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 4px;
    height: 100%;
    width: 50em;
    padding-top: 2em;
    position: relative;

`

const BoxShadow = styled.div`
    display: flex;
    flex-direction: column;

    background-color: #0000001A;

    stroke: Solid #3C3A3A;
    box-shadow: 0px 0px 18px 3px rgba(160, 160, 160, 0.1);

    margin: .3em;
    padding: 2em 1.5em 2em 2.5em;
`


const AdminContentBox = styled.div`
    display: flex;
    flex-direction: column;
    
    position: relative;
    
    background-color: #FFFFFF;
    color: #3C3939;

    width: 100%;
    margin: 2em 0em;

    padding: 2.5em 1.5em;
    box-shadow: 0px 0px 4px 1px rgba(186, 186, 186, 0.25);
`


export { StyledBoxLogin, WhiteBoardExercices, AdminContentBox, BoxShadow }
