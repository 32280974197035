import * as React from "react";
import { Container, ButtonStyledLeft, ButtonStyledRigth} from "./styled";

export default function Index(props){

  return(

    <Container >

      <h1  > {props.titulo} </h1>
      <div className="btn-actions"   >
        <ButtonStyledLeft bg={props.carouselState.currentSlide} onClick={props.previous}  style={{transform: 'matrix(-1, 0, 0, 1, 0, 0)'}}>  <img src="/assets/images/Icon metro-arrow-right.svg" alt=""/> </ButtonStyledLeft>
        <ButtonStyledRigth  bg={props.carouselState.currentSlide} total={props.carouselState.totalItems} onClick={props.next}>      <img src="/assets/images/Icon metro-arrow-right.svg" alt=""/> </ButtonStyledRigth>
      </div>

    </Container>
  )
}
