import styled from 'styled-components'

const Container = styled.div`

display: flex;
flex-direction: column;

.inp{
    display: flex;
    align-items: center;
    gap: 10px;
    
    width:100%;
    font: 1em Roboto;
    text-align: right;
    border:black;
}
.inp input:not([type="checkbox"]):not([type="radio"]){
    height:auto;

    width: 63%;
    height: 2.5em;
    font: 400 1em Roboto;
    color:  #615858 ;
    border-radius: 5px;
    border: 1px solid rgba(168,168,168,1);
    outline: none;
    padding: 0em 0.5em;

}
.imgPrevi{
    height: 9em;
    cursor: pointer;
}
.form-columnS2{
    width:40%;

}




    

.form-column {
        display: flex;
        flex-direction: column;
    }


    .form{ 
        display: flex;
        flex-direction: row;

        text-align: right;
        align-items:center;
        
    }



   

    .form-r{
        display: flex;
        flex-direction: row;
        margin: 0.3em 0em;

        width:100%;

    
    }


    .form-row1 {
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        width:100%;
        
        
        

        margin: 0.3em 0em;
    }



    .form-row{
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 0.3em 0em;
    }

    .center {
        justify-content: center;
    }

    .top {
        align-items: flex-start;
    }

    .s1 {
        width: 30%;
    }

    .s2 {

        width: 42%; 
        
    }

    .s3 {
        width: 50%;

    }


    input[type="file"] {
                display: none;
            }




    
    .table-container {
        width: 95%;
        margin: 0px auto;
        margin-top: 1em;
    }
`

export const Label = styled.div`
    font: 1em Roboto;
    width: 150px;
    text-align: right;
    margin-right: 10px;

    cursor:auto;

    
`






export const BoxImagens = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
 
    

    & > div {
        display: flex;
        justify-content: flex-start;
    
    }

    & svg {
        width: 9em;
        height: 9em;
        margin: 0em 1em;
        cursor:pointer;
    }

    & path{
        cursor:pointer;
    }

`



export const Text = styled.div`
    font: 1em Roboto;
    text-align: left;
    margin-right: 10px;
`


export {Container}