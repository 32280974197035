import axios from 'axios'

import { API_URL } from '../config'

const api = new axios.create({
    baseURL: `${API_URL}/website`
})

export default class Api{
    async listgaleria(id){
        let r = await api.get(`/galeria/${id}`)
        return r.data
    }

}