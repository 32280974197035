import styled from "styled-components";

const Container = styled.div`
  background-color: #f7f9fb;
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  align-items: center;
  border-radius: 21px;
  margin: 5em 8em;
  justify-content: space-evenly;

  .title {
    text-align: center;
    width: 15em;
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 2.3em;
    margin-bottom: 1em;
 
  }

  .imgR img {
    margin-right: 1em;
    height: 3em;
    width: 3em;
    cursor: pointer;

  
  }



 

  .sub {
    text-align: center;
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 2.2em;
    margin-top: 1em;
  }

  .text {
    text-align: center;   
    font-family: Manrope;
    font-weight: 500;
    font-size: 1.2em;
    
    color: #1C1F26;
    span{
      /* text-decoration-line: underline; */
    }
    
    line-height: 40px;
  }
  @media (max-width: 1024px){
      margin: 2em 1.0em;
      height: auto;
      padding: 4em 0em;
      margin-bottom: 4em;

    .title{
      width: auto;
      font-size: 36px;
      margin-bottom: 1em;
      padding: 0em 1em;
    }
    .sub {
      /* margin: 1em 0em; */
    }
    .text{
      width: 100%;
      font-size: 18px;
      line-height: 30px;
    }
    .subtit {
      display: none;
    }
  }
`;

export { Container };
