import { Container } from "./styled";
import { API_URL } from "../../../service/config";

export default function Index(props){
    const contato = props.info.contato
    const redesSociais = props.info.redesSociais
   
    return(
        <Container id={props.id}>
            <h1>Venha nos Visitar</h1>
            <div className="divmap">
                <div className="mapStyle">
                <div style={{Width: "100%"}}>
                    <iframe 
                        title="mapaLocalizador"
                        src={`https://www.google.com/maps/embed/v1/place?q=${contato.latitude},${contato.longitude}&key=${process.env.REACT_APP_MAPS_KEY}`}
                        width="100%" 
                        height="500"
                        allowfullscreen="" 
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    >
                   </iframe>
                </div>
                </div>
                <div className="infos">
                    <div>
                        <div><b>Endereço:</b> {contato.endereco}</div>
                        <div><b>E-mail:</b> {contato.email}</div>
                        <div><b>{contato.telefone2 ? 'Telefone 1:' : 'Telefone:'}</b> {contato.telefone1}</div>
                        {contato.telefone2 && <div><b>Telefone 2:</b> {contato.telefone2}</div>}
                    </div>
                    <div className="social"  data-aos-easing="ease-out-cubic" >
                    {redesSociais.map(i => 
                        <a href={i.url}><img src={`${API_URL}/website/busca?imagem=${i.imagem.replace(/\\/g, '/')}`} alt=""/></a>
                     )}
                    </div>
                </div>
            </div>
        </Container>
    )
}