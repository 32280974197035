import styled from "styled-components";

const Infos = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	background-color: white;
	border-radius: 1em;
	width: 100%;
	max-width: 1200px;
  	height: 20em;
	//padding: 1em 0em;

  
  
  & > div {
    margin: 0em .5em;
  }

  .image { text-align: center; }
	.image img {
	  height: auto;
    width: 100%;
    max-width: 17em;
	}

	.text-box {
		display: flex;
		flex-direction: column;
    width: 40%;
    margin: 0em 1em;
	}

	.title {
		font: 700 2rem 'Manrope';
	}

	.description {
		font: 500 1em 'Manrope';
    line-height: 1.5em;
		color: #777c86;
	}


  .computed-columns {
    display: flex;
  }

	.box-value {
		background: #e8efff;
		box-shadow: 0px 8px 21px rgba(196, 196, 196, 0.25);
		border-radius: 20px;

		height: 6em;
		width: 6.5em;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		padding: 0em 0.4em;
    margin: .5em;
	}

	.value {
		font: 500 2em "Bebas Neue";
		color: #160e4a;
	}

	.value-title {
		font: 500 1em "Manrope";
		color: #160e4a;
	}

	.celeste {
		background-color: #e8fffe;

		.value {
			color: #01d7f5 !important;
		}
	}

  
  @media (max-width: 1024px) {
    flex-direction: ${props => getDirectionForLayout(props.layout)};
    height: auto;
    padding: 2em 0em;

    .image {
      padding: 0em 1em;
    }
    .image img {
      width: 70%;
    }
    .text-box {
      align-items: center;
      width: 90%;
    }
    .text-box > div { margin: .3em .5em; }
    .title { font-size: 2.5rem; }
    .description { text-align: center; }

    .computed-columns {
      margin-top: 1em;
    }

    
  }


`;



function getDirectionForLayout(layout) {
  switch (layout) {
	  case 4:
	  case 3:
		  return 'column-reverse';
    default: return 'column';
  }
}


export { Infos };
