import Phrases from "../../../components/commum/home/phrase/phraseModule/";
import { Container } from "./styled";
import Carousel from "react-multi-carousel";
import { responsive, CustomDot } from "./configCarousel";

import ApiGospelDay from "../../../service/home/phrase.js";
import { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import wait from "../../../components/commum/wait";

const apiGospelDay = new ApiGospelDay();

export default function Index(props) {
	const [phrase, setPhrase] = useState([]);
	const [loading, setLoading] = useState(true);

  async function fListPhrase() {
    await wait(1500);
		let r = await apiGospelDay.listGospelDay();
		setPhrase(r);
		setLoading(false);
	}

	useEffect(() => fListPhrase(), []);

	let pageWidth = window.screen.width;
	let svgWidth;
	if (pageWidth > 700)
		svgWidth = 700;
	else
		svgWidth = pageWidth * 0.9;
    

	return (
		<Container id={props.id}>
			<div className="box1">
				<div className="duo">
					<div className="caption"> Evangelho Segundo o Espiritismo </div>
					<div className="title"> Evangelho do Dia </div>
				</div>
				{loading ? (
					<ContentLoader speed={2} width={svgWidth} height={600} viewBox="0 0 400 200" backgroundColor="#f2f2f2" foregroundColor="#e8e8e8">
						<rect x="80" y="91" rx="3" ry="3" width="254" height="6" /> 
						<rect x="78" y="106" rx="3" ry="3" width="254" height="6" /> 
						<rect x="150" y="121" rx="3" ry="3" width="118" height="6" /> 
						<rect x="28" y="35" rx="32" ry="32" width="15" height="15" /> 
						<rect x="28" y="27" rx="0" ry="0" width="4" height="18" /> 
						<rect x="45" y="35" rx="32" ry="32" width="15" height="15" /> 
						<rect x="45" y="27" rx="0" ry="0" width="4" height="19" /> 
						<rect x="336" y="149" rx="32" ry="32" width="15" height="15" /> 
						<rect x="357" y="149" rx="32" ry="32" width="15" height="15" /> 
						<rect x="347" y="154" rx="0" ry="0" width="4" height="18" /> 
						<rect x="368" y="154" rx="0" ry="0" width="4" height="18" /> 
						<rect x="79" y="76" rx="3" ry="3" width="254" height="6" /> 
						<rect x="79" y="61" rx="3" ry="3" width="254" height="6" /> 
						<circle cx="195" cy="168" r="6" /> 
						<circle cx="210" cy="168" r="6" /> 
						<circle cx="224" cy="168" r="6" /> 
						<rect x="167" y="24" rx="0" ry="0" width="19" height="19" /> 
						<rect x="196" y="25" rx="0" ry="0" width="19" height="19" /> 
						<rect x="222" y="26" rx="0" ry="0" width="19" height="19" />
					</ContentLoader>
				) : (
					<Carousel
						responsive={responsive}
						showDots={true}
						infinite={true}
						arrows={false}
						autoPlay={false}
        				autoPlaySpeed={5000}
						customDot={<CustomDot />}
						containerClass="BoxCaroucel"
						itemClass="itemCaroucel">
						{phrase.map((item, index) => (
							<Phrases key={index} evaluation="../assets/images/avaliacao.png" phrase={item.mensagem.texto} chapter={item.mensagem.capitulo} info={item} />
						))}
					</Carousel>
				)}
			</div>
		</Container>
	);
}
