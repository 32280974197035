import {createContext, useContext, useReducer} from 'react'

const reducer = (state, action) => {
    switch (action.type) {
        case 'setCasaId': return {...state, casaId: action.casaId}
        case 'setTitle': return{...state, title: action.title }
        case 'setSubTitle': return {...state, subTitle: action.subTitle }
        case 'setNoticeState'   : return{...state, noticeState: action.noticeState}
        case 'setThisModal': return {...state,  thisModal: action.thisModal }
        case 'setPhotoModal': return {...state, photoModal: action.photoModal }
        case 'setBtmMore': return {...state,btmMore: action.btmMore }
        case 'setNoticeVisu': return {...state,noticeVisu: action.noticeVisu }
        case 'setMenuList': return {...state, menuList: action.menuList}
        case 'inicio': return {...InitialState}
        case 'clearNotices': return {...state, noticeState: []}
        case 'setLimit': return { ...state, limit: action.limit }
        case 'setFiltersToLoad': return { ...state, limit: action.limit, title: action.title, subTitle: action.subTitle, btmMore: action.btmMore}
        default:
        break;
    }
}

const InitialState = {
    casaId: 0,
    title: 'Casa de Fabiano',
    subTitle: 'Todas as Notícias',
    noticeState: [],
    thisModal: {show: false},
    photoModal: '',
    btmMore: true,
    noticeVisu: [],
    menuList: [],
    limit: 5
}

const EventContext = createContext();

export default function EventContextProvider(props){
    const [eventoState, setEventoState] = useReducer(reducer,InitialState);

    return (
        <EventContext.Provider value={{ eventoState, setEventoState }}>
            {props.children}
        </EventContext.Provider>
    )
}

export function UseeventContext() {
    const context = useContext(EventContext)

    const {eventoState, setEventoState} = context

    return{ 
        eventoState,
        setCasaId: (casaId) => setEventoState({ type: 'setCasaId', casaId }),
        setTitle: (title) => setEventoState({ type: 'setTitle', title }),
        setSubTitle: (subTitle) => setEventoState({ type: 'setSubTitle', subTitle }),
        setNoticeState: (noticeState) => setEventoState({ type: 'setNoticeState', noticeState }),
        setThisModal: (thisModal) => setEventoState({ type: 'setThisModal', thisModal }),
        setPhotoModal: (photoModal) => setEventoState({ type: 'setPhotoModal', photoModal }),
        setBtmMore: (btmMore) => setEventoState({ type: 'setBtmMore', btmMore }),
        setNoticeVisu: (noticeVisu) => setEventoState({ type: 'setNoticeVisu', noticeVisu }),
        setMenuList: (menuList) => setEventoState({ type: 'setMenuList', menuList }),
        clearNotices: () => setEventoState({type: 'clearNotices'}),
        setLimit: (limit) => setEventoState({ type: 'setLimit', limit }),
        setFiltersToLoad: (limit, title, subTitle) => setEventoState({ type: 'setFiltersToLoad', limit, title, subTitle, btmMore: limit === 5 })
    }
} 
