import React from "react";


//Carousel Responsiveness Setting (Required)
const responsive = {
    desktop: {
        breakpoint: {
          max: 3000,
          min: 0
        },              
        items: 5,
      },
      mobile: {
        breakpoint: {
          max: 1280,
          min: 0
        },
        items:4,
      },
   
};



// arrow customization
const  CustomLeftArrow  =  ( { onClick, id   } )  =>  
    < i  id={id} className="custom-left-arrow" onClick = { ( )  =>  onClick ( ) } > <img src="/assets/images/Icon metro-arrow-right.svg" alt=""/> </i>; 

const  CustomRightArrow  =  ( { onClick, id  } )  =>  
    < i  id={id} style={{display: 'none'}} className="custom-right-arrow" onClick = { ( )  =>  onClick ( ) } > <img src="/assets/images/Icon metro-arrow-right.svg" alt=""/> </i>; 




// customization of navigation points

export {responsive, CustomLeftArrow, CustomRightArrow};

