import { Label } from "../../styled";
import { AdminContentBox } from "../../../../../components/style/boxes";
import Title from "../../../../../components/admin/title";

import { InputAdm } from "../../../../../components/style/inputs";
import ConfirmButton from "../../../../../components/admin/confirmButton";

import { UseInstitutionContext } from "../../institutionContext";
import { useEffect } from "react";
import { toast } from 'react-toastify';

import { useLocation } from "react-router-dom";

import ApiInstitution from "../../../../../service/admin/institution";
const api = new ApiInstitution()

export default function Index() {

  const { search } = useLocation()

  const {
    institutionState: { id, contato: { endereco, latitude, longitude, email, telefone1, telefone2 } },
    setEndereco,
    setLatitude,
    setlongitude,
    setEmail,
    setTelefone1,
    setTelefone2
  } = UseInstitutionContext();

  async function getContact() {
    let r = await api.getContact(search.slice(4))
    setEndereco(r.contato.endereco)
    setLatitude(r.contato.latitude)
    setlongitude(r.contato.longitude)
    setEmail(r.contato.email)
    setTelefone1(r.contato.telefone1)
    setTelefone2(r.contato.telefone2)
  }

  async function putContact() {
    let r = await api.putContact(id, endereco, latitude, longitude, telefone1, telefone2, email)
    if (r.erro) {
      toast.error(r.erro)
    } else {
      toast.success('Meios de Contato foram Atualizados')
      getContact()
    }
  }
  useEffect(
    () => { getContact() },
    // eslint-disable-next-line 
    [search]
  );
  return (
    <AdminContentBox>
      <ConfirmButton onClick={putContact} />
      <Title>Contato</Title>
      <div style={{ width: "100%" }} className="form-row top center">
        <div className="form-column5">
          <div className="form-row" style={{ position: "relative" }}>
            <Label> Endereço: </Label>
            <InputAdm value={endereco} onChange={(e) => setEndereco(e.target.value)} />
            <img
              src="/assets/images/icons8-search.svg"
              alt=""
              className="icon-search"
            />
          </div>

          <div className="form-column2">
            <div className="form-row">
              <Label> Latitude </Label>
              <InputAdm value={latitude} onChange={e => setLatitude(e.target.value)} />
            </div>
            <div className="form-row">
              <Label> Longitude </Label>
              <InputAdm value={longitude} onChange={e => setlongitude(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="form-column5">
          <div className="form-row">
            <Label> E-mail: </Label>
            <InputAdm value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="form-tel">
            <Label> Telefone 1: </Label>
            <div style={{ width: "100%" }}>
              <InputAdm value={telefone1} onChange={e => setTelefone1(e.target.value)} />
            </div>
          </div>
          <div className="form-tel">
            <Label> Telefone 2: </Label>
            <div style={{ width: "100%" }}>
              <InputAdm value={telefone2} onChange={e => setTelefone2(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
    </AdminContentBox>
  );
}
