import { API_URL } from "../../../../../service/config"
import Container from "./styled"
import {UseLoginContext} from "../../../login/loginContext"

export default function box(props) {    
    const info = props.info;
    const {
        usuState: {auth},
    } = UseLoginContext();
    return(
        <Container onClick={() => props.onClick('')}>
            <img src={`${API_URL}/admin/casa/busca?imagem=${info.imagem.replace(/\\/g, '/')}&x-access-token=${auth}`}  alt=""/>
            <div className="title">
                <span>{info.titulo.length >= 22 ? info.titulo.substring(0, 20) + '...' : info.titulo}</span>
            </div>
        </Container>
    )
}