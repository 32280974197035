import { Container } from "./styled";
import BoxAtividade from "./boxAtividade";

import { useEffect, useState } from "react";
import Api from '../../../service/activity/'
const api = new Api()

export default function Index(props) {
  const [ id ] = useState(props.info._id)
  const data = props.data;
  const [ApiResponse, setApiResponse] = useState([])

  async function getActivies() {
      if(data){
        setApiResponse(data)
        return;
      }
      let r = await api.ListActivityHouse(id)
      setApiResponse(r)
  }
  useEffect(
    () => {getActivies()}, 
    // eslint-disable-next-line 
    [id]
  )
  
  return (
    <Container id={props.id}>
      <div className="title1"  >Atividades</div>
      <div className="comp">
        {ApiResponse.map((i) => (
          <BoxAtividade casa={props.info.nome} info={i} house={props.info}/>
        ))}
      </div>
    </Container>
  );
}
