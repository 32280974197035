import styled from 'styled-components'

const Container = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    
    width: 350px;
    height: 171px;
    
    &:hover{
        transition: 1s;
        cursor: pointer;
        border-color: #004FFF;;
    }

    margin: 1em;
    padding: 1em 1em;

    @media(max-width: 700px){
        margin: 1em 0px;
    }
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-sizing: border-box;
    box-shadow: 0px 15px 20px 1px rgba(0, 0, 0, 0.05);
    border-radius: 15px;

    .infos{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1em;
        flex-grow: 1;
    }
    .title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 5px;
        margin-top: 10px;
        color: #000000;
    }
    p1{
        font-family: 'Manrope';
        font-style: bold;
        font-weight: 300;
        font-size: .9em;
        line-height: 18px;

        color: #000000;

        max-width: 11em;
    }

    span{
        font-family: 'Manrope';
        font-style: italic;
        font-weight: 300;
        font-size: 1em;
        line-height: 18px;

        text-align: center;
        
        color: #000000;
    }

    .prev{
        background-image: url(${(props)=> props.image});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;


        min-width: 8em;
        width: 8em;
        height: 100%;

        border-radius: 10px;
    }

    button{
        background-color: white;
        border: solid 0px;
        font-family: 'Roboto';
        /* font-style: italic;
        text-decoration: underline; */
        font-weight: 700;
        font-size: 10px;
        line-height: 18px;
        text-transform: uppercase;

        text-align: right;

        color: #000000;

        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }

`

const ContainerRender=styled.div`

    width: 100%;

    display: flex;
    align-items: center;    
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;


`

export {Container, ContainerRender}