
const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1401
    },              
    items: 4,
    partialVisibilityGutter: 100
  },
  tablet: {
    breakpoint: {
      max: 1400,
      min: 1025
    },
    items: 2,
    partialVisibilityGutter: 100
  },
  mobile: {
    breakpoint: {
      max: 1024,
      min: 0
    },
    items:1
  }
};

export {responsive};
