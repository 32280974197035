import axios from 'axios'
import { API_URL } from '../config.js'
const api = new axios.create({
    baseURL: `${API_URL}/website`    
})

export default class Api{
    async listMenu(){
        let r = await api.get('/noticia/menu')
        return r.data
    }

    async listTopicsMenu(id){
        let r = await api.get(`/noticia/menu/topics?id=${id}`)
        return r.data
    }

    async listNews(casa, categoria){
        let r = await api.get(`/noticia/list?casa=${casa}&categoria=${categoria}`)
        return r.data
    }

    async listNewsForHouse(house){
        let r = await api.get(`/noticia/house/${house}`)
        return r.data
    }
}