import axios from 'axios'
import { API_URL } from '../config'
const api = new axios.create({
    baseURL: `${API_URL}/admin`,
    
})

export default class Api{

    async login(email, password){ 
        let r = await api.post('/login', {email, password});
        return r.data;
    }

    async listAdmin(){
        let r = await api.get(`/`);
        return r.data;
    }

    async postAdmin(user, email, active, thisAdmin, houseId, position, activities, administrative){
        let r = await api.post('/new', {user, email, active, thisAdmin, houseId, position, activities, administrative});
        return r.data;
    }

    async putAdmin(id, user, email, active, thisAdmin, houseId, position, activities, administrative){
        let r = await api.put(`/${id}`, {user, email, active, thisAdmin, houseId, position, activities, administrative});
        return r.data;
    }

    async alterReset(id, reset){
        let r = await api.put(`/${id}/reset`, {reset})
        return r.data;
    }
    
    async resetPassword(id, senha){
        let r = await api.put(`/${id}/senha`, {senha})
        return r.data;
    }

    async deleteAdmin(id){
        let r = await api.delete(`/${id}`);
        return r.data
    }
}