import styled from "styled-components";

const Container = styled.div`
    .partTwo{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
`

export {Container}