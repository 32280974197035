import { Container } from "./styled";
import BoxEvents from "../../../components/commum/box-events";
import CustomButtonGroupAsArrows from "../../../components/commum/carouselButtonExternal";
import Carousel from "react-multi-carousel";
import { responsive } from "./configCarousel";
import { useState, useEffect } from "react";
import Modal from "../../../components/commum/modal";
import ReactPlayer from "react-player";
import { API_URL } from "../../../service/config";

import ApiRecentsNews from "../../../service/home/events/eventTwo.js";
import loaders from "../../../components/commum/loaders";
import wait from "../../../components/commum/wait";
const apiRecentNews = new ApiRecentsNews();

export default function App(props) {
  const [recentNews, setRecentNews] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fListRecentNews() {
    await wait(1500);

    let r = await apiRecentNews.listRecentNews();
    setRecentNews(r);
    setLoading(false);
  }

  const [thisModal, setThisModal] = useState({ show: false });
  const [link, setLink] = useState("");

  function getResolution() {
    //alert(window.innerWidth)
  //  if (window.devicePixelRatio > 1.25)
      return window.innerWidth;
    //else
    //  return window.screen.width * window.devicePixelRatio;
  }

  function OpenModal(infos) {
    setLink(infos.link);
    setThisModal({ show: !thisModal.show });
  }

  useEffect(() => {
    fListRecentNews();
  }, []);

  
  return (
    <Container id={props.id}>
      {!loading && recentNews.length == 0
        ? ''
        : <>
          
          <Modal open={() => setThisModal(false)} options={thisModal}>
            <ReactPlayer
              width="100%"
              height="100%"
              url={link}
              playing={thisModal.show}
            />
          </Modal>
          <Carousel
            responsive={responsive}
            centerMode={getResolution() <= 768 ? false : true}
            infinite={getResolution() <= 768 ? false : true}
            containerClass="carousel-container"
            autoPlay={true}
            autoPlaySpeed={3000}
            arrows={false}
            showDots={false}
            renderDotsOutside={false}
            renderButtonGroupOutside={true}
            customButtonGroup={
              <CustomButtonGroupAsArrows titulo="Notícias Recentes" />
            }
          >
            {loading
              ? loaders()
              : recentNews.map((i, index) => (
                <BoxEvents
                  image={`${API_URL}/website/busca?imagem=${i.imagens[0].replace(
                    /\\/g,
                    "/"
                  )}`}
                  open={OpenModal}
                  url={i.link}
                  data={i.data.substring(0, 10)}
                  title={i.titulo}
                  record={false}
                  all={i}
                  casa={i.categoria}
                  key={index}
                />
              ))
            }
          </Carousel>

        </>
      }


    </Container>
  );
}
