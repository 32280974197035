import { createContext, useContext, useReducer } from "react";

const reducer = (state, action) =>{
  switch(action.type){
    case 'setOrdem': return {...state, palestra: {...state.palestra,  categoria: {...state.palestra.categoria, ordem: action.ordem}}};
    case 'setDia': return { ...state, palestra: { ...state.palestra, categoria: { ...state.palestra.categoria, dia: action.dia } } };
    case 'setCentro': return {...state, palestra: {...state.palestra,  categoria: {...state.palestra.categoria, centroEspirita: action.centro}}};
    case 'setTipo': return {...state, palestra: {...state.palestra,  categoria: {...state.palestra.categoria, tipo: action.tipo}}};

    case 'setTitulo': return {...state, palestra: {...state.palestra, info: {...state.palestra.info, titulo: action.titulo}}};
    case 'setUrl': return {...state, palestra: {...state.palestra, info: {...state.palestra.info, url: action.url}}};
    case 'setImagem': return {...state, palestra: {...state.palestra, info: {...state.palestra.info, imagem: action.imagem}}};
    case 'setData': return {...state, palestra: {...state.palestra, info: {...state.palestra.info, data: action.data}}};

    case 'editPalestra': return{...state, palestra: action.palestra } ; 
    case 'novaPalestra': return{...state, palestra: initialState.palestra };
    case 'setListagem': return {...state,  listagem: action.listagem};
    case 'setHouse': return {...state,  house: action.house};

    default:
      break;
  }
}


const initialState ={
  titulo:'',
  palestra:{
      id:'',                              // dúvida
      categoria: {                                 
        dia:'',
        centroEspirita:'',
        tipo: '',
        ordem: 0,
      },
      info: {
        titulo:'',
        url:'',
        imagem:null,
        data:'' 
      }  
  },
  listagem:[],
  house:[]
}

const LecturesContext = createContext();

export default function LecturesContextProvider(props) {

  const [palestraState, setPalestraState] = useReducer(reducer, initialState);
    
    return (
      <LecturesContext.Provider value={{  palestraState, setPalestraState
        }}>
        {props.children}
      </LecturesContext.Provider>
    )
  }

export function UselecturesContext() {
    const context = useContext(LecturesContext);

    const { palestraState, setPalestraState } = context;
    
    return {
      palestraState,
      setOrdem:(ordem) => setPalestraState({ type:'setOrdem', ordem: isNaN(ordem) ? 0 : Number(ordem)}),
      setDia:(dia) => setPalestraState({ type:'setDia', dia}),
      setCentro:(centro) => setPalestraState({ type:'setCentro', centro}),
      setTipo:(tipo) => setPalestraState({ type:'setTipo', tipo}),


      setTitulo:(titulo) => setPalestraState({ type:'setTitulo', titulo}),
      setUrl:(url) => setPalestraState({ type:'setUrl', url}),
      setImagem:(imagem) => setPalestraState({ type:'setImagem', imagem}),
      setData:(data) => setPalestraState({ type:'setData', data}),


      setHouse:(house)=> setPalestraState({type:'setHouse', house}),
      setListagem:(listagem)=> setPalestraState({type:'setListagem', listagem}),
      editPalestra:(palestra)=> setPalestraState({type:'editPalestra', palestra}),   //dúvida
      novaPalestra:()=> setPalestraState({type:'novaPalestra'})

    }
  }