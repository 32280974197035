import styled from "styled-components"

export const Container = styled.div`
    background: #FFFFFF;
    border-radius: 17px;

    width: 100%;
    padding: 2.5em 2em;

    display: flex;
    align-items: center;

    font-size: 1em;

    & > div {
        margin: 0em 1em;
    }


    .image {
        width: 10em;
    }

    .text-box {
        display: flex;
        flex-direction: column;
    }

    .title {
        font: 700 2em Manrope;
    }

    .description {
        font: .8em Manrope;
        color: #777C86;
    }


    .box-value {
        background: #E8EFFF;
        box-shadow: 0px 8px 21px rgba(196, 196, 196, 0.25);
        border-radius: 20px;

        height: 6em;
        width: 6.5em;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 0em .4em;
    }

    .value {
        font: 500 2em 'Bebas Neue';
        color: #160E4A;
    }

    .value-title {
        font: 500 1em 'Manrope';
        color: #160E4A;
    }

    .light-blue {
        background-color: #E8FFFE;

        .value {
            color: #01D7F5 !important;
        }
    }



    @media (max-width: 1440px) {
        font-size: .97em;
    }

    @media (max-width: 1366px) {
        font-size: .90em;
    }

    @media (max-width: 1280px) {
        font-size: .85em;
    }
`

