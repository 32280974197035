import React from "react";
import classNames from "classnames";


//Carousel Responsiveness Setting (Required)
const responsive = {
    desktop: {
        breakpoint: {
          max: 3000,
          min: 0
        },              
        items: 5,
      },
      mobile: {
        breakpoint: {
          max: 1280,
          min: 0
        },
        items:1,
      },
   
};



// arrow customization
const  CustomLeftArrow  =  ( { onClick   } )  =>  
    < i  className="custom-left-arrow" onClick = { ( )  =>  onClick ( ) } > <img src="/assets/images/Icon metro-arrow-right.svg" alt=""/> </i>; 

const  CustomRightArrow  =  ( { onClick  } )  =>  
    < i  className="custom-right-arrow" onClick = { ( )  =>  onClick ( ) } > <img src="/assets/images/Icon metro-arrow-right.svg" alt=""/> </i>; 




// customization of navigation points
const CustomDot = ({ index, onClick,    active}) => {
    return (
        <button onClick={e => {onClick(); e.preventDefault();}}
            className={classNames("custom-dot", {"custom-dot--active": active })}
        >
        {React.Children.toArray[index]}
        </button>
    );
}
export {responsive, CustomLeftArrow, CustomRightArrow, CustomDot};

