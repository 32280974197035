import {Container, StyledBurger} from './styled.js'
import List from '../ListMenu'
import { useState } from 'react'
import MenuContextProvider from './MenuContext.js'

export default function App(props){
  const [open, setOpen] = useState(true)
  
  
  return(

    <MenuContextProvider>
      <Container className="containerMenu" open={open} loading={props.loading} showCustomMenu={props.showCustomMenu}>
        {!props.loading && <>
          
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div className="title-fixed"> Notícias </div>
        </StyledBurger>
        <div className="titles">
            <div className="box-infos">
              <List displayImg="none"
                    info={{
                      categoria: 'TODOS',
                      qtd: props.noticeMenu.reduce((r, i) => r + i.qtd, 0),
                      SubCategoria: []
                }} />
              {props.noticeMenu.map((item, index) => 
                <List info={item} key={index} />
              )}
            </div>
          </div>

          <div className="voltar" onClick={props.customMenu}> {'<<'} Voltar </div>

        </>}
      </Container>
    </MenuContextProvider>
  )
}