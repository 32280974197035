import { Link } from "react-router-dom";
import { Component } from "./styled";

export default function Index(props) {
  const info = props.info;
  return (
    <Component>
      <div className="com">
        <div className="tit">{info.titulo}</div>
        {info.item.map((i, index) =>
          i.action != undefined
            ?
              <a href={i.action}>
                {i.titulo}
              </a>
            :
              <Link key={index} to={i.link ?? ''}>
                  <div className="item" key={index}> {i.titulo ?? i} </div>
              </Link>
        )}
      </div>
    </Component>
  );
}