import ButtonStyled from "../../../style/button";
import { Container, Imga } from "./styled";
import { Link } from "react-router-dom";
import { API_URL } from "../../../../service/config";
import { useState } from "react";
import React from "react";


export default function Index(props) {
	const [load, setLoad] = useState(false);

    const house = props.info;
	const img = `${API_URL}/website/busca?imagem=${house.imagens.carrousel.replace(/\\/g, "/")}`;

	
	return (
		<Container>
			<Imga image={img} className="center" alt="asd" onLoad={() => setLoad(!load)}>
				<div className="information">
					<div className="info">
						<div className="texto">
							<h1>{house.nome}</h1>
							<div className="street">{house.contato.endereco}</div>
						</div>
						<div className="icons">
							<a href={`https://www.google.com/maps?q=${house.contato.latitude},${house.contato.longitude}&zoom=16`} target="_blank" rel="noreferrer">
								<img src="/assets/images/icon-localization.svg" alt="icon" />
							</a>
							<a href={`tel: ${house.contato.telefone1}`} className="tel">
								<div>{house.contato.telefone1}</div>
								<img src="/assets/images/icon-phone.svg" alt="icon" />
							</a>
							<a href={`mailto: ${house.contato.email}`}>
								<img src="/assets/images/icon-email.svg" alt="icon" />
							</a>
						</div>
					</div>
					{house.origem == 'externo'
						? <a href={house.externo.link} target="__blank">
							<ButtonStyled style={{ width: "10em", alignSelf: "center", margin: "2em 0em 4em 0em" }}>
								Conheça
								<img src="/assets/images/icon-arrow-circle.svg" alt="icon" />
							</ButtonStyled>
						  </a>
						: <Link to={`/ce/${house.nome.toLowerCase().replace(/ /g, "")}`}>
							<ButtonStyled style={{ width: "10em", alignSelf: "center", margin: "2em 0em 4em 0em" }}>
								Conheça
								<img src="/assets/images/icon-arrow-circle.svg" alt="icon" />
							</ButtonStyled>
						  </Link>
					}
				</div>
			</Imga>
		</Container>
	);
}
