import { Text } from "../styled";

import { AdminContentBox } from '../../../../components/style/boxes';
import { InputAdm,DropdownAdm } from '../../../../components/style/inputs';

import { RoundedDeleteIcon, RoundedEditIcon } from "../../../../components/style/assets";

import TableAdmin from '../../../../components/admin/tableAdmin'
import { Td, Tr } from "../../../../components/admin/tableAdmin/styled";

import Title from "../../../../components/admin/title";
import ConfirmButton from "../../../../components/admin/confirmButton";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";


import { toast, ToastContainer } from 'react-toastify'

import { Label } from '../styled';

import { UseActivityContext } from "../activityContext";
import { useEffect, useState } from "react";

import Api from '../../../../service/admin/activity'
const api = new Api()

export default function Index() {

    const {
        activityState:{
            _id,
            calendario:{
                _idCalendario,
                dia,
                descricao: Descricao, 
            },
            calendarioArray
        },
        setIdCalendario,
        setDescCalendario,
        setDiaCalendario,
        setArrayCalendario,
        setEdtCalendario
    } = UseActivityContext()

    function edtDia(item) {
        setItems(item.atividades)
        setIdCalendario(item._idCalendario)
        setDiaCalendario(item.dia)
    }

    const [arrayItems, setItems] = useState([])

    async function listCalendar() {
        
        if(_id ==='')
            return
        
        let r = await api.listCalendar(_id)
        if(r.calendario){
            setArrayCalendario(r.calendario)
        }
        
    }

    async function postDay() {

        // if(_idCalendario !== ''){
        //     setEdtCalendario()
        //     setItems([])
        //     listCalendar()
        //     return
        // }
        
        let r = await api.postDay(_id, arrayItems, dia)

        if(r.erro){
            return toast.error(r.erro)
        } else {
            toast.success('Dia Adicionado com sucesso')
        }

        setEdtCalendario()
        setItems([])
        listCalendar()
    }
    function addArray() {
      
        if (_idCalendario === ''){
            setItems([...arrayItems, {Descricao}])
            setDescCalendario('')    
            return 
        }

        // api.updateItem(dia, Descricao) 
        setDescCalendario('')    
        setItems([...arrayItems, {Descricao}])
            
    }

    async function deleteItem(item) {

        if (_idCalendario === '')
            return setItems(arrayItems.filter(i => i !== item))

        let r = await api.deleteItem(_id,_idCalendario ,item._idItem)

        if(r.erro){
            toast.error(r.erro)
        } else {
            toast.success('Descrição excluído com sucesso')
            setItems(arrayItems.filter(i => i !== item))
        }  

        listCalendar()
    }

    const ItemdeleteConfirm = async (item) => {
        confirmAlert({
            title: "Remover esta Descrição",
            message: `Tem certeza que deseja remover esta Descrição ? `,
            buttons: [
            {
                label: "Sim",
                onClick: async () => {
                deleteItem(item);
                },
            },
            { label: "Não" },
            ],
        });
    };

    async function deleteDAY(item) {

        let r = await api.deleteDay(_id,item._idCalendario)
        
        if(r.erro){
            toast.error(r.erro)
        } else {
            toast.success('Dia excluído com sucesso')
        }

        listCalendar()

    }

    const daydeleteConfirm = async (DAY) => {
        confirmAlert({
            title: "Remover este Dia",
            message: `Tem certeza que deseja remover este Dia ? `,
            buttons: [
            {
                label: "Sim",
                onClick: async () => {
                deleteDAY(DAY);
                },
            },
            { label: "Não" },
            ],
        });
    };  

    useEffect(
        () => {listCalendar()}, 
        // eslint-disable-next-line 
        [_id]
    )

    return(
        <div className="Calendario">
            <ToastContainer/>
            <AdminContentBox>
                <ConfirmButton />
                <Title>Calendário Semanal</Title>
                <div className="all">
                    <div className="manager">
                        <div>
                            <div className="itemManager" style={{width: '88%'}}>
                                <Label>Dia da Semana:</Label>
                                <DropdownAdm disabled={_idCalendario === '' ? false : true} value={dia} onChange={ (e) => setDiaCalendario(e.target.value)} >
                                    <option key="Selecione"     value="Selecione">Selecione</option>
                                    <option key="Segunda-Feira" value="Segunda-Feira">Segunda-Feira</option>
                                    <option key="Terça-Feira"   value="Terça-Feira">Terça-Feira</option>
                                    <option key="Quarta-Feira"  value="Quarta-Feira">Quarta-Feira</option>
                                    <option key="Quinta-Feira"  value="Quinta-Feira">Quinta-Feira</option>
                                    <option key="Sexta-Feira"   value="Sexta-Feira">Sexta-Feira</option>
                                    <option key="Sabado"        value="Sabado">Sabado</option>
                                    <option key="Domingo"       value="Domingo">Domingo</option>
                                </DropdownAdm>
                            </div>
                            <div className="itemManager">
                                <Label > Descrição:</Label>
                                <InputAdm value={Descricao} style={{width: '93%'}} onChange={e => setDescCalendario(e.target.value)} />
                                <div className="circle" onClick={addArray}><img src="/assets/images/+.svg" alt=""/></div>
                            </div>
                            <div className="item">
                                {arrayItems.map( i =>
                                    <div className="Previ">
                                        {i.Descricao.length > 43 ? i.Descricao.substr(0, 43) + '..' : i.Descricao}
                                        <img className="clear" onClick={() => ItemdeleteConfirm(i)} src="/assets/images/X.svg" alt=""/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <button className="salveBtm" onClick={postDay}>Salvar</button>
                    </div>
                    <div className="list">
                        <TableAdmin
                            length="3em"
                            size="35em"
                            columns={[
                                { title: 'Dia da Semana' },
                                { title: 'Descrição' },
                                { title: '' },
                                { title: '' }
                            ]}>

                            {calendarioArray.map(item =>
                                <Tr key={item.id}>
                                    <Td> <Text> {item.dia} </Text> </Td>
                                    <Td> <Text> {item.atividades[0] === undefined ? 'Nada Cadastrado' : item.atividades[0].Descricao} </Text> </Td>

                                    <Td config={{ visibility: 'hidden', width: '1em' }}
                                        onClick={() => {edtDia(item)}}>
                                        <RoundedEditIcon />
                                    </Td>

                                    <Td config={{ visibility: 'hidden', width: '1em' }}
                                        onClick={() => {daydeleteConfirm(item)}}>
                                        <RoundedDeleteIcon />
                                    </Td>

                                </Tr>
                            )}
                        </TableAdmin>
                    </div>
                </div>
            </AdminContentBox>
        </div>
    )
}