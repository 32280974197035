import styled from "styled-components";


export const Label = styled.div`
    font: 1em Roboto;
    width: 150px;
    text-align: right;
    margin-right: 10px;
`

export const AdminContainer = styled.div`
    display: flex;
    flex-direction: column;

    .put{
    display: flex;
    align-items: left;
    justify-content: left;
    width:100%;
    
    font: 1em Roboto;
    text-align: right;
    border:black;
 }

 .put input{
    height:auto;

    width: 64%;
    height: 2.5em;
    font: 400 1em Roboto;
    color:  #615858 ;
    border-radius: 5px;
    border: 1px solid rgba(168,168,168,1);
    outline: none;
    padding: 0em 0.5em;


}

    .img{
        width: 13em;
        height: 10em;
        margin: 0em 2em 0em 2em;
    }

    .rem {
        text-align: center;
        margin-bottom: 2em;
        cursor: pointer;
        color: #848484;
        font: 500 14px Roboto;
        text-decoration: underline;
    }

    .form-column {
        display: flex;
        flex-direction: column;
        margin-left: 0em;
    }

    .form-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 0.3em 0em;
    }

    .center {
        justify-content: center;
    }

    .top {
        align-items: flex-start;
    }

    .s1 {
        width: 30%;
    }

    .s2 {
        width: 40%;
    }

    .s3 {
        width: 50%;
    }

`


export const BoxImagens = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & > div {
        display: flex;
        justify-content: flex-start;
    }

    & svg {
        width: 13em;
        height: 10em;
        margin: 0em 2em 2em 2em;
    }

    & * {
        cursor: pointer;
    }

    input[type=file] {
        display: none;
    }

    @media (max-width: 1366px) {
        & svg {
            width: 8em;
            height: 8em;
            margin: 0em 1em;
        }
    }
    
    @media (max-width: 1280px) {
        & svg {
            width: 7em;
            height: 7em;
            margin: 0em 1em;
        }
    }
`

