import React from "react";
import classNames from "classnames";


//Carousel Responsiveness Setting (Required)
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};



// arrow customization
const  CustomLeftArrow  =  ( { onClick   } )  =>  
    < i  className="custom-left-arrow" onClick = { ( )  =>  onClick ( ) } > <img src="/assets/images/icon-left-arrow-circle.svg" alt=""/> </i>; 

const  CustomRightArrow  =  ( { onClick  } )  =>  
    < i  className="custom-right-arrow" onClick = { ( )  =>  onClick ( ) } > <img src="/assets/images/icon-rigth-arrow-circle.svg" alt=""/> </i>; 




// customization of navigation points
const CustomDot = ({ index, onClick,    active}) => {
    return (
        <button onClick={e => {onClick(); e.preventDefault();}}
            className={classNames("custom-dot", {"custom-dot--active": active })}
        >
        {React.Children.toArray[index]}
        </button>
    );
}
export {responsive, CustomLeftArrow, CustomRightArrow, CustomDot};

