import { Container } from "./styled";
import ButtonStyled from "../../../style/button";
import { API_URL } from "../../../../service/config";

export default function Index(props) {
  const info = props.info;
  let img = info.imagem;

  function JoinsDate(fulldate) {
    if (fulldate === undefined) return ''
    let date = new Date(fulldate);
    return (
      date.getUTCDate() +
      " de " +
      date.toLocaleString("default", { month: "long" }).substring(0, 3) +
      " " +
      date.getUTCFullYear()
    );
  }

  function formatImg() {
    if(props.imgLocal === true)
      return img
    else 
      return `${API_URL}/website/busca?imagem=${img.replace(/\\/g, "/")}`;
  }
  
  
  return (
    <Container bgSize={props.bgSize} img={formatImg()}>
      <div className="imagem" />
      <div className="subs">
        {info.data && <div className="date">{JoinsDate(info.data)}</div>}
        <div className="titulo">{info.titulo}</div>
        <div className="descricao" dangerouslySetInnerHTML={{ __html: info.descricao }}></div>

        {info.link && info.link.url && (
            <a
              href={info.link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonStyled
                Bgcolor={props.buttonColor}
                style={{ marginTop: "1em" }}
              >
                {props.buttonText === undefined
                  ? "Saiba Mais"
                  : props.buttonText}
              </ButtonStyled>
            </a>
          )}
        
      </div>
    </Container>
  );
}
