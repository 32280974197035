import Container from "./styled";

import { AdminContentBox } from "../../../../components/style/boxes";
import ConfirmButton from "../../../../components/admin/confirmButton";
import Title from "../../../../components/admin/title";
import { Label } from "../../lectures/styled";
import {
  InputAdm,
  CheckboxAdm,
  DropdownAdm,
} from "../../../../components/style/inputs";
import { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import { UseManagerUserContext } from "../context";
import ApiAdmin from "../../../../service/admin/login";
import ApiInstitution from "../../../../service/admin/institution";
const apiInstitution = new ApiInstitution();
const apiAdmin = new ApiAdmin();

export default function Manager() {
  const {
    managerUserState: {
      idAlter,
      User,
      email,
      reset,
      house,
      position,
      active,
      admin,
      activitys,
      administrative,
    },
    setList,
    setUser,
    setEmail,
    setReset,
    setHouse,
    setPosition,
    setActive,
    setAdmin,
    setAdministrative,
    setActivitys,
    clearValues,
  } = UseManagerUserContext();

  const admins = [
    "Eventos",
    "Notícias",
    "Palestras Doutrinátorias",
    "Arrec. & Doações",
    "Evangelho do Dia",
  ];

  const carga = [
    "Administrador",
    "Coordenador de Atividades",
    "Gerenciador de Notícias",
    "Gerenciador de Galeria",
  ];

  const activities = [
    "Camerata",
    "Passe",
    "Teatro",
    "Grupo de Estudo",
    "Evangelização",
  ];

  const [houses, setHouses] = useState([]);

  function handleChange(e, functionValue) {
    let value = [];

    for (var i = 0; i < e.target.selectedOptions.length; i++) {
      value.push(e.target.selectedOptions[i].value);
    }
    functionValue(value);
  }

  async function newAdmin() {
    if (idAlter === "") {
      let r = await apiAdmin.postAdmin(
        User,
        email,
        active,
        admin,
        house,
        position,
        activitys,
        administrative
      );

      if (r.erro) toast.error(r.erro);
      else {
        toast.success("Novo Administrador cadrastado 😃");
        clearValues();
        getInitial();
      }
    } else {
      let r = await apiAdmin.putAdmin(
        idAlter,
        User,
        email,
        active,
        admin,
        house,
        position,
        activitys,
        administrative
      );

      if (r.erro) toast.error(r.erro);
      else {
        toast.success("Administrador alterado com sucesso 😃");
        clearValues();
        getInitial();
      }
    }
  }

  async function resetPassword() {
    let r = await apiAdmin.alterReset(idAlter, reset)
    if(r.erro)
      toast.error(r.error)
    else {
      toast.success("Foi resetada a senha, peça para que o administrador tente entrar novamente ultilizando o reset")
      clearValues();
      getInitial();
    }
  }

  async function getInitial() {
    let house = await apiInstitution.getHouse();
    setHouses(house);

    let admins = await apiAdmin.listAdmin();
    setList(admins);
  }

  useEffect(
    () => {
      getInitial();
    }, 
    // eslint-disable-next-line 
    []
  );
  return (
    <Container>
      <ToastContainer />
      <AdminContentBox>
        <ConfirmButton onClick={() => newAdmin()} />
        <Title>
          Usuário <span> - Gerenciar Acesso</span>
        </Title>
        <div className="ContainerInput">
          <div className="Line One">
            <div className="column">
              <div className="inputConfig">
                <Label> Usuário: </Label>
                <InputAdm
                  value={User}
                  onChange={(e) => setUser(e.target.value)}
                />
              </div>
              <div className="inputConfig">
                <Label> E-mail: </Label>
                <InputAdm
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="column">
              <div className="inputConfig">
                <Label> Casa: </Label>
                <DropdownAdm
                  value={house}
                  onChange={(e) => setHouse(e.target.value)}
                >
                  <option key="Selecione" value="Selecione">
                    Selecione uma Casa
                  </option>
                  {houses.map((x) => (
                    <option key={x.nome} value={x._id}>
                      {x.nome}
                    </option>
                  ))}
                </DropdownAdm>
              </div>
              <div className="inputConfig">
                <Label> Cargo: </Label>
                <DropdownAdm
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <option value="Selecionar"> Selecione um Cargo </option>
                  {carga.map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </DropdownAdm>
              </div>
            </div>
          </div>
          <div className="Line Two">
            <div className="column reset">
              <div className="inputConfig">
                <Label> Reset de Senha: </Label>
                <InputAdm
                  value={reset}
                  onChange={(e) => setReset(e.target.value)}
                />
                {idAlter !== "" ? (
                  <ConfirmButton
                    style={{ position: "relative", left: "24px", top: "0px" }}
                    onClick={() => resetPassword()}
                  />
                ) : (
                  <ConfirmButton
                    theme="BLACK"
                    style={{ position: "relative", left: "24px", top: "0px" }}
                  />
                )}
              </div>
              <div className="inputConfig">
                <Label> Ativo: </Label>
                <CheckboxAdm
                  checked={active}
                  value={active}
                  onClick={() => setActive(!active)}
                />
              </div>
            </div>
            <div className="column select">
              <div className="inputConfig multiple">
                <Label> Atividade: </Label>
                <DropdownAdm
                  value={activitys}
                  onChange={(e) => handleChange(e, setActivitys)}
                  style={{ height: "8em" }}
                  multiple
                >
                  {activities.map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </DropdownAdm>
              </div>
            </div>
          </div>
          <div className="Line three">
            <div className="column">
              <div className="inputConfig">
                <Label> Administrador: </Label>
                <CheckboxAdm
                  checked={admin}
                  value={admin}
                  onClick={() => setAdmin(!admin)}
                />
              </div>
            </div>
            <div className="column select">
              <div className="inputConfig multiple">
                <Label> Adminisrativo: </Label>
                <DropdownAdm
                  value={administrative}
                  onChange={(e) => handleChange(e, setAdministrative)}
                  style={{ height: "8em" }}
                  multiple
                >
                  {admins.map((x) => (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  ))}
                </DropdownAdm>
              </div>
            </div>
          </div>
        </div>
      </AdminContentBox>
    </Container>
  );
}
