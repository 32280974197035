import styled from 'styled-components'


const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;
  text-align: center;

  background: #F4F7FA;
  border-radius: 90px;

  height: 18em;
  width: 12em;

  padding: 1em 1em 5em 1em;
  margin: 1em;


  h2{
    font-size: 2.4em;

    font-family: 'Manrope';
    font-style: normal;
    font-weight: bold;

  }

  span{
    Font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;

    font-size: 1.1em;

    color: ${(props) => props.presentDay === undefined ? '#706666' : '#E2E8FA'};
    
    div {
      padding: 0px 0px 15px 0px;
    }
  }


  color: ${(props) => props.presentDay === undefined ? '#333131': '#FFFFFF'};
  background: ${(props) => props.presentDay === undefined ? '#F4F7FA': '#0180F5'};
  box-shadow: ${(props) => props.presentDay === undefined ? '' : '0px 7px 21px #0180F5'};

 

  @media (max-width: 1440px) {
    transform: scale(0.85);
    margin: 0em;
  }

  

`


export default Container