import styled from 'styled-components'

const HeaderAdm = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: .5em 0em 0em 2em;
    background-color: #FFFFFF;

    box-shadow: 0px 0px 5px 0px #D9D9D9;
    height: 5.5em;
    width: 100%;

    justify-content: space-between;
`

const ProfileAdm = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: relative;
    width: 100%;

    .perfil-icon img {
        margin-right: 1em;
    }

    .perfil {
        display: flex;
        align-items: center;
        font-size: 1em;
        font-weight: 400;
    }

    .perfil-icon {
        position: relative;
    }

    .badge {
        font-family: Roboto-medium;
        font-size: 12px;
        width: 20px;
        height: 20px;
        border-radius: 50px;
        border: 2px solid #FFFFFF;

        background-color: #CFB115;
        color: #FFFFFF;

        text-align: center;

        position: absolute;
        right: 1em;
        top: -.2em;

        padding: .1em;
    }


    .user-name {
        font: 500 1em Roboto;
        color: #615858;
    }

    .user-name span {
        font: 700 .9em Roboto;
        color: #615858;
    }

`


const ButtonsAdm = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 2em;

    & button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    div * { cursor: pointer; }

    

    .refresh button {
        border: none;
        outline: none;
        background-color: #CFB115;
        color: white;
        border-radius: 100%;
        margin: 0em .4em;
        width: 40px;
        height: 40px;
    }

    .logout button {
        border: none;
        outline: none;
        background-color: #2B3031;
        color: white;
        border-radius: 100%;
        width: 40px;
        height: 40px;
    }
`

export { HeaderAdm, ProfileAdm, ButtonsAdm };