import styled from 'styled-components';

const Container = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: auto;

  padding: 2em 1em 10em 1em ;

  background: #F7F9FB;

  text-align: center;

  h1{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -1.584px;

    color: #1C1F26;
    margin-bottom:2em;

  }

  .imgParticipant{
    display: flex;
    flex-direction: row;
    align-self: center;
    flex-wrap: wrap;
    max-width: 45em;

   
  }
  .imgParticipant > .people:nth-child(-8n+97){
    margin-left: 5em;

   
    
  }

  @media (max-width: 1024px){
    padding: 5em 0em 7em 0em;
    
    .imgParticipant{
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: center;
    } 
    .imgParticipant > .people:nth-child(-8n+97){
      margin-left: 0em;
    }

    h1 {
      font-size: 40px;
    }
      
  }
  
`

const People = styled.div`
  height: 8em;
  width: 8em;
  border-radius: 101.5px;

  margin-right: 2em;
  margin-bottom: 2em;

  background: url(${props => props.photo});
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center center;



  
  
  position: relative;
  @media (max-width: 800px){
    margin: 1em 10px;
  }
`
const Format = styled.div`
    
  height: 3em;
  width: 3em;
  
  display: ${props => props.display};
  background: ${props => props.bg };
  border-radius: ${props => props.format};

  position: absolute;
  top: ${props => props.top} ;
  left: ${props => props.left};
  
  transform: rotate(${props => props.left === '70%' && props.top === '70%' 
                      ? '268deg' : 
                        props.top === "-8%"  && props.left === "-8%" 
                        ? '88deg': 
                          props.left === "70%" && props.top === '-8%' 
                          ? '128deg' :'60deg'
                    })
                

`

export { Container, People, Format}

