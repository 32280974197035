import { Infos } from "./styled";
import { API_URL } from "../../../../service/config";


export default function Index({ info: { id, colunas, layout } }) {

  function renderFirst() {
    if (colunas[0].tipo === 'computed')
      return (
        <div className="computed-columns">
        {colunas.filter(col => col.tipo === 'computed').map(col =>
          <ComputedColumn {...col} /> 
        )}
      </div>
      )
    
    else 
      return (
        colunas.filter(col => col.tipo !== 'computed').map(col =>
          col.tipo === 'image'
            ? <ImageColumn {...col} />
            : <TextColumn {...col} />
        )
      )
  }

  function renderSecond() {
    if (colunas[0].tipo !== 'computed')
      return (
        <div className="computed-columns">
        {colunas.filter(col => col.tipo === 'computed').map(col =>
          <ComputedColumn {...col} /> 
        )}
      </div>
      )
    
    else 
      return (
        colunas.filter(col => col.tipo !== 'computed').map(col =>
          col.tipo === 'image'
            ? <ImageColumn {...col} />
            : <TextColumn {...col} />
        )
      )
  }
  
  return (
    <Infos id={id} layout={layout}>
      {renderFirst()}
      {renderSecond()}
    </Infos>
  );
}



function ImageColumn({ imagem }) {
  return (
    <div className="image">
      <img src={`${API_URL}/website/busca?imagem=${imagem.replace(/\\/g, '/')}`} alt="" />
    </div>
  )
}

function TextColumn({ titulo, descricao }) {
  return (
    <div className="text-box">
        <div className="title">
            {titulo}
        </div>
        <div className="description">
            {descricao}
        </div>
    </div>
  )
}


function ComputedColumn({ titulo, webservice, className }) {
  return (
    <div className={`box-value ${className}`}>
        <div className="value">
          {titulo}        
        </div>
        <div className="value-title">
          {webservice}
        </div>
    </div>
  )
}


