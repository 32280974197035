import styled from 'styled-components'


const Container = styled.div`

.s2{
    width: 100%;
    input{
        width: 10%;
    }
}
.iconDelete{
    position: absolute;
    top: 6px;
    right: 79px;
    width: 2em;
    cursor: pointer;
}
.box-row {
    position: relative;
}
.staticBtm {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
      right: 37px;
      top: 0;
      .custom-right-arrow {
        position: absolute !important;
        right: -48px;
        z-index: 2;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 12px 15px;
        background-color: #dadada;
        cursor: pointer;
        top: 100px;
        border: solid 0px;
        img {
          cursor: pointer;
        }
        &:hover {
          background-color: #160e4a;
        }
      }
    }
    .box-more {
      width: 11.5em;
      height: 15em;

      background: #ffffff;
      border: 1px solid #b9b9b9;
      box-sizing: border-box;
      border-radius: 5px;

      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      cursor: pointer;

      img {
        cursor: pointer;
        width: 4em;
        height: 4em;

        margin-bottom: 1em;
      }
      font-family: Montserrat;
      font-style: normal;
      font-weight: thin;
      font-size: 1em;
      line-height: 108%;

      text-align: center;

      color: #666666;
    }
.Previ{
    display: flex;
    flex-direciton: row;
    background: #9673D4;
    border-radius: 14px;
    justify-content: space-between;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 14px;
    line-height: 116.5%;
    cursor: pointer;
    align-items: center;
    padding: 2px 8px;

    color: #FFFFFF;
    white-space: nowrap;

    width: 74%;
    width: 74%;
    margin: 5px 0px;
}
.item{
    display: flex;
    flex-direction: column;
    align-items: end;
}
.total{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.input-previa {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 14px;
    line-height: 116.5%;
    cursor: pointer;
    width: 90%;
    color: #FFFFFF;
}

.form-row-one{
    display: flex;
    flex-direction: row;
    margin: 1em 0;
    align-items: center;
}
.inpuit{
    width: 50%;
    margin-bottom: 1em;
}
.desc{ 
    width: auto;
    text-align: left;
    font-weight: 500;
    margin-top: 5px;
}

.form-row{
    display: flex;
    flex-direction: row;
    width:  94%;
    align-items: center;
   

    margin: 3em auto;
}

.form-row-two{
    display: flex;
        flex-direction: row;
        align-items: center;

        margin: 0.3em 0em;
}


.form-tel{
        display: flex;
        margin: .5em 0em 0em 0em;
        align-items: center;
        justify-content: left;

        input{
            width: 40%;
        }
        
    }
.form-tel {
    margin-top: 1.5em;
}
.titulodivs{
    margin: 1.5em 0em;
}

.map {
        border: 1px solid #A8A8A8;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        height: 100%;
    }
    .form-column2{
        display: flex;
        flex-direction: column;
        margin-top: 1em;
        height: 100%;
    }
    .form-column5{
        width: 46%;
    }

    .form-column6{
        display: flex;
        flex-direction: column;
        width: 45%;
        top: 1px;
    }

.form-row-two{
    display: flex;
        flex-direction: row;
        align-items: center;

        margin: 0.3em 0em;
}

.text {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -5em;

}
.textAreaSecao{
    height: 100%;
    /* margin-top: 1em; */
}

.chooseImage{
    width: auto;

    svg {
      width: 250px;
      margin-left: 50px;
    }
}

.imgPrevi{
    max-width: 200px;
    margin-left: 5em;
}

.inp{
    width:2.4em;
}

 .inpN{
    width:100%;
 }

 .form-column5{
        width: 46%;
    }

    .all{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        .manager{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            .salveBtm{
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              text-align: center;
              color: #ffffff;
              background: #151515;
              border-radius: 44px;
              padding: 0.6em 1.4em;
              border: none;
              cursor: pointer;
              
                margin-top: 5em;
            }
        }
        .clear{
            cursor: pointer;
            width: 1.5em;
            margin: 5px;
        }
        .tipo{
            border: solid 0px;
            background: #9673D4;
            border-radius: 14px;
            justify-content: space-around;
            text-align: center;
            font-family: 'Roboto';
            font-weight: 300;
            font-size: 14px;
            line-height: 116.5%;
            cursor: pointer;
            width: 50%;

            color: #FFFFFF;
            outline: none;
        }
        .itemManager{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: .8em;
            
            .circle{
                display: flex;
                align-items:center;
                justify-content: center;
                img{
                    width: 1em
                }
                width: 4em;
                height: 2.5em;
                background: #453E51;
                border-radius: 50%;
                margin-left: 1em;
                cursor: pointer;
            }
            .input-file{
                width: 93px;
                height: 25px;
                background: #9673D4;
                border-radius: 14px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-family: 'Roboto';
                font-weight: 300;
                font-size: 10px;
                line-height: 116.5%;
                cursor: pointer;

                color: #FFFFFF;
            }
            input[type="file"] {
                display: none;
            }
        } 
    }





input[type="file"] {
                display: none;
            }

.form-row-I{
    display: flex;
    flex-direction: row;


    margin: 0.4em 0em 0 3.2em;
}

.caroucel{ 
        width: 68.3vw;
        align-self: center;
}



.right{
    display: flex;
    flex-direction: column;
    margin-right:5em;
    margin-top: 3em;
    textarea{
        height: 270px;
    }
}

.form-row-top{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-right:3em;
    margin-bottom: 1em;
}

.select {
    border-radius: 5px;
    border: 1px solid rgba(168, 168, 168, 1);
    outline: none;
    resize: none;
    padding: 0.7em 1em;
    width: 100%;

    font: 400 1em Roboto;
    color: #615858;
}
            

`



export const Label = styled.div`
    font: 1em Roboto;
    width: 180px;
    text-align: right;
    margin-right: 10px;
`




export const BoxImagens = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
 
    

    & > div {
        display: flex;
        justify-content: flex-start;
    
    }

    & svg {
        width: 9em;
        height: 9em;
        margin: 0em 1em;
        cursor:pointer;
    }

    & path{
        cursor:pointer;
    }
    `





export const TextAreaAdm = styled.textarea`
    width: 100%;
    height: 160px;

    font: 400 1em Roboto;
    color: #615858;

    border-radius: 5px;
    border: 1px solid rgba(168, 168, 168, 1);
    outline: none;
    resize: none;

    padding: .5em .5em;
    margin: 0em 0em;
    /* padding-right: 7em; */
`

export const Text = styled.div`
    font: 1em Roboto;
    text-align: left;
    margin-right: 10px;
`



export { Container }