import InstitutionContextProvider from './institutionContext.js'

import { AdminContainer  } from "./styled";

import InfoAll from './components/boxs/infoAll'
import GerenciarImagen from './components/boxs/gerenciaImagens'
import Contact from './components/boxs/contact'
import Networks from './components/boxs/networks'
import Orientacao from './components/boxs/orientacao'
import Activities from './components/boxs/activities'
import Gallery from './components/gallery/index'

import {UseLoginContext} from '../login/loginContext'
import Cookies from 'js-cookie';

export default function Institution() {

    
    const {
        usuState: {auth},
        setAuth
    } = UseLoginContext();

    let c = JSON.parse(Cookies.get('userCookie'))
  
    if(auth === "") 
        setAuth(c.auth)
    

    return (
        <AdminContainer>        
            <InstitutionContextProvider>
                <InfoAll/>
                <GerenciarImagen/>
                <Contact/>
                <Networks/>
                <Orientacao/> 
                <Gallery/>   
                <Activities/>  
            </InstitutionContextProvider>
        </AdminContainer>
    )
}