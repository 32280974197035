import styled from "styled-components";

const Component = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Manrope;
  

  .com {
    display: flex;
    flex-direction: column;
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 1.1em;
    line-height: 35px;
  }
  .tit {
    margin-bottom: 2em;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;  
  }

  a, a * {
    cursor: pointer !important; 
  }

  @media(max-width: 1165px) {
    margin: 0em 2em 3em 0em;

    .tit {
      margin-bottom: 0em;
      width: 11em;
    }

   :nth-child(4){
     margin: 0em 0em 1em;
    }
  }
  
`;

export { Component };