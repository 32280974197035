import { AdminContentBox } from '../../../../components/style/boxes';
import { InputAdm } from '../../../../components/style/inputs';

import Title from "../../../../components/admin/title";
import ConfirmButton from "../../../../components/admin/confirmButton";

import { Label } from '../styled';

import { UseActivityContext } from '../activityContext';

import { useEffect } from "react";

import { toast } from "react-toastify";

import Api from '../../../../service/admin/activity';
const api = new Api();

export default function Index() {

    const {
        activityState: {
            _id,
            contato: {
                titulo,
                descricao,
                endereco,
                email,
                telefone1,
                telefone2,
                latitude,
                longitude,
                link: {
                    titulo: tituloLINK,
                    url
                }
            }
        },
        setEndereco,
        setEmail,
        setTelefone1,
        setTelefone2,
        setLatitude,
        setLongitude,
        setTituloContato,
        setDescContato,
        setURLContato,
        setTituloURL,
    } = UseActivityContext();

    async function putContact() {
        let r = await api.putContact(_id, titulo, descricao, endereco, telefone1, telefone2, latitude, longitude, email, tituloLINK, url)
        if (r.erro) {
            toast.error(r.erro)
        } else {
            toast.success("Foi Alterado com sucesso")
        }
    }

    async function getContact() {

        if (_id === "")
            return

        let r = await api.getContato(_id)

        if (r.contato) {
            setEndereco(r.contato.endereco);
            setEmail(r.contato.email);
            setTelefone1(r.contato.telefone1);
            setTelefone2(r.contato.telefone2);
            setLatitude(r.contato.latitude);
            setLongitude(r.contato.longitude);
            setTituloContato(r.contato.titulo);
            setDescContato(r.contato.descricao);
            setURLContato(r.contato.link.url);
            setTituloURL(r.contato.link.titulo);
        }
    }

    useEffect(
        () => { getContact() },
        // eslint-disable-next-line 
        [_id]
    )
    return (
        <div className="contact">
            <AdminContentBox>
                <ConfirmButton onClick={() => putContact()} />
                <Title>Contato</Title>
                <div style={{ width: '100%' }} className="form-row top center">
                    <div className="form-column5">
                        <div className="form-row-two" >
                            <Label > Endereço: </Label>
                            <InputAdm value={endereco} onChange={e => setEndereco(e.target.value)} />
                        </div>

                        <div className="form-column2" >
                            <div className="form-row-two">
                                <Label > Latitude: </Label>
                                <InputAdm value={latitude} onChange={e => setLatitude(e.target.value)} />
                            </div>
                            <div className="form-row-two">
                                <Label > Longitude: </Label>
                                <InputAdm value={longitude} onChange={e => setLongitude(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="form-column6">
                        <div className="form-row-two">
                            <Label > E-mail: </Label>
                            <InputAdm value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="form-tel 1">
                            <Label> Telefone 1: </Label>
                            <div style={{ width: '100%' }}>
                                <InputAdm value={telefone1} onChange={e => setTelefone1(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-tel">
                            <Label> Telefone 2: </Label>
                            <div style={{ width: '100%' }}>
                                <InputAdm value={telefone2} onChange={e => setTelefone2(e.target.value)} />
                            </div>
                        </div>

                        <div className='titulodivs'>
                            <div className="form-row-two">
                                <Label > Titulo: </Label>
                                <InputAdm value={titulo} onChange={e => setTituloContato(e.target.value)} />
                            </div>
                            <div className="form-row-two">
                                <Label > Descrição: </Label>
                                <InputAdm value={descricao} onChange={e => setDescContato(e.target.value)} />
                            </div>
                        </div>

                        <div className="form-row-two">
                            <Label > Url do Link: </Label>
                            <InputAdm value={url} onChange={e => setURLContato(e.target.value)} />
                        </div>
                        <div className="form-row-two">
                            <Label > Titulo do Link: </Label>
                            <InputAdm value={tituloLINK} onChange={e => setTituloURL(e.target.value)} />
                        </div>
                    </div>
                </div>
            </AdminContentBox>
        </div>
    )
}