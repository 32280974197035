
import styled from 'styled-components'

const Container = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;

    display: ${props => props.show === true ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0);
    height: 100vh;
    width: 100vw;
    z-index: 100;

  .content {
    background-color: #fff;
    padding: 2px;
    height: 50%;
    width: 60%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .close{
      width: 3em;
      height: 3em;
      position: absolute;
      cursor: pointer;
      top: -18px;
      right: -18px;
      background: white;
      display: flex;
      border-radius: 50%;
      img{
        width: 4em;
        cursor: pointer;
      }
      
     
      }
  }

  animation: ${props => props.show === true ? 'show_animation' : 'none'} .3s forwards;
  animation-delay: .1s;
  
  @keyframes show_animation {
    100% {
      background-color: rgba(0,0,0,0.6);
    }
  }
  
`


export default Container