import axios from 'axios'
import { API_URL } from '../config'
const api = new axios.create({
    baseURL: `${API_URL}/admin`,
    headers: {
        'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYWRtaW4iLCJuYW1lIjoiYnJ1bm9kZW9saXZlaXJhLjIyLjEwQGdtYWlsLmNvbSIsImlhdCI6MTY0MzgzOTk0N30.oBFi1LnfeJ5h2ZCEAhC44HD5V0uyqolgg43J-HtI8Oo",
        'Content-Type': 'application/json'
      }
})

export default class Api{
    
    async listAlbuns(id){
        let r = await api.get(`/casa/album/${id}`)
        return r.data
    }

    async postAlbum(idCasa, titulo, descricao, capa ){
        let formData = new FormData();
        formData.append('idCasa', idCasa);
        formData.append('titulo', titulo);
        formData.append('descricao', descricao);
        formData.append('capa', capa)

        let r = await api.post('/casa/album/', formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});

        return r.data
    }

    async putAlbumCapa(id,idCasa, titulo, descricao, capa){
        let formData = new FormData();
        formData.append('idCasa', idCasa);
        formData.append('titulo', titulo);
        formData.append('descricao', descricao);
        formData.append('capa', capa)

        let r = await api.put(`/casa/albumCapa/${id}`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});

        return r.data
    }

    async putAlbum(id,idCasa, titulo, descricao, capa){
        let r = await api.put(`/casa/album/${id}`, {idCasa, titulo, descricao, capa})
        return r.data
    }

    async deleteAlbum(id){
        let r = await api.delete(`/casa/album/${id}`)
        return r.data
    }




















    async listAlbunsAtividade(id){
        let r = await api.get(`/atividades/${id}/album/`)
        return r.data
    }

    async postAlbumAtividade(idCasa, idAtividade, titulo, descricao, capa){
        let formData = new FormData();
        formData.append('idCasa', idCasa);
        formData.append('titulo', titulo);
        formData.append('descricao', descricao);
        formData.append('capa', capa);

        let r = await api.post(`/casa/${idCasa}/atividades/${idAtividade}/album/`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});

        return r.data
    }

    async putAlbumCapaAtividade(id,idCasa,idAtividade, titulo, descricao, capa){
        let formData = new FormData();
        formData.append('idCasa', idCasa);
        formData.append('idAtividade', idAtividade);
        formData.append('titulo', titulo);
        formData.append('descricao', descricao);
        formData.append('capa', capa)

        let r = await api.put(`/atividades/albumCapa/${id}`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
        }});

        return r.data
    }

    async putAlbumAtividade(id,idCasa, idAtividade,titulo, descricao, capa){
        let r = await api.put(`/atividades/album/${id}`, {idCasa, idAtividade,titulo, descricao, capa})
        return r.data
    }

    async deleteAlbumAtividade(id){
        let r = await api.delete(`/atividades/album/${id}`)
        return r.data
    }

}