import Container from './styled'
import { API_URL } from '../../../service/config'

export default function Index(props){
  const info = props.info

  return(
    <Container id={props.id} bg={props.bg} margin={props.margin} row={props.row}>
      <img src={`${API_URL}/website/busca?imagem=${info.imagem === null ? '' : info.imagem.replace(/\\/g, '/')}`}  alt=""  />
      <div className="info"> 
        <h1 className="icon"  >{info.titulo}</h1>
        <div className="icon" dangerouslySetInnerHTML={{ __html: info.descricao }}></div>
      </div>
    </Container>
  )
}