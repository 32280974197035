import { createContext, useContext, useReducer } from "react";
import Cookies from "js-cookie";

const reducer  = (state,action) => {
    switch (action.type) {
      case 'setAuth' : return { auth: action.auth};
      default: return ''
    }
}


const initialState = {
  auth: JSON.parse(Cookies.get('userCookie') ?? "{}").auth ?? ''
}

const LoginContext = createContext();

export default function LoginContextProvider(props) {
    
   const [ usuState, setUsuState] = useReducer(reducer, initialState)
    return (
      <LoginContext.Provider value={{ usuState, setUsuState }}>
        {props.children}
      </LoginContext.Provider>
    )
  }

export function UseLoginContext() {
    const context = useContext(LoginContext);

    const { usuState, setUsuState } = context;
    return {
      usuState,
      setAuth: (auth) => setUsuState({ type: 'setAuth', auth})
    }
}