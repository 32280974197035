import styled from 'styled-components';

const Container = styled.div`

  background-color: ${props => props.loading ? 'unset' : '#F7F9FB'} ;
  border-radius: 21px;
  padding: 1.5em;
  width: 20em;
  margin-left: 2em;
  animation: slideInRight; 
  animation-duration: 2s;

  position: sticky;
  position: -webkit-sticky;
  top: 8em; 

  .title-fixed{
    font-family: Manrope;
    font-weight: 700;
    font-size: 1.3em;
    line-height: 33px;
    color: #1C1F26;
  }

  .voltar {
    display: none;
  }


  @media (max-width: 500px) {

    .voltar {
      display: ${props => props.showCustomMenu ? 'block' : 'none'};
      font: 600 1em 'Manrope';
      position: absolute;
      bottom: -5em;
      right: 0em;
      padding: 2em 0em;
    }

  }


`




const Box = styled.div`

`



const StyledBurger = styled.div`
    
    width: 2rem;
    height: 2rem;
    top: 2.5em;
    right: 2em;
    z-index: 20;



    @media (max-width: 700px) {
      display: flex;
      justify-content: space-around;
      flex-flow: column nowrap;
    }
  `;



export {Container, Box, StyledBurger}
