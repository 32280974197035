import { AdminContentBox } from '../../../../../components/style/boxes';
import ConfirmButton from "../../../../../components/admin/confirmButton";
import Title from "../../../../../components/admin/title";

import { UseInstitutionContext } from "../../institutionContext";

import ContentLoader from "react-content-loader";

import { useEffect } from 'react';

import Carousel from "react-multi-carousel";
import { CustomLeftArrow, CustomRightArrow, responsive } from "../configCarroucel";
import Box from "../gallery/boxAlbum/boxAlbum";

import { Link } from 'react-router-dom';

import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

import Api from '../../../../../service/admin/activity';
const api = new Api()

export default function Index() {

    function clique(id) {
        if (atividades.length < 5)
            return
        let x = document.getElementById(id)
        x.click()
    }

    const {
        institutionState: { id, atividades },
        setatividades
    } = UseInstitutionContext();

    async function getAtividades() {
        if (id === "")
            return ""

        let r = await api.listActivity(id)
        setatividades(r)
    }

    const Loader = () => (
        <ContentLoader
            speed={2}
            width={400}
            height={300}
            backgroundColor="#c0c0c0"
            foregroundColor="#ecebeb"
        >
            <rect x="-3" y="-2" rx="2" ry="2" width="187" height="250" />
            <rect x="195" y="-2" rx="2" ry="2" width="187" height="250" />
            <rect x="499" y="52" rx="2" ry="2" width="187" height="250" />
        </ContentLoader>
    );


    async function fDelete(id) {
        let r = await api.deleteActivity(id)
        if (!r.erro) {
            toast.success("Album excluído com sucesso");
            getAtividades();
        } else {
            toast.error("Não foi possível excluir este Album");
        }
    }

    const SNdelete = async (id) => {
        confirmAlert({
            title: "Remover Atividade",
            message: `Tem certeza que deseja remover esta Atividade? `,
            buttons: [
                {
                    label: "Sim",
                    onClick: async () => {
                        fDelete(id);
                    },
                },
                { label: "Não" },
            ],
        });
    };

    useEffect(
        () => { getAtividades() }, 
        // eslint-disable-next-line 
        [id]
    )

    return (
        <div className="actives">
            <AdminContentBox style={{ height: '26em' }}>
                <ConfirmButton />
                <Title>Atividades</Title>
                <div className="box-row">
                    {atividades.length === 0 ? <div><Loader /> <Loader /> <Loader /></div> :
                        <div className="caroucel" >
                            <Carousel
                                customLeftArrow={<CustomLeftArrow />}
                                customRightArrow={<CustomRightArrow id="activesRight" />}
                                showDots={false}
                                containerClass="carousel-container"
                                responsive={responsive}
                                itemClass="item-conteiner"
                                infinite={true}
                            >
                                {atividades.map(i =>
                                    <div style={{ position: "relative" }}>
                                        <img
                                            src="/assets/images/trash-3.svg"
                                            alt=""
                                            onClick={() => SNdelete(i._id)}
                                            className="iconDelete"
                                        />
                                        <Box info={i} idCasa={id} />
                                    </div>
                                )
                                }
                            </Carousel>
                        </div>
                    }
                    <div className="staticBtm">
                        <button className="custom-right-arrow" onClick={() => clique("activesRight")}><img src="/assets/images/Icon metro-arrow-right.svg" alt="" /></button>
                        <Link to={"/admin/atividade"} state={{ idCasa: id }} >
                            <div className="box-more">
                                <img src="/assets/images/Group 403.svg" alt="more-icon" />
                                <div>Novo</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </AdminContentBox>
        </div>
    )
}