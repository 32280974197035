import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    height: 16em;
    background: #FFFFFF;
    box-shadow: 0px 3px 16px rgba(173, 173, 173, 0.25);
    border-radius: 11px;

    display: flex;
    flex-direction: row;

    padding: .5em;
    transform: scale(0.95);
    transition: all ease 0.2s;
    cursor: pointer;
    opacity: 0.5;

    *{
        cursor: pointer;
    }
    :hover{
        transform: scale(1);
    }

    
    .imagem {
        border-radius: 11px;

        background: url(${(props) => props.img});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        
        /* width: 150px;
        min-width: 150px;
        max-width:150px; */
        width: 100%;
        
        background-size: ${(props) => props.bgSize === undefined ? "cover" : props.bgSize};

        /* margin-right: 2em;
        animation: fadeInLeft;
        animation-duration: 2s; */
    }


    @media (min-height: 668px) and (min-width: 1280px) {
        transform: scale(0.869);

        :hover{
        transform: scale(0.935);
        }
    }
`

const Pt2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.3em 1.3em;
    
    .TextTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }
`

const Data = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    font-family: Manrope;
    background-color: #E8EFFF;
    color: #004FFF;
    
    border: solid 0px;
    border-radius: 24px;
    
    height: 31px;
    font-size: .8em;
    font-weight: 500;

    padding: 0px 15px;

    .text {
        border: solid 0px;
    }
`

const Title = styled.div`
    font-family: Manrope;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
`

const Extra = styled.div`
    font-family: Manrope;
    font-size: 1em;
    line-height: 19px;
    font-style: normal;
    font-weight: 400;
    color: #747474;
    cursor: pointer;

    /* :hover {
        text-decoration: underline;
    } */
`

const Casa = styled.div`
    color: #636363;
    font-family: Manrope;
    font-size: 1em;
    margin: 5px 0px;

    cursor: pointer;

    /* :hover {
        text-decoration: underline;
    } */

`


export {Container, Pt2, Data, Title, Extra, Casa}