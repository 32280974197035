import { Container } from "./styled";
import BoxEvents from "../../../components/commum/box-lectures";
import CustomButtonGroupAsArrows from "../../../components/commum/carouselButtonExternal/";
import Carousel from "react-multi-carousel";
import { responsive } from "./configCarousel";
import { useState, useEffect } from "react";
import Modal from "../../../components/commum/modal";
import ReactPlayer from "react-player";
import { API_URL } from "../../../service/config";

import ApiLectures from "../../../service/home/events/eventOne.js";
import ContentLoader from "react-content-loader";
import loaders from "../../../components/commum/loaders";
import wait from "../../../components/commum/wait";

const apiLectures = new ApiLectures();

export default function Index(props) {
  const [lectures, setlectures] = useState([]);
  const [loading, setLoading] = useState(true);

  const [thisModal, setThisModal] = useState({ show: false });
  const [link, setLink] = useState("");


  async function fListLectures() {
    await wait(1500);

    let r = await apiLectures.listLectures();
    setlectures(r);
    setLoading(false);
  }


  function getResolution() {
    if (window.devicePixelRatio > 1.25) return window.screen.width;
    else return window.screen.width * window.devicePixelRatio;
  }

  function OpenModal(infos) {
    setLink(infos.link);
  }
  
  useEffect(() => {
    if (link) {
      setThisModal({ show: !thisModal.show });
    }
  }, [link])


  useEffect(() => {
    fListLectures();
  }, []);


  return (
    <Container
      id={props.id}
    >
      {!loading && lectures.length == 0
        ? ''
        : <>
          <Modal open={() => setThisModal({show: false})} options={thisModal}>
            <ReactPlayer
              key={link}
              width="100%"
              height="100%"
              url={link}
              playing={thisModal.show}
            />
          </Modal>
          <Carousel
            responsive={responsive}
            centerMode={getResolution() <= 1280 ? false : true}
            infinite={getResolution() <= 1280 ? false : true}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            arrows={false}
            renderDotsOutside={false}
            renderButtonGroupOutside={true}
            containerClass="carousel-container"
            customButtonGroup={
              <CustomButtonGroupAsArrows titulo="Estudos Online" />
            }
          >
            {loading
              ?
              loaders()
              :
              lectures.map((i, index) =>
                <BoxEvents
                  image={`${API_URL}/website/busca?imagem=${i.info.imagem.replace(
                    /\\/g,
                    "/"
                  )}`}
                  key={index}
                  open={OpenModal}
                  record={true}
                  {...i}
                />
              )}
          </Carousel>

        </>
      }


    </Container>
  );
}
