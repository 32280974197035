import { Container } from "./styled";
import Modal from "../../../components/commum/modal";

import BoxEvents from "../../../components/commum/box-events";

import CustomButtonGroupAsArrows from "../../../components/commum/carouselButtonExternal";
import Carousel from "react-multi-carousel";
import { responsive } from "./configCarousel";

import { useEffect, useState } from "react";

import ReactPlayer from "react-player";

import { API_URL } from "../../../service/config";

import Api from "../../../service/notice/notices";
const api = new Api();

export default function App(props) {
  const [nameActivities] = useState(props.atividade);
  const data = props.data;

  const [apiResponse, setApiResponse] = useState([]);

  const [thisModal, setThisModal] = useState({ show: false });
  const [link, setLink] = useState("");

  function getResolution() {
    if (window.devicePixelRatio > 1.25) return window.screen.width;
    else return window.screen.width * window.devicePixelRatio;
  }

  function OpenModal(infos) {
    setLink(infos.link);
    setThisModal({ show: !thisModal.show });
  }

  async function getNews() {
    if(data){
      setApiResponse(data);
      return;
    }

    let r = await api.listNews(props.house, nameActivities);
    setApiResponse(r);
  }

  useEffect(() => {
    getNews();
    // eslint-disable-next-line 
  }, [nameActivities]);

  return (
    <Container id={props.id}>
      <Modal open={() => setThisModal(false)} options={thisModal}>
        <ReactPlayer
          width="100%"
          height="100%"
          url={link}
          playing={thisModal.show}
        />
      </Modal>
      <Carousel
        responsive={responsive}
        centerMode={getResolution() <= 1280 ? false : true}
        showDots={false}
        arrows={false}
        containerClass="carousel-container"
        renderDotsOutside={false}
        renderButtonGroupOutside={true}
        infinite={getResolution() <= 1280 ? false : true}
        customButtonGroup={
          <CustomButtonGroupAsArrows titulo="O que tem acontecido ?" />
        }
      >
        {apiResponse.map((i, index) => (
          <BoxEvents
            image={`${API_URL}/website/busca?imagem=${i.imagens[0].replace(
              /\\/g,
              "/"
            )}`}
            open={OpenModal}
            url={i.link}
            data={i.data}
            title={i.titulo}
            record={false}
            casa={i.categoria}
            all={i}
            key={index}
          />
        ))}
      </Carousel>
    </Container>
  );
}
