import Container from "./styled";

export default function Index(props){
  return(
    <Container presentDay={props.presentDay}>
      <h2>{props.day}</h2>
      {props.hours[0] === undefined || props.hours === ""
        ? <span>-</span>
        : <span>
          {props.hours.map(i =>
            <div dangerouslySetInnerHTML={{__html: i.Descricao}}></div>
          )}
        </span>
      }
    </Container>
  );
}